//$navbar-item-img-max-height: 20px;
$body-background-color: $primary;
$oblique-style: oblique 35deg;
$light-blue: #0196ff;
$text-shadow-heavy: 1px 1px #050042,
               2px 2px #050042,
               3px 3px #050042,
               4px 4px #050042;
$text-shadow-medium: 1px 1px #050042,
               2px 2px #050042;
$text-shadow-light: 1px 1px #050042;

.hero-title {
  @extend .is-spaced;
  @extend .mt-2;
}

body {
  .content {
    figure {
      margin-left: 0;
      margin-right: 0;
      img {
        margin-bottom: -7em;
        //clip-path: circle(30.67% at center center)
        //clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);
        //clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 84% 78%, 49% 89%, 16% 78%, 0% 75%);
        //clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 65% 75%, 49% 89%, 35% 75%, 0% 75%);
        clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 89%, 58% 89%, 33% 65%, 0 65%);
      }
    }
  }
}

h4 {
  font-size: 1.25em;
  font-weight: 800;
  @extend .mb-3;
}

p {
  @extend .mb-3;
}

placeholder {
  color: $black;
}


.content {
  h2:not(:first-child) {
    color: $light-blue;
    text-shadow: $text-shadow-medium;
  }
}

@keyframes rotateGradient {
  0% {
    background-position: bottom right
  }
  to {
    background-position: top left
  }
}

/* By default, make the column responsive */
.column.is-fixed-width {
  flex: 1;
  max-width: 100%;
}

/* Apply fixed-width for screens larger than 780px */
@media screen and (min-width: 781px) {
  .column.is-fixed-width {
    flex: none;
    width: 780px;
    max-width: 780px;
  }
}

.valign-middle {
    vertical-align: middle;
}

#logo {
  img {
  }
  span {
    @extend .ml-2;
    line-height: 5rem;
    font-size: .8em;
    white-space: nowrap;
  }
}

.above-title {
  font-weight: 800;
  font-style: $oblique-style;
}

a {
  //color: #6f99ff;
  //color: $white;
}

a:hover {
  text-shadow: none;
}

strong {
  font-style: $oblique-style;
}


h1 {
  @extend .is-size-1;
  @extend .title;
  font-style: $oblique-style;
  //@extend .has-text-white;
}

h2 {
  @extend .is-size-2;
  @extend .title;
  font-style: $oblique-style;
  //@extend .has-text-white;
}

h3 {
  @extend .is-size-3;
  @extend .title;
  font-style: $oblique-style;
  //@extend .has-text-white;
}

ul {
  @extend .mb-3;
  list-style: circle;
}

.title {
  word-break: normal;
}

.subtitle {
  font-style: $oblique-style;
  margin-bottom: 1em;
}

.table-of-contents {
  ul {
    margin-left: 0.25em;
  }

  li {
    @extend .has-text-weight-medium;
    margin-left: 0;
  }
}

.has-background-dark {
  color: $white;

  a, strong {
    color: $white;
  }

  .hero-title {
    animation: rotateGradient 1s cubic-bezier(.42,0,.58,1) forwards;
    background-size: 100% 150%;
    background-image: linear-gradient(to bottom right, $blue 50%,#fff 50%);
    background-clip: text;
    //text-shadow: .015em .035em 0 #fbfbfb, -.015em -.015em 1em $blue;
    text-shadow: $text-shadow-light;
    font-style: $oblique-style;
    font-weight: 800;
  }

  .subtitle {
    text-shadow: $text-shadow-light;
    color: $white;
    margin-bottom: 0em;
    @extend .has-text-light;
  }

  .navbar {
    button {
      padding-top: 3.5em;
    }
  }

  .navbar-item {
    text-shadow: $text-shadow-light;
  }

  .button-call-to-action {
    animation: rotateGradient 1s cubic-bezier(.42,0,.58,1) forwards;
    background-size: 100% 150%;
    background-image: linear-gradient(to bottom right, rgba(0, 0, 255, 0.2) 50%, transparent 50%);
    text-shadow: $text-shadow-light;
    border-radius: 9px;
    background-color: $blue;
    border-color: $blue;
    font-size: 1.2rem;
    font-weight: 800;
    font-style: $oblique-style;
    color: $white;
    opacity: 1;
  }

  .button-call-to-action:hover {
    color: $white;
    opacity: .8;
  }

  h2, h3 {
    text-shadow: $text-shadow-light;
  }
}

a:hover {
  text-decoration: underline;
  text-shadow: none;
}

.has-background-white {
  .hero-title {
    @extend .title;
    animation: rotateGradient 1s cubic-bezier(.42,0,.58,1) forwards;
    background-size: 100% 150%;
    background-image: linear-gradient(to bottom right, $blue 50%, $dark 50%);
    background-clip: text;
    text-shadow: $text-shadow-heavy;
    font-style: $oblique-style;
    font-weight: 800;
    color: $light-blue;
  }
}


.table-of-contents {
  ul {
    list-style-type: disc;
    margin-left: 0;
    padding-left: 0.25em;
  }

  /* First-level ul */
  ul > li > ul {
    margin-left: 1em;
  }

  /* Second-level ul */
  ul > li > ul > li > ul {
    margin-left: 1em;
  }

  /* Third-level ul (and deeper) */
  ul ul ul {
    margin-left: 1em;
  }
}

.hero-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hero.is-fullheight-with-navbar {
  min-height: calc(100vh - 12rem);
}
