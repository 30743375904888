.pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis, .file-cta, .file-name, .select select, .textarea, .input, .button {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
  vertical-align: top;
  border: 1px solid #0000;
  border-radius: 0;
  justify-content: flex-start;
  align-items: center;
  height: 3.3em;
  padding: calc(.5em - 1px) calc(.75em - 1px);
  font-size: .875rem;
  line-height: 1.5;
  display: inline-flex;
  position: relative;
}

.pagination-previous:focus, .pagination-next:focus, .pagination-link:focus, .pagination-ellipsis:focus, .file-cta:focus, .file-name:focus, .select select:focus, .textarea:focus, .input:focus, .button:focus, .is-focused.pagination-previous, .is-focused.pagination-next, .is-focused.pagination-link, .is-focused.pagination-ellipsis, .is-focused.file-cta, .is-focused.file-name, .select select.is-focused, .is-focused.textarea, .is-focused.input, .is-focused.button, .pagination-previous:active, .pagination-next:active, .pagination-link:active, .pagination-ellipsis:active, .file-cta:active, .file-name:active, .select select:active, .textarea:active, .input:active, .button:active, .is-active.pagination-previous, .is-active.pagination-next, .is-active.pagination-link, .is-active.pagination-ellipsis, .is-active.file-cta, .is-active.file-name, .select select.is-active, .is-active.textarea, .is-active.input, .is-active.button {
  outline: none;
}

[disabled].pagination-previous, [disabled].pagination-next, [disabled].pagination-link, [disabled].pagination-ellipsis, [disabled].file-cta, [disabled].file-name, .select select[disabled], [disabled].textarea, [disabled].input, [disabled].button, fieldset[disabled] .pagination-previous, fieldset[disabled] .pagination-next, fieldset[disabled] .pagination-link, fieldset[disabled] .pagination-ellipsis, fieldset[disabled] .file-cta, fieldset[disabled] .file-name, fieldset[disabled] .select select, .select fieldset[disabled] select, fieldset[disabled] .textarea, fieldset[disabled] .input, fieldset[disabled] .button {
  cursor: not-allowed;
}

.is-unselectable, .tabs, .pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis, .breadcrumb, .file, .button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.navbar-link:not(.is-arrowless):after, .select:not(.is-multiple):not(.is-loading):after {
  content: " ";
  pointer-events: none;
  transform-origin: center;
  border-top: 0;
  border-bottom: 3px solid #0000;
  border-left: 3px solid #0000;
  border-right: 0;
  border-radius: 2px;
  width: .625em;
  height: .625em;
  margin-top: -.4375em;
  display: block;
  position: absolute;
  top: 50%;
  transform: rotate(-45deg);
}

.tabs:not(:last-child), .pagination:not(:last-child), .message:not(:last-child), .level:not(:last-child), .breadcrumb:not(:last-child), .block:not(:last-child), .title:not(:last-child), .has-background-white .hero-title:not(:last-child), h3:not(:last-child), h2:not(:last-child), h1:not(:last-child), .subtitle:not(:last-child), .table-container:not(:last-child), .table:not(:last-child), .progress:not(:last-child), .notification:not(:last-child), .content:not(:last-child), .box:not(:last-child) {
  margin-bottom: 1.5rem;
}

.modal-close, .delete {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  pointer-events: auto;
  vertical-align: top;
  background-color: #0a0a0a33;
  border: none;
  border-radius: 9999px;
  outline: none;
  flex-grow: 0;
  flex-shrink: 0;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.modal-close:before, .delete:before, .modal-close:after, .delete:after {
  content: "";
  transform-origin: center;
  background-color: #fff;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.modal-close:before, .delete:before {
  width: 50%;
  height: 2px;
}

.modal-close:after, .delete:after {
  width: 2px;
  height: 50%;
}

.modal-close:hover, .delete:hover, .modal-close:focus, .delete:focus {
  background-color: #0a0a0a4d;
}

.modal-close:active, .delete:active {
  background-color: #0a0a0a66;
}

.is-small.modal-close, .is-small.delete {
  width: 16px;
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
}

.is-medium.modal-close, .is-medium.delete {
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
}

.is-large.modal-close, .is-large.delete {
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
}

.control.is-loading:after, .select.is-loading:after, .loader, .button.is-loading:after {
  content: "";
  border: 2px solid #0000;
  border-color: #0000 #0000 #d6d3d1 #d6d3d1;
  border-radius: 9999px;
  width: 1em;
  height: 1em;
  animation: .5s linear infinite spinAround;
  display: block;
  position: relative;
}

.hero-video, .is-overlay, .modal-background, .modal, .image.is-square img, .image.is-square .has-ratio, .image.is-1by1 img, .image.is-1by1 .has-ratio, .image.is-5by4 img, .image.is-5by4 .has-ratio, .image.is-4by3 img, .image.is-4by3 .has-ratio, .image.is-3by2 img, .image.is-3by2 .has-ratio, .image.is-5by3 img, .image.is-5by3 .has-ratio, .image.is-16by9 img, .image.is-16by9 .has-ratio, .image.is-2by1 img, .image.is-2by1 .has-ratio, .image.is-3by1 img, .image.is-3by1 .has-ratio, .image.is-4by5 img, .image.is-4by5 .has-ratio, .image.is-3by4 img, .image.is-3by4 .has-ratio, .image.is-2by3 img, .image.is-2by3 .has-ratio, .image.is-3by5 img, .image.is-3by5 .has-ratio, .image.is-9by16 img, .image.is-9by16 .has-ratio, .image.is-1by2 img, .image.is-1by2 .has-ratio, .image.is-1by3 img, .image.is-1by3 .has-ratio {
  position: absolute;
  inset: 0;
}

.navbar-burger {
  -webkit-appearance: none;
  appearance: none;
  color: currentColor;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 1em;
}

html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

button, input, select, textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

img, video {
  max-width: 100%;
  height: auto;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

td:not([align]), th:not([align]) {
  text-align: inherit;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background-color: #fff;
  min-width: 300px;
  font-size: 16px;
  overflow: hidden scroll;
}

article, aside, figure, footer, header, hgroup, section {
  display: block;
}

body, button, input, optgroup, select, textarea {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, DM Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

code, pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: monospace;
}

body {
  color: #44403c;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
}

a {
  color: #004bff;
  cursor: pointer;
  text-decoration: none;
}

a strong {
  color: currentColor;
}

a:hover {
  color: #004bff;
}

code {
  color: #f22104;
  background-color: #fafaf9;
  padding: .25em .5em;
  font-size: .875em;
  font-weight: normal;
}

hr {
  background-color: #fafaf9;
  border: none;
  height: 2px;
  margin: 1.5rem 0;
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

input[type="checkbox"], input[type="radio"] {
  vertical-align: baseline;
}

small {
  font-size: .875em;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  color: #292524;
  font-weight: 700;
}

fieldset {
  border: none;
}

pre {
  -webkit-overflow-scrolling: touch;
  color: #44403c;
  white-space: pre;
  word-wrap: normal;
  background-color: #fafaf9;
  padding: 1.25rem 1.5rem;
  font-size: .875em;
  overflow-x: auto;
}

pre code {
  color: currentColor;
  background-color: #0000;
  padding: 0;
  font-size: 1em;
}

table td, table th {
  vertical-align: top;
}

table td:not([align]), table th:not([align]) {
  text-align: inherit;
}

table th {
  color: #292524;
}

@keyframes spinAround {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.box {
  box-shadow: none;
  color: #44403c;
  background-color: #fff;
  border-radius: 0;
  padding: 1.25rem;
  display: block;
}

a.box:hover, a.box:focus {
  box-shadow: 0 .5em 1em -.125em #0a0a0a1a, 0 0 0 1px #004bff;
}

a.box:active {
  box-shadow: inset 0 1px 2px #0a0a0a33, 0 0 0 1px #004bff;
}

.button {
  color: #292524;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border-width: 1px;
  border-color: #e7e5e4;
  justify-content: center;
  padding: calc(.9em - 1px) 1.5rem;
}

.button strong {
  color: inherit;
}

.button .icon, .button .icon.is-small, .button .icon.is-medium, .button .icon.is-large {
  width: 1.5em;
  height: 1.5em;
}

.button .icon:first-child:not(:last-child) {
  margin-left: calc(-.75rem - 1px);
  margin-right: .375rem;
}

.button .icon:last-child:not(:first-child) {
  margin-left: .375rem;
  margin-right: calc(-.75rem - 1px);
}

.button .icon:first-child:last-child {
  margin-left: calc(-.75rem - 1px);
  margin-right: calc(-.75rem - 1px);
}

.button:hover, .button.is-hovered, .button:focus, .button.is-focused {
  color: #004bff;
  border-color: #004e41;
}

.button:focus:not(:active), .button.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #004bff40;
}

.button:active, .button.is-active {
  color: #004bff;
  border-color: #004e41;
}

.button.is-text {
  color: #44403c;
  background-color: #0000;
  border-color: #0000;
  text-decoration: underline;
}

.button.is-text:hover, .button.is-text.is-hovered, .button.is-text:focus, .button.is-text.is-focused {
  color: #292524;
  background-color: #fafaf9;
}

.button.is-text:active, .button.is-text.is-active {
  color: #292524;
  background-color: #eeeeeb;
}

.button.is-text[disabled], fieldset[disabled] .button.is-text {
  box-shadow: none;
  background-color: #0000;
  border-color: #0000;
}

.button.is-ghost {
  color: #004bff;
  background: none;
  border-color: #0000;
  text-decoration: none;
}

.button.is-ghost:hover, .button.is-ghost.is-hovered {
  color: #004bff;
  text-decoration: underline;
}

.button.is-white {
  color: #222;
  background-color: #fff;
  border-color: #0000;
}

.button.is-white:hover, .button.is-white.is-hovered {
  color: #222;
  background-color: #f9f9f9;
  border-color: #0000;
}

.button.is-white:focus, .button.is-white.is-focused {
  color: #222;
  border-color: #0000;
}

.button.is-white:focus:not(:active), .button.is-white.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #ffffff40;
}

.button.is-white:active, .button.is-white.is-active {
  color: #222;
  background-color: #f2f2f2;
  border-color: #0000;
}

.button.is-white[disabled], fieldset[disabled] .button.is-white {
  box-shadow: none;
  background-color: #fff;
  border-color: #fff;
}

.button.is-white.is-inverted {
  color: #fff;
  background-color: #222;
}

.button.is-white.is-inverted:hover, .button.is-white.is-inverted.is-hovered {
  background-color: #151515;
}

.button.is-white.is-inverted[disabled], fieldset[disabled] .button.is-white.is-inverted {
  box-shadow: none;
  color: #fff;
  background-color: #222;
  border-color: #0000;
}

.button.is-white.is-loading:after {
  border-color: #0000 #0000 #222 #222 !important;
}

.button.is-white.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-white.is-outlined:hover, .button.is-white.is-outlined.is-hovered, .button.is-white.is-outlined:focus, .button.is-white.is-outlined.is-focused {
  color: #222;
  background-color: #fff;
  border-color: #fff;
}

.button.is-white.is-outlined.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-white.is-outlined.is-loading:hover:after, .button.is-white.is-outlined.is-loading.is-hovered:after, .button.is-white.is-outlined.is-loading:focus:after, .button.is-white.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #222 #222 !important;
}

.button.is-white.is-outlined[disabled], fieldset[disabled] .button.is-white.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-white.is-inverted.is-outlined {
  color: #222;
  background-color: #0000;
  border-color: #222;
}

.button.is-white.is-inverted.is-outlined:hover, .button.is-white.is-inverted.is-outlined.is-hovered, .button.is-white.is-inverted.is-outlined:focus, .button.is-white.is-inverted.is-outlined.is-focused {
  color: #fff;
  background-color: #222;
}

.button.is-white.is-inverted.is-outlined.is-loading:hover:after, .button.is-white.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-white.is-inverted.is-outlined.is-loading:focus:after, .button.is-white.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-white.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-white.is-inverted.is-outlined {
  box-shadow: none;
  color: #222;
  background-color: #0000;
  border-color: #222;
}

.button.is-black {
  color: #fff;
  background-color: #222;
  border-color: #0000;
}

.button.is-black:hover, .button.is-black.is-hovered {
  color: #fff;
  background-color: #1c1c1c;
  border-color: #0000;
}

.button.is-black:focus, .button.is-black.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-black:focus:not(:active), .button.is-black.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #22222240;
}

.button.is-black:active, .button.is-black.is-active {
  color: #fff;
  background-color: #151515;
  border-color: #0000;
}

.button.is-black[disabled], fieldset[disabled] .button.is-black {
  box-shadow: none;
  background-color: #222;
  border-color: #222;
}

.button.is-black.is-inverted {
  color: #222;
  background-color: #fff;
}

.button.is-black.is-inverted:hover, .button.is-black.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-black.is-inverted[disabled], fieldset[disabled] .button.is-black.is-inverted {
  box-shadow: none;
  color: #222;
  background-color: #fff;
  border-color: #0000;
}

.button.is-black.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-black.is-outlined {
  color: #222;
  background-color: #0000;
  border-color: #222;
}

.button.is-black.is-outlined:hover, .button.is-black.is-outlined.is-hovered, .button.is-black.is-outlined:focus, .button.is-black.is-outlined.is-focused {
  color: #fff;
  background-color: #222;
  border-color: #222;
}

.button.is-black.is-outlined.is-loading:after {
  border-color: #0000 #0000 #222 #222 !important;
}

.button.is-black.is-outlined.is-loading:hover:after, .button.is-black.is-outlined.is-loading.is-hovered:after, .button.is-black.is-outlined.is-loading:focus:after, .button.is-black.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-black.is-outlined[disabled], fieldset[disabled] .button.is-black.is-outlined {
  box-shadow: none;
  color: #222;
  background-color: #0000;
  border-color: #222;
}

.button.is-black.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-black.is-inverted.is-outlined:hover, .button.is-black.is-inverted.is-outlined.is-hovered, .button.is-black.is-inverted.is-outlined:focus, .button.is-black.is-inverted.is-outlined.is-focused {
  color: #222;
  background-color: #fff;
}

.button.is-black.is-inverted.is-outlined.is-loading:hover:after, .button.is-black.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-black.is-inverted.is-outlined.is-loading:focus:after, .button.is-black.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #222 #222 !important;
}

.button.is-black.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-black.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-light {
  color: #000000b3;
  background-color: #f5f5f4;
  border-color: #0000;
}

.button.is-light:hover, .button.is-light.is-hovered {
  color: #000000b3;
  background-color: #efefed;
  border-color: #0000;
}

.button.is-light:focus, .button.is-light.is-focused {
  color: #000000b3;
  border-color: #0000;
}

.button.is-light:focus:not(:active), .button.is-light.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #f5f5f440;
}

.button.is-light:active, .button.is-light.is-active {
  color: #000000b3;
  background-color: #e9e9e7;
  border-color: #0000;
}

.button.is-light[disabled], fieldset[disabled] .button.is-light {
  box-shadow: none;
  background-color: #f5f5f4;
  border-color: #f5f5f4;
}

.button.is-light.is-inverted {
  color: #f5f5f4;
  background-color: #000000b3;
}

.button.is-light.is-inverted:hover, .button.is-light.is-inverted.is-hovered {
  background-color: #000000b3;
}

.button.is-light.is-inverted[disabled], fieldset[disabled] .button.is-light.is-inverted {
  box-shadow: none;
  color: #f5f5f4;
  background-color: #000000b3;
  border-color: #0000;
}

.button.is-light.is-loading:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-light.is-outlined {
  color: #f5f5f4;
  background-color: #0000;
  border-color: #f5f5f4;
}

.button.is-light.is-outlined:hover, .button.is-light.is-outlined.is-hovered, .button.is-light.is-outlined:focus, .button.is-light.is-outlined.is-focused {
  color: #000000b3;
  background-color: #f5f5f4;
  border-color: #f5f5f4;
}

.button.is-light.is-outlined.is-loading:after {
  border-color: #0000 #0000 #f5f5f4 #f5f5f4 !important;
}

.button.is-light.is-outlined.is-loading:hover:after, .button.is-light.is-outlined.is-loading.is-hovered:after, .button.is-light.is-outlined.is-loading:focus:after, .button.is-light.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-light.is-outlined[disabled], fieldset[disabled] .button.is-light.is-outlined {
  box-shadow: none;
  color: #f5f5f4;
  background-color: #0000;
  border-color: #f5f5f4;
}

.button.is-light.is-inverted.is-outlined {
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-light.is-inverted.is-outlined:hover, .button.is-light.is-inverted.is-outlined.is-hovered, .button.is-light.is-inverted.is-outlined:focus, .button.is-light.is-inverted.is-outlined.is-focused {
  color: #f5f5f4;
  background-color: #000000b3;
}

.button.is-light.is-inverted.is-outlined.is-loading:hover:after, .button.is-light.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-light.is-inverted.is-outlined.is-loading:focus:after, .button.is-light.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #f5f5f4 #f5f5f4 !important;
}

.button.is-light.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-light.is-inverted.is-outlined {
  box-shadow: none;
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-dark {
  color: #fff;
  background-color: #004bff;
  border-color: #0000;
}

.button.is-dark:hover, .button.is-dark.is-hovered {
  color: #fff;
  background-color: #0047f2;
  border-color: #0000;
}

.button.is-dark:focus, .button.is-dark.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-dark:focus:not(:active), .button.is-dark.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #004bff40;
}

.button.is-dark:active, .button.is-dark.is-active {
  color: #fff;
  background-color: #0044e6;
  border-color: #0000;
}

.button.is-dark[disabled], fieldset[disabled] .button.is-dark {
  box-shadow: none;
  background-color: #004bff;
  border-color: #004bff;
}

.button.is-dark.is-inverted {
  color: #004bff;
  background-color: #fff;
}

.button.is-dark.is-inverted:hover, .button.is-dark.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-dark.is-inverted[disabled], fieldset[disabled] .button.is-dark.is-inverted {
  box-shadow: none;
  color: #004bff;
  background-color: #fff;
  border-color: #0000;
}

.button.is-dark.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-dark.is-outlined {
  color: #004bff;
  background-color: #0000;
  border-color: #004bff;
}

.button.is-dark.is-outlined:hover, .button.is-dark.is-outlined.is-hovered, .button.is-dark.is-outlined:focus, .button.is-dark.is-outlined.is-focused {
  color: #fff;
  background-color: #004bff;
  border-color: #004bff;
}

.button.is-dark.is-outlined.is-loading:after {
  border-color: #0000 #0000 #004bff #004bff !important;
}

.button.is-dark.is-outlined.is-loading:hover:after, .button.is-dark.is-outlined.is-loading.is-hovered:after, .button.is-dark.is-outlined.is-loading:focus:after, .button.is-dark.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-dark.is-outlined[disabled], fieldset[disabled] .button.is-dark.is-outlined {
  box-shadow: none;
  color: #004bff;
  background-color: #0000;
  border-color: #004bff;
}

.button.is-dark.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-dark.is-inverted.is-outlined:hover, .button.is-dark.is-inverted.is-outlined.is-hovered, .button.is-dark.is-inverted.is-outlined:focus, .button.is-dark.is-inverted.is-outlined.is-focused {
  color: #004bff;
  background-color: #fff;
}

.button.is-dark.is-inverted.is-outlined.is-loading:hover:after, .button.is-dark.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-dark.is-inverted.is-outlined.is-loading:focus:after, .button.is-dark.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #004bff #004bff !important;
}

.button.is-dark.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-dark.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-primary {
  color: #fff;
  background-color: #004bff;
  border-color: #0000;
}

.button.is-primary:hover, .button.is-primary.is-hovered {
  color: #fff;
  background-color: #0047f2;
  border-color: #0000;
}

.button.is-primary:focus, .button.is-primary.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-primary:focus:not(:active), .button.is-primary.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #004bff40;
}

.button.is-primary:active, .button.is-primary.is-active {
  color: #fff;
  background-color: #0044e6;
  border-color: #0000;
}

.button.is-primary[disabled], fieldset[disabled] .button.is-primary {
  box-shadow: none;
  background-color: #004bff;
  border-color: #004bff;
}

.button.is-primary.is-inverted {
  color: #004bff;
  background-color: #fff;
}

.button.is-primary.is-inverted:hover, .button.is-primary.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-primary.is-inverted[disabled], fieldset[disabled] .button.is-primary.is-inverted {
  box-shadow: none;
  color: #004bff;
  background-color: #fff;
  border-color: #0000;
}

.button.is-primary.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-primary.is-outlined {
  color: #004bff;
  background-color: #0000;
  border-color: #004bff;
}

.button.is-primary.is-outlined:hover, .button.is-primary.is-outlined.is-hovered, .button.is-primary.is-outlined:focus, .button.is-primary.is-outlined.is-focused {
  color: #fff;
  background-color: #004bff;
  border-color: #004bff;
}

.button.is-primary.is-outlined.is-loading:after {
  border-color: #0000 #0000 #004bff #004bff !important;
}

.button.is-primary.is-outlined.is-loading:hover:after, .button.is-primary.is-outlined.is-loading.is-hovered:after, .button.is-primary.is-outlined.is-loading:focus:after, .button.is-primary.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-primary.is-outlined[disabled], fieldset[disabled] .button.is-primary.is-outlined {
  box-shadow: none;
  color: #004bff;
  background-color: #0000;
  border-color: #004bff;
}

.button.is-primary.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-primary.is-inverted.is-outlined:hover, .button.is-primary.is-inverted.is-outlined.is-hovered, .button.is-primary.is-inverted.is-outlined:focus, .button.is-primary.is-inverted.is-outlined.is-focused {
  color: #004bff;
  background-color: #fff;
}

.button.is-primary.is-inverted.is-outlined.is-loading:hover:after, .button.is-primary.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-primary.is-inverted.is-outlined.is-loading:focus:after, .button.is-primary.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #004bff #004bff !important;
}

.button.is-primary.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-primary.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-primary.is-light {
  color: #004afa;
  background-color: #ebf1ff;
}

.button.is-primary.is-light:hover, .button.is-primary.is-light.is-hovered {
  color: #004afa;
  background-color: #dee8ff;
  border-color: #0000;
}

.button.is-primary.is-light:active, .button.is-primary.is-light.is-active {
  color: #004afa;
  background-color: #d1dfff;
  border-color: #0000;
}

.button.is-link {
  color: #fff;
  background-color: #004bff;
  border-color: #0000;
}

.button.is-link:hover, .button.is-link.is-hovered {
  color: #fff;
  background-color: #0047f2;
  border-color: #0000;
}

.button.is-link:focus, .button.is-link.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-link:focus:not(:active), .button.is-link.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #004bff40;
}

.button.is-link:active, .button.is-link.is-active {
  color: #fff;
  background-color: #0044e6;
  border-color: #0000;
}

.button.is-link[disabled], fieldset[disabled] .button.is-link {
  box-shadow: none;
  background-color: #004bff;
  border-color: #004bff;
}

.button.is-link.is-inverted {
  color: #004bff;
  background-color: #fff;
}

.button.is-link.is-inverted:hover, .button.is-link.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-link.is-inverted[disabled], fieldset[disabled] .button.is-link.is-inverted {
  box-shadow: none;
  color: #004bff;
  background-color: #fff;
  border-color: #0000;
}

.button.is-link.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-link.is-outlined {
  color: #004bff;
  background-color: #0000;
  border-color: #004bff;
}

.button.is-link.is-outlined:hover, .button.is-link.is-outlined.is-hovered, .button.is-link.is-outlined:focus, .button.is-link.is-outlined.is-focused {
  color: #fff;
  background-color: #004bff;
  border-color: #004bff;
}

.button.is-link.is-outlined.is-loading:after {
  border-color: #0000 #0000 #004bff #004bff !important;
}

.button.is-link.is-outlined.is-loading:hover:after, .button.is-link.is-outlined.is-loading.is-hovered:after, .button.is-link.is-outlined.is-loading:focus:after, .button.is-link.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-link.is-outlined[disabled], fieldset[disabled] .button.is-link.is-outlined {
  box-shadow: none;
  color: #004bff;
  background-color: #0000;
  border-color: #004bff;
}

.button.is-link.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-link.is-inverted.is-outlined:hover, .button.is-link.is-inverted.is-outlined.is-hovered, .button.is-link.is-inverted.is-outlined:focus, .button.is-link.is-inverted.is-outlined.is-focused {
  color: #004bff;
  background-color: #fff;
}

.button.is-link.is-inverted.is-outlined.is-loading:hover:after, .button.is-link.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-link.is-inverted.is-outlined.is-loading:focus:after, .button.is-link.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #004bff #004bff !important;
}

.button.is-link.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-link.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-link.is-light {
  color: #004afa;
  background-color: #ebf1ff;
}

.button.is-link.is-light:hover, .button.is-link.is-light.is-hovered {
  color: #004afa;
  background-color: #dee8ff;
  border-color: #0000;
}

.button.is-link.is-light:active, .button.is-link.is-light.is-active {
  color: #004afa;
  background-color: #d1dfff;
  border-color: #0000;
}

.button.is-info {
  color: #fff;
  background-color: #56a2bd;
  border-color: #0000;
}

.button.is-info:hover, .button.is-info.is-hovered {
  color: #fff;
  background-color: #4d9db9;
  border-color: #0000;
}

.button.is-info:focus, .button.is-info.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-info:focus:not(:active), .button.is-info.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #56a2bd40;
}

.button.is-info:active, .button.is-info.is-active {
  color: #fff;
  background-color: #4697b3;
  border-color: #0000;
}

.button.is-info[disabled], fieldset[disabled] .button.is-info {
  box-shadow: none;
  background-color: #56a2bd;
  border-color: #56a2bd;
}

.button.is-info.is-inverted {
  color: #56a2bd;
  background-color: #fff;
}

.button.is-info.is-inverted:hover, .button.is-info.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-info.is-inverted[disabled], fieldset[disabled] .button.is-info.is-inverted {
  box-shadow: none;
  color: #56a2bd;
  background-color: #fff;
  border-color: #0000;
}

.button.is-info.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-info.is-outlined {
  color: #56a2bd;
  background-color: #0000;
  border-color: #56a2bd;
}

.button.is-info.is-outlined:hover, .button.is-info.is-outlined.is-hovered, .button.is-info.is-outlined:focus, .button.is-info.is-outlined.is-focused {
  color: #fff;
  background-color: #56a2bd;
  border-color: #56a2bd;
}

.button.is-info.is-outlined.is-loading:after {
  border-color: #0000 #0000 #56a2bd #56a2bd !important;
}

.button.is-info.is-outlined.is-loading:hover:after, .button.is-info.is-outlined.is-loading.is-hovered:after, .button.is-info.is-outlined.is-loading:focus:after, .button.is-info.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-info.is-outlined[disabled], fieldset[disabled] .button.is-info.is-outlined {
  box-shadow: none;
  color: #56a2bd;
  background-color: #0000;
  border-color: #56a2bd;
}

.button.is-info.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-info.is-inverted.is-outlined:hover, .button.is-info.is-inverted.is-outlined.is-hovered, .button.is-info.is-inverted.is-outlined:focus, .button.is-info.is-inverted.is-outlined.is-focused {
  color: #56a2bd;
  background-color: #fff;
}

.button.is-info.is-inverted.is-outlined.is-loading:hover:after, .button.is-info.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-info.is-inverted.is-outlined.is-loading:focus:after, .button.is-info.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #56a2bd #56a2bd !important;
}

.button.is-info.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-info.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-info.is-light {
  color: #357288;
  background-color: #f0f7f9;
}

.button.is-info.is-light:hover, .button.is-info.is-light.is-hovered {
  color: #357288;
  background-color: #e7f2f6;
  border-color: #0000;
}

.button.is-info.is-light:active, .button.is-info.is-light.is-active {
  color: #357288;
  background-color: #deedf2;
  border-color: #0000;
}

.button.is-success {
  color: #fff;
  background-color: #65b996;
  border-color: #0000;
}

.button.is-success:hover, .button.is-success.is-hovered {
  color: #fff;
  background-color: #5cb590;
  border-color: #0000;
}

.button.is-success:focus, .button.is-success.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-success:focus:not(:active), .button.is-success.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #65b99640;
}

.button.is-success:active, .button.is-success.is-active {
  color: #fff;
  background-color: #53b18a;
  border-color: #0000;
}

.button.is-success[disabled], fieldset[disabled] .button.is-success {
  box-shadow: none;
  background-color: #65b996;
  border-color: #65b996;
}

.button.is-success.is-inverted {
  color: #65b996;
  background-color: #fff;
}

.button.is-success.is-inverted:hover, .button.is-success.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-success.is-inverted[disabled], fieldset[disabled] .button.is-success.is-inverted {
  box-shadow: none;
  color: #65b996;
  background-color: #fff;
  border-color: #0000;
}

.button.is-success.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-success.is-outlined {
  color: #65b996;
  background-color: #0000;
  border-color: #65b996;
}

.button.is-success.is-outlined:hover, .button.is-success.is-outlined.is-hovered, .button.is-success.is-outlined:focus, .button.is-success.is-outlined.is-focused {
  color: #fff;
  background-color: #65b996;
  border-color: #65b996;
}

.button.is-success.is-outlined.is-loading:after {
  border-color: #0000 #0000 #65b996 #65b996 !important;
}

.button.is-success.is-outlined.is-loading:hover:after, .button.is-success.is-outlined.is-loading.is-hovered:after, .button.is-success.is-outlined.is-loading:focus:after, .button.is-success.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-success.is-outlined[disabled], fieldset[disabled] .button.is-success.is-outlined {
  box-shadow: none;
  color: #65b996;
  background-color: #0000;
  border-color: #65b996;
}

.button.is-success.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-success.is-inverted.is-outlined:hover, .button.is-success.is-inverted.is-outlined.is-hovered, .button.is-success.is-inverted.is-outlined:focus, .button.is-success.is-inverted.is-outlined.is-focused {
  color: #65b996;
  background-color: #fff;
}

.button.is-success.is-inverted.is-outlined.is-loading:hover:after, .button.is-success.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-success.is-inverted.is-outlined.is-loading:focus:after, .button.is-success.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #65b996 #65b996 !important;
}

.button.is-success.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-success.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-success.is-light {
  color: #357459;
  background-color: #f1f9f5;
}

.button.is-success.is-light:hover, .button.is-success.is-light.is-hovered {
  color: #357459;
  background-color: #e8f5ef;
  border-color: #0000;
}

.button.is-success.is-light:active, .button.is-success.is-light.is-active {
  color: #357459;
  background-color: #dff1e9;
  border-color: #0000;
}

.button.is-warning {
  color: #000000b3;
  background-color: #fbdc8e;
  border-color: #0000;
}

.button.is-warning:hover, .button.is-warning.is-hovered {
  color: #000000b3;
  background-color: #fbd882;
  border-color: #0000;
}

.button.is-warning:focus, .button.is-warning.is-focused {
  color: #000000b3;
  border-color: #0000;
}

.button.is-warning:focus:not(:active), .button.is-warning.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #fbdc8e40;
}

.button.is-warning:active, .button.is-warning.is-active {
  color: #000000b3;
  background-color: #fad475;
  border-color: #0000;
}

.button.is-warning[disabled], fieldset[disabled] .button.is-warning {
  box-shadow: none;
  background-color: #fbdc8e;
  border-color: #fbdc8e;
}

.button.is-warning.is-inverted {
  color: #fbdc8e;
  background-color: #000000b3;
}

.button.is-warning.is-inverted:hover, .button.is-warning.is-inverted.is-hovered {
  background-color: #000000b3;
}

.button.is-warning.is-inverted[disabled], fieldset[disabled] .button.is-warning.is-inverted {
  box-shadow: none;
  color: #fbdc8e;
  background-color: #000000b3;
  border-color: #0000;
}

.button.is-warning.is-loading:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-warning.is-outlined {
  color: #fbdc8e;
  background-color: #0000;
  border-color: #fbdc8e;
}

.button.is-warning.is-outlined:hover, .button.is-warning.is-outlined.is-hovered, .button.is-warning.is-outlined:focus, .button.is-warning.is-outlined.is-focused {
  color: #000000b3;
  background-color: #fbdc8e;
  border-color: #fbdc8e;
}

.button.is-warning.is-outlined.is-loading:after {
  border-color: #0000 #0000 #fbdc8e #fbdc8e !important;
}

.button.is-warning.is-outlined.is-loading:hover:after, .button.is-warning.is-outlined.is-loading.is-hovered:after, .button.is-warning.is-outlined.is-loading:focus:after, .button.is-warning.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-warning.is-outlined[disabled], fieldset[disabled] .button.is-warning.is-outlined {
  box-shadow: none;
  color: #fbdc8e;
  background-color: #0000;
  border-color: #fbdc8e;
}

.button.is-warning.is-inverted.is-outlined {
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-warning.is-inverted.is-outlined:hover, .button.is-warning.is-inverted.is-outlined.is-hovered, .button.is-warning.is-inverted.is-outlined:focus, .button.is-warning.is-inverted.is-outlined.is-focused {
  color: #fbdc8e;
  background-color: #000000b3;
}

.button.is-warning.is-inverted.is-outlined.is-loading:hover:after, .button.is-warning.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-warning.is-inverted.is-outlined.is-loading:focus:after, .button.is-warning.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fbdc8e #fbdc8e !important;
}

.button.is-warning.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-warning.is-inverted.is-outlined {
  box-shadow: none;
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-warning.is-light {
  color: #8f6805;
  background-color: #fef9eb;
}

.button.is-warning.is-light:hover, .button.is-warning.is-light.is-hovered {
  color: #8f6805;
  background-color: #fef5df;
  border-color: #0000;
}

.button.is-warning.is-light:active, .button.is-warning.is-light.is-active {
  color: #8f6805;
  background-color: #fdf1d3;
  border-color: #0000;
}

.button.is-danger {
  color: #fff;
  background-color: #fc5c46;
  border-color: #0000;
}

.button.is-danger:hover, .button.is-danger.is-hovered {
  color: #fff;
  background-color: #fc5139;
  border-color: #0000;
}

.button.is-danger:focus, .button.is-danger.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-danger:focus:not(:active), .button.is-danger.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #fc5c4640;
}

.button.is-danger:active, .button.is-danger.is-active {
  color: #fff;
  background-color: #fc462d;
  border-color: #0000;
}

.button.is-danger[disabled], fieldset[disabled] .button.is-danger {
  box-shadow: none;
  background-color: #fc5c46;
  border-color: #fc5c46;
}

.button.is-danger.is-inverted {
  color: #fc5c46;
  background-color: #fff;
}

.button.is-danger.is-inverted:hover, .button.is-danger.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-danger.is-inverted[disabled], fieldset[disabled] .button.is-danger.is-inverted {
  box-shadow: none;
  color: #fc5c46;
  background-color: #fff;
  border-color: #0000;
}

.button.is-danger.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-danger.is-outlined {
  color: #fc5c46;
  background-color: #0000;
  border-color: #fc5c46;
}

.button.is-danger.is-outlined:hover, .button.is-danger.is-outlined.is-hovered, .button.is-danger.is-outlined:focus, .button.is-danger.is-outlined.is-focused {
  color: #fff;
  background-color: #fc5c46;
  border-color: #fc5c46;
}

.button.is-danger.is-outlined.is-loading:after {
  border-color: #0000 #0000 #fc5c46 #fc5c46 !important;
}

.button.is-danger.is-outlined.is-loading:hover:after, .button.is-danger.is-outlined.is-loading.is-hovered:after, .button.is-danger.is-outlined.is-loading:focus:after, .button.is-danger.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-danger.is-outlined[disabled], fieldset[disabled] .button.is-danger.is-outlined {
  box-shadow: none;
  color: #fc5c46;
  background-color: #0000;
  border-color: #fc5c46;
}

.button.is-danger.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-danger.is-inverted.is-outlined:hover, .button.is-danger.is-inverted.is-outlined.is-hovered, .button.is-danger.is-inverted.is-outlined:focus, .button.is-danger.is-inverted.is-outlined.is-focused {
  color: #fc5c46;
  background-color: #fff;
}

.button.is-danger.is-inverted.is-outlined.is-loading:hover:after, .button.is-danger.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-danger.is-inverted.is-outlined.is-loading:focus:after, .button.is-danger.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fc5c46 #fc5c46 !important;
}

.button.is-danger.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-danger.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-danger.is-light {
  color: #c91b03;
  background-color: #ffedeb;
}

.button.is-danger.is-light:hover, .button.is-danger.is-light.is-hovered {
  color: #c91b03;
  background-color: #fee2de;
  border-color: #0000;
}

.button.is-danger.is-light:active, .button.is-danger.is-light.is-active {
  color: #c91b03;
  background-color: #fed7d2;
  border-color: #0000;
}

.button.is-small {
  font-size: .75rem;
}

.button.is-small:not(.is-rounded) {
  border-radius: 0;
}

.button.is-normal {
  font-size: .875rem;
}

.button.is-medium {
  font-size: 1rem;
}

.button.is-large {
  font-size: 1.25rem;
}

.button[disabled], fieldset[disabled] .button {
  box-shadow: none;
  opacity: .5;
  background-color: #fff;
  border-color: #e7e5e4;
}

.button.is-fullwidth {
  width: 100%;
  display: flex;
}

.button.is-loading {
  pointer-events: none;
  color: #0000 !important;
}

.button.is-loading:after {
  position: absolute;
  top: calc(50% - .5em);
  left: calc(50% - .5em);
  position: absolute !important;
}

.button.is-static {
  color: #7a7a7a;
  box-shadow: none;
  pointer-events: none;
  background-color: #fafaf9;
  border-color: #e7e5e4;
}

.button.is-rounded {
  border-radius: 9999px;
  padding-left: calc(1.5rem + .25em);
  padding-right: calc(1.5rem + .25em);
}

.buttons {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.buttons .button {
  margin-bottom: .5rem;
}

.buttons .button:not(:last-child):not(.is-fullwidth) {
  margin-right: .5rem;
}

.buttons:last-child {
  margin-bottom: -.5rem;
}

.buttons:not(:last-child) {
  margin-bottom: 1rem;
}

.buttons.are-small .button:not(.is-normal):not(.is-medium):not(.is-large) {
  font-size: .75rem;
}

.buttons.are-small .button:not(.is-normal):not(.is-medium):not(.is-large):not(.is-rounded) {
  border-radius: 0;
}

.buttons.are-medium .button:not(.is-small):not(.is-normal):not(.is-large) {
  font-size: 1rem;
}

.buttons.are-large .button:not(.is-small):not(.is-normal):not(.is-medium) {
  font-size: 1.25rem;
}

.buttons.has-addons .button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.buttons.has-addons .button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
}

.buttons.has-addons .button:last-child {
  margin-right: 0;
}

.buttons.has-addons .button:hover, .buttons.has-addons .button.is-hovered {
  z-index: 2;
}

.buttons.has-addons .button:focus, .buttons.has-addons .button.is-focused, .buttons.has-addons .button:active, .buttons.has-addons .button.is-active, .buttons.has-addons .button.is-selected {
  z-index: 3;
}

.buttons.has-addons .button:focus:hover, .buttons.has-addons .button.is-focused:hover, .buttons.has-addons .button:active:hover, .buttons.has-addons .button.is-active:hover, .buttons.has-addons .button.is-selected:hover {
  z-index: 4;
}

.buttons.has-addons .button.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.buttons.is-centered {
  justify-content: center;
}

.buttons.is-centered:not(.has-addons) .button:not(.is-fullwidth) {
  margin-left: .25rem;
  margin-right: .25rem;
}

.buttons.is-right {
  justify-content: flex-end;
}

.buttons.is-right:not(.has-addons) .button:not(.is-fullwidth) {
  margin-left: .25rem;
  margin-right: .25rem;
}

@media screen and (width <= 768px) {
  .button.is-responsive.is-small {
    font-size: .5625rem;
  }

  .button.is-responsive, .button.is-responsive.is-normal {
    font-size: .65625rem;
  }

  .button.is-responsive.is-medium {
    font-size: .75rem;
  }

  .button.is-responsive.is-large {
    font-size: .875rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .button.is-responsive.is-small {
    font-size: .65625rem;
  }

  .button.is-responsive, .button.is-responsive.is-normal {
    font-size: .75rem;
  }

  .button.is-responsive.is-medium {
    font-size: .875rem;
  }

  .button.is-responsive.is-large {
    font-size: 1rem;
  }
}

.container {
  flex-grow: 1;
  width: auto;
  margin: 0 auto;
  position: relative;
}

.container.is-fluid {
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  max-width: none !important;
}

@media screen and (width >= 1024px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (width <= 1215px) {
  .container.is-widescreen:not(.is-max-desktop) {
    max-width: 1076px;
  }
}

@media screen and (width <= 1407px) {
  .container.is-fullhd:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1076px;
  }
}

@media screen and (width >= 1216px) {
  .container:not(.is-max-desktop) {
    max-width: 1076px;
  }
}

@media screen and (width >= 1408px) {
  .container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1076px;
  }
}

.content li + li {
  margin-top: .25em;
}

.content p:not(:last-child), .content dl:not(:last-child), .content ol:not(:last-child), .content ul:not(:last-child), .content blockquote:not(:last-child), .content pre:not(:last-child), .content table:not(:last-child) {
  margin-bottom: 1em;
}

.content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
  color: #292524;
  font-weight: 600;
  line-height: 1.125;
}

.content h1 {
  margin-bottom: .5em;
  font-size: 2em;
}

.content h1:not(:first-child) {
  margin-top: 1em;
}

.content h2 {
  margin-bottom: .5714em;
  font-size: 1.75em;
}

.content h2:not(:first-child) {
  margin-top: 1.1428em;
}

.content h3 {
  margin-bottom: .6666em;
  font-size: 1.5em;
}

.content h3:not(:first-child) {
  margin-top: 1.3333em;
}

.content h4 {
  margin-bottom: .8em;
  font-size: 1.25em;
}

.content h5 {
  margin-bottom: .8888em;
  font-size: 1.125em;
}

.content h6 {
  margin-bottom: 1em;
  font-size: 1em;
}

.content blockquote {
  background-color: #fafaf9;
  border-left: 5px solid #e7e5e4;
  padding: 1.25em 1.5em;
}

.content ol {
  margin-top: 1em;
  margin-left: 2em;
  list-style-position: outside;
}

.content ol:not([type]) {
  list-style-type: decimal;
}

.content ol:not([type]).is-lower-alpha {
  list-style-type: lower-alpha;
}

.content ol:not([type]).is-lower-roman {
  list-style-type: lower-roman;
}

.content ol:not([type]).is-upper-alpha {
  list-style-type: upper-alpha;
}

.content ol:not([type]).is-upper-roman {
  list-style-type: upper-roman;
}

.content ul {
  margin-top: 1em;
  margin-left: 2em;
  list-style: disc;
}

.content ul ul {
  margin-top: .5em;
  list-style-type: circle;
}

.content ul ul ul {
  list-style-type: square;
}

.content dd {
  margin-left: 2em;
}

.content figure {
  text-align: center;
  margin-left: 2em;
  margin-right: 2em;
}

.content figure:not(:first-child) {
  margin-top: 2em;
}

.content figure:not(:last-child) {
  margin-bottom: 2em;
}

.content figure img {
  display: inline-block;
}

.content figure figcaption {
  font-style: italic;
}

.content pre {
  -webkit-overflow-scrolling: touch;
  white-space: pre;
  word-wrap: normal;
  padding: 1.25em 1.5em;
  overflow-x: auto;
}

.content sup, .content sub {
  font-size: 75%;
}

.content table {
  width: 100%;
}

.content table td, .content table th {
  vertical-align: top;
  border: 0 solid #e7e5e4;
  border-bottom-width: 1px;
  padding: .5em .75em;
}

.content table th {
  color: #292524;
}

.content table th:not([align]) {
  text-align: inherit;
}

.content table thead td, .content table thead th {
  color: #292524;
  border-width: 0 0 2px;
}

.content table tfoot td, .content table tfoot th {
  color: #292524;
  border-width: 2px 0 0;
}

.content table tbody tr:last-child td, .content table tbody tr:last-child th {
  border-bottom-width: 0;
}

.content .tabs li + li {
  margin-top: 0;
}

.content.is-small {
  font-size: .75rem;
}

.content.is-normal {
  font-size: .875rem;
}

.content.is-medium {
  font-size: 1rem;
}

.content.is-large {
  font-size: 1.25rem;
}

.icon {
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
}

.icon.is-small {
  width: 1rem;
  height: 1rem;
}

.icon.is-medium {
  width: 2rem;
  height: 2rem;
}

.icon.is-large {
  width: 3rem;
  height: 3rem;
}

.icon-text {
  color: inherit;
  vertical-align: top;
  flex-wrap: wrap;
  align-items: flex-start;
  line-height: 1.5rem;
  display: inline-flex;
}

.icon-text .icon {
  flex-grow: 0;
  flex-shrink: 0;
}

.icon-text .icon:not(:last-child) {
  margin-right: .25em;
}

.icon-text .icon:not(:first-child) {
  margin-left: .25em;
}

div.icon-text {
  display: flex;
}

.image {
  display: block;
  position: relative;
}

.image img {
  width: 100%;
  height: auto;
  display: block;
}

.image img.is-rounded {
  border-radius: 9999px;
}

.image.is-fullwidth {
  width: 100%;
}

.image.is-square img, .image.is-square .has-ratio, .image.is-1by1 img, .image.is-1by1 .has-ratio, .image.is-5by4 img, .image.is-5by4 .has-ratio, .image.is-4by3 img, .image.is-4by3 .has-ratio, .image.is-3by2 img, .image.is-3by2 .has-ratio, .image.is-5by3 img, .image.is-5by3 .has-ratio, .image.is-16by9 img, .image.is-16by9 .has-ratio, .image.is-2by1 img, .image.is-2by1 .has-ratio, .image.is-3by1 img, .image.is-3by1 .has-ratio, .image.is-4by5 img, .image.is-4by5 .has-ratio, .image.is-3by4 img, .image.is-3by4 .has-ratio, .image.is-2by3 img, .image.is-2by3 .has-ratio, .image.is-3by5 img, .image.is-3by5 .has-ratio, .image.is-9by16 img, .image.is-9by16 .has-ratio, .image.is-1by2 img, .image.is-1by2 .has-ratio, .image.is-1by3 img, .image.is-1by3 .has-ratio {
  width: 100%;
  height: 100%;
}

.image.is-square, .image.is-1by1 {
  padding-top: 100%;
}

.image.is-5by4 {
  padding-top: 80%;
}

.image.is-4by3 {
  padding-top: 75%;
}

.image.is-3by2 {
  padding-top: 66.6666%;
}

.image.is-5by3 {
  padding-top: 60%;
}

.image.is-16by9 {
  padding-top: 56.25%;
}

.image.is-2by1 {
  padding-top: 50%;
}

.image.is-3by1 {
  padding-top: 33.3333%;
}

.image.is-4by5 {
  padding-top: 125%;
}

.image.is-3by4 {
  padding-top: 133.333%;
}

.image.is-2by3 {
  padding-top: 150%;
}

.image.is-3by5 {
  padding-top: 166.667%;
}

.image.is-9by16 {
  padding-top: 177.778%;
}

.image.is-1by2 {
  padding-top: 200%;
}

.image.is-1by3 {
  padding-top: 300%;
}

.image.is-16x16 {
  width: 16px;
  height: 16px;
}

.image.is-24x24 {
  width: 24px;
  height: 24px;
}

.image.is-32x32 {
  width: 32px;
  height: 32px;
}

.image.is-48x48 {
  width: 48px;
  height: 48px;
}

.image.is-64x64 {
  width: 64px;
  height: 64px;
}

.image.is-96x96 {
  width: 96px;
  height: 96px;
}

.image.is-128x128 {
  width: 128px;
  height: 128px;
}

.notification {
  background-color: #fafaf9;
  border-radius: 0;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
  position: relative;
}

.notification a:not(.button):not(.dropdown-item) {
  color: currentColor;
  text-decoration: underline;
}

.notification strong {
  color: currentColor;
}

.notification code, .notification pre {
  background: #fff;
}

.notification pre code {
  background: none;
}

.notification > .delete {
  position: absolute;
  top: .5rem;
  right: .5rem;
}

.notification .title, .notification .has-background-white .hero-title, .has-background-white .notification .hero-title, .notification h3, .notification h2, .notification h1, .notification .subtitle, .notification .content {
  color: currentColor;
}

.notification.is-white {
  color: #222;
  background-color: #fff;
}

.notification.is-black {
  color: #fff;
  background-color: #222;
}

.notification.is-light {
  color: #000000b3;
  background-color: #f5f5f4;
}

.notification.is-dark, .notification.is-primary {
  color: #fff;
  background-color: #004bff;
}

.notification.is-primary.is-light {
  color: #004afa;
  background-color: #ebf1ff;
}

.notification.is-link {
  color: #fff;
  background-color: #004bff;
}

.notification.is-link.is-light {
  color: #004afa;
  background-color: #ebf1ff;
}

.notification.is-info {
  color: #fff;
  background-color: #56a2bd;
}

.notification.is-info.is-light {
  color: #357288;
  background-color: #f0f7f9;
}

.notification.is-success {
  color: #fff;
  background-color: #65b996;
}

.notification.is-success.is-light {
  color: #357459;
  background-color: #f1f9f5;
}

.notification.is-warning {
  color: #000000b3;
  background-color: #fbdc8e;
}

.notification.is-warning.is-light {
  color: #8f6805;
  background-color: #fef9eb;
}

.notification.is-danger {
  color: #fff;
  background-color: #fc5c46;
}

.notification.is-danger.is-light {
  color: #c91b03;
  background-color: #ffedeb;
}

.progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  border-radius: 9999px;
  width: 100%;
  height: .875rem;
  padding: 0;
  display: block;
  overflow: hidden;
}

.progress::-webkit-progress-bar {
  background-color: #fafaf9;
}

.progress::-webkit-progress-value {
  background-color: #44403c;
}

.progress::-moz-progress-bar {
  background-color: #44403c;
}

.progress::-ms-fill {
  background-color: #44403c;
  border: none;
}

.progress.is-white::-webkit-progress-value {
  background-color: #fff;
}

.progress.is-white::-moz-progress-bar {
  background-color: #fff;
}

.progress.is-white::-ms-fill {
  background-color: #fff;
}

.progress.is-white:indeterminate {
  background-image: linear-gradient(to right, #fff 30%, #fafaf9 30%);
}

.progress.is-black::-webkit-progress-value {
  background-color: #222;
}

.progress.is-black::-moz-progress-bar {
  background-color: #222;
}

.progress.is-black::-ms-fill {
  background-color: #222;
}

.progress.is-black:indeterminate {
  background-image: linear-gradient(to right, #222 30%, #fafaf9 30%);
}

.progress.is-light::-webkit-progress-value {
  background-color: #f5f5f4;
}

.progress.is-light::-moz-progress-bar {
  background-color: #f5f5f4;
}

.progress.is-light::-ms-fill {
  background-color: #f5f5f4;
}

.progress.is-light:indeterminate {
  background-image: linear-gradient(to right, #f5f5f4 30%, #fafaf9 30%);
}

.progress.is-dark::-webkit-progress-value {
  background-color: #004bff;
}

.progress.is-dark::-moz-progress-bar {
  background-color: #004bff;
}

.progress.is-dark::-ms-fill {
  background-color: #004bff;
}

.progress.is-dark:indeterminate {
  background-image: linear-gradient(to right, #004bff 30%, #fafaf9 30%);
}

.progress.is-primary::-webkit-progress-value {
  background-color: #004bff;
}

.progress.is-primary::-moz-progress-bar {
  background-color: #004bff;
}

.progress.is-primary::-ms-fill {
  background-color: #004bff;
}

.progress.is-primary:indeterminate {
  background-image: linear-gradient(to right, #004bff 30%, #fafaf9 30%);
}

.progress.is-link::-webkit-progress-value {
  background-color: #004bff;
}

.progress.is-link::-moz-progress-bar {
  background-color: #004bff;
}

.progress.is-link::-ms-fill {
  background-color: #004bff;
}

.progress.is-link:indeterminate {
  background-image: linear-gradient(to right, #004bff 30%, #fafaf9 30%);
}

.progress.is-info::-webkit-progress-value {
  background-color: #56a2bd;
}

.progress.is-info::-moz-progress-bar {
  background-color: #56a2bd;
}

.progress.is-info::-ms-fill {
  background-color: #56a2bd;
}

.progress.is-info:indeterminate {
  background-image: linear-gradient(to right, #56a2bd 30%, #fafaf9 30%);
}

.progress.is-success::-webkit-progress-value {
  background-color: #65b996;
}

.progress.is-success::-moz-progress-bar {
  background-color: #65b996;
}

.progress.is-success::-ms-fill {
  background-color: #65b996;
}

.progress.is-success:indeterminate {
  background-image: linear-gradient(to right, #65b996 30%, #fafaf9 30%);
}

.progress.is-warning::-webkit-progress-value {
  background-color: #fbdc8e;
}

.progress.is-warning::-moz-progress-bar {
  background-color: #fbdc8e;
}

.progress.is-warning::-ms-fill {
  background-color: #fbdc8e;
}

.progress.is-warning:indeterminate {
  background-image: linear-gradient(to right, #fbdc8e 30%, #fafaf9 30%);
}

.progress.is-danger::-webkit-progress-value {
  background-color: #fc5c46;
}

.progress.is-danger::-moz-progress-bar {
  background-color: #fc5c46;
}

.progress.is-danger::-ms-fill {
  background-color: #fc5c46;
}

.progress.is-danger:indeterminate {
  background-image: linear-gradient(to right, #fc5c46 30%, #fafaf9 30%);
}

.progress:indeterminate {
  background-color: #fafaf9;
  background-image: linear-gradient(to right, #44403c 30%, #fafaf9 30%);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 150% 150%;
  animation-name: moveIndeterminate;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.progress:indeterminate::-webkit-progress-bar {
  background-color: #0000;
}

.progress:indeterminate::-moz-progress-bar {
  background-color: #0000;
}

.progress:indeterminate::-ms-fill {
  animation-name: none;
}

.progress.is-small {
  height: .75rem;
}

.progress.is-medium {
  height: 1rem;
}

.progress.is-large {
  height: 1.25rem;
}

@keyframes moveIndeterminate {
  from {
    background-position: 200% 0;
  }

  to {
    background-position: -200% 0;
  }
}

.table {
  color: #292524;
  background-color: #fff;
}

.table td, .table th {
  vertical-align: top;
  border: 0 solid #e7e5e4;
  border-bottom-width: 1px;
  padding: .5em .75em;
}

.table td.is-white, .table th.is-white {
  color: #222;
  background-color: #fff;
  border-color: #fff;
}

.table td.is-black, .table th.is-black {
  color: #fff;
  background-color: #222;
  border-color: #222;
}

.table td.is-light, .table th.is-light {
  color: #000000b3;
  background-color: #f5f5f4;
  border-color: #f5f5f4;
}

.table td.is-dark, .table th.is-dark, .table td.is-primary, .table th.is-primary, .table td.is-link, .table th.is-link {
  color: #fff;
  background-color: #004bff;
  border-color: #004bff;
}

.table td.is-info, .table th.is-info {
  color: #fff;
  background-color: #56a2bd;
  border-color: #56a2bd;
}

.table td.is-success, .table th.is-success {
  color: #fff;
  background-color: #65b996;
  border-color: #65b996;
}

.table td.is-warning, .table th.is-warning {
  color: #000000b3;
  background-color: #fbdc8e;
  border-color: #fbdc8e;
}

.table td.is-danger, .table th.is-danger {
  color: #fff;
  background-color: #fc5c46;
  border-color: #fc5c46;
}

.table td.is-narrow, .table th.is-narrow {
  white-space: nowrap;
  width: 1%;
}

.table td.is-selected, .table th.is-selected {
  color: #fff;
  background-color: #004bff;
}

.table td.is-selected a, .table td.is-selected strong, .table th.is-selected a, .table th.is-selected strong {
  color: currentColor;
}

.table td.is-vcentered, .table th.is-vcentered {
  vertical-align: middle;
}

.table th {
  color: #292524;
}

.table th:not([align]) {
  text-align: left;
}

.table tr.is-selected {
  color: #fff;
  background-color: #004bff;
}

.table tr.is-selected a, .table tr.is-selected strong {
  color: currentColor;
}

.table tr.is-selected td, .table tr.is-selected th {
  color: currentColor;
  border-color: #fff;
}

.table thead {
  background-color: #0000;
}

.table thead td, .table thead th {
  color: #292524;
  border-width: 0 0 2px;
}

.table tfoot {
  background-color: #0000;
}

.table tfoot td, .table tfoot th {
  color: #292524;
  border-width: 2px 0 0;
}

.table tbody {
  background-color: #0000;
}

.table tbody tr:last-child td, .table tbody tr:last-child th {
  border-bottom-width: 0;
}

.table.is-bordered td, .table.is-bordered th {
  border-width: 1px;
}

.table.is-bordered tr:last-child td, .table.is-bordered tr:last-child th {
  border-bottom-width: 1px;
}

.table.is-fullwidth {
  width: 100%;
}

.table.is-hoverable tbody tr:not(.is-selected):hover, .table.is-hoverable.is-striped tbody tr:not(.is-selected):hover {
  background-color: #f5f5f4;
}

.table.is-hoverable.is-striped tbody tr:not(.is-selected):hover:nth-child(2n) {
  background-color: #fafaf9;
}

.table.is-narrow td, .table.is-narrow th {
  padding: .25em .5em;
}

.table.is-striped tbody tr:not(.is-selected):nth-child(2n) {
  background-color: #f5f5f4;
}

.table-container {
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
  overflow: auto hidden;
}

.tags {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.tags .tag {
  margin-bottom: .5rem;
}

.tags .tag:not(:last-child) {
  margin-right: .5rem;
}

.tags:last-child {
  margin-bottom: -.5rem;
}

.tags:not(:last-child) {
  margin-bottom: 1rem;
}

.tags.are-medium .tag:not(.is-normal):not(.is-large) {
  font-size: .875rem;
}

.tags.are-large .tag:not(.is-normal):not(.is-medium) {
  font-size: 1rem;
}

.tags.is-centered {
  justify-content: center;
}

.tags.is-centered .tag {
  margin-left: .25rem;
  margin-right: .25rem;
}

.tags.is-right {
  justify-content: flex-end;
}

.tags.is-right .tag:not(:first-child) {
  margin-left: .5rem;
}

.tags.is-right .tag:not(:last-child), .tags.has-addons .tag {
  margin-right: 0;
}

.tags.has-addons .tag:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
}

.tags.has-addons .tag:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tag:not(body) {
  color: #000;
  white-space: nowrap;
  background-color: #fafaf9;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  height: 2em;
  padding-left: .75em;
  padding-right: .75em;
  font-size: .75rem;
  line-height: 1.5;
  display: inline-flex;
}

.tag:not(body) .delete {
  margin-left: .25rem;
  margin-right: -.375rem;
}

.tag:not(body).is-white {
  color: #222;
  background-color: #fff;
}

.tag:not(body).is-black {
  color: #fff;
  background-color: #222;
}

.tag:not(body).is-light {
  color: #000000b3;
  background-color: #f5f5f4;
}

.tag:not(body).is-dark, .tag:not(body).is-primary {
  color: #fff;
  background-color: #004bff;
}

.tag:not(body).is-primary.is-light {
  color: #004afa;
  background-color: #ebf1ff;
}

.tag:not(body).is-link {
  color: #fff;
  background-color: #004bff;
}

.tag:not(body).is-link.is-light {
  color: #004afa;
  background-color: #ebf1ff;
}

.tag:not(body).is-info {
  color: #fff;
  background-color: #56a2bd;
}

.tag:not(body).is-info.is-light {
  color: #357288;
  background-color: #f0f7f9;
}

.tag:not(body).is-success {
  color: #fff;
  background-color: #65b996;
}

.tag:not(body).is-success.is-light {
  color: #357459;
  background-color: #f1f9f5;
}

.tag:not(body).is-warning {
  color: #000000b3;
  background-color: #fbdc8e;
}

.tag:not(body).is-warning.is-light {
  color: #8f6805;
  background-color: #fef9eb;
}

.tag:not(body).is-danger {
  color: #fff;
  background-color: #fc5c46;
}

.tag:not(body).is-danger.is-light {
  color: #c91b03;
  background-color: #ffedeb;
}

.tag:not(body).is-normal {
  font-size: .75rem;
}

.tag:not(body).is-medium {
  font-size: .875rem;
}

.tag:not(body).is-large {
  font-size: 1rem;
}

.tag:not(body) .icon:first-child:not(:last-child) {
  margin-left: -.375em;
  margin-right: .1875em;
}

.tag:not(body) .icon:last-child:not(:first-child) {
  margin-left: .1875em;
  margin-right: -.375em;
}

.tag:not(body) .icon:first-child:last-child {
  margin-left: -.375em;
  margin-right: -.375em;
}

.tag:not(body).is-delete {
  width: 2em;
  margin-left: 1px;
  padding: 0;
  position: relative;
}

.tag:not(body).is-delete:before, .tag:not(body).is-delete:after {
  content: "";
  transform-origin: center;
  background-color: currentColor;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.tag:not(body).is-delete:before {
  width: 50%;
  height: 1px;
}

.tag:not(body).is-delete:after {
  width: 1px;
  height: 50%;
}

.tag:not(body).is-delete:hover, .tag:not(body).is-delete:focus {
  background-color: #eeeeeb;
}

.tag:not(body).is-delete:active {
  background-color: #e3e3dd;
}

.tag:not(body).is-rounded {
  border-radius: 9999px;
}

a.tag:hover {
  text-decoration: underline;
}

.title, .has-background-white .hero-title, h3, h2, h1, .subtitle {
  word-break: break-word;
}

.title em, .has-background-white .hero-title em, h3 em, h2 em, h1 em, .title span, .has-background-white .hero-title span, h3 span, h2 span, h1 span, .subtitle em, .subtitle span {
  font-weight: inherit;
}

.title sub, .has-background-white .hero-title sub, h3 sub, h2 sub, h1 sub, .subtitle sub, .title sup, .has-background-white .hero-title sup, h3 sup, h2 sup, h1 sup, .subtitle sup {
  font-size: .75em;
}

.title .tag, .has-background-white .hero-title .tag, h3 .tag, h2 .tag, h1 .tag, .subtitle .tag {
  vertical-align: middle;
}

.title, .has-background-white .hero-title, h3, h2, h1 {
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 1.2;
}

.title strong, .has-background-white .hero-title strong, h3 strong, h2 strong, h1 strong {
  color: inherit;
  font-weight: inherit;
}

.title:not(.is-spaced):not(.hero-title) + .subtitle, .has-background-white .hero-title:not(.is-spaced):not(.hero-title) + .subtitle, h3:not(.is-spaced):not(.hero-title) + .subtitle, h2:not(.is-spaced):not(.hero-title) + .subtitle, h1:not(.is-spaced):not(.hero-title) + .subtitle {
  margin-top: -1.25rem;
}

.title.is-1, .has-background-white .is-1.hero-title, h3.is-1, h2.is-1, h1.is-1 {
  font-size: 4rem;
}

.title.is-2, .has-background-white .is-2.hero-title, h3.is-2, h2.is-2, h1.is-2 {
  font-size: 3rem;
}

.title.is-3, .has-background-white .is-3.hero-title, h3.is-3, h2.is-3, h1.is-3 {
  font-size: 2.25rem;
}

.title.is-4, .has-background-white .is-4.hero-title, h3.is-4, h2.is-4, h1.is-4 {
  font-size: 1.25rem;
}

.title.is-5, .has-background-white .is-5.hero-title, h3.is-5, h2.is-5, h1.is-5 {
  font-size: 1rem;
}

.title.is-6, .has-background-white .is-6.hero-title, h3.is-6, h2.is-6, h1.is-6 {
  font-size: .875rem;
}

.title.is-7, .has-background-white .is-7.hero-title, h3.is-7, h2.is-7, h1.is-7 {
  font-size: .75rem;
}

.subtitle {
  color: #000;
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-weight: 800;
  line-height: 2;
}

.subtitle strong {
  color: #292524;
  font-weight: 600;
}

.subtitle:not(.is-spaced):not(.hero-title) + .title, .has-background-white .subtitle:not(.is-spaced):not(.hero-title) + .hero-title, .subtitle:not(.is-spaced):not(.hero-title) + h3, .subtitle:not(.is-spaced):not(.hero-title) + h2, .subtitle:not(.is-spaced):not(.hero-title) + h1 {
  margin-top: -1.25rem;
}

.subtitle.is-1 {
  font-size: 4rem;
}

.subtitle.is-2 {
  font-size: 3rem;
}

.subtitle.is-3 {
  font-size: 2.25rem;
}

.subtitle.is-4 {
  font-size: 1.25rem;
}

.subtitle.is-5 {
  font-size: 1rem;
}

.subtitle.is-6 {
  font-size: .875rem;
}

.subtitle.is-7 {
  font-size: .75rem;
}

.heading {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 11px;
  display: block;
}

.number {
  text-align: center;
  vertical-align: top;
  background-color: #fafaf9;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  min-width: 2.5em;
  height: 2em;
  margin-right: 1.5rem;
  padding: .25rem .5rem;
  font-size: 1rem;
  display: inline-flex;
}

.select select, .textarea, .input {
  color: #292524;
  background-color: #fff;
  border-color: #e7e5e4;
  border-radius: 0;
}

.select select::-moz-placeholder {
  color: #2925244d;
}

.textarea::-moz-placeholder {
  color: #2925244d;
}

.input::-moz-placeholder {
  color: #2925244d;
}

.select select::-webkit-input-placeholder {
  color: #2925244d;
}

.textarea::-webkit-input-placeholder {
  color: #2925244d;
}

.input::-webkit-input-placeholder {
  color: #2925244d;
}

.select select:-moz-placeholder {
  color: #2925244d;
}

.textarea:-moz-placeholder {
  color: #2925244d;
}

.input:-moz-placeholder {
  color: #2925244d;
}

.select select:-ms-input-placeholder {
  color: #2925244d;
}

.textarea:-ms-input-placeholder {
  color: #2925244d;
}

.input:-ms-input-placeholder {
  color: #2925244d;
}

.select select:hover, .textarea:hover, .input:hover, .select select.is-hovered, .is-hovered.textarea, .is-hovered.input {
  border-color: #f5f5f4;
}

.select select:focus, .textarea:focus, .input:focus, .select select.is-focused, .is-focused.textarea, .is-focused.input, .select select:active, .textarea:active, .input:active, .select select.is-active, .is-active.textarea, .is-active.input {
  border-color: #004bff;
  box-shadow: 0 0 0 .125em #004bff40;
}

.select select[disabled], [disabled].textarea, [disabled].input, fieldset[disabled] .select select, .select fieldset[disabled] select, fieldset[disabled] .textarea, fieldset[disabled] .input {
  box-shadow: none;
  color: #7a7a7a;
  background-color: #fafaf9;
  border-color: #fafaf9;
}

.select select[disabled]::-moz-placeholder {
  color: #7a7a7a4d;
}

[disabled].textarea::-moz-placeholder {
  color: #7a7a7a4d;
}

[disabled].input::-moz-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .select select::-moz-placeholder {
  color: #7a7a7a4d;
}

.select fieldset[disabled] select::-moz-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .textarea::-moz-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .input::-moz-placeholder {
  color: #7a7a7a4d;
}

.select select[disabled]::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

[disabled].textarea::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

[disabled].input::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .select select::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

.select fieldset[disabled] select::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .textarea::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .input::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

.select select[disabled]:-moz-placeholder {
  color: #7a7a7a4d;
}

[disabled].textarea:-moz-placeholder {
  color: #7a7a7a4d;
}

[disabled].input:-moz-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .select select:-moz-placeholder {
  color: #7a7a7a4d;
}

.select fieldset[disabled] select:-moz-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .textarea:-moz-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .input:-moz-placeholder {
  color: #7a7a7a4d;
}

.select select[disabled]:-ms-input-placeholder {
  color: #7a7a7a4d;
}

[disabled].textarea:-ms-input-placeholder {
  color: #7a7a7a4d;
}

[disabled].input:-ms-input-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .select select:-ms-input-placeholder {
  color: #7a7a7a4d;
}

.select fieldset[disabled] select:-ms-input-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .textarea:-ms-input-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .input:-ms-input-placeholder {
  color: #7a7a7a4d;
}

.textarea, .input {
  width: 100%;
  max-width: 100%;
  box-shadow: inset 0 .0625em .125em #0a0a0a0d;
}

[readonly].textarea, [readonly].input {
  box-shadow: none;
}

.is-white.textarea, .is-white.input {
  border-color: #fff;
}

.is-white.textarea:focus, .is-white.input:focus, .is-white.is-focused.textarea, .is-white.is-focused.input, .is-white.textarea:active, .is-white.input:active, .is-white.is-active.textarea, .is-white.is-active.input {
  box-shadow: 0 0 0 .125em #ffffff40;
}

.is-black.textarea, .is-black.input {
  border-color: #222;
}

.is-black.textarea:focus, .is-black.input:focus, .is-black.is-focused.textarea, .is-black.is-focused.input, .is-black.textarea:active, .is-black.input:active, .is-black.is-active.textarea, .is-black.is-active.input {
  box-shadow: 0 0 0 .125em #22222240;
}

.is-light.textarea, .is-light.input {
  border-color: #f5f5f4;
}

.is-light.textarea:focus, .is-light.input:focus, .is-light.is-focused.textarea, .is-light.is-focused.input, .is-light.textarea:active, .is-light.input:active, .is-light.is-active.textarea, .is-light.is-active.input {
  box-shadow: 0 0 0 .125em #f5f5f440;
}

.is-dark.textarea, .is-dark.input {
  border-color: #004bff;
}

.is-dark.textarea:focus, .is-dark.input:focus, .is-dark.is-focused.textarea, .is-dark.is-focused.input, .is-dark.textarea:active, .is-dark.input:active, .is-dark.is-active.textarea, .is-dark.is-active.input {
  box-shadow: 0 0 0 .125em #004bff40;
}

.is-primary.textarea, .is-primary.input {
  border-color: #004bff;
}

.is-primary.textarea:focus, .is-primary.input:focus, .is-primary.is-focused.textarea, .is-primary.is-focused.input, .is-primary.textarea:active, .is-primary.input:active, .is-primary.is-active.textarea, .is-primary.is-active.input {
  box-shadow: 0 0 0 .125em #004bff40;
}

.is-link.textarea, .is-link.input {
  border-color: #004bff;
}

.is-link.textarea:focus, .is-link.input:focus, .is-link.is-focused.textarea, .is-link.is-focused.input, .is-link.textarea:active, .is-link.input:active, .is-link.is-active.textarea, .is-link.is-active.input {
  box-shadow: 0 0 0 .125em #004bff40;
}

.is-info.textarea, .is-info.input {
  border-color: #56a2bd;
}

.is-info.textarea:focus, .is-info.input:focus, .is-info.is-focused.textarea, .is-info.is-focused.input, .is-info.textarea:active, .is-info.input:active, .is-info.is-active.textarea, .is-info.is-active.input {
  box-shadow: 0 0 0 .125em #56a2bd40;
}

.is-success.textarea, .is-success.input {
  border-color: #65b996;
}

.is-success.textarea:focus, .is-success.input:focus, .is-success.is-focused.textarea, .is-success.is-focused.input, .is-success.textarea:active, .is-success.input:active, .is-success.is-active.textarea, .is-success.is-active.input {
  box-shadow: 0 0 0 .125em #65b99640;
}

.is-warning.textarea, .is-warning.input {
  border-color: #fbdc8e;
}

.is-warning.textarea:focus, .is-warning.input:focus, .is-warning.is-focused.textarea, .is-warning.is-focused.input, .is-warning.textarea:active, .is-warning.input:active, .is-warning.is-active.textarea, .is-warning.is-active.input {
  box-shadow: 0 0 0 .125em #fbdc8e40;
}

.is-danger.textarea, .is-danger.input {
  border-color: #fc5c46;
}

.is-danger.textarea:focus, .is-danger.input:focus, .is-danger.is-focused.textarea, .is-danger.is-focused.input, .is-danger.textarea:active, .is-danger.input:active, .is-danger.is-active.textarea, .is-danger.is-active.input {
  box-shadow: 0 0 0 .125em #fc5c4640;
}

.is-small.textarea, .is-small.input {
  border-radius: 0;
  font-size: .75rem;
}

.is-medium.textarea, .is-medium.input {
  font-size: 1rem;
}

.is-large.textarea, .is-large.input {
  font-size: 1.25rem;
}

.is-fullwidth.textarea, .is-fullwidth.input {
  width: 100%;
  display: block;
}

.is-inline.textarea, .is-inline.input {
  width: auto;
  display: inline;
}

.input.is-rounded {
  border-radius: 9999px;
  padding-left: calc(1.125em - 1px);
  padding-right: calc(1.125em - 1px);
}

.input.is-static {
  box-shadow: none;
  background-color: #0000;
  border-color: #0000;
  padding-left: 0;
  padding-right: 0;
}

.textarea {
  resize: vertical;
  min-width: 100%;
  max-width: 100%;
  padding: calc(.75em - 1px);
  display: block;
}

.textarea:not([rows]) {
  min-height: 8em;
  max-height: 40em;
}

.textarea[rows] {
  height: initial;
}

.textarea.has-fixed-size {
  resize: none;
}

.radio, .checkbox {
  cursor: pointer;
  line-height: 1.25;
  display: inline-block;
  position: relative;
}

.radio input, .checkbox input {
  cursor: pointer;
}

.radio:hover, .checkbox:hover {
  color: #292524;
}

[disabled].radio, [disabled].checkbox, fieldset[disabled] .radio, fieldset[disabled] .checkbox, .radio input[disabled], .checkbox input[disabled] {
  color: #7a7a7a;
  cursor: not-allowed;
}

.radio + .radio {
  margin-left: .5em;
}

.select {
  vertical-align: top;
  max-width: 100%;
  display: inline-block;
  position: relative;
}

.select:not(.is-multiple) {
  height: 3.3em;
}

.select:not(.is-multiple):not(.is-loading):after {
  z-index: 4;
  border-color: #004bff;
  right: 1.125em;
}

.select.is-rounded select {
  border-radius: 9999px;
  padding-left: 1em;
}

.select select {
  cursor: pointer;
  outline: none;
  max-width: 100%;
  font-size: 1em;
  display: block;
}

.select select::-ms-expand {
  display: none;
}

.select select[disabled]:hover, fieldset[disabled] .select select:hover {
  border-color: #fafaf9;
}

.select select:not([multiple]) {
  padding-right: 2.5em;
}

.select select[multiple] {
  height: auto;
  padding: 0;
}

.select select[multiple] option {
  padding: .5em 1em;
}

.select:not(.is-multiple):not(.is-loading):hover:after {
  border-color: #292524;
}

.select.is-white:not(:hover):after, .select.is-white select {
  border-color: #fff;
}

.select.is-white select:hover, .select.is-white select.is-hovered {
  border-color: #f2f2f2;
}

.select.is-white select:focus, .select.is-white select.is-focused, .select.is-white select:active, .select.is-white select.is-active {
  box-shadow: 0 0 0 .125em #ffffff40;
}

.select.is-black:not(:hover):after, .select.is-black select {
  border-color: #222;
}

.select.is-black select:hover, .select.is-black select.is-hovered {
  border-color: #151515;
}

.select.is-black select:focus, .select.is-black select.is-focused, .select.is-black select:active, .select.is-black select.is-active {
  box-shadow: 0 0 0 .125em #22222240;
}

.select.is-light:not(:hover):after, .select.is-light select {
  border-color: #f5f5f4;
}

.select.is-light select:hover, .select.is-light select.is-hovered {
  border-color: #e9e9e7;
}

.select.is-light select:focus, .select.is-light select.is-focused, .select.is-light select:active, .select.is-light select.is-active {
  box-shadow: 0 0 0 .125em #f5f5f440;
}

.select.is-dark:not(:hover):after, .select.is-dark select {
  border-color: #004bff;
}

.select.is-dark select:hover, .select.is-dark select.is-hovered {
  border-color: #0044e6;
}

.select.is-dark select:focus, .select.is-dark select.is-focused, .select.is-dark select:active, .select.is-dark select.is-active {
  box-shadow: 0 0 0 .125em #004bff40;
}

.select.is-primary:not(:hover):after, .select.is-primary select {
  border-color: #004bff;
}

.select.is-primary select:hover, .select.is-primary select.is-hovered {
  border-color: #0044e6;
}

.select.is-primary select:focus, .select.is-primary select.is-focused, .select.is-primary select:active, .select.is-primary select.is-active {
  box-shadow: 0 0 0 .125em #004bff40;
}

.select.is-link:not(:hover):after, .select.is-link select {
  border-color: #004bff;
}

.select.is-link select:hover, .select.is-link select.is-hovered {
  border-color: #0044e6;
}

.select.is-link select:focus, .select.is-link select.is-focused, .select.is-link select:active, .select.is-link select.is-active {
  box-shadow: 0 0 0 .125em #004bff40;
}

.select.is-info:not(:hover):after, .select.is-info select {
  border-color: #56a2bd;
}

.select.is-info select:hover, .select.is-info select.is-hovered {
  border-color: #4697b3;
}

.select.is-info select:focus, .select.is-info select.is-focused, .select.is-info select:active, .select.is-info select.is-active {
  box-shadow: 0 0 0 .125em #56a2bd40;
}

.select.is-success:not(:hover):after, .select.is-success select {
  border-color: #65b996;
}

.select.is-success select:hover, .select.is-success select.is-hovered {
  border-color: #53b18a;
}

.select.is-success select:focus, .select.is-success select.is-focused, .select.is-success select:active, .select.is-success select.is-active {
  box-shadow: 0 0 0 .125em #65b99640;
}

.select.is-warning:not(:hover):after, .select.is-warning select {
  border-color: #fbdc8e;
}

.select.is-warning select:hover, .select.is-warning select.is-hovered {
  border-color: #fad475;
}

.select.is-warning select:focus, .select.is-warning select.is-focused, .select.is-warning select:active, .select.is-warning select.is-active {
  box-shadow: 0 0 0 .125em #fbdc8e40;
}

.select.is-danger:not(:hover):after, .select.is-danger select {
  border-color: #fc5c46;
}

.select.is-danger select:hover, .select.is-danger select.is-hovered {
  border-color: #fc462d;
}

.select.is-danger select:focus, .select.is-danger select.is-focused, .select.is-danger select:active, .select.is-danger select.is-active {
  box-shadow: 0 0 0 .125em #fc5c4640;
}

.select.is-small {
  border-radius: 0;
  font-size: .75rem;
}

.select.is-medium {
  font-size: 1rem;
}

.select.is-large {
  font-size: 1.25rem;
}

.select.is-disabled:after {
  opacity: .5;
  border-color: #7a7a7a !important;
}

.select.is-fullwidth, .select.is-fullwidth select {
  width: 100%;
}

.select.is-loading:after {
  margin-top: 0;
  position: absolute;
  top: .625em;
  right: .625em;
  transform: none;
}

.select.is-loading.is-small:after {
  font-size: .75rem;
}

.select.is-loading.is-medium:after {
  font-size: 1rem;
}

.select.is-loading.is-large:after {
  font-size: 1.25rem;
}

.file {
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: relative;
}

.file.is-white .file-cta {
  color: #222;
  background-color: #fff;
  border-color: #0000;
}

.file.is-white:hover .file-cta, .file.is-white.is-hovered .file-cta {
  color: #222;
  background-color: #f9f9f9;
  border-color: #0000;
}

.file.is-white:focus .file-cta, .file.is-white.is-focused .file-cta {
  color: #222;
  border-color: #0000;
  box-shadow: 0 0 .5em #ffffff40;
}

.file.is-white:active .file-cta, .file.is-white.is-active .file-cta {
  color: #222;
  background-color: #f2f2f2;
  border-color: #0000;
}

.file.is-black .file-cta {
  color: #fff;
  background-color: #222;
  border-color: #0000;
}

.file.is-black:hover .file-cta, .file.is-black.is-hovered .file-cta {
  color: #fff;
  background-color: #1c1c1c;
  border-color: #0000;
}

.file.is-black:focus .file-cta, .file.is-black.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #22222240;
}

.file.is-black:active .file-cta, .file.is-black.is-active .file-cta {
  color: #fff;
  background-color: #151515;
  border-color: #0000;
}

.file.is-light .file-cta {
  color: #000000b3;
  background-color: #f5f5f4;
  border-color: #0000;
}

.file.is-light:hover .file-cta, .file.is-light.is-hovered .file-cta {
  color: #000000b3;
  background-color: #efefed;
  border-color: #0000;
}

.file.is-light:focus .file-cta, .file.is-light.is-focused .file-cta {
  color: #000000b3;
  border-color: #0000;
  box-shadow: 0 0 .5em #f5f5f440;
}

.file.is-light:active .file-cta, .file.is-light.is-active .file-cta {
  color: #000000b3;
  background-color: #e9e9e7;
  border-color: #0000;
}

.file.is-dark .file-cta {
  color: #fff;
  background-color: #004bff;
  border-color: #0000;
}

.file.is-dark:hover .file-cta, .file.is-dark.is-hovered .file-cta {
  color: #fff;
  background-color: #0047f2;
  border-color: #0000;
}

.file.is-dark:focus .file-cta, .file.is-dark.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #004bff40;
}

.file.is-dark:active .file-cta, .file.is-dark.is-active .file-cta {
  color: #fff;
  background-color: #0044e6;
  border-color: #0000;
}

.file.is-primary .file-cta {
  color: #fff;
  background-color: #004bff;
  border-color: #0000;
}

.file.is-primary:hover .file-cta, .file.is-primary.is-hovered .file-cta {
  color: #fff;
  background-color: #0047f2;
  border-color: #0000;
}

.file.is-primary:focus .file-cta, .file.is-primary.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #004bff40;
}

.file.is-primary:active .file-cta, .file.is-primary.is-active .file-cta {
  color: #fff;
  background-color: #0044e6;
  border-color: #0000;
}

.file.is-link .file-cta {
  color: #fff;
  background-color: #004bff;
  border-color: #0000;
}

.file.is-link:hover .file-cta, .file.is-link.is-hovered .file-cta {
  color: #fff;
  background-color: #0047f2;
  border-color: #0000;
}

.file.is-link:focus .file-cta, .file.is-link.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #004bff40;
}

.file.is-link:active .file-cta, .file.is-link.is-active .file-cta {
  color: #fff;
  background-color: #0044e6;
  border-color: #0000;
}

.file.is-info .file-cta {
  color: #fff;
  background-color: #56a2bd;
  border-color: #0000;
}

.file.is-info:hover .file-cta, .file.is-info.is-hovered .file-cta {
  color: #fff;
  background-color: #4d9db9;
  border-color: #0000;
}

.file.is-info:focus .file-cta, .file.is-info.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #56a2bd40;
}

.file.is-info:active .file-cta, .file.is-info.is-active .file-cta {
  color: #fff;
  background-color: #4697b3;
  border-color: #0000;
}

.file.is-success .file-cta {
  color: #fff;
  background-color: #65b996;
  border-color: #0000;
}

.file.is-success:hover .file-cta, .file.is-success.is-hovered .file-cta {
  color: #fff;
  background-color: #5cb590;
  border-color: #0000;
}

.file.is-success:focus .file-cta, .file.is-success.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #65b99640;
}

.file.is-success:active .file-cta, .file.is-success.is-active .file-cta {
  color: #fff;
  background-color: #53b18a;
  border-color: #0000;
}

.file.is-warning .file-cta {
  color: #000000b3;
  background-color: #fbdc8e;
  border-color: #0000;
}

.file.is-warning:hover .file-cta, .file.is-warning.is-hovered .file-cta {
  color: #000000b3;
  background-color: #fbd882;
  border-color: #0000;
}

.file.is-warning:focus .file-cta, .file.is-warning.is-focused .file-cta {
  color: #000000b3;
  border-color: #0000;
  box-shadow: 0 0 .5em #fbdc8e40;
}

.file.is-warning:active .file-cta, .file.is-warning.is-active .file-cta {
  color: #000000b3;
  background-color: #fad475;
  border-color: #0000;
}

.file.is-danger .file-cta {
  color: #fff;
  background-color: #fc5c46;
  border-color: #0000;
}

.file.is-danger:hover .file-cta, .file.is-danger.is-hovered .file-cta {
  color: #fff;
  background-color: #fc5139;
  border-color: #0000;
}

.file.is-danger:focus .file-cta, .file.is-danger.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #fc5c4640;
}

.file.is-danger:active .file-cta, .file.is-danger.is-active .file-cta {
  color: #fff;
  background-color: #fc462d;
  border-color: #0000;
}

.file.is-small {
  font-size: .75rem;
}

.file.is-normal {
  font-size: .875rem;
}

.file.is-medium {
  font-size: 1rem;
}

.file.is-medium .file-icon .fa {
  font-size: 21px;
}

.file.is-large {
  font-size: 1.25rem;
}

.file.is-large .file-icon .fa {
  font-size: 28px;
}

.file.has-name .file-cta {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.file.has-name .file-name {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.file.has-name.is-empty .file-cta {
  border-radius: 0;
}

.file.has-name.is-empty .file-name {
  display: none;
}

.file.is-boxed .file-label {
  flex-direction: column;
}

.file.is-boxed .file-cta {
  flex-direction: column;
  height: auto;
  padding: 1em 3em;
}

.file.is-boxed .file-name {
  border-width: 0 1px 1px;
}

.file.is-boxed .file-icon {
  width: 1.5em;
  height: 1.5em;
}

.file.is-boxed .file-icon .fa {
  font-size: 21px;
}

.file.is-boxed.is-small .file-icon .fa {
  font-size: 14px;
}

.file.is-boxed.is-medium .file-icon .fa {
  font-size: 28px;
}

.file.is-boxed.is-large .file-icon .fa {
  font-size: 35px;
}

.file.is-boxed.has-name .file-cta {
  border-radius: 0;
}

.file.is-boxed.has-name .file-name {
  border-width: 0 1px 1px;
  border-radius: 0;
}

.file.is-centered {
  justify-content: center;
}

.file.is-fullwidth .file-label {
  width: 100%;
}

.file.is-fullwidth .file-name {
  flex-grow: 1;
  max-width: none;
}

.file.is-right {
  justify-content: flex-end;
}

.file.is-right .file-cta {
  border-radius: 0;
}

.file.is-right .file-name {
  border-width: 1px 0 1px 1px;
  border-radius: 0;
  order: -1;
}

.file-label {
  cursor: pointer;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: relative;
  overflow: hidden;
}

.file-label:hover .file-cta {
  color: #292524;
  background-color: #f4f4f2;
}

.file-label:hover .file-name {
  border-color: #e1dfdd;
}

.file-label:active .file-cta {
  color: #292524;
  background-color: #eeeeeb;
}

.file-label:active .file-name {
  border-color: #dbd8d7;
}

.file-input {
  opacity: 0;
  outline: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.file-cta, .file-name {
  white-space: nowrap;
  border-color: #e7e5e4;
  border-radius: 0;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 1em;
}

.file-cta {
  color: #44403c;
  background-color: #fafaf9;
}

.file-name {
  text-align: inherit;
  text-overflow: ellipsis;
  border: 1px solid #e7e5e4;
  border-left-width: 0;
  max-width: 16em;
  display: block;
  overflow: hidden;
}

.file-icon {
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  margin-right: .5em;
  display: flex;
}

.file-icon .fa {
  font-size: 14px;
}

.label {
  color: #292524;
  font-size: .875rem;
  font-weight: 700;
  display: block;
}

.label:not(:last-child) {
  margin-bottom: .5em;
}

.label.is-small {
  font-size: .75rem;
}

.label.is-medium {
  font-size: 1rem;
}

.label.is-large {
  font-size: 1.25rem;
}

.help {
  margin-top: .25rem;
  font-size: .75rem;
  display: block;
}

.help.is-white {
  color: #fff;
}

.help.is-black {
  color: #222;
}

.help.is-light {
  color: #f5f5f4;
}

.help.is-dark, .help.is-primary, .help.is-link {
  color: #004bff;
}

.help.is-info {
  color: #56a2bd;
}

.help.is-success {
  color: #65b996;
}

.help.is-warning {
  color: #fbdc8e;
}

.help.is-danger {
  color: #fc5c46;
}

.field:not(:last-child) {
  margin-bottom: .75rem;
}

.field.has-addons {
  justify-content: flex-start;
  display: flex;
}

.field.has-addons .control:not(:last-child) {
  margin-right: -1px;
}

.field.has-addons .control:not(:first-child):not(:last-child) .button, .field.has-addons .control:not(:first-child):not(:last-child) .input, .field.has-addons .control:not(:first-child):not(:last-child) .select select {
  border-radius: 0;
}

.field.has-addons .control:first-child:not(:only-child) .button, .field.has-addons .control:first-child:not(:only-child) .input, .field.has-addons .control:first-child:not(:only-child) .select select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.field.has-addons .control:last-child:not(:only-child) .button, .field.has-addons .control:last-child:not(:only-child) .input, .field.has-addons .control:last-child:not(:only-child) .select select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.field.has-addons .control .button:not([disabled]):hover, .field.has-addons .control .button:not([disabled]).is-hovered, .field.has-addons .control .input:not([disabled]):hover, .field.has-addons .control .input:not([disabled]).is-hovered, .field.has-addons .control .select select:not([disabled]):hover, .field.has-addons .control .select select:not([disabled]).is-hovered {
  z-index: 2;
}

.field.has-addons .control .button:not([disabled]):focus, .field.has-addons .control .button:not([disabled]).is-focused, .field.has-addons .control .button:not([disabled]):active, .field.has-addons .control .button:not([disabled]).is-active, .field.has-addons .control .input:not([disabled]):focus, .field.has-addons .control .input:not([disabled]).is-focused, .field.has-addons .control .input:not([disabled]):active, .field.has-addons .control .input:not([disabled]).is-active, .field.has-addons .control .select select:not([disabled]):focus, .field.has-addons .control .select select:not([disabled]).is-focused, .field.has-addons .control .select select:not([disabled]):active, .field.has-addons .control .select select:not([disabled]).is-active {
  z-index: 3;
}

.field.has-addons .control .button:not([disabled]):focus:hover, .field.has-addons .control .button:not([disabled]).is-focused:hover, .field.has-addons .control .button:not([disabled]):active:hover, .field.has-addons .control .button:not([disabled]).is-active:hover, .field.has-addons .control .input:not([disabled]):focus:hover, .field.has-addons .control .input:not([disabled]).is-focused:hover, .field.has-addons .control .input:not([disabled]):active:hover, .field.has-addons .control .input:not([disabled]).is-active:hover, .field.has-addons .control .select select:not([disabled]):focus:hover, .field.has-addons .control .select select:not([disabled]).is-focused:hover, .field.has-addons .control .select select:not([disabled]):active:hover, .field.has-addons .control .select select:not([disabled]).is-active:hover {
  z-index: 4;
}

.field.has-addons .control.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.field.has-addons.has-addons-centered {
  justify-content: center;
}

.field.has-addons.has-addons-right {
  justify-content: flex-end;
}

.field.has-addons.has-addons-fullwidth .control {
  flex-grow: 1;
  flex-shrink: 0;
}

.field.is-grouped {
  justify-content: flex-start;
  display: flex;
}

.field.is-grouped > .control {
  flex-shrink: 0;
}

.field.is-grouped > .control:not(:last-child) {
  margin-bottom: 0;
  margin-right: .75rem;
}

.field.is-grouped > .control.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.field.is-grouped.is-grouped-centered {
  justify-content: center;
}

.field.is-grouped.is-grouped-right {
  justify-content: flex-end;
}

.field.is-grouped.is-grouped-multiline {
  flex-wrap: wrap;
}

.field.is-grouped.is-grouped-multiline > .control:last-child, .field.is-grouped.is-grouped-multiline > .control:not(:last-child) {
  margin-bottom: .75rem;
}

.field.is-grouped.is-grouped-multiline:last-child {
  margin-bottom: -.75rem;
}

.field.is-grouped.is-grouped-multiline:not(:last-child) {
  margin-bottom: 0;
}

@media screen and (width >= 769px), print {
  .field.is-horizontal {
    display: flex;
  }
}

.field-label .label {
  font-size: inherit;
}

@media screen and (width <= 768px) {
  .field-label {
    margin-bottom: .5rem;
  }
}

@media screen and (width >= 769px), print {
  .field-label {
    text-align: right;
    flex: 1 0 0;
    margin-right: 1.5rem;
  }

  .field-label.is-small {
    padding-top: .375em;
    font-size: .75rem;
  }

  .field-label.is-normal {
    padding-top: .375em;
  }

  .field-label.is-medium {
    padding-top: .375em;
    font-size: 1rem;
  }

  .field-label.is-large {
    padding-top: .375em;
    font-size: 1.25rem;
  }
}

.field-body .field .field {
  margin-bottom: 0;
}

@media screen and (width >= 769px), print {
  .field-body {
    flex: 5 1 0;
    display: flex;
  }

  .field-body .field {
    margin-bottom: 0;
  }

  .field-body > .field {
    flex-shrink: 1;
  }

  .field-body > .field:not(.is-narrow) {
    flex-grow: 1;
  }

  .field-body > .field:not(:last-child) {
    margin-right: .75rem;
  }
}

.control {
  box-sizing: border-box;
  clear: both;
  text-align: inherit;
  font-size: .875rem;
  position: relative;
}

.control.has-icons-left .input:focus ~ .icon, .control.has-icons-left .select:focus ~ .icon, .control.has-icons-right .input:focus ~ .icon, .control.has-icons-right .select:focus ~ .icon {
  color: #44403c;
}

.control.has-icons-left .input.is-small ~ .icon, .control.has-icons-left .select.is-small ~ .icon, .control.has-icons-right .input.is-small ~ .icon, .control.has-icons-right .select.is-small ~ .icon {
  font-size: .75rem;
}

.control.has-icons-left .input.is-medium ~ .icon, .control.has-icons-left .select.is-medium ~ .icon, .control.has-icons-right .input.is-medium ~ .icon, .control.has-icons-right .select.is-medium ~ .icon {
  font-size: 1rem;
}

.control.has-icons-left .input.is-large ~ .icon, .control.has-icons-left .select.is-large ~ .icon, .control.has-icons-right .input.is-large ~ .icon, .control.has-icons-right .select.is-large ~ .icon {
  font-size: 1.25rem;
}

.control.has-icons-left .icon, .control.has-icons-right .icon {
  color: #e7e5e4;
  pointer-events: none;
  z-index: 4;
  width: 3.3em;
  height: 3.3em;
  position: absolute;
  top: 0;
}

.control.has-icons-left .input, .control.has-icons-left .select select {
  padding-left: 3.3em;
}

.control.has-icons-left .icon.is-left {
  left: 0;
}

.control.has-icons-right .input, .control.has-icons-right .select select {
  padding-right: 3.3em;
}

.control.has-icons-right .icon.is-right {
  right: 0;
}

.control.is-loading:after {
  z-index: 4;
  top: .625em;
  right: .625em;
  position: absolute !important;
}

.control.is-loading.is-small:after {
  font-size: .75rem;
}

.control.is-loading.is-medium:after {
  font-size: 1rem;
}

.control.is-loading.is-large:after {
  font-size: 1.25rem;
}

.breadcrumb {
  white-space: nowrap;
  font-size: .875rem;
}

.breadcrumb a {
  color: #004bff;
  justify-content: center;
  align-items: center;
  padding: 0 .75em;
  display: flex;
}

.breadcrumb a:hover {
  color: #004bff;
}

.breadcrumb li {
  align-items: center;
  display: flex;
}

.breadcrumb li:first-child a {
  padding-left: 0;
}

.breadcrumb li.is-active a {
  color: #292524;
  cursor: default;
  pointer-events: none;
}

.breadcrumb li + li:before {
  color: #f5f5f4;
  content: "/";
}

.breadcrumb ul, .breadcrumb ol {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.breadcrumb .icon:first-child {
  margin-right: .5em;
}

.breadcrumb .icon:last-child {
  margin-left: .5em;
}

.breadcrumb.is-centered ol, .breadcrumb.is-centered ul {
  justify-content: center;
}

.breadcrumb.is-right ol, .breadcrumb.is-right ul {
  justify-content: flex-end;
}

.breadcrumb.is-small {
  font-size: .75rem;
}

.breadcrumb.is-medium {
  font-size: 1rem;
}

.breadcrumb.is-large {
  font-size: 1.25rem;
}

.breadcrumb.has-arrow-separator li + li:before {
  content: "→";
}

.breadcrumb.has-bullet-separator li + li:before {
  content: "•";
}

.breadcrumb.has-dot-separator li + li:before {
  content: "·";
}

.breadcrumb.has-succeeds-separator li + li:before {
  content: "≻";
}

.card {
  color: #44403c;
  background-color: #fff;
  border-radius: .25rem;
  max-width: 100%;
  position: relative;
  box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
}

.card-footer:first-child, .card-content:first-child, .card-header:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.card-footer:last-child, .card-content:last-child, .card-header:last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.card-header {
  background-color: #0000;
  align-items: stretch;
  display: flex;
  box-shadow: 0 .125em .25em #0a0a0a1a;
}

.card-header-title {
  color: #292524;
  flex-grow: 1;
  align-items: center;
  padding: .75rem 1rem;
  font-weight: 700;
  display: flex;
}

.card-header-title.is-centered {
  justify-content: center;
}

.card-header-icon {
  -webkit-appearance: none;
  appearance: none;
  color: currentColor;
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: .75rem 1rem;
  font-family: inherit;
  font-size: 1em;
  display: flex;
}

.card-image {
  display: block;
  position: relative;
}

.card-image:first-child img {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.card-image:last-child img {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.card-content {
  background-color: #0000;
  padding: 1.5rem;
}

.card-footer {
  background-color: #0000;
  border-top: 1px solid #fafaf9;
  align-items: stretch;
  display: flex;
}

.card-footer-item {
  flex: 1 0 0;
  justify-content: center;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.card-footer-item:not(:last-child) {
  border-right: 1px solid #fafaf9;
}

.card .media:not(:last-child) {
  margin-bottom: 1.5rem;
}

.dropdown {
  vertical-align: top;
  display: inline-flex;
  position: relative;
}

.dropdown.is-active .dropdown-menu, .dropdown.is-hoverable:hover .dropdown-menu {
  display: block;
}

.dropdown.is-right .dropdown-menu {
  left: auto;
  right: 0;
}

.dropdown.is-up .dropdown-menu {
  padding-bottom: 4px;
  padding-top: initial;
  top: auto;
  bottom: 100%;
}

.dropdown-menu {
  z-index: 20;
  min-width: 12rem;
  padding-top: 4px;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown-content {
  background-color: #fff;
  border-radius: 0;
  padding-top: .5rem;
  padding-bottom: .5rem;
  box-shadow: 0 .5em 1em -.125em #0a0a0a1a, 0 0 0 1px #0a0a0a05;
}

.dropdown-item {
  color: #44403c;
  padding: .375rem 1rem;
  font-size: .875rem;
  line-height: 1.5;
  display: block;
  position: relative;
}

a.dropdown-item, button.dropdown-item {
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
  padding-right: 3rem;
}

a.dropdown-item:hover, button.dropdown-item:hover {
  color: #0a0a0a;
  background-color: #fafaf9;
}

a.dropdown-item.is-active, button.dropdown-item.is-active {
  color: #fff;
  background-color: #004bff;
}

.dropdown-divider {
  background-color: #fafaf9;
  border: none;
  height: 1px;
  margin: .5rem 0;
  display: block;
}

.level {
  justify-content: space-between;
  align-items: center;
}

.level code {
  border-radius: 0;
}

.level img {
  vertical-align: top;
  display: inline-block;
}

.level.is-mobile, .level.is-mobile .level-left, .level.is-mobile .level-right {
  display: flex;
}

.level.is-mobile .level-left + .level-right {
  margin-top: 0;
}

.level.is-mobile .level-item:not(:last-child) {
  margin-bottom: 0;
  margin-right: .75rem;
}

.level.is-mobile .level-item:not(.is-narrow) {
  flex-grow: 1;
}

@media screen and (width >= 769px), print {
  .level {
    display: flex;
  }

  .level > .level-item:not(.is-narrow) {
    flex-grow: 1;
  }
}

.level-item {
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.level-item .title, .level-item .has-background-white .hero-title, .has-background-white .level-item .hero-title, .level-item h3, .level-item h2, .level-item h1, .level-item .subtitle {
  margin-bottom: 0;
}

@media screen and (width <= 768px) {
  .level-item:not(:last-child) {
    margin-bottom: .75rem;
  }
}

.level-left, .level-right {
  flex: none;
}

.level-left .level-item.is-flexible, .level-right .level-item.is-flexible {
  flex-grow: 1;
}

@media screen and (width >= 769px), print {
  .level-left .level-item:not(:last-child), .level-right .level-item:not(:last-child) {
    margin-right: .75rem;
  }
}

.level-left {
  justify-content: flex-start;
  align-items: center;
}

@media screen and (width <= 768px) {
  .level-left + .level-right {
    margin-top: 1.5rem;
  }
}

@media screen and (width >= 769px), print {
  .level-left {
    display: flex;
  }
}

.level-right {
  justify-content: flex-end;
  align-items: center;
}

@media screen and (width >= 769px), print {
  .level-right {
    display: flex;
  }
}

.media {
  text-align: inherit;
  align-items: flex-start;
  display: flex;
}

.media .content:not(:last-child) {
  margin-bottom: .75rem;
}

.media .media {
  border-top: 1px solid #e7e5e480;
  padding-top: .75rem;
  display: flex;
}

.media .media .content:not(:last-child), .media .media .control:not(:last-child) {
  margin-bottom: .5rem;
}

.media .media .media {
  padding-top: .5rem;
}

.media .media .media + .media {
  margin-top: .5rem;
}

.media + .media {
  border-top: 1px solid #e7e5e480;
  margin-top: 1rem;
  padding-top: 1rem;
}

.media.is-large + .media {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}

.media-left, .media-right {
  flex: none;
}

.media-left {
  margin-right: 1rem;
}

.media-right {
  margin-left: 1rem;
}

.media-content {
  text-align: inherit;
  flex: auto;
}

@media screen and (width <= 768px) {
  .media-content {
    overflow-x: auto;
  }
}

.menu {
  font-size: .875rem;
}

.menu.is-small {
  font-size: .75rem;
}

.menu.is-medium {
  font-size: 1rem;
}

.menu.is-large {
  font-size: 1.25rem;
}

.menu-list {
  line-height: 1.25;
}

.menu-list a {
  color: #44403c;
  border-radius: 0;
  padding: .5em .75em;
  display: block;
}

.menu-list a:hover {
  color: #292524;
  background-color: #fafaf9;
}

.menu-list a.is-active {
  color: #fff;
  background-color: #004bff;
}

.menu-list li ul {
  border-left: 1px solid #e7e5e4;
  margin: .75em;
  padding-left: .75em;
}

.menu-label {
  color: #7a7a7a;
  letter-spacing: .1em;
  text-transform: uppercase;
  font-size: .75em;
}

.menu-label:not(:first-child) {
  margin-top: 1em;
}

.menu-label:not(:last-child) {
  margin-bottom: 1em;
}

.message {
  background-color: #fafaf9;
  border-radius: 0;
  font-size: .875rem;
}

.message strong {
  color: currentColor;
}

.message a:not(.button):not(.tag):not(.dropdown-item) {
  color: currentColor;
  text-decoration: underline;
}

.message.is-small {
  font-size: .75rem;
}

.message.is-medium {
  font-size: 1rem;
}

.message.is-large {
  font-size: 1.25rem;
}

.message.is-white {
  background-color: #fff;
}

.message.is-white .message-header {
  color: #222;
  background-color: #fff;
}

.message.is-white .message-body {
  border-color: #fff;
}

.message.is-black {
  background-color: #fafafa;
}

.message.is-black .message-header {
  color: #fff;
  background-color: #222;
}

.message.is-black .message-body {
  border-color: #222;
}

.message.is-light {
  background-color: #fafafa;
}

.message.is-light .message-header {
  color: #000000b3;
  background-color: #f5f5f4;
}

.message.is-light .message-body {
  border-color: #f5f5f4;
}

.message.is-dark {
  background-color: #f5f8ff;
}

.message.is-dark .message-header {
  color: #fff;
  background-color: #004bff;
}

.message.is-dark .message-body {
  border-color: #004bff;
}

.message.is-primary {
  background-color: #ebf1ff;
}

.message.is-primary .message-header {
  color: #fff;
  background-color: #004bff;
}

.message.is-primary .message-body {
  color: #004afa;
  border-color: #004bff;
}

.message.is-link {
  background-color: #ebf1ff;
}

.message.is-link .message-header {
  color: #fff;
  background-color: #004bff;
}

.message.is-link .message-body {
  color: #004afa;
  border-color: #004bff;
}

.message.is-info {
  background-color: #f0f7f9;
}

.message.is-info .message-header {
  color: #fff;
  background-color: #56a2bd;
}

.message.is-info .message-body {
  color: #357288;
  border-color: #56a2bd;
}

.message.is-success {
  background-color: #f1f9f5;
}

.message.is-success .message-header {
  color: #fff;
  background-color: #65b996;
}

.message.is-success .message-body {
  color: #357459;
  border-color: #65b996;
}

.message.is-warning {
  background-color: #fef9eb;
}

.message.is-warning .message-header {
  color: #000000b3;
  background-color: #fbdc8e;
}

.message.is-warning .message-body {
  color: #8f6805;
  border-color: #fbdc8e;
}

.message.is-danger {
  background-color: #ffedeb;
}

.message.is-danger .message-header {
  color: #fff;
  background-color: #fc5c46;
}

.message.is-danger .message-body {
  color: #c91b03;
  border-color: #fc5c46;
}

.message-header {
  color: #fff;
  background-color: #44403c;
  border-radius: 0;
  justify-content: space-between;
  align-items: center;
  padding: .75em 1em;
  font-weight: 700;
  line-height: 1.25;
  display: flex;
  position: relative;
}

.message-header .delete {
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: .75em;
}

.message-header + .message-body {
  border-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.message-body {
  color: #44403c;
  border: 0 solid #e7e5e4;
  border-left-width: 4px;
  border-radius: 0;
  padding: 1.25em 1.5em;
}

.message-body code, .message-body pre {
  background-color: #fff;
}

.message-body pre code {
  background-color: #0000;
}

.modal {
  z-index: 40;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  overflow: hidden;
}

.modal.is-active {
  display: flex;
}

.modal-background {
  background-color: #0a0a0adb;
}

.modal-content, .modal-card {
  width: 100%;
  max-height: calc(100vh - 160px);
  margin: 0 20px;
  position: relative;
  overflow: auto;
}

@media screen and (width >= 769px) {
  .modal-content, .modal-card {
    width: 640px;
    max-height: calc(100vh - 40px);
    margin: 0 auto;
  }
}

.modal-close {
  background: none;
  width: 40px;
  height: 40px;
  position: fixed;
  top: 20px;
  right: 20px;
}

.modal-card {
  -ms-overflow-y: visible;
  flex-direction: column;
  max-height: calc(100vh - 40px);
  display: flex;
  overflow: hidden;
}

.modal-card-head, .modal-card-foot {
  background-color: #fafaf9;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  display: flex;
  position: relative;
}

.modal-card-head {
  border-bottom: 1px solid #e7e5e4;
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}

.modal-card-title {
  color: #292524;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.25rem;
  line-height: 1;
}

.modal-card-foot {
  border-top: 1px solid #e7e5e4;
  border-bottom-right-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.modal-card-foot .button:not(:last-child) {
  margin-right: .5em;
}

.modal-card-body {
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 20px;
  overflow: auto;
}

.navbar {
  z-index: 30;
  background-color: #fff;
  min-height: 6rem;
  position: relative;
}

.navbar.is-white {
  color: #222;
  background-color: #fff;
}

.navbar.is-white .navbar-brand > .navbar-item, .navbar.is-white .navbar-brand .navbar-link {
  color: #222;
}

.navbar.is-white .navbar-brand > a.navbar-item:focus, .navbar.is-white .navbar-brand > a.navbar-item:hover, .navbar.is-white .navbar-brand > a.navbar-item.is-active, .navbar.is-white .navbar-brand .navbar-link:focus, .navbar.is-white .navbar-brand .navbar-link:hover, .navbar.is-white .navbar-brand .navbar-link.is-active {
  color: #222;
  background-color: #f2f2f2;
}

.navbar.is-white .navbar-brand .navbar-link:after {
  border-color: #222;
}

.navbar.is-white .navbar-burger {
  color: #222;
}

@media screen and (width >= 1024px) {
  .navbar.is-white .navbar-start > .navbar-item, .navbar.is-white .navbar-start .navbar-link, .navbar.is-white .navbar-end > .navbar-item, .navbar.is-white .navbar-end .navbar-link {
    color: #222;
  }

  .navbar.is-white .navbar-start > a.navbar-item:focus, .navbar.is-white .navbar-start > a.navbar-item:hover, .navbar.is-white .navbar-start > a.navbar-item.is-active, .navbar.is-white .navbar-start .navbar-link:focus, .navbar.is-white .navbar-start .navbar-link:hover, .navbar.is-white .navbar-start .navbar-link.is-active, .navbar.is-white .navbar-end > a.navbar-item:focus, .navbar.is-white .navbar-end > a.navbar-item:hover, .navbar.is-white .navbar-end > a.navbar-item.is-active, .navbar.is-white .navbar-end .navbar-link:focus, .navbar.is-white .navbar-end .navbar-link:hover, .navbar.is-white .navbar-end .navbar-link.is-active {
    color: #222;
    background-color: #f2f2f2;
  }

  .navbar.is-white .navbar-start .navbar-link:after, .navbar.is-white .navbar-end .navbar-link:after {
    border-color: #222;
  }

  .navbar.is-white .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-white .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-white .navbar-item.has-dropdown.is-active .navbar-link {
    color: #222;
    background-color: #f2f2f2;
  }

  .navbar.is-white .navbar-dropdown a.navbar-item.is-active {
    color: #222;
    background-color: #fff;
  }
}

.navbar.is-black {
  color: #fff;
  background-color: #222;
}

.navbar.is-black .navbar-brand > .navbar-item, .navbar.is-black .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-black .navbar-brand > a.navbar-item:focus, .navbar.is-black .navbar-brand > a.navbar-item:hover, .navbar.is-black .navbar-brand > a.navbar-item.is-active, .navbar.is-black .navbar-brand .navbar-link:focus, .navbar.is-black .navbar-brand .navbar-link:hover, .navbar.is-black .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #151515;
}

.navbar.is-black .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-black .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-black .navbar-start > .navbar-item, .navbar.is-black .navbar-start .navbar-link, .navbar.is-black .navbar-end > .navbar-item, .navbar.is-black .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-black .navbar-start > a.navbar-item:focus, .navbar.is-black .navbar-start > a.navbar-item:hover, .navbar.is-black .navbar-start > a.navbar-item.is-active, .navbar.is-black .navbar-start .navbar-link:focus, .navbar.is-black .navbar-start .navbar-link:hover, .navbar.is-black .navbar-start .navbar-link.is-active, .navbar.is-black .navbar-end > a.navbar-item:focus, .navbar.is-black .navbar-end > a.navbar-item:hover, .navbar.is-black .navbar-end > a.navbar-item.is-active, .navbar.is-black .navbar-end .navbar-link:focus, .navbar.is-black .navbar-end .navbar-link:hover, .navbar.is-black .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #151515;
  }

  .navbar.is-black .navbar-start .navbar-link:after, .navbar.is-black .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-black .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-black .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-black .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #151515;
  }

  .navbar.is-black .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #222;
  }
}

.navbar.is-light {
  color: #000000b3;
  background-color: #f5f5f4;
}

.navbar.is-light .navbar-brand > .navbar-item, .navbar.is-light .navbar-brand .navbar-link {
  color: #000000b3;
}

.navbar.is-light .navbar-brand > a.navbar-item:focus, .navbar.is-light .navbar-brand > a.navbar-item:hover, .navbar.is-light .navbar-brand > a.navbar-item.is-active, .navbar.is-light .navbar-brand .navbar-link:focus, .navbar.is-light .navbar-brand .navbar-link:hover, .navbar.is-light .navbar-brand .navbar-link.is-active {
  color: #000000b3;
  background-color: #e9e9e7;
}

.navbar.is-light .navbar-brand .navbar-link:after {
  border-color: #000000b3;
}

.navbar.is-light .navbar-burger {
  color: #000000b3;
}

@media screen and (width >= 1024px) {
  .navbar.is-light .navbar-start > .navbar-item, .navbar.is-light .navbar-start .navbar-link, .navbar.is-light .navbar-end > .navbar-item, .navbar.is-light .navbar-end .navbar-link {
    color: #000000b3;
  }

  .navbar.is-light .navbar-start > a.navbar-item:focus, .navbar.is-light .navbar-start > a.navbar-item:hover, .navbar.is-light .navbar-start > a.navbar-item.is-active, .navbar.is-light .navbar-start .navbar-link:focus, .navbar.is-light .navbar-start .navbar-link:hover, .navbar.is-light .navbar-start .navbar-link.is-active, .navbar.is-light .navbar-end > a.navbar-item:focus, .navbar.is-light .navbar-end > a.navbar-item:hover, .navbar.is-light .navbar-end > a.navbar-item.is-active, .navbar.is-light .navbar-end .navbar-link:focus, .navbar.is-light .navbar-end .navbar-link:hover, .navbar.is-light .navbar-end .navbar-link.is-active {
    color: #000000b3;
    background-color: #e9e9e7;
  }

  .navbar.is-light .navbar-start .navbar-link:after, .navbar.is-light .navbar-end .navbar-link:after {
    border-color: #000000b3;
  }

  .navbar.is-light .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-light .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-light .navbar-item.has-dropdown.is-active .navbar-link {
    color: #000000b3;
    background-color: #e9e9e7;
  }

  .navbar.is-light .navbar-dropdown a.navbar-item.is-active {
    color: #000000b3;
    background-color: #f5f5f4;
  }
}

.navbar.is-dark {
  color: #fff;
  background-color: #004bff;
}

.navbar.is-dark .navbar-brand > .navbar-item, .navbar.is-dark .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-dark .navbar-brand > a.navbar-item:focus, .navbar.is-dark .navbar-brand > a.navbar-item:hover, .navbar.is-dark .navbar-brand > a.navbar-item.is-active, .navbar.is-dark .navbar-brand .navbar-link:focus, .navbar.is-dark .navbar-brand .navbar-link:hover, .navbar.is-dark .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #0044e6;
}

.navbar.is-dark .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-dark .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-dark .navbar-start > .navbar-item, .navbar.is-dark .navbar-start .navbar-link, .navbar.is-dark .navbar-end > .navbar-item, .navbar.is-dark .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-dark .navbar-start > a.navbar-item:focus, .navbar.is-dark .navbar-start > a.navbar-item:hover, .navbar.is-dark .navbar-start > a.navbar-item.is-active, .navbar.is-dark .navbar-start .navbar-link:focus, .navbar.is-dark .navbar-start .navbar-link:hover, .navbar.is-dark .navbar-start .navbar-link.is-active, .navbar.is-dark .navbar-end > a.navbar-item:focus, .navbar.is-dark .navbar-end > a.navbar-item:hover, .navbar.is-dark .navbar-end > a.navbar-item.is-active, .navbar.is-dark .navbar-end .navbar-link:focus, .navbar.is-dark .navbar-end .navbar-link:hover, .navbar.is-dark .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #0044e6;
  }

  .navbar.is-dark .navbar-start .navbar-link:after, .navbar.is-dark .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-dark .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-dark .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-dark .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #0044e6;
  }

  .navbar.is-dark .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #004bff;
  }
}

.navbar.is-primary {
  color: #fff;
  background-color: #004bff;
}

.navbar.is-primary .navbar-brand > .navbar-item, .navbar.is-primary .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-primary .navbar-brand > a.navbar-item:focus, .navbar.is-primary .navbar-brand > a.navbar-item:hover, .navbar.is-primary .navbar-brand > a.navbar-item.is-active, .navbar.is-primary .navbar-brand .navbar-link:focus, .navbar.is-primary .navbar-brand .navbar-link:hover, .navbar.is-primary .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #0044e6;
}

.navbar.is-primary .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-primary .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-primary .navbar-start > .navbar-item, .navbar.is-primary .navbar-start .navbar-link, .navbar.is-primary .navbar-end > .navbar-item, .navbar.is-primary .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-primary .navbar-start > a.navbar-item:focus, .navbar.is-primary .navbar-start > a.navbar-item:hover, .navbar.is-primary .navbar-start > a.navbar-item.is-active, .navbar.is-primary .navbar-start .navbar-link:focus, .navbar.is-primary .navbar-start .navbar-link:hover, .navbar.is-primary .navbar-start .navbar-link.is-active, .navbar.is-primary .navbar-end > a.navbar-item:focus, .navbar.is-primary .navbar-end > a.navbar-item:hover, .navbar.is-primary .navbar-end > a.navbar-item.is-active, .navbar.is-primary .navbar-end .navbar-link:focus, .navbar.is-primary .navbar-end .navbar-link:hover, .navbar.is-primary .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #0044e6;
  }

  .navbar.is-primary .navbar-start .navbar-link:after, .navbar.is-primary .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-primary .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-primary .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-primary .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #0044e6;
  }

  .navbar.is-primary .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #004bff;
  }
}

.navbar.is-link {
  color: #fff;
  background-color: #004bff;
}

.navbar.is-link .navbar-brand > .navbar-item, .navbar.is-link .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-link .navbar-brand > a.navbar-item:focus, .navbar.is-link .navbar-brand > a.navbar-item:hover, .navbar.is-link .navbar-brand > a.navbar-item.is-active, .navbar.is-link .navbar-brand .navbar-link:focus, .navbar.is-link .navbar-brand .navbar-link:hover, .navbar.is-link .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #0044e6;
}

.navbar.is-link .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-link .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-link .navbar-start > .navbar-item, .navbar.is-link .navbar-start .navbar-link, .navbar.is-link .navbar-end > .navbar-item, .navbar.is-link .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-link .navbar-start > a.navbar-item:focus, .navbar.is-link .navbar-start > a.navbar-item:hover, .navbar.is-link .navbar-start > a.navbar-item.is-active, .navbar.is-link .navbar-start .navbar-link:focus, .navbar.is-link .navbar-start .navbar-link:hover, .navbar.is-link .navbar-start .navbar-link.is-active, .navbar.is-link .navbar-end > a.navbar-item:focus, .navbar.is-link .navbar-end > a.navbar-item:hover, .navbar.is-link .navbar-end > a.navbar-item.is-active, .navbar.is-link .navbar-end .navbar-link:focus, .navbar.is-link .navbar-end .navbar-link:hover, .navbar.is-link .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #0044e6;
  }

  .navbar.is-link .navbar-start .navbar-link:after, .navbar.is-link .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-link .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-link .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-link .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #0044e6;
  }

  .navbar.is-link .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #004bff;
  }
}

.navbar.is-info {
  color: #fff;
  background-color: #56a2bd;
}

.navbar.is-info .navbar-brand > .navbar-item, .navbar.is-info .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-info .navbar-brand > a.navbar-item:focus, .navbar.is-info .navbar-brand > a.navbar-item:hover, .navbar.is-info .navbar-brand > a.navbar-item.is-active, .navbar.is-info .navbar-brand .navbar-link:focus, .navbar.is-info .navbar-brand .navbar-link:hover, .navbar.is-info .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #4697b3;
}

.navbar.is-info .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-info .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-info .navbar-start > .navbar-item, .navbar.is-info .navbar-start .navbar-link, .navbar.is-info .navbar-end > .navbar-item, .navbar.is-info .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-info .navbar-start > a.navbar-item:focus, .navbar.is-info .navbar-start > a.navbar-item:hover, .navbar.is-info .navbar-start > a.navbar-item.is-active, .navbar.is-info .navbar-start .navbar-link:focus, .navbar.is-info .navbar-start .navbar-link:hover, .navbar.is-info .navbar-start .navbar-link.is-active, .navbar.is-info .navbar-end > a.navbar-item:focus, .navbar.is-info .navbar-end > a.navbar-item:hover, .navbar.is-info .navbar-end > a.navbar-item.is-active, .navbar.is-info .navbar-end .navbar-link:focus, .navbar.is-info .navbar-end .navbar-link:hover, .navbar.is-info .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #4697b3;
  }

  .navbar.is-info .navbar-start .navbar-link:after, .navbar.is-info .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-info .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-info .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-info .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #4697b3;
  }

  .navbar.is-info .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #56a2bd;
  }
}

.navbar.is-success {
  color: #fff;
  background-color: #65b996;
}

.navbar.is-success .navbar-brand > .navbar-item, .navbar.is-success .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-success .navbar-brand > a.navbar-item:focus, .navbar.is-success .navbar-brand > a.navbar-item:hover, .navbar.is-success .navbar-brand > a.navbar-item.is-active, .navbar.is-success .navbar-brand .navbar-link:focus, .navbar.is-success .navbar-brand .navbar-link:hover, .navbar.is-success .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #53b18a;
}

.navbar.is-success .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-success .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-success .navbar-start > .navbar-item, .navbar.is-success .navbar-start .navbar-link, .navbar.is-success .navbar-end > .navbar-item, .navbar.is-success .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-success .navbar-start > a.navbar-item:focus, .navbar.is-success .navbar-start > a.navbar-item:hover, .navbar.is-success .navbar-start > a.navbar-item.is-active, .navbar.is-success .navbar-start .navbar-link:focus, .navbar.is-success .navbar-start .navbar-link:hover, .navbar.is-success .navbar-start .navbar-link.is-active, .navbar.is-success .navbar-end > a.navbar-item:focus, .navbar.is-success .navbar-end > a.navbar-item:hover, .navbar.is-success .navbar-end > a.navbar-item.is-active, .navbar.is-success .navbar-end .navbar-link:focus, .navbar.is-success .navbar-end .navbar-link:hover, .navbar.is-success .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #53b18a;
  }

  .navbar.is-success .navbar-start .navbar-link:after, .navbar.is-success .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-success .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-success .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-success .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #53b18a;
  }

  .navbar.is-success .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #65b996;
  }
}

.navbar.is-warning {
  color: #000000b3;
  background-color: #fbdc8e;
}

.navbar.is-warning .navbar-brand > .navbar-item, .navbar.is-warning .navbar-brand .navbar-link {
  color: #000000b3;
}

.navbar.is-warning .navbar-brand > a.navbar-item:focus, .navbar.is-warning .navbar-brand > a.navbar-item:hover, .navbar.is-warning .navbar-brand > a.navbar-item.is-active, .navbar.is-warning .navbar-brand .navbar-link:focus, .navbar.is-warning .navbar-brand .navbar-link:hover, .navbar.is-warning .navbar-brand .navbar-link.is-active {
  color: #000000b3;
  background-color: #fad475;
}

.navbar.is-warning .navbar-brand .navbar-link:after {
  border-color: #000000b3;
}

.navbar.is-warning .navbar-burger {
  color: #000000b3;
}

@media screen and (width >= 1024px) {
  .navbar.is-warning .navbar-start > .navbar-item, .navbar.is-warning .navbar-start .navbar-link, .navbar.is-warning .navbar-end > .navbar-item, .navbar.is-warning .navbar-end .navbar-link {
    color: #000000b3;
  }

  .navbar.is-warning .navbar-start > a.navbar-item:focus, .navbar.is-warning .navbar-start > a.navbar-item:hover, .navbar.is-warning .navbar-start > a.navbar-item.is-active, .navbar.is-warning .navbar-start .navbar-link:focus, .navbar.is-warning .navbar-start .navbar-link:hover, .navbar.is-warning .navbar-start .navbar-link.is-active, .navbar.is-warning .navbar-end > a.navbar-item:focus, .navbar.is-warning .navbar-end > a.navbar-item:hover, .navbar.is-warning .navbar-end > a.navbar-item.is-active, .navbar.is-warning .navbar-end .navbar-link:focus, .navbar.is-warning .navbar-end .navbar-link:hover, .navbar.is-warning .navbar-end .navbar-link.is-active {
    color: #000000b3;
    background-color: #fad475;
  }

  .navbar.is-warning .navbar-start .navbar-link:after, .navbar.is-warning .navbar-end .navbar-link:after {
    border-color: #000000b3;
  }

  .navbar.is-warning .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-warning .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-warning .navbar-item.has-dropdown.is-active .navbar-link {
    color: #000000b3;
    background-color: #fad475;
  }

  .navbar.is-warning .navbar-dropdown a.navbar-item.is-active {
    color: #000000b3;
    background-color: #fbdc8e;
  }
}

.navbar.is-danger {
  color: #fff;
  background-color: #fc5c46;
}

.navbar.is-danger .navbar-brand > .navbar-item, .navbar.is-danger .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-danger .navbar-brand > a.navbar-item:focus, .navbar.is-danger .navbar-brand > a.navbar-item:hover, .navbar.is-danger .navbar-brand > a.navbar-item.is-active, .navbar.is-danger .navbar-brand .navbar-link:focus, .navbar.is-danger .navbar-brand .navbar-link:hover, .navbar.is-danger .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #fc462d;
}

.navbar.is-danger .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-danger .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-danger .navbar-start > .navbar-item, .navbar.is-danger .navbar-start .navbar-link, .navbar.is-danger .navbar-end > .navbar-item, .navbar.is-danger .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-danger .navbar-start > a.navbar-item:focus, .navbar.is-danger .navbar-start > a.navbar-item:hover, .navbar.is-danger .navbar-start > a.navbar-item.is-active, .navbar.is-danger .navbar-start .navbar-link:focus, .navbar.is-danger .navbar-start .navbar-link:hover, .navbar.is-danger .navbar-start .navbar-link.is-active, .navbar.is-danger .navbar-end > a.navbar-item:focus, .navbar.is-danger .navbar-end > a.navbar-item:hover, .navbar.is-danger .navbar-end > a.navbar-item.is-active, .navbar.is-danger .navbar-end .navbar-link:focus, .navbar.is-danger .navbar-end .navbar-link:hover, .navbar.is-danger .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #fc462d;
  }

  .navbar.is-danger .navbar-start .navbar-link:after, .navbar.is-danger .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-danger .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-danger .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-danger .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #fc462d;
  }

  .navbar.is-danger .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #fc5c46;
  }
}

.navbar > .container {
  align-items: stretch;
  width: 100%;
  min-height: 6rem;
  display: flex;
}

.navbar.has-shadow {
  box-shadow: 0 2px #fafaf9;
}

.navbar.is-fixed-bottom, .navbar.is-fixed-top {
  z-index: 30;
  position: fixed;
  left: 0;
  right: 0;
}

.navbar.is-fixed-bottom {
  bottom: 0;
}

.navbar.is-fixed-bottom.has-shadow {
  box-shadow: 0 -2px #fafaf9;
}

.navbar.is-fixed-top {
  top: 0;
}

html.has-navbar-fixed-top, body.has-navbar-fixed-top {
  padding-top: 6rem;
}

html.has-navbar-fixed-bottom, body.has-navbar-fixed-bottom {
  padding-bottom: 6rem;
}

.navbar-brand, .navbar-tabs {
  flex-shrink: 0;
  align-items: stretch;
  min-height: 6rem;
  display: flex;
}

.navbar-brand a.navbar-item:focus, .navbar-brand a.navbar-item:hover {
  background-color: #0000;
}

.navbar-tabs {
  -webkit-overflow-scrolling: touch;
  max-width: 100vw;
  overflow: auto hidden;
}

.navbar-burger {
  color: #000;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background: none;
  border: none;
  width: 6rem;
  height: 6rem;
  margin-left: auto;
  display: block;
  position: relative;
}

.navbar-burger span {
  transform-origin: center;
  background-color: currentColor;
  width: 16px;
  height: 1px;
  transition-property: background-color, opacity, transform;
  transition-duration: 86ms;
  transition-timing-function: ease-out;
  display: block;
  position: absolute;
  left: calc(50% - 8px);
}

.navbar-burger span:first-child {
  top: calc(50% - 6px);
}

.navbar-burger span:nth-child(2) {
  top: calc(50% - 1px);
}

.navbar-burger span:nth-child(3) {
  top: calc(50% + 4px);
}

.navbar-burger:hover {
  background-color: #0000000d;
}

.navbar-burger.is-active span:first-child {
  transform: translateY(5px)rotate(45deg);
}

.navbar-burger.is-active span:nth-child(2) {
  opacity: 0;
}

.navbar-burger.is-active span:nth-child(3) {
  transform: translateY(-5px)rotate(-45deg);
}

.navbar-menu {
  display: none;
}

.navbar-item, .navbar-link {
  color: #000;
  padding: .5rem .75rem;
  line-height: 1.5;
  display: block;
  position: relative;
}

.navbar-item .icon:only-child, .navbar-link .icon:only-child {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

a.navbar-item, .navbar-link {
  cursor: pointer;
}

a.navbar-item:focus, a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:focus, .navbar-link:hover, .navbar-link.is-active, a.navbar-item:focus-within, .navbar-link:focus-within {
  color: #000;
  background-color: #0000;
}

.navbar-item {
  flex-grow: 0;
  flex-shrink: 0;
}

.navbar-item img {
  max-height: 1.75rem;
}

.navbar-item.has-dropdown {
  padding: 0;
}

.navbar-item.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.navbar-item.is-tab {
  border-bottom: 1px solid #0000;
  min-height: 6rem;
  padding-bottom: calc(.5rem - 1px);
}

.navbar-item.is-tab:focus, .navbar-item.is-tab:hover {
  background-color: #0000;
  border-bottom-color: #004bff;
}

.navbar-item.is-tab.is-active {
  color: #004bff;
  background-color: #0000;
  border-bottom: 3px solid #004bff;
  padding-bottom: calc(.5rem - 3px);
}

.navbar-content {
  flex-grow: 1;
  flex-shrink: 1;
}

.navbar-link:not(.is-arrowless) {
  padding-right: 2.5em;
}

.navbar-link:not(.is-arrowless):after {
  border-color: #004bff;
  margin-top: -.375em;
  right: 1.125em;
}

.navbar-dropdown {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: .875rem;
}

.navbar-dropdown .navbar-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.navbar-divider {
  background-color: #fafaf9;
  border: none;
  height: 2px;
  margin: .5rem 0;
  display: none;
}

@media screen and (width <= 1023px) {
  .navbar > .container {
    display: block;
  }

  .navbar-brand .navbar-item, .navbar-tabs .navbar-item {
    align-items: center;
    display: flex;
  }

  .navbar-link:after {
    display: none;
  }

  .navbar-menu {
    background-color: #fff;
    padding: .5rem 0;
    box-shadow: 0 8px 16px #0a0a0a1a;
  }

  .navbar-menu.is-active {
    display: block;
  }

  .navbar.is-fixed-bottom-touch, .navbar.is-fixed-top-touch {
    z-index: 30;
    position: fixed;
    left: 0;
    right: 0;
  }

  .navbar.is-fixed-bottom-touch {
    bottom: 0;
  }

  .navbar.is-fixed-bottom-touch.has-shadow {
    box-shadow: 0 -2px 3px #0a0a0a1a;
  }

  .navbar.is-fixed-top-touch {
    top: 0;
  }

  .navbar.is-fixed-top .navbar-menu, .navbar.is-fixed-top-touch .navbar-menu {
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 6rem);
    overflow: auto;
  }

  html.has-navbar-fixed-top-touch, body.has-navbar-fixed-top-touch {
    padding-top: 6rem;
  }

  html.has-navbar-fixed-bottom-touch, body.has-navbar-fixed-bottom-touch {
    padding-bottom: 6rem;
  }
}

@media screen and (width >= 1024px) {
  .navbar, .navbar-menu, .navbar-start, .navbar-end {
    align-items: stretch;
    display: flex;
  }

  .navbar {
    min-height: 6rem;
  }

  .navbar.is-spaced, .navbar.hero-title {
    padding: 1rem 2rem;
  }

  .navbar.is-spaced .navbar-start, .navbar.hero-title .navbar-start, .navbar.is-spaced .navbar-end, .navbar.hero-title .navbar-end {
    align-items: center;
  }

  .navbar.is-spaced a.navbar-item, .navbar.hero-title a.navbar-item, .navbar.is-spaced .navbar-link, .navbar.hero-title .navbar-link {
    border-radius: 0;
  }

  .navbar.is-transparent a.navbar-item:focus, .navbar.is-transparent a.navbar-item:hover, .navbar.is-transparent a.navbar-item.is-active, .navbar.is-transparent .navbar-link:focus, .navbar.is-transparent .navbar-link:hover, .navbar.is-transparent .navbar-link.is-active, .navbar.is-transparent .navbar-item.has-dropdown.is-active .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:focus .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:hover .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:focus-within .navbar-link {
    background-color: #0000 !important;
  }

  .navbar.is-transparent .navbar-dropdown a.navbar-item:focus, .navbar.is-transparent .navbar-dropdown a.navbar-item:hover {
    color: #0a0a0a;
    background-color: #fafaf9;
  }

  .navbar.is-transparent .navbar-dropdown a.navbar-item.is-active {
    color: #004bff;
    background-color: #fafaf9;
  }

  .navbar-burger {
    display: none;
  }

  .navbar-item, .navbar-link {
    align-items: center;
    display: flex;
  }

  .navbar-item.has-dropdown {
    align-items: stretch;
  }

  .navbar-item.has-dropdown-up .navbar-link:after {
    transform: rotate(135deg)translate(.25em, -.25em);
  }

  .navbar-item.has-dropdown-up .navbar-dropdown {
    border-top: none;
    border-bottom: 2px solid #e7e5e4;
    border-radius: 9999px 9999px 0 0;
    top: auto;
    bottom: 100%;
    box-shadow: 0 -8px 8px #0a0a0a1a;
  }

  .navbar-item.is-active .navbar-dropdown, .navbar-item.is-hoverable:focus .navbar-dropdown, .navbar-item.is-hoverable:hover .navbar-dropdown, .navbar-item.is-hoverable:focus-within .navbar-dropdown {
    display: block;
  }

  .navbar.is-spaced .navbar-item.is-active .navbar-dropdown, .navbar.hero-title .navbar-item.is-active .navbar-dropdown, .navbar-item.is-active .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:focus .navbar-dropdown, .navbar.hero-title .navbar-item.is-hoverable:focus .navbar-dropdown, .navbar-item.is-hoverable:focus .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:hover .navbar-dropdown, .navbar.hero-title .navbar-item.is-hoverable:hover .navbar-dropdown, .navbar-item.is-hoverable:hover .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:focus-within .navbar-dropdown, .navbar.hero-title .navbar-item.is-hoverable:focus-within .navbar-dropdown, .navbar-item.is-hoverable:focus-within .navbar-dropdown.is-boxed {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }

  .navbar-menu {
    flex-grow: 1;
    flex-shrink: 0;
  }

  .navbar-start {
    justify-content: flex-start;
    margin-right: auto;
  }

  .navbar-end {
    justify-content: flex-end;
    margin-left: auto;
  }

  .navbar-dropdown {
    z-index: 20;
    background-color: #fff;
    border-top: 2px solid #e7e5e4;
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
    min-width: 100%;
    font-size: .875rem;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0 8px 8px #0a0a0a1a;
  }

  .navbar-dropdown .navbar-item {
    white-space: nowrap;
    padding: .375rem 1rem;
  }

  .navbar-dropdown a.navbar-item {
    padding-right: 3rem;
  }

  .navbar-dropdown a.navbar-item:focus, .navbar-dropdown a.navbar-item:hover {
    color: #0a0a0a;
    background-color: #fafaf9;
  }

  .navbar-dropdown a.navbar-item.is-active {
    color: #004bff;
    background-color: #fafaf9;
  }

  .navbar.is-spaced .navbar-dropdown, .navbar.hero-title .navbar-dropdown, .navbar-dropdown.is-boxed {
    opacity: 0;
    pointer-events: none;
    border-top: none;
    border-radius: 9999px;
    transition-property: opacity, transform;
    transition-duration: 86ms;
    display: block;
    top: calc(100% - 4px);
    transform: translateY(-5px);
    box-shadow: 0 8px 8px #0a0a0a1a, 0 0 0 1px #0a0a0a1a;
  }

  .navbar-dropdown.is-right {
    left: auto;
    right: 0;
  }

  .navbar-divider {
    display: block;
  }

  .navbar > .container .navbar-brand, .container > .navbar .navbar-brand {
    margin-left: -.75rem;
  }

  .navbar > .container .navbar-menu, .container > .navbar .navbar-menu {
    margin-right: -.75rem;
  }

  .navbar.is-fixed-bottom-desktop, .navbar.is-fixed-top-desktop {
    z-index: 30;
    position: fixed;
    left: 0;
    right: 0;
  }

  .navbar.is-fixed-bottom-desktop {
    bottom: 0;
  }

  .navbar.is-fixed-bottom-desktop.has-shadow {
    box-shadow: 0 -2px 3px #0a0a0a1a;
  }

  .navbar.is-fixed-top-desktop {
    top: 0;
  }

  html.has-navbar-fixed-top-desktop, body.has-navbar-fixed-top-desktop {
    padding-top: 6rem;
  }

  html.has-navbar-fixed-bottom-desktop, body.has-navbar-fixed-bottom-desktop {
    padding-bottom: 6rem;
  }

  html.has-spaced-navbar-fixed-top, body.has-spaced-navbar-fixed-top {
    padding-top: 8rem;
  }

  html.has-spaced-navbar-fixed-bottom, body.has-spaced-navbar-fixed-bottom {
    padding-bottom: 8rem;
  }

  a.navbar-item.is-active, .navbar-link.is-active {
    color: #0a0a0a;
  }

  a.navbar-item.is-active:not(:focus):not(:hover), .navbar-link.is-active:not(:focus):not(:hover), .navbar-item.has-dropdown:focus .navbar-link, .navbar-item.has-dropdown:hover .navbar-link, .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: #0000;
  }
}

.pagination {
  margin: -.25rem;
  font-size: .875rem;
}

.pagination.is-small {
  font-size: .75rem;
}

.pagination.is-medium {
  font-size: 1rem;
}

.pagination.is-large {
  font-size: 1.25rem;
}

.pagination.is-rounded .pagination-previous, .pagination.is-rounded .pagination-next {
  border-radius: 9999px;
  padding-left: 1em;
  padding-right: 1em;
}

.pagination.is-rounded .pagination-link {
  border-radius: 9999px;
}

.pagination, .pagination-list {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis {
  text-align: center;
  justify-content: center;
  margin: .25rem;
  padding-left: .5em;
  padding-right: .5em;
  font-size: 1em;
}

.pagination-previous, .pagination-next, .pagination-link {
  color: #292524;
  border-color: #e7e5e4;
  min-width: 3.3em;
}

.pagination-previous:hover, .pagination-next:hover, .pagination-link:hover {
  color: #004bff;
  border-color: #004e41;
}

.pagination-previous:focus, .pagination-next:focus, .pagination-link:focus {
  border-color: #004e41;
}

.pagination-previous:active, .pagination-next:active, .pagination-link:active {
  box-shadow: inset 0 1px 2px #0a0a0a33;
}

.pagination-previous[disabled], .pagination-previous.is-disabled, .pagination-next[disabled], .pagination-next.is-disabled, .pagination-link[disabled], .pagination-link.is-disabled {
  box-shadow: none;
  color: #7a7a7a;
  opacity: .5;
  background-color: #e7e5e4;
  border-color: #e7e5e4;
}

.pagination-previous, .pagination-next {
  white-space: nowrap;
  padding-left: .75em;
  padding-right: .75em;
}

.pagination-link.is-current {
  color: #fff;
  background-color: #004bff;
  border-color: #004bff;
}

.pagination-ellipsis {
  color: #a8a29e;
  pointer-events: none;
}

.pagination-list {
  flex-wrap: wrap;
}

.pagination-list li {
  list-style: none;
}

@media screen and (width <= 768px) {
  .pagination {
    flex-wrap: wrap;
  }

  .pagination-previous, .pagination-next, .pagination-list li {
    flex-grow: 1;
    flex-shrink: 1;
  }
}

@media screen and (width >= 769px), print {
  .pagination-list {
    flex-grow: 1;
    flex-shrink: 1;
    order: 1;
    justify-content: flex-start;
  }

  .pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis {
    margin-top: 0;
    margin-bottom: 0;
  }

  .pagination-previous {
    order: 2;
  }

  .pagination-next {
    order: 3;
  }

  .pagination {
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 0;
  }

  .pagination.is-centered .pagination-previous {
    order: 1;
  }

  .pagination.is-centered .pagination-list {
    order: 2;
    justify-content: center;
  }

  .pagination.is-centered .pagination-next {
    order: 3;
  }

  .pagination.is-right .pagination-previous {
    order: 1;
  }

  .pagination.is-right .pagination-next {
    order: 2;
  }

  .pagination.is-right .pagination-list {
    order: 3;
    justify-content: flex-end;
  }
}

.panel {
  box-shadow: none;
  border-radius: .25rem;
  font-size: .875rem;
}

.panel:not(:last-child) {
  margin-bottom: 1.5rem;
}

.panel.is-white .panel-heading {
  color: #222;
  background-color: #fff;
}

.panel.is-white .panel-tabs a.is-active {
  border-bottom-color: #fff;
}

.panel.is-white .panel-block.is-active .panel-icon {
  color: #fff;
}

.panel.is-black .panel-heading {
  color: #fff;
  background-color: #222;
}

.panel.is-black .panel-tabs a.is-active {
  border-bottom-color: #222;
}

.panel.is-black .panel-block.is-active .panel-icon {
  color: #222;
}

.panel.is-light .panel-heading {
  color: #000000b3;
  background-color: #f5f5f4;
}

.panel.is-light .panel-tabs a.is-active {
  border-bottom-color: #f5f5f4;
}

.panel.is-light .panel-block.is-active .panel-icon {
  color: #f5f5f4;
}

.panel.is-dark .panel-heading {
  color: #fff;
  background-color: #004bff;
}

.panel.is-dark .panel-tabs a.is-active {
  border-bottom-color: #004bff;
}

.panel.is-dark .panel-block.is-active .panel-icon {
  color: #004bff;
}

.panel.is-primary .panel-heading {
  color: #fff;
  background-color: #004bff;
}

.panel.is-primary .panel-tabs a.is-active {
  border-bottom-color: #004bff;
}

.panel.is-primary .panel-block.is-active .panel-icon {
  color: #004bff;
}

.panel.is-link .panel-heading {
  color: #fff;
  background-color: #004bff;
}

.panel.is-link .panel-tabs a.is-active {
  border-bottom-color: #004bff;
}

.panel.is-link .panel-block.is-active .panel-icon {
  color: #004bff;
}

.panel.is-info .panel-heading {
  color: #fff;
  background-color: #56a2bd;
}

.panel.is-info .panel-tabs a.is-active {
  border-bottom-color: #56a2bd;
}

.panel.is-info .panel-block.is-active .panel-icon {
  color: #56a2bd;
}

.panel.is-success .panel-heading {
  color: #fff;
  background-color: #65b996;
}

.panel.is-success .panel-tabs a.is-active {
  border-bottom-color: #65b996;
}

.panel.is-success .panel-block.is-active .panel-icon {
  color: #65b996;
}

.panel.is-warning .panel-heading {
  color: #000000b3;
  background-color: #fbdc8e;
}

.panel.is-warning .panel-tabs a.is-active {
  border-bottom-color: #fbdc8e;
}

.panel.is-warning .panel-block.is-active .panel-icon {
  color: #fbdc8e;
}

.panel.is-danger .panel-heading {
  color: #fff;
  background-color: #fc5c46;
}

.panel.is-danger .panel-tabs a.is-active {
  border-bottom-color: #fc5c46;
}

.panel.is-danger .panel-block.is-active .panel-icon {
  color: #fc5c46;
}

.panel-tabs:not(:last-child), .panel-block:not(:last-child) {
  border-bottom: 1px solid #fafaf9;
}

.panel-heading {
  color: #292524;
  background-color: #fafaf9;
  border-radius: .25rem .25rem 0 0;
  padding: .75em 1em;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.25;
}

.panel-tabs {
  justify-content: center;
  align-items: flex-end;
  font-size: .875em;
  display: flex;
}

.panel-tabs a {
  border-bottom: 1px solid #e7e5e4;
  margin-bottom: -1px;
  padding: .5em;
}

.panel-tabs a.is-active {
  color: #004e41;
  border-bottom-color: #004e41;
}

.panel-list a {
  color: #44403c;
}

.panel-list a:hover {
  color: #004bff;
}

.panel-block {
  color: #292524;
  justify-content: flex-start;
  align-items: center;
  padding: .5em .75em;
  display: flex;
}

.panel-block input[type="checkbox"] {
  margin-right: .75em;
}

.panel-block > .control {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
}

.panel-block.is-wrapped {
  flex-wrap: wrap;
}

.panel-block.is-active {
  color: #004e41;
  border-left-color: #004bff;
}

.panel-block.is-active .panel-icon {
  color: #004bff;
}

.panel-block:last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

a.panel-block, label.panel-block {
  cursor: pointer;
}

a.panel-block:hover, label.panel-block:hover {
  background-color: #fafaf9;
}

.panel-icon {
  text-align: center;
  vertical-align: top;
  color: #7a7a7a;
  width: 1em;
  height: 1em;
  margin-right: .75em;
  font-size: 14px;
  line-height: 1em;
  display: inline-block;
}

.panel-icon .fa {
  font-size: inherit;
  line-height: inherit;
}

.tabs {
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  justify-content: space-between;
  align-items: stretch;
  font-size: .875rem;
  display: flex;
  overflow: auto hidden;
}

.tabs a {
  color: #44403c;
  vertical-align: top;
  border-bottom: 1px solid #e7e5e4;
  justify-content: center;
  align-items: center;
  margin-bottom: -1px;
  padding: .5em 1em;
  display: flex;
}

.tabs a:hover {
  color: #292524;
  border-bottom-color: #292524;
}

.tabs li {
  display: block;
}

.tabs li.is-active a {
  color: #004bff;
  border-bottom-color: #004bff;
}

.tabs ul {
  border-bottom: 1px solid #e7e5e4;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.tabs ul.is-left {
  padding-right: .75em;
}

.tabs ul.is-center {
  flex: none;
  justify-content: center;
  padding-left: .75em;
  padding-right: .75em;
}

.tabs ul.is-right {
  justify-content: flex-end;
  padding-left: .75em;
}

.tabs .icon:first-child {
  margin-right: .5em;
}

.tabs .icon:last-child {
  margin-left: .5em;
}

.tabs.is-centered ul {
  justify-content: center;
}

.tabs.is-right ul {
  justify-content: flex-end;
}

.tabs.is-boxed a {
  border: 1px solid #0000;
  border-radius: 0;
}

.tabs.is-boxed a:hover {
  background-color: #fafaf9;
  border-bottom-color: #e7e5e4;
}

.tabs.is-boxed li.is-active a {
  background-color: #fff;
  border-color: #e7e5e4;
  border-bottom-color: #0000 !important;
}

.tabs.is-fullwidth li {
  flex-grow: 1;
  flex-shrink: 0;
}

.tabs.is-toggle a {
  border: 1px solid #e7e5e4;
  margin-bottom: 0;
  position: relative;
}

.tabs.is-toggle a:hover {
  z-index: 2;
  background-color: #fafaf9;
  border-color: #f5f5f4;
}

.tabs.is-toggle li + li {
  margin-left: -1px;
}

.tabs.is-toggle li:first-child a {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.tabs.is-toggle li:last-child a {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tabs.is-toggle li.is-active a {
  color: #fff;
  z-index: 1;
  background-color: #004bff;
  border-color: #004bff;
}

.tabs.is-toggle ul {
  border-bottom: none;
}

.tabs.is-toggle.is-toggle-rounded li:first-child a {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
  padding-left: 1.25em;
}

.tabs.is-toggle.is-toggle-rounded li:last-child a {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
  padding-right: 1.25em;
}

.tabs.is-small {
  font-size: .75rem;
}

.tabs.is-medium {
  font-size: 1rem;
}

.tabs.is-large {
  font-size: 1.25rem;
}

.column {
  flex: 1 1 0;
  padding: .75rem;
  display: block;
}

.columns.is-mobile > .column.is-narrow {
  width: unset;
  flex: none;
}

.columns.is-mobile > .column.is-full {
  flex: none;
  width: 100%;
}

.columns.is-mobile > .column.is-three-quarters {
  flex: none;
  width: 75%;
}

.columns.is-mobile > .column.is-two-thirds {
  flex: none;
  width: 66.6666%;
}

.columns.is-mobile > .column.is-half {
  flex: none;
  width: 50%;
}

.columns.is-mobile > .column.is-one-third {
  flex: none;
  width: 33.3333%;
}

.columns.is-mobile > .column.is-one-quarter {
  flex: none;
  width: 25%;
}

.columns.is-mobile > .column.is-one-fifth {
  flex: none;
  width: 20%;
}

.columns.is-mobile > .column.is-two-fifths {
  flex: none;
  width: 40%;
}

.columns.is-mobile > .column.is-three-fifths {
  flex: none;
  width: 60%;
}

.columns.is-mobile > .column.is-four-fifths {
  flex: none;
  width: 80%;
}

.columns.is-mobile > .column.is-offset-three-quarters {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-offset-two-thirds {
  margin-left: 66.6666%;
}

.columns.is-mobile > .column.is-offset-half {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-offset-one-third {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-offset-one-quarter {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-offset-one-fifth {
  margin-left: 20%;
}

.columns.is-mobile > .column.is-offset-two-fifths {
  margin-left: 40%;
}

.columns.is-mobile > .column.is-offset-three-fifths {
  margin-left: 60%;
}

.columns.is-mobile > .column.is-offset-four-fifths {
  margin-left: 80%;
}

.columns.is-mobile > .column.is-0 {
  flex: none;
  width: 0%;
}

.columns.is-mobile > .column.is-offset-0 {
  margin-left: 0%;
}

.columns.is-mobile > .column.is-1 {
  flex: none;
  width: 8.33333%;
}

.columns.is-mobile > .column.is-offset-1 {
  margin-left: 8.33333%;
}

.columns.is-mobile > .column.is-2 {
  flex: none;
  width: 16.6667%;
}

.columns.is-mobile > .column.is-offset-2 {
  margin-left: 16.6667%;
}

.columns.is-mobile > .column.is-3 {
  flex: none;
  width: 25%;
}

.columns.is-mobile > .column.is-offset-3 {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-4 {
  flex: none;
  width: 33.3333%;
}

.columns.is-mobile > .column.is-offset-4 {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-5 {
  flex: none;
  width: 41.6667%;
}

.columns.is-mobile > .column.is-offset-5 {
  margin-left: 41.6667%;
}

.columns.is-mobile > .column.is-6 {
  flex: none;
  width: 50%;
}

.columns.is-mobile > .column.is-offset-6 {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-7 {
  flex: none;
  width: 58.3333%;
}

.columns.is-mobile > .column.is-offset-7 {
  margin-left: 58.3333%;
}

.columns.is-mobile > .column.is-8 {
  flex: none;
  width: 66.6667%;
}

.columns.is-mobile > .column.is-offset-8 {
  margin-left: 66.6667%;
}

.columns.is-mobile > .column.is-9 {
  flex: none;
  width: 75%;
}

.columns.is-mobile > .column.is-offset-9 {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-10 {
  flex: none;
  width: 83.3333%;
}

.columns.is-mobile > .column.is-offset-10 {
  margin-left: 83.3333%;
}

.columns.is-mobile > .column.is-11 {
  flex: none;
  width: 91.6667%;
}

.columns.is-mobile > .column.is-offset-11 {
  margin-left: 91.6667%;
}

.columns.is-mobile > .column.is-12 {
  flex: none;
  width: 100%;
}

.columns.is-mobile > .column.is-offset-12 {
  margin-left: 100%;
}

@media screen and (width <= 768px) {
  .column.is-narrow-mobile {
    width: unset;
    flex: none;
  }

  .column.is-full-mobile {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-mobile {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-mobile {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-mobile {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-mobile {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-mobile {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-mobile {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-mobile {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-mobile {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-mobile {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-mobile {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-mobile {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-mobile {
    margin-left: 50%;
  }

  .column.is-offset-one-third-mobile {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-mobile {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-mobile {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-mobile {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-mobile {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-mobile {
    margin-left: 80%;
  }

  .column.is-0-mobile {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-mobile {
    margin-left: 0%;
  }

  .column.is-1-mobile {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-mobile {
    margin-left: 8.33333%;
  }

  .column.is-2-mobile {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-mobile {
    margin-left: 16.6667%;
  }

  .column.is-3-mobile {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-mobile {
    margin-left: 25%;
  }

  .column.is-4-mobile {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-mobile {
    margin-left: 33.3333%;
  }

  .column.is-5-mobile {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-mobile {
    margin-left: 41.6667%;
  }

  .column.is-6-mobile {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-mobile {
    margin-left: 50%;
  }

  .column.is-7-mobile {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-mobile {
    margin-left: 58.3333%;
  }

  .column.is-8-mobile {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-mobile {
    margin-left: 66.6667%;
  }

  .column.is-9-mobile {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-mobile {
    margin-left: 75%;
  }

  .column.is-10-mobile {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-mobile {
    margin-left: 83.3333%;
  }

  .column.is-11-mobile {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-mobile {
    margin-left: 91.6667%;
  }

  .column.is-12-mobile {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-mobile {
    margin-left: 100%;
  }
}

@media screen and (width >= 769px), print {
  .column.is-narrow, .column.is-narrow-tablet {
    width: unset;
    flex: none;
  }

  .column.is-full, .column.is-full-tablet {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters, .column.is-three-quarters-tablet {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds, .column.is-two-thirds-tablet {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half, .column.is-half-tablet {
    flex: none;
    width: 50%;
  }

  .column.is-one-third, .column.is-one-third-tablet {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter, .column.is-one-quarter-tablet {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth, .column.is-one-fifth-tablet {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths, .column.is-two-fifths-tablet {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths, .column.is-three-fifths-tablet {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths, .column.is-four-fifths-tablet {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters, .column.is-offset-three-quarters-tablet {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds, .column.is-offset-two-thirds-tablet {
    margin-left: 66.6666%;
  }

  .column.is-offset-half, .column.is-offset-half-tablet {
    margin-left: 50%;
  }

  .column.is-offset-one-third, .column.is-offset-one-third-tablet {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter, .column.is-offset-one-quarter-tablet {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth, .column.is-offset-one-fifth-tablet {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths, .column.is-offset-two-fifths-tablet {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths, .column.is-offset-three-fifths-tablet {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths, .column.is-offset-four-fifths-tablet {
    margin-left: 80%;
  }

  .column.is-0, .column.is-0-tablet {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0, .column.is-offset-0-tablet {
    margin-left: 0%;
  }

  .column.is-1, .column.is-1-tablet {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1, .column.is-offset-1-tablet {
    margin-left: 8.33333%;
  }

  .column.is-2, .column.is-2-tablet {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2, .column.is-offset-2-tablet {
    margin-left: 16.6667%;
  }

  .column.is-3, .column.is-3-tablet {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3, .column.is-offset-3-tablet {
    margin-left: 25%;
  }

  .column.is-4, .column.is-4-tablet {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4, .column.is-offset-4-tablet {
    margin-left: 33.3333%;
  }

  .column.is-5, .column.is-5-tablet {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5, .column.is-offset-5-tablet {
    margin-left: 41.6667%;
  }

  .column.is-6, .column.is-6-tablet {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6, .column.is-offset-6-tablet {
    margin-left: 50%;
  }

  .column.is-7, .column.is-7-tablet {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7, .column.is-offset-7-tablet {
    margin-left: 58.3333%;
  }

  .column.is-8, .column.is-8-tablet {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8, .column.is-offset-8-tablet {
    margin-left: 66.6667%;
  }

  .column.is-9, .column.is-9-tablet {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9, .column.is-offset-9-tablet {
    margin-left: 75%;
  }

  .column.is-10, .column.is-10-tablet {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10, .column.is-offset-10-tablet {
    margin-left: 83.3333%;
  }

  .column.is-11, .column.is-11-tablet {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11, .column.is-offset-11-tablet {
    margin-left: 91.6667%;
  }

  .column.is-12, .column.is-12-tablet {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12, .column.is-offset-12-tablet {
    margin-left: 100%;
  }
}

@media screen and (width <= 1023px) {
  .column.is-narrow-touch {
    width: unset;
    flex: none;
  }

  .column.is-full-touch {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-touch {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-touch {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-touch {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-touch {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-touch {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-touch {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-touch {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-touch {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-touch {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-touch {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-touch {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-touch {
    margin-left: 50%;
  }

  .column.is-offset-one-third-touch {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-touch {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-touch {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-touch {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-touch {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-touch {
    margin-left: 80%;
  }

  .column.is-0-touch {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-touch {
    margin-left: 0%;
  }

  .column.is-1-touch {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-touch {
    margin-left: 8.33333%;
  }

  .column.is-2-touch {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-touch {
    margin-left: 16.6667%;
  }

  .column.is-3-touch {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-touch {
    margin-left: 25%;
  }

  .column.is-4-touch {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-touch {
    margin-left: 33.3333%;
  }

  .column.is-5-touch {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-touch {
    margin-left: 41.6667%;
  }

  .column.is-6-touch {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-touch {
    margin-left: 50%;
  }

  .column.is-7-touch {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-touch {
    margin-left: 58.3333%;
  }

  .column.is-8-touch {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-touch {
    margin-left: 66.6667%;
  }

  .column.is-9-touch {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-touch {
    margin-left: 75%;
  }

  .column.is-10-touch {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-touch {
    margin-left: 83.3333%;
  }

  .column.is-11-touch {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-touch {
    margin-left: 91.6667%;
  }

  .column.is-12-touch {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-touch {
    margin-left: 100%;
  }
}

@media screen and (width >= 1024px) {
  .column.is-narrow-desktop {
    width: unset;
    flex: none;
  }

  .column.is-full-desktop {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-desktop {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-desktop {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-desktop {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-desktop {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-desktop {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-desktop {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-desktop {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-desktop {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-desktop {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-desktop {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-desktop {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-desktop {
    margin-left: 50%;
  }

  .column.is-offset-one-third-desktop {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-desktop {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-desktop {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-desktop {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-desktop {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-desktop {
    margin-left: 80%;
  }

  .column.is-0-desktop {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-desktop {
    margin-left: 0%;
  }

  .column.is-1-desktop {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-desktop {
    margin-left: 8.33333%;
  }

  .column.is-2-desktop {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-desktop {
    margin-left: 16.6667%;
  }

  .column.is-3-desktop {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-desktop {
    margin-left: 25%;
  }

  .column.is-4-desktop {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-desktop {
    margin-left: 33.3333%;
  }

  .column.is-5-desktop {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-desktop {
    margin-left: 41.6667%;
  }

  .column.is-6-desktop {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-desktop {
    margin-left: 50%;
  }

  .column.is-7-desktop {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-desktop {
    margin-left: 58.3333%;
  }

  .column.is-8-desktop {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-desktop {
    margin-left: 66.6667%;
  }

  .column.is-9-desktop {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-desktop {
    margin-left: 75%;
  }

  .column.is-10-desktop {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-desktop {
    margin-left: 83.3333%;
  }

  .column.is-11-desktop {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-desktop {
    margin-left: 91.6667%;
  }

  .column.is-12-desktop {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-desktop {
    margin-left: 100%;
  }
}

@media screen and (width >= 1216px) {
  .column.is-narrow-widescreen {
    width: unset;
    flex: none;
  }

  .column.is-full-widescreen {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-widescreen {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-widescreen {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-widescreen {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-widescreen {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-widescreen {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-widescreen {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-widescreen {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-widescreen {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-widescreen {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-widescreen {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-widescreen {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-widescreen {
    margin-left: 50%;
  }

  .column.is-offset-one-third-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-widescreen {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-widescreen {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-widescreen {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-widescreen {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-widescreen {
    margin-left: 80%;
  }

  .column.is-0-widescreen {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-widescreen {
    margin-left: 0%;
  }

  .column.is-1-widescreen {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-widescreen {
    margin-left: 8.33333%;
  }

  .column.is-2-widescreen {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-widescreen {
    margin-left: 16.6667%;
  }

  .column.is-3-widescreen {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-widescreen {
    margin-left: 25%;
  }

  .column.is-4-widescreen {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-5-widescreen {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-widescreen {
    margin-left: 41.6667%;
  }

  .column.is-6-widescreen {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-widescreen {
    margin-left: 50%;
  }

  .column.is-7-widescreen {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-widescreen {
    margin-left: 58.3333%;
  }

  .column.is-8-widescreen {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-widescreen {
    margin-left: 66.6667%;
  }

  .column.is-9-widescreen {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-widescreen {
    margin-left: 75%;
  }

  .column.is-10-widescreen {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-widescreen {
    margin-left: 83.3333%;
  }

  .column.is-11-widescreen {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-widescreen {
    margin-left: 91.6667%;
  }

  .column.is-12-widescreen {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-widescreen {
    margin-left: 100%;
  }
}

@media screen and (width >= 1408px) {
  .column.is-narrow-fullhd {
    width: unset;
    flex: none;
  }

  .column.is-full-fullhd {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-fullhd {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-fullhd {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-fullhd {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-fullhd {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-fullhd {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-fullhd {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-fullhd {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-fullhd {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-fullhd {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-fullhd {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-fullhd {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-fullhd {
    margin-left: 50%;
  }

  .column.is-offset-one-third-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-fullhd {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-fullhd {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-fullhd {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-fullhd {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-fullhd {
    margin-left: 80%;
  }

  .column.is-0-fullhd {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-fullhd {
    margin-left: 0%;
  }

  .column.is-1-fullhd {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-fullhd {
    margin-left: 8.33333%;
  }

  .column.is-2-fullhd {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-fullhd {
    margin-left: 16.6667%;
  }

  .column.is-3-fullhd {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-fullhd {
    margin-left: 25%;
  }

  .column.is-4-fullhd {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-5-fullhd {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-fullhd {
    margin-left: 41.6667%;
  }

  .column.is-6-fullhd {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-fullhd {
    margin-left: 50%;
  }

  .column.is-7-fullhd {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-fullhd {
    margin-left: 58.3333%;
  }

  .column.is-8-fullhd {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-fullhd {
    margin-left: 66.6667%;
  }

  .column.is-9-fullhd {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-fullhd {
    margin-left: 75%;
  }

  .column.is-10-fullhd {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-fullhd {
    margin-left: 83.3333%;
  }

  .column.is-11-fullhd {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-fullhd {
    margin-left: 91.6667%;
  }

  .column.is-12-fullhd {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-fullhd {
    margin-left: 100%;
  }
}

.columns {
  margin-top: -.75rem;
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.columns:last-child {
  margin-bottom: -.75rem;
}

.columns:not(:last-child) {
  margin-bottom: .75rem;
}

.columns.is-centered {
  justify-content: center;
}

.columns.is-gapless {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.columns.is-gapless > .column {
  margin: 0;
  padding: 0 !important;
}

.columns.is-gapless:not(:last-child) {
  margin-bottom: 1.5rem;
}

.columns.is-gapless:last-child {
  margin-bottom: 0;
}

.columns.is-mobile {
  display: flex;
}

.columns.is-multiline {
  flex-wrap: wrap;
}

.columns.is-vcentered {
  align-items: center;
}

@media screen and (width >= 769px), print {
  .columns:not(.is-desktop) {
    display: flex;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-desktop {
    display: flex;
  }
}

.columns.is-variable {
  --columnGap: .75rem;
  margin-left: calc(-1 * var(--columnGap));
  margin-right: calc(-1 * var(--columnGap));
}

.columns.is-variable > .column {
  padding-left: var(--columnGap);
  padding-right: var(--columnGap);
}

.columns.is-variable.is-0 {
  --columnGap: 0rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-0-mobile {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-0-tablet {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-0-tablet-only {
    --columnGap: 0rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-0-touch {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-0-desktop {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-0-desktop-only {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-0-widescreen {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-0-widescreen-only {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-0-fullhd {
    --columnGap: 0rem;
  }
}

.columns.is-variable.is-1 {
  --columnGap: .25rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-1-mobile {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-1-tablet {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-1-tablet-only {
    --columnGap: .25rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-1-touch {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-1-desktop {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-1-desktop-only {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-1-widescreen {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-1-widescreen-only {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-1-fullhd {
    --columnGap: .25rem;
  }
}

.columns.is-variable.is-2 {
  --columnGap: .5rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-2-mobile {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-2-tablet {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-2-tablet-only {
    --columnGap: .5rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-2-touch {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-2-desktop {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-2-desktop-only {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-2-widescreen {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-2-widescreen-only {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-2-fullhd {
    --columnGap: .5rem;
  }
}

.columns.is-variable.is-3 {
  --columnGap: .75rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-3-mobile {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-3-tablet {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-3-tablet-only {
    --columnGap: .75rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-3-touch {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-3-desktop {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-3-desktop-only {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-3-widescreen {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-3-widescreen-only {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-3-fullhd {
    --columnGap: .75rem;
  }
}

.columns.is-variable.is-4 {
  --columnGap: 1rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-4-mobile {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-4-tablet {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-4-tablet-only {
    --columnGap: 1rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-4-touch {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-4-desktop {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-4-desktop-only {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-4-widescreen {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-4-widescreen-only {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-4-fullhd {
    --columnGap: 1rem;
  }
}

.columns.is-variable.is-5 {
  --columnGap: 1.25rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-5-mobile {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-5-tablet {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-5-tablet-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-5-touch {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-5-desktop {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-5-desktop-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-5-widescreen {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-5-widescreen-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-5-fullhd {
    --columnGap: 1.25rem;
  }
}

.columns.is-variable.is-6 {
  --columnGap: 1.5rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-6-mobile {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-6-tablet {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-6-tablet-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-6-touch {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-6-desktop {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-6-desktop-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-6-widescreen {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-6-widescreen-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-6-fullhd {
    --columnGap: 1.5rem;
  }
}

.columns.is-variable.is-7 {
  --columnGap: 1.75rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-7-mobile {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-7-tablet {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-7-tablet-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-7-touch {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-7-desktop {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-7-desktop-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-7-widescreen {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-7-widescreen-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-7-fullhd {
    --columnGap: 1.75rem;
  }
}

.columns.is-variable.is-8 {
  --columnGap: 2rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-8-mobile {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-8-tablet {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-8-tablet-only {
    --columnGap: 2rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-8-touch {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-8-desktop {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-8-desktop-only {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-8-widescreen {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-8-widescreen-only {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-8-fullhd {
    --columnGap: 2rem;
  }
}

.tile {
  flex: 1 1 0;
  align-items: stretch;
  min-height: min-content;
  display: block;
}

.tile.is-ancestor {
  margin-top: -.75rem;
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.tile.is-ancestor:last-child {
  margin-bottom: -.75rem;
}

.tile.is-ancestor:not(:last-child) {
  margin-bottom: .75rem;
}

.tile.is-child {
  margin: 0 !important;
}

.tile.is-parent {
  padding: .75rem;
}

.tile.is-vertical {
  flex-direction: column;
}

.tile.is-vertical > .tile.is-child:not(:last-child) {
  margin-bottom: 1.5rem !important;
}

@media screen and (width >= 769px), print {
  .tile:not(.is-child) {
    display: flex;
  }

  .tile.is-1 {
    flex: none;
    width: 8.33333%;
  }

  .tile.is-2 {
    flex: none;
    width: 16.6667%;
  }

  .tile.is-3 {
    flex: none;
    width: 25%;
  }

  .tile.is-4 {
    flex: none;
    width: 33.3333%;
  }

  .tile.is-5 {
    flex: none;
    width: 41.6667%;
  }

  .tile.is-6 {
    flex: none;
    width: 50%;
  }

  .tile.is-7 {
    flex: none;
    width: 58.3333%;
  }

  .tile.is-8 {
    flex: none;
    width: 66.6667%;
  }

  .tile.is-9 {
    flex: none;
    width: 75%;
  }

  .tile.is-10 {
    flex: none;
    width: 83.3333%;
  }

  .tile.is-11 {
    flex: none;
    width: 91.6667%;
  }

  .tile.is-12 {
    flex: none;
    width: 100%;
  }
}

.has-text-white {
  color: #fff !important;
}

a.has-text-white:hover, a.has-text-white:focus {
  color: #e6e6e6 !important;
}

.has-background-white {
  background-color: #fff !important;
}

.has-text-black {
  color: #222 !important;
}

a.has-text-black:hover, a.has-text-black:focus {
  color: #090909 !important;
}

.has-background-black {
  background-color: #222 !important;
}

.has-text-light, .has-background-dark .subtitle {
  color: #f5f5f4 !important;
}

a.has-text-light:hover, .has-background-dark a.subtitle:hover, a.has-text-light:focus, .has-background-dark a.subtitle:focus {
  color: #ddddd9 !important;
}

.has-background-light {
  background-color: #f5f5f4 !important;
}

.has-text-dark {
  color: #004bff !important;
}

a.has-text-dark:hover, a.has-text-dark:focus {
  color: #003ccc !important;
}

.has-background-dark {
  background-color: #004bff !important;
}

.has-text-primary {
  color: #004bff !important;
}

a.has-text-primary:hover, a.has-text-primary:focus {
  color: #003ccc !important;
}

.has-background-primary {
  background-color: #004bff !important;
}

.has-text-primary-light {
  color: #ebf1ff !important;
}

a.has-text-primary-light:hover, a.has-text-primary-light:focus {
  color: #b8cdff !important;
}

.has-background-primary-light {
  background-color: #ebf1ff !important;
}

.has-text-primary-dark {
  color: #004afa !important;
}

a.has-text-primary-dark:hover, a.has-text-primary-dark:focus {
  color: #2e6bff !important;
}

.has-background-primary-dark {
  background-color: #004afa !important;
}

.has-text-link {
  color: #004bff !important;
}

a.has-text-link:hover, a.has-text-link:focus {
  color: #003ccc !important;
}

.has-background-link {
  background-color: #004bff !important;
}

.has-text-link-light {
  color: #ebf1ff !important;
}

a.has-text-link-light:hover, a.has-text-link-light:focus {
  color: #b8cdff !important;
}

.has-background-link-light {
  background-color: #ebf1ff !important;
}

.has-text-link-dark {
  color: #004afa !important;
}

a.has-text-link-dark:hover, a.has-text-link-dark:focus {
  color: #2e6bff !important;
}

.has-background-link-dark {
  background-color: #004afa !important;
}

.has-text-info {
  color: #56a2bd !important;
}

a.has-text-info:hover, a.has-text-info:focus {
  color: #3f87a1 !important;
}

.has-background-info {
  background-color: #56a2bd !important;
}

.has-text-info-light {
  color: #f0f7f9 !important;
}

a.has-text-info-light:hover, a.has-text-info-light:focus {
  color: #cce3eb !important;
}

.has-background-info-light {
  background-color: #f0f7f9 !important;
}

.has-text-info-dark {
  color: #357288 !important;
}

a.has-text-info-dark:hover, a.has-text-info-dark:focus {
  color: #4391ac !important;
}

.has-background-info-dark {
  background-color: #357288 !important;
}

.has-text-success {
  color: #65b996 !important;
}

a.has-text-success:hover, a.has-text-success:focus {
  color: #49a27d !important;
}

.has-background-success {
  background-color: #65b996 !important;
}

.has-text-success-light {
  color: #f1f9f5 !important;
}

a.has-text-success-light:hover, a.has-text-success-light:focus {
  color: #cee9de !important;
}

.has-background-success-light {
  background-color: #f1f9f5 !important;
}

.has-text-success-dark {
  color: #357459 !important;
}

a.has-text-success-dark:hover, a.has-text-success-dark:focus {
  color: #459775 !important;
}

.has-background-success-dark {
  background-color: #357459 !important;
}

.has-text-warning {
  color: #fbdc8e !important;
}

a.has-text-warning:hover, a.has-text-warning:focus {
  color: #f9cd5d !important;
}

.has-background-warning {
  background-color: #fbdc8e !important;
}

.has-text-warning-light {
  color: #fef9eb !important;
}

a.has-text-warning-light:hover, a.has-text-warning-light:focus {
  color: #fdeaba !important;
}

.has-background-warning-light {
  background-color: #fef9eb !important;
}

.has-text-warning-dark {
  color: #8f6805 !important;
}

a.has-text-warning-dark:hover, a.has-text-warning-dark:focus {
  color: #c08b07 !important;
}

.has-background-warning-dark {
  background-color: #8f6805 !important;
}

.has-text-danger {
  color: #fc5c46 !important;
}

a.has-text-danger:hover, a.has-text-danger:focus {
  color: #fb3014 !important;
}

.has-background-danger {
  background-color: #fc5c46 !important;
}

.has-text-danger-light {
  color: #ffedeb !important;
}

a.has-text-danger-light:hover, a.has-text-danger-light:focus {
  color: #fec1b9 !important;
}

.has-background-danger-light {
  background-color: #ffedeb !important;
}

.has-text-danger-dark {
  color: #c91b03 !important;
}

a.has-text-danger-dark:hover, a.has-text-danger-dark:focus {
  color: #fb2204 !important;
}

.has-background-danger-dark {
  background-color: #c91b03 !important;
}

.has-text-black-bis {
  color: #292524 !important;
}

.has-background-black-bis {
  background-color: #292524 !important;
}

.has-text-black-ter {
  color: #44403c !important;
}

.has-background-black-ter {
  background-color: #44403c !important;
}

.has-text-grey-darker {
  color: #57534e !important;
}

.has-background-grey-darker {
  background-color: #57534e !important;
}

.has-text-grey-dark {
  color: #78716c !important;
}

.has-background-grey-dark {
  background-color: #78716c !important;
}

.has-text-grey {
  color: #7a7a7a !important;
}

.has-background-grey {
  background-color: #7a7a7a !important;
}

.has-text-grey-light {
  color: #a8a29e !important;
}

.has-background-grey-light {
  background-color: #a8a29e !important;
}

.has-text-grey-lighter {
  color: #d6d3d1 !important;
}

.has-background-grey-lighter {
  background-color: #d6d3d1 !important;
}

.has-text-white-ter {
  color: #fafaf9 !important;
}

.has-background-white-ter {
  background-color: #fafaf9 !important;
}

.has-text-white-bis {
  color: #f5f5f4 !important;
}

.has-background-white-bis {
  background-color: #f5f5f4 !important;
}

.is-flex-direction-row {
  flex-direction: row !important;
}

.is-flex-direction-row-reverse {
  flex-direction: row-reverse !important;
}

.is-flex-direction-column {
  flex-direction: column !important;
}

.is-flex-direction-column-reverse {
  flex-direction: column-reverse !important;
}

.is-flex-wrap-nowrap {
  flex-wrap: nowrap !important;
}

.is-flex-wrap-wrap {
  flex-wrap: wrap !important;
}

.is-flex-wrap-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.is-justify-content-flex-start {
  justify-content: flex-start !important;
}

.is-justify-content-flex-end {
  justify-content: flex-end !important;
}

.is-justify-content-center {
  justify-content: center !important;
}

.is-justify-content-space-between {
  justify-content: space-between !important;
}

.is-justify-content-space-around {
  justify-content: space-around !important;
}

.is-justify-content-space-evenly {
  justify-content: space-evenly !important;
}

.is-justify-content-start {
  justify-content: start !important;
}

.is-justify-content-end {
  justify-content: end !important;
}

.is-justify-content-left {
  justify-content: left !important;
}

.is-justify-content-right {
  justify-content: right !important;
}

.is-align-content-flex-start {
  align-content: flex-start !important;
}

.is-align-content-flex-end {
  align-content: flex-end !important;
}

.is-align-content-center {
  align-content: center !important;
}

.is-align-content-space-between {
  align-content: space-between !important;
}

.is-align-content-space-around {
  align-content: space-around !important;
}

.is-align-content-space-evenly {
  align-content: space-evenly !important;
}

.is-align-content-stretch {
  align-content: stretch !important;
}

.is-align-content-start {
  align-content: start !important;
}

.is-align-content-end {
  align-content: end !important;
}

.is-align-content-baseline {
  align-content: baseline !important;
}

.is-align-items-stretch {
  align-items: stretch !important;
}

.is-align-items-flex-start {
  align-items: flex-start !important;
}

.is-align-items-flex-end {
  align-items: flex-end !important;
}

.is-align-items-center {
  align-items: center !important;
}

.is-align-items-baseline {
  align-items: baseline !important;
}

.is-align-items-start {
  align-items: start !important;
}

.is-align-items-end {
  align-items: end !important;
}

.is-align-items-self-start {
  align-items: self-start !important;
}

.is-align-items-self-end {
  align-items: self-end !important;
}

.is-align-self-auto {
  align-self: auto !important;
}

.is-align-self-flex-start {
  align-self: flex-start !important;
}

.is-align-self-flex-end {
  align-self: flex-end !important;
}

.is-align-self-center {
  align-self: center !important;
}

.is-align-self-baseline {
  align-self: baseline !important;
}

.is-align-self-stretch {
  align-self: stretch !important;
}

.is-flex-grow-0 {
  flex-grow: 0 !important;
}

.is-flex-grow-1 {
  flex-grow: 1 !important;
}

.is-flex-grow-2 {
  flex-grow: 2 !important;
}

.is-flex-grow-3 {
  flex-grow: 3 !important;
}

.is-flex-grow-4 {
  flex-grow: 4 !important;
}

.is-flex-grow-5 {
  flex-grow: 5 !important;
}

.is-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.is-flex-shrink-1 {
  flex-shrink: 1 !important;
}

.is-flex-shrink-2 {
  flex-shrink: 2 !important;
}

.is-flex-shrink-3 {
  flex-shrink: 3 !important;
}

.is-flex-shrink-4 {
  flex-shrink: 4 !important;
}

.is-flex-shrink-5 {
  flex-shrink: 5 !important;
}

.is-clearfix:after {
  clear: both;
  content: " ";
  display: table;
}

.is-pulled-left {
  float: left !important;
}

.is-pulled-right {
  float: right !important;
}

.is-radiusless {
  border-radius: 0 !important;
}

.is-shadowless {
  box-shadow: none !important;
}

.is-clickable {
  cursor: pointer !important;
  pointer-events: all !important;
}

.is-clipped {
  overflow: hidden !important;
}

.is-marginless {
  margin: 0 !important;
}

.is-paddingless {
  padding: 0 !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mr-1 {
  margin-right: .25rem !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.ml-1 {
  margin-left: .25rem !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.mt-2, .hero-title {
  margin-top: .5rem !important;
}

.mr-2 {
  margin-right: .5rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.ml-2, #logo span {
  margin-left: .5rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.m-3 {
  margin: .75rem !important;
}

.mt-3 {
  margin-top: .75rem !important;
}

.mr-3 {
  margin-right: .75rem !important;
}

.mb-3, ul, p, h4 {
  margin-bottom: .75rem !important;
}

.ml-3 {
  margin-left: .75rem !important;
}

.mx-3 {
  margin-left: .75rem !important;
  margin-right: .75rem !important;
}

.my-3 {
  margin-top: .75rem !important;
  margin-bottom: .75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mr-4 {
  margin-right: 1rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mr-5 {
  margin-right: 1.25rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.ml-5 {
  margin-left: 1.25rem !important;
}

.mx-5 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mr-6 {
  margin-right: 1.5rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.ml-6 {
  margin-left: 1.5rem !important;
}

.mx-6 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.m-7 {
  margin: 1.75rem !important;
}

.mt-7 {
  margin-top: 1.75rem !important;
}

.mr-7 {
  margin-right: 1.75rem !important;
}

.mb-7 {
  margin-bottom: 1.75rem !important;
}

.ml-7 {
  margin-left: 1.75rem !important;
}

.mx-7 {
  margin-left: 1.75rem !important;
  margin-right: 1.75rem !important;
}

.my-7 {
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mr-8 {
  margin-right: 2rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.ml-8 {
  margin-left: 2rem !important;
}

.mx-8 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.m-9 {
  margin: 2.25rem !important;
}

.mt-9 {
  margin-top: 2.25rem !important;
}

.mr-9 {
  margin-right: 2.25rem !important;
}

.mb-9 {
  margin-bottom: 2.25rem !important;
}

.ml-9 {
  margin-left: 2.25rem !important;
}

.mx-9 {
  margin-left: 2.25rem !important;
  margin-right: 2.25rem !important;
}

.my-9 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mr-10 {
  margin-right: 2.5rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.ml-10 {
  margin-left: 2.5rem !important;
}

.mx-10 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.m-11 {
  margin: 2.75rem !important;
}

.mt-11 {
  margin-top: 2.75rem !important;
}

.mr-11 {
  margin-right: 2.75rem !important;
}

.mb-11 {
  margin-bottom: 2.75rem !important;
}

.ml-11 {
  margin-left: 2.75rem !important;
}

.mx-11 {
  margin-left: 2.75rem !important;
  margin-right: 2.75rem !important;
}

.my-11 {
  margin-top: 2.75rem !important;
  margin-bottom: 2.75rem !important;
}

.m-12 {
  margin: 3rem !important;
}

.mt-12 {
  margin-top: 3rem !important;
}

.mr-12 {
  margin-right: 3rem !important;
}

.mb-12 {
  margin-bottom: 3rem !important;
}

.ml-12 {
  margin-left: 3rem !important;
}

.mx-12 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.m-14 {
  margin: 3.5rem !important;
}

.mt-14 {
  margin-top: 3.5rem !important;
}

.mr-14 {
  margin-right: 3.5rem !important;
}

.mb-14 {
  margin-bottom: 3.5rem !important;
}

.ml-14 {
  margin-left: 3.5rem !important;
}

.mx-14 {
  margin-left: 3.5rem !important;
  margin-right: 3.5rem !important;
}

.my-14 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.m-16 {
  margin: 4rem !important;
}

.mt-16 {
  margin-top: 4rem !important;
}

.mr-16 {
  margin-right: 4rem !important;
}

.mb-16 {
  margin-bottom: 4rem !important;
}

.ml-16 {
  margin-left: 4rem !important;
}

.mx-16 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.my-16 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.m-20 {
  margin: 5rem !important;
}

.mt-20 {
  margin-top: 5rem !important;
}

.mr-20 {
  margin-right: 5rem !important;
}

.mb-20 {
  margin-bottom: 5rem !important;
}

.ml-20 {
  margin-left: 5rem !important;
}

.mx-20 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.my-20 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.m-24 {
  margin: 6rem !important;
}

.mt-24 {
  margin-top: 6rem !important;
}

.mr-24 {
  margin-right: 6rem !important;
}

.mb-24 {
  margin-bottom: 6rem !important;
}

.ml-24 {
  margin-left: 6rem !important;
}

.mx-24 {
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}

.my-24 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.m-28 {
  margin: 7rem !important;
}

.mt-28 {
  margin-top: 7rem !important;
}

.mr-28 {
  margin-right: 7rem !important;
}

.mb-28 {
  margin-bottom: 7rem !important;
}

.ml-28 {
  margin-left: 7rem !important;
}

.mx-28 {
  margin-left: 7rem !important;
  margin-right: 7rem !important;
}

.my-28 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important;
}

.m-32 {
  margin: 8rem !important;
}

.mt-32 {
  margin-top: 8rem !important;
}

.mr-32 {
  margin-right: 8rem !important;
}

.mb-32 {
  margin-bottom: 8rem !important;
}

.ml-32 {
  margin-left: 8rem !important;
}

.mx-32 {
  margin-left: 8rem !important;
  margin-right: 8rem !important;
}

.my-32 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.m-36 {
  margin: 9rem !important;
}

.mt-36 {
  margin-top: 9rem !important;
}

.mr-36 {
  margin-right: 9rem !important;
}

.mb-36 {
  margin-bottom: 9rem !important;
}

.ml-36 {
  margin-left: 9rem !important;
}

.mx-36 {
  margin-left: 9rem !important;
  margin-right: 9rem !important;
}

.my-36 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important;
}

.m-40 {
  margin: 10rem !important;
}

.mt-40 {
  margin-top: 10rem !important;
}

.mr-40 {
  margin-right: 10rem !important;
}

.mb-40 {
  margin-bottom: 10rem !important;
}

.ml-40 {
  margin-left: 10rem !important;
}

.mx-40 {
  margin-left: 10rem !important;
  margin-right: 10rem !important;
}

.my-40 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.m-44 {
  margin: 11rem !important;
}

.mt-44 {
  margin-top: 11rem !important;
}

.mr-44 {
  margin-right: 11rem !important;
}

.mb-44 {
  margin-bottom: 11rem !important;
}

.ml-44 {
  margin-left: 11rem !important;
}

.mx-44 {
  margin-left: 11rem !important;
  margin-right: 11rem !important;
}

.my-44 {
  margin-top: 11rem !important;
  margin-bottom: 11rem !important;
}

.m-48 {
  margin: 12rem !important;
}

.mt-48 {
  margin-top: 12rem !important;
}

.mr-48 {
  margin-right: 12rem !important;
}

.mb-48 {
  margin-bottom: 12rem !important;
}

.ml-48 {
  margin-left: 12rem !important;
}

.mx-48 {
  margin-left: 12rem !important;
  margin-right: 12rem !important;
}

.my-48 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important;
}

.m-52 {
  margin: 13rem !important;
}

.mt-52 {
  margin-top: 13rem !important;
}

.mr-52 {
  margin-right: 13rem !important;
}

.mb-52 {
  margin-bottom: 13rem !important;
}

.ml-52 {
  margin-left: 13rem !important;
}

.mx-52 {
  margin-left: 13rem !important;
  margin-right: 13rem !important;
}

.my-52 {
  margin-top: 13rem !important;
  margin-bottom: 13rem !important;
}

.m-56 {
  margin: 14rem !important;
}

.mt-56 {
  margin-top: 14rem !important;
}

.mr-56 {
  margin-right: 14rem !important;
}

.mb-56 {
  margin-bottom: 14rem !important;
}

.ml-56 {
  margin-left: 14rem !important;
}

.mx-56 {
  margin-left: 14rem !important;
  margin-right: 14rem !important;
}

.my-56 {
  margin-top: 14rem !important;
  margin-bottom: 14rem !important;
}

.m-60 {
  margin: 15rem !important;
}

.mt-60 {
  margin-top: 15rem !important;
}

.mr-60 {
  margin-right: 15rem !important;
}

.mb-60 {
  margin-bottom: 15rem !important;
}

.ml-60 {
  margin-left: 15rem !important;
}

.mx-60 {
  margin-left: 15rem !important;
  margin-right: 15rem !important;
}

.my-60 {
  margin-top: 15rem !important;
  margin-bottom: 15rem !important;
}

.m-64 {
  margin: 16rem !important;
}

.mt-64 {
  margin-top: 16rem !important;
}

.mr-64 {
  margin-right: 16rem !important;
}

.mb-64 {
  margin-bottom: 16rem !important;
}

.ml-64 {
  margin-left: 16rem !important;
}

.mx-64 {
  margin-left: 16rem !important;
  margin-right: 16rem !important;
}

.my-64 {
  margin-top: 16rem !important;
  margin-bottom: 16rem !important;
}

.m-72 {
  margin: 18rem !important;
}

.mt-72 {
  margin-top: 18rem !important;
}

.mr-72 {
  margin-right: 18rem !important;
}

.mb-72 {
  margin-bottom: 18rem !important;
}

.ml-72 {
  margin-left: 18rem !important;
}

.mx-72 {
  margin-left: 18rem !important;
  margin-right: 18rem !important;
}

.my-72 {
  margin-top: 18rem !important;
  margin-bottom: 18rem !important;
}

.m-80 {
  margin: 20rem !important;
}

.mt-80 {
  margin-top: 20rem !important;
}

.mr-80 {
  margin-right: 20rem !important;
}

.mb-80 {
  margin-bottom: 20rem !important;
}

.ml-80 {
  margin-left: 20rem !important;
}

.mx-80 {
  margin-left: 20rem !important;
  margin-right: 20rem !important;
}

.my-80 {
  margin-top: 20rem !important;
  margin-bottom: 20rem !important;
}

.m-96 {
  margin: 24rem !important;
}

.mt-96 {
  margin-top: 24rem !important;
}

.mr-96 {
  margin-right: 24rem !important;
}

.mb-96 {
  margin-bottom: 24rem !important;
}

.ml-96 {
  margin-left: 24rem !important;
}

.mx-96 {
  margin-left: 24rem !important;
  margin-right: 24rem !important;
}

.my-96 {
  margin-top: 24rem !important;
  margin-bottom: 24rem !important;
}

.p-auto {
  padding: auto !important;
}

.pt-auto {
  padding-top: auto !important;
}

.pr-auto {
  padding-right: auto !important;
}

.pb-auto {
  padding-bottom: auto !important;
}

.pl-auto {
  padding-left: auto !important;
}

.px-auto {
  padding-left: auto !important;
  padding-right: auto !important;
}

.py-auto {
  padding-top: auto !important;
  padding-bottom: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pr-1 {
  padding-right: .25rem !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pl-1 {
  padding-left: .25rem !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pr-2 {
  padding-right: .5rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pl-2 {
  padding-left: .5rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.p-3 {
  padding: .75rem !important;
}

.pt-3 {
  padding-top: .75rem !important;
}

.pr-3 {
  padding-right: .75rem !important;
}

.pb-3 {
  padding-bottom: .75rem !important;
}

.pl-3 {
  padding-left: .75rem !important;
}

.px-3 {
  padding-left: .75rem !important;
  padding-right: .75rem !important;
}

.py-3 {
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pr-5 {
  padding-right: 1.25rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

.pl-5 {
  padding-left: 1.25rem !important;
}

.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pr-6 {
  padding-right: 1.5rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pl-6 {
  padding-left: 1.5rem !important;
}

.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.p-7 {
  padding: 1.75rem !important;
}

.pt-7 {
  padding-top: 1.75rem !important;
}

.pr-7 {
  padding-right: 1.75rem !important;
}

.pb-7 {
  padding-bottom: 1.75rem !important;
}

.pl-7 {
  padding-left: 1.75rem !important;
}

.px-7 {
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important;
}

.py-7 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pr-8 {
  padding-right: 2rem !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}

.pl-8 {
  padding-left: 2rem !important;
}

.px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.p-9 {
  padding: 2.25rem !important;
}

.pt-9 {
  padding-top: 2.25rem !important;
}

.pr-9 {
  padding-right: 2.25rem !important;
}

.pb-9 {
  padding-bottom: 2.25rem !important;
}

.pl-9 {
  padding-left: 2.25rem !important;
}

.px-9 {
  padding-left: 2.25rem !important;
  padding-right: 2.25rem !important;
}

.py-9 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.pt-10 {
  padding-top: 2.5rem !important;
}

.pr-10 {
  padding-right: 2.5rem !important;
}

.pb-10 {
  padding-bottom: 2.5rem !important;
}

.pl-10 {
  padding-left: 2.5rem !important;
}

.px-10 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.p-11 {
  padding: 2.75rem !important;
}

.pt-11 {
  padding-top: 2.75rem !important;
}

.pr-11 {
  padding-right: 2.75rem !important;
}

.pb-11 {
  padding-bottom: 2.75rem !important;
}

.pl-11 {
  padding-left: 2.75rem !important;
}

.px-11 {
  padding-left: 2.75rem !important;
  padding-right: 2.75rem !important;
}

.py-11 {
  padding-top: 2.75rem !important;
  padding-bottom: 2.75rem !important;
}

.p-12 {
  padding: 3rem !important;
}

.pt-12 {
  padding-top: 3rem !important;
}

.pr-12 {
  padding-right: 3rem !important;
}

.pb-12 {
  padding-bottom: 3rem !important;
}

.pl-12 {
  padding-left: 3rem !important;
}

.px-12 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.p-14 {
  padding: 3.5rem !important;
}

.pt-14 {
  padding-top: 3.5rem !important;
}

.pr-14 {
  padding-right: 3.5rem !important;
}

.pb-14 {
  padding-bottom: 3.5rem !important;
}

.pl-14 {
  padding-left: 3.5rem !important;
}

.px-14 {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.py-14 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.p-16 {
  padding: 4rem !important;
}

.pt-16 {
  padding-top: 4rem !important;
}

.pr-16 {
  padding-right: 4rem !important;
}

.pb-16 {
  padding-bottom: 4rem !important;
}

.pl-16 {
  padding-left: 4rem !important;
}

.px-16 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.p-20 {
  padding: 5rem !important;
}

.pt-20 {
  padding-top: 5rem !important;
}

.pr-20 {
  padding-right: 5rem !important;
}

.pb-20 {
  padding-bottom: 5rem !important;
}

.pl-20 {
  padding-left: 5rem !important;
}

.px-20 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.py-20 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.p-24 {
  padding: 6rem !important;
}

.pt-24 {
  padding-top: 6rem !important;
}

.pr-24 {
  padding-right: 6rem !important;
}

.pb-24 {
  padding-bottom: 6rem !important;
}

.pl-24 {
  padding-left: 6rem !important;
}

.px-24 {
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}

.py-24 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.p-28 {
  padding: 7rem !important;
}

.pt-28 {
  padding-top: 7rem !important;
}

.pr-28 {
  padding-right: 7rem !important;
}

.pb-28 {
  padding-bottom: 7rem !important;
}

.pl-28 {
  padding-left: 7rem !important;
}

.px-28 {
  padding-left: 7rem !important;
  padding-right: 7rem !important;
}

.py-28 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.p-32 {
  padding: 8rem !important;
}

.pt-32 {
  padding-top: 8rem !important;
}

.pr-32 {
  padding-right: 8rem !important;
}

.pb-32 {
  padding-bottom: 8rem !important;
}

.pl-32 {
  padding-left: 8rem !important;
}

.px-32 {
  padding-left: 8rem !important;
  padding-right: 8rem !important;
}

.py-32 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.p-36 {
  padding: 9rem !important;
}

.pt-36 {
  padding-top: 9rem !important;
}

.pr-36 {
  padding-right: 9rem !important;
}

.pb-36 {
  padding-bottom: 9rem !important;
}

.pl-36 {
  padding-left: 9rem !important;
}

.px-36 {
  padding-left: 9rem !important;
  padding-right: 9rem !important;
}

.py-36 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

.p-40 {
  padding: 10rem !important;
}

.pt-40 {
  padding-top: 10rem !important;
}

.pr-40 {
  padding-right: 10rem !important;
}

.pb-40 {
  padding-bottom: 10rem !important;
}

.pl-40 {
  padding-left: 10rem !important;
}

.px-40 {
  padding-left: 10rem !important;
  padding-right: 10rem !important;
}

.py-40 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.p-44 {
  padding: 11rem !important;
}

.pt-44 {
  padding-top: 11rem !important;
}

.pr-44 {
  padding-right: 11rem !important;
}

.pb-44 {
  padding-bottom: 11rem !important;
}

.pl-44 {
  padding-left: 11rem !important;
}

.px-44 {
  padding-left: 11rem !important;
  padding-right: 11rem !important;
}

.py-44 {
  padding-top: 11rem !important;
  padding-bottom: 11rem !important;
}

.p-48 {
  padding: 12rem !important;
}

.pt-48 {
  padding-top: 12rem !important;
}

.pr-48 {
  padding-right: 12rem !important;
}

.pb-48 {
  padding-bottom: 12rem !important;
}

.pl-48 {
  padding-left: 12rem !important;
}

.px-48 {
  padding-left: 12rem !important;
  padding-right: 12rem !important;
}

.py-48 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.p-52 {
  padding: 13rem !important;
}

.pt-52 {
  padding-top: 13rem !important;
}

.pr-52 {
  padding-right: 13rem !important;
}

.pb-52 {
  padding-bottom: 13rem !important;
}

.pl-52 {
  padding-left: 13rem !important;
}

.px-52 {
  padding-left: 13rem !important;
  padding-right: 13rem !important;
}

.py-52 {
  padding-top: 13rem !important;
  padding-bottom: 13rem !important;
}

.p-56 {
  padding: 14rem !important;
}

.pt-56 {
  padding-top: 14rem !important;
}

.pr-56 {
  padding-right: 14rem !important;
}

.pb-56 {
  padding-bottom: 14rem !important;
}

.pl-56 {
  padding-left: 14rem !important;
}

.px-56 {
  padding-left: 14rem !important;
  padding-right: 14rem !important;
}

.py-56 {
  padding-top: 14rem !important;
  padding-bottom: 14rem !important;
}

.p-60 {
  padding: 15rem !important;
}

.pt-60 {
  padding-top: 15rem !important;
}

.pr-60 {
  padding-right: 15rem !important;
}

.pb-60 {
  padding-bottom: 15rem !important;
}

.pl-60 {
  padding-left: 15rem !important;
}

.px-60 {
  padding-left: 15rem !important;
  padding-right: 15rem !important;
}

.py-60 {
  padding-top: 15rem !important;
  padding-bottom: 15rem !important;
}

.p-64 {
  padding: 16rem !important;
}

.pt-64 {
  padding-top: 16rem !important;
}

.pr-64 {
  padding-right: 16rem !important;
}

.pb-64 {
  padding-bottom: 16rem !important;
}

.pl-64 {
  padding-left: 16rem !important;
}

.px-64 {
  padding-left: 16rem !important;
  padding-right: 16rem !important;
}

.py-64 {
  padding-top: 16rem !important;
  padding-bottom: 16rem !important;
}

.p-72 {
  padding: 18rem !important;
}

.pt-72 {
  padding-top: 18rem !important;
}

.pr-72 {
  padding-right: 18rem !important;
}

.pb-72 {
  padding-bottom: 18rem !important;
}

.pl-72 {
  padding-left: 18rem !important;
}

.px-72 {
  padding-left: 18rem !important;
  padding-right: 18rem !important;
}

.py-72 {
  padding-top: 18rem !important;
  padding-bottom: 18rem !important;
}

.p-80 {
  padding: 20rem !important;
}

.pt-80 {
  padding-top: 20rem !important;
}

.pr-80 {
  padding-right: 20rem !important;
}

.pb-80 {
  padding-bottom: 20rem !important;
}

.pl-80 {
  padding-left: 20rem !important;
}

.px-80 {
  padding-left: 20rem !important;
  padding-right: 20rem !important;
}

.py-80 {
  padding-top: 20rem !important;
  padding-bottom: 20rem !important;
}

.p-96 {
  padding: 24rem !important;
}

.pt-96 {
  padding-top: 24rem !important;
}

.pr-96 {
  padding-right: 24rem !important;
}

.pb-96 {
  padding-bottom: 24rem !important;
}

.pl-96 {
  padding-left: 24rem !important;
}

.px-96 {
  padding-left: 24rem !important;
  padding-right: 24rem !important;
}

.py-96 {
  padding-top: 24rem !important;
  padding-bottom: 24rem !important;
}

.is-size-1, h1 {
  font-size: 4rem !important;
}

.is-size-2, h2 {
  font-size: 3rem !important;
}

.is-size-3, h3 {
  font-size: 2.25rem !important;
}

.is-size-4 {
  font-size: 1.25rem !important;
}

.is-size-5 {
  font-size: 1rem !important;
}

.is-size-6 {
  font-size: .875rem !important;
}

.is-size-7 {
  font-size: .75rem !important;
}

@media screen and (width <= 768px) {
  .is-size-1-mobile {
    font-size: 4rem !important;
  }

  .is-size-2-mobile {
    font-size: 3rem !important;
  }

  .is-size-3-mobile {
    font-size: 2.25rem !important;
  }

  .is-size-4-mobile {
    font-size: 1.25rem !important;
  }

  .is-size-5-mobile {
    font-size: 1rem !important;
  }

  .is-size-6-mobile {
    font-size: .875rem !important;
  }

  .is-size-7-mobile {
    font-size: .75rem !important;
  }
}

@media screen and (width >= 769px), print {
  .is-size-1-tablet {
    font-size: 4rem !important;
  }

  .is-size-2-tablet {
    font-size: 3rem !important;
  }

  .is-size-3-tablet {
    font-size: 2.25rem !important;
  }

  .is-size-4-tablet {
    font-size: 1.25rem !important;
  }

  .is-size-5-tablet {
    font-size: 1rem !important;
  }

  .is-size-6-tablet {
    font-size: .875rem !important;
  }

  .is-size-7-tablet {
    font-size: .75rem !important;
  }
}

@media screen and (width <= 1023px) {
  .is-size-1-touch {
    font-size: 4rem !important;
  }

  .is-size-2-touch {
    font-size: 3rem !important;
  }

  .is-size-3-touch {
    font-size: 2.25rem !important;
  }

  .is-size-4-touch {
    font-size: 1.25rem !important;
  }

  .is-size-5-touch {
    font-size: 1rem !important;
  }

  .is-size-6-touch {
    font-size: .875rem !important;
  }

  .is-size-7-touch {
    font-size: .75rem !important;
  }
}

@media screen and (width >= 1024px) {
  .is-size-1-desktop {
    font-size: 4rem !important;
  }

  .is-size-2-desktop {
    font-size: 3rem !important;
  }

  .is-size-3-desktop {
    font-size: 2.25rem !important;
  }

  .is-size-4-desktop {
    font-size: 1.25rem !important;
  }

  .is-size-5-desktop {
    font-size: 1rem !important;
  }

  .is-size-6-desktop {
    font-size: .875rem !important;
  }

  .is-size-7-desktop {
    font-size: .75rem !important;
  }
}

@media screen and (width >= 1216px) {
  .is-size-1-widescreen {
    font-size: 4rem !important;
  }

  .is-size-2-widescreen {
    font-size: 3rem !important;
  }

  .is-size-3-widescreen {
    font-size: 2.25rem !important;
  }

  .is-size-4-widescreen {
    font-size: 1.25rem !important;
  }

  .is-size-5-widescreen {
    font-size: 1rem !important;
  }

  .is-size-6-widescreen {
    font-size: .875rem !important;
  }

  .is-size-7-widescreen {
    font-size: .75rem !important;
  }
}

@media screen and (width >= 1408px) {
  .is-size-1-fullhd {
    font-size: 4rem !important;
  }

  .is-size-2-fullhd {
    font-size: 3rem !important;
  }

  .is-size-3-fullhd {
    font-size: 2.25rem !important;
  }

  .is-size-4-fullhd {
    font-size: 1.25rem !important;
  }

  .is-size-5-fullhd {
    font-size: 1rem !important;
  }

  .is-size-6-fullhd {
    font-size: .875rem !important;
  }

  .is-size-7-fullhd {
    font-size: .75rem !important;
  }
}

.has-text-centered {
  text-align: center !important;
}

.has-text-justified {
  text-align: justify !important;
}

.has-text-left {
  text-align: left !important;
}

.has-text-right {
  text-align: right !important;
}

@media screen and (width <= 768px) {
  .has-text-centered-mobile {
    text-align: center !important;
  }
}

@media screen and (width >= 769px), print {
  .has-text-centered-tablet {
    text-align: center !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-text-centered-tablet-only {
    text-align: center !important;
  }
}

@media screen and (width <= 1023px) {
  .has-text-centered-touch {
    text-align: center !important;
  }
}

@media screen and (width >= 1024px) {
  .has-text-centered-desktop {
    text-align: center !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-text-centered-desktop-only {
    text-align: center !important;
  }
}

@media screen and (width >= 1216px) {
  .has-text-centered-widescreen {
    text-align: center !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-text-centered-widescreen-only {
    text-align: center !important;
  }
}

@media screen and (width >= 1408px) {
  .has-text-centered-fullhd {
    text-align: center !important;
  }
}

@media screen and (width <= 768px) {
  .has-text-justified-mobile {
    text-align: justify !important;
  }
}

@media screen and (width >= 769px), print {
  .has-text-justified-tablet {
    text-align: justify !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-text-justified-tablet-only {
    text-align: justify !important;
  }
}

@media screen and (width <= 1023px) {
  .has-text-justified-touch {
    text-align: justify !important;
  }
}

@media screen and (width >= 1024px) {
  .has-text-justified-desktop {
    text-align: justify !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-text-justified-desktop-only {
    text-align: justify !important;
  }
}

@media screen and (width >= 1216px) {
  .has-text-justified-widescreen {
    text-align: justify !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-text-justified-widescreen-only {
    text-align: justify !important;
  }
}

@media screen and (width >= 1408px) {
  .has-text-justified-fullhd {
    text-align: justify !important;
  }
}

@media screen and (width <= 768px) {
  .has-text-left-mobile {
    text-align: left !important;
  }
}

@media screen and (width >= 769px), print {
  .has-text-left-tablet {
    text-align: left !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-text-left-tablet-only {
    text-align: left !important;
  }
}

@media screen and (width <= 1023px) {
  .has-text-left-touch {
    text-align: left !important;
  }
}

@media screen and (width >= 1024px) {
  .has-text-left-desktop {
    text-align: left !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-text-left-desktop-only {
    text-align: left !important;
  }
}

@media screen and (width >= 1216px) {
  .has-text-left-widescreen {
    text-align: left !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-text-left-widescreen-only {
    text-align: left !important;
  }
}

@media screen and (width >= 1408px) {
  .has-text-left-fullhd {
    text-align: left !important;
  }
}

@media screen and (width <= 768px) {
  .has-text-right-mobile {
    text-align: right !important;
  }
}

@media screen and (width >= 769px), print {
  .has-text-right-tablet {
    text-align: right !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-text-right-tablet-only {
    text-align: right !important;
  }
}

@media screen and (width <= 1023px) {
  .has-text-right-touch {
    text-align: right !important;
  }
}

@media screen and (width >= 1024px) {
  .has-text-right-desktop {
    text-align: right !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-text-right-desktop-only {
    text-align: right !important;
  }
}

@media screen and (width >= 1216px) {
  .has-text-right-widescreen {
    text-align: right !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-text-right-widescreen-only {
    text-align: right !important;
  }
}

@media screen and (width >= 1408px) {
  .has-text-right-fullhd {
    text-align: right !important;
  }
}

.is-capitalized {
  text-transform: capitalize !important;
}

.is-lowercase {
  text-transform: lowercase !important;
}

.is-uppercase {
  text-transform: uppercase !important;
}

.is-italic {
  font-style: italic !important;
}

.is-underlined {
  text-decoration: underline !important;
}

.has-text-weight-light {
  font-weight: 300 !important;
}

.has-text-weight-normal {
  font-weight: 400 !important;
}

.has-text-weight-medium, .table-of-contents li {
  font-weight: 500 !important;
}

.has-text-weight-semibold {
  font-weight: 600 !important;
}

.has-text-weight-bold {
  font-weight: 700 !important;
}

.is-family-primary, .is-family-secondary, .is-family-sans-serif {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

.is-family-monospace, .is-family-code {
  font-family: monospace !important;
}

.is-block {
  display: block !important;
}

@media screen and (width <= 768px) {
  .is-block-mobile {
    display: block !important;
  }
}

@media screen and (width >= 769px), print {
  .is-block-tablet {
    display: block !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-block-tablet-only {
    display: block !important;
  }
}

@media screen and (width <= 1023px) {
  .is-block-touch {
    display: block !important;
  }
}

@media screen and (width >= 1024px) {
  .is-block-desktop {
    display: block !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-block-desktop-only {
    display: block !important;
  }
}

@media screen and (width >= 1216px) {
  .is-block-widescreen {
    display: block !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-block-widescreen-only {
    display: block !important;
  }
}

@media screen and (width >= 1408px) {
  .is-block-fullhd {
    display: block !important;
  }
}

.is-flex {
  display: flex !important;
}

@media screen and (width <= 768px) {
  .is-flex-mobile {
    display: flex !important;
  }
}

@media screen and (width >= 769px), print {
  .is-flex-tablet {
    display: flex !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-flex-tablet-only {
    display: flex !important;
  }
}

@media screen and (width <= 1023px) {
  .is-flex-touch {
    display: flex !important;
  }
}

@media screen and (width >= 1024px) {
  .is-flex-desktop {
    display: flex !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-flex-desktop-only {
    display: flex !important;
  }
}

@media screen and (width >= 1216px) {
  .is-flex-widescreen {
    display: flex !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-flex-widescreen-only {
    display: flex !important;
  }
}

@media screen and (width >= 1408px) {
  .is-flex-fullhd {
    display: flex !important;
  }
}

.is-inline {
  display: inline !important;
}

@media screen and (width <= 768px) {
  .is-inline-mobile {
    display: inline !important;
  }
}

@media screen and (width >= 769px), print {
  .is-inline-tablet {
    display: inline !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-inline-tablet-only {
    display: inline !important;
  }
}

@media screen and (width <= 1023px) {
  .is-inline-touch {
    display: inline !important;
  }
}

@media screen and (width >= 1024px) {
  .is-inline-desktop {
    display: inline !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-inline-desktop-only {
    display: inline !important;
  }
}

@media screen and (width >= 1216px) {
  .is-inline-widescreen {
    display: inline !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-inline-widescreen-only {
    display: inline !important;
  }
}

@media screen and (width >= 1408px) {
  .is-inline-fullhd {
    display: inline !important;
  }
}

.is-inline-block {
  display: inline-block !important;
}

@media screen and (width <= 768px) {
  .is-inline-block-mobile {
    display: inline-block !important;
  }
}

@media screen and (width >= 769px), print {
  .is-inline-block-tablet {
    display: inline-block !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-inline-block-tablet-only {
    display: inline-block !important;
  }
}

@media screen and (width <= 1023px) {
  .is-inline-block-touch {
    display: inline-block !important;
  }
}

@media screen and (width >= 1024px) {
  .is-inline-block-desktop {
    display: inline-block !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-inline-block-desktop-only {
    display: inline-block !important;
  }
}

@media screen and (width >= 1216px) {
  .is-inline-block-widescreen {
    display: inline-block !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-inline-block-widescreen-only {
    display: inline-block !important;
  }
}

@media screen and (width >= 1408px) {
  .is-inline-block-fullhd {
    display: inline-block !important;
  }
}

.is-inline-flex {
  display: inline-flex !important;
}

@media screen and (width <= 768px) {
  .is-inline-flex-mobile {
    display: inline-flex !important;
  }
}

@media screen and (width >= 769px), print {
  .is-inline-flex-tablet {
    display: inline-flex !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-inline-flex-tablet-only {
    display: inline-flex !important;
  }
}

@media screen and (width <= 1023px) {
  .is-inline-flex-touch {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1024px) {
  .is-inline-flex-desktop {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-inline-flex-desktop-only {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1216px) {
  .is-inline-flex-widescreen {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-inline-flex-widescreen-only {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1408px) {
  .is-inline-flex-fullhd {
    display: inline-flex !important;
  }
}

.is-hidden {
  display: none !important;
}

.is-sr-only {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: none !important;
  width: .01em !important;
  height: .01em !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

@media screen and (width <= 768px) {
  .is-hidden-mobile {
    display: none !important;
  }
}

@media screen and (width >= 769px), print {
  .is-hidden-tablet {
    display: none !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-hidden-tablet-only {
    display: none !important;
  }
}

@media screen and (width <= 1023px) {
  .is-hidden-touch {
    display: none !important;
  }
}

@media screen and (width >= 1024px) {
  .is-hidden-desktop {
    display: none !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-hidden-desktop-only {
    display: none !important;
  }
}

@media screen and (width >= 1216px) {
  .is-hidden-widescreen {
    display: none !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-hidden-widescreen-only {
    display: none !important;
  }
}

@media screen and (width >= 1408px) {
  .is-hidden-fullhd {
    display: none !important;
  }
}

.is-invisible {
  visibility: hidden !important;
}

@media screen and (width <= 768px) {
  .is-invisible-mobile {
    visibility: hidden !important;
  }
}

@media screen and (width >= 769px), print {
  .is-invisible-tablet {
    visibility: hidden !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-invisible-tablet-only {
    visibility: hidden !important;
  }
}

@media screen and (width <= 1023px) {
  .is-invisible-touch {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1024px) {
  .is-invisible-desktop {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-invisible-desktop-only {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1216px) {
  .is-invisible-widescreen {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-invisible-widescreen-only {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1408px) {
  .is-invisible-fullhd {
    visibility: hidden !important;
  }
}

.hero {
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.hero .navbar {
  background: none;
}

.hero .tabs ul {
  border-bottom: none;
}

.hero.is-white {
  color: #222;
  background-color: #fff;
}

.hero.is-white a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-white strong {
  color: inherit;
}

.hero.is-white .title, .hero.is-white .has-background-white .hero-title, .has-background-white .hero.is-white .hero-title, .hero.is-white h3, .hero.is-white h2, .hero.is-white h1 {
  color: #222;
}

.hero.is-white .subtitle {
  color: #222222e6;
}

.hero.is-white .subtitle a:not(.button), .hero.is-white .subtitle strong {
  color: #222;
}

@media screen and (width <= 1023px) {
  .hero.is-white .navbar-menu {
    background-color: #fff;
  }
}

.hero.is-white .navbar-item, .hero.is-white .navbar-link {
  color: #222222b3;
}

.hero.is-white a.navbar-item:hover, .hero.is-white a.navbar-item.is-active, .hero.is-white .navbar-link:hover, .hero.is-white .navbar-link.is-active {
  color: #222;
  background-color: #f2f2f2;
}

.hero.is-white .tabs a {
  color: #222;
  opacity: .9;
}

.hero.is-white .tabs a:hover {
  opacity: 1;
}

.hero.is-white .tabs li.is-active a {
  opacity: 1;
  color: #fff !important;
}

.hero.is-white .tabs.is-boxed a, .hero.is-white .tabs.is-toggle a {
  color: #222;
}

.hero.is-white .tabs.is-boxed a:hover, .hero.is-white .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-white .tabs.is-boxed li.is-active a, .hero.is-white .tabs.is-boxed li.is-active a:hover, .hero.is-white .tabs.is-toggle li.is-active a, .hero.is-white .tabs.is-toggle li.is-active a:hover {
  color: #fff;
  background-color: #222;
  border-color: #222;
}

.hero.is-white.is-bold {
  background-image: linear-gradient(141deg, #e8e3e4 0%, #fff 71% 100%);
}

@media screen and (width <= 768px) {
  .hero.is-white.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #e8e3e4 0%, #fff 71% 100%);
  }
}

.hero.is-black {
  color: #fff;
  background-color: #222;
}

.hero.is-black a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-black strong {
  color: inherit;
}

.hero.is-black .title, .hero.is-black .has-background-white .hero-title, .has-background-white .hero.is-black .hero-title, .hero.is-black h3, .hero.is-black h2, .hero.is-black h1 {
  color: #fff;
}

.hero.is-black .subtitle {
  color: #ffffffe6;
}

.hero.is-black .subtitle a:not(.button), .hero.is-black .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-black .navbar-menu {
    background-color: #222;
  }
}

.hero.is-black .navbar-item, .hero.is-black .navbar-link {
  color: #ffffffb3;
}

.hero.is-black a.navbar-item:hover, .hero.is-black a.navbar-item.is-active, .hero.is-black .navbar-link:hover, .hero.is-black .navbar-link.is-active {
  color: #fff;
  background-color: #151515;
}

.hero.is-black .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-black .tabs a:hover {
  opacity: 1;
}

.hero.is-black .tabs li.is-active a {
  opacity: 1;
  color: #222 !important;
}

.hero.is-black .tabs.is-boxed a, .hero.is-black .tabs.is-toggle a {
  color: #fff;
}

.hero.is-black .tabs.is-boxed a:hover, .hero.is-black .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-black .tabs.is-boxed li.is-active a, .hero.is-black .tabs.is-boxed li.is-active a:hover, .hero.is-black .tabs.is-toggle li.is-active a, .hero.is-black .tabs.is-toggle li.is-active a:hover {
  color: #222;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-black.is-bold {
  background-image: linear-gradient(141deg, #090808 0%, #222 71%, #312d2c 100%);
}

@media screen and (width <= 768px) {
  .hero.is-black.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #090808 0%, #222 71%, #312d2c 100%);
  }
}

.hero.is-light {
  color: #000000b3;
  background-color: #f5f5f4;
}

.hero.is-light a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-light strong {
  color: inherit;
}

.hero.is-light .title, .hero.is-light .has-background-white .hero-title, .has-background-white .hero.is-light .hero-title, .hero.is-light h3, .hero.is-light h2, .hero.is-light h1 {
  color: #000000b3;
}

.hero.is-light .subtitle {
  color: #000000e6;
}

.hero.is-light .subtitle a:not(.button), .hero.is-light .subtitle strong {
  color: #000000b3;
}

@media screen and (width <= 1023px) {
  .hero.is-light .navbar-menu {
    background-color: #f5f5f4;
  }
}

.hero.is-light .navbar-item, .hero.is-light .navbar-link {
  color: #000000b3;
}

.hero.is-light a.navbar-item:hover, .hero.is-light a.navbar-item.is-active, .hero.is-light .navbar-link:hover, .hero.is-light .navbar-link.is-active {
  color: #000000b3;
  background-color: #e9e9e7;
}

.hero.is-light .tabs a {
  color: #000000b3;
  opacity: .9;
}

.hero.is-light .tabs a:hover {
  opacity: 1;
}

.hero.is-light .tabs li.is-active a {
  opacity: 1;
  color: #f5f5f4 !important;
}

.hero.is-light .tabs.is-boxed a, .hero.is-light .tabs.is-toggle a {
  color: #000000b3;
}

.hero.is-light .tabs.is-boxed a:hover, .hero.is-light .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-light .tabs.is-boxed li.is-active a, .hero.is-light .tabs.is-boxed li.is-active a:hover, .hero.is-light .tabs.is-toggle li.is-active a, .hero.is-light .tabs.is-toggle li.is-active a:hover {
  color: #f5f5f4;
  background-color: #000000b3;
  border-color: #000000b3;
}

.hero.is-light.is-bold {
  background-image: linear-gradient(141deg, #e0dfd6 0%, #f5f5f4 71%, #fff 100%);
}

@media screen and (width <= 768px) {
  .hero.is-light.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #e0dfd6 0%, #f5f5f4 71%, #fff 100%);
  }
}

.hero.is-dark {
  color: #fff;
  background-color: #004bff;
}

.hero.is-dark a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-dark strong {
  color: inherit;
}

.hero.is-dark .title, .hero.is-dark .has-background-white .hero-title, .has-background-white .hero.is-dark .hero-title, .hero.is-dark h3, .hero.is-dark h2, .hero.is-dark h1 {
  color: #fff;
}

.hero.is-dark .subtitle {
  color: #ffffffe6;
}

.hero.is-dark .subtitle a:not(.button), .hero.is-dark .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-dark .navbar-menu {
    background-color: #004bff;
  }
}

.hero.is-dark .navbar-item, .hero.is-dark .navbar-link {
  color: #ffffffb3;
}

.hero.is-dark a.navbar-item:hover, .hero.is-dark a.navbar-item.is-active, .hero.is-dark .navbar-link:hover, .hero.is-dark .navbar-link.is-active {
  color: #fff;
  background-color: #0044e6;
}

.hero.is-dark .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-dark .tabs a:hover {
  opacity: 1;
}

.hero.is-dark .tabs li.is-active a {
  opacity: 1;
  color: #004bff !important;
}

.hero.is-dark .tabs.is-boxed a, .hero.is-dark .tabs.is-toggle a {
  color: #fff;
}

.hero.is-dark .tabs.is-boxed a:hover, .hero.is-dark .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-dark .tabs.is-boxed li.is-active a, .hero.is-dark .tabs.is-boxed li.is-active a:hover, .hero.is-dark .tabs.is-toggle li.is-active a, .hero.is-dark .tabs.is-toggle li.is-active a:hover {
  color: #004bff;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-dark.is-bold {
  background-image: linear-gradient(141deg, #005ecc 0%, #004bff 71%, #1a37ff 100%);
}

@media screen and (width <= 768px) {
  .hero.is-dark.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #005ecc 0%, #004bff 71%, #1a37ff 100%);
  }
}

.hero.is-primary {
  color: #fff;
  background-color: #004bff;
}

.hero.is-primary a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-primary strong {
  color: inherit;
}

.hero.is-primary .title, .hero.is-primary .has-background-white .hero-title, .has-background-white .hero.is-primary .hero-title, .hero.is-primary h3, .hero.is-primary h2, .hero.is-primary h1 {
  color: #fff;
}

.hero.is-primary .subtitle {
  color: #ffffffe6;
}

.hero.is-primary .subtitle a:not(.button), .hero.is-primary .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-primary .navbar-menu {
    background-color: #004bff;
  }
}

.hero.is-primary .navbar-item, .hero.is-primary .navbar-link {
  color: #ffffffb3;
}

.hero.is-primary a.navbar-item:hover, .hero.is-primary a.navbar-item.is-active, .hero.is-primary .navbar-link:hover, .hero.is-primary .navbar-link.is-active {
  color: #fff;
  background-color: #0044e6;
}

.hero.is-primary .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-primary .tabs a:hover {
  opacity: 1;
}

.hero.is-primary .tabs li.is-active a {
  opacity: 1;
  color: #004bff !important;
}

.hero.is-primary .tabs.is-boxed a, .hero.is-primary .tabs.is-toggle a {
  color: #fff;
}

.hero.is-primary .tabs.is-boxed a:hover, .hero.is-primary .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-primary .tabs.is-boxed li.is-active a, .hero.is-primary .tabs.is-boxed li.is-active a:hover, .hero.is-primary .tabs.is-toggle li.is-active a, .hero.is-primary .tabs.is-toggle li.is-active a:hover {
  color: #004bff;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-primary.is-bold {
  background-image: linear-gradient(141deg, #005ecc 0%, #004bff 71%, #1a37ff 100%);
}

@media screen and (width <= 768px) {
  .hero.is-primary.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #005ecc 0%, #004bff 71%, #1a37ff 100%);
  }
}

.hero.is-link {
  color: #fff;
  background-color: #004bff;
}

.hero.is-link a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-link strong {
  color: inherit;
}

.hero.is-link .title, .hero.is-link .has-background-white .hero-title, .has-background-white .hero.is-link .hero-title, .hero.is-link h3, .hero.is-link h2, .hero.is-link h1 {
  color: #fff;
}

.hero.is-link .subtitle {
  color: #ffffffe6;
}

.hero.is-link .subtitle a:not(.button), .hero.is-link .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-link .navbar-menu {
    background-color: #004bff;
  }
}

.hero.is-link .navbar-item, .hero.is-link .navbar-link {
  color: #ffffffb3;
}

.hero.is-link a.navbar-item:hover, .hero.is-link a.navbar-item.is-active, .hero.is-link .navbar-link:hover, .hero.is-link .navbar-link.is-active {
  color: #fff;
  background-color: #0044e6;
}

.hero.is-link .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-link .tabs a:hover {
  opacity: 1;
}

.hero.is-link .tabs li.is-active a {
  opacity: 1;
  color: #004bff !important;
}

.hero.is-link .tabs.is-boxed a, .hero.is-link .tabs.is-toggle a {
  color: #fff;
}

.hero.is-link .tabs.is-boxed a:hover, .hero.is-link .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-link .tabs.is-boxed li.is-active a, .hero.is-link .tabs.is-boxed li.is-active a:hover, .hero.is-link .tabs.is-toggle li.is-active a, .hero.is-link .tabs.is-toggle li.is-active a:hover {
  color: #004bff;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-link.is-bold {
  background-image: linear-gradient(141deg, #005ecc 0%, #004bff 71%, #1a37ff 100%);
}

@media screen and (width <= 768px) {
  .hero.is-link.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #005ecc 0%, #004bff 71%, #1a37ff 100%);
  }
}

.hero.is-info {
  color: #fff;
  background-color: #56a2bd;
}

.hero.is-info a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-info strong {
  color: inherit;
}

.hero.is-info .title, .hero.is-info .has-background-white .hero-title, .has-background-white .hero.is-info .hero-title, .hero.is-info h3, .hero.is-info h2, .hero.is-info h1 {
  color: #fff;
}

.hero.is-info .subtitle {
  color: #ffffffe6;
}

.hero.is-info .subtitle a:not(.button), .hero.is-info .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-info .navbar-menu {
    background-color: #56a2bd;
  }
}

.hero.is-info .navbar-item, .hero.is-info .navbar-link {
  color: #ffffffb3;
}

.hero.is-info a.navbar-item:hover, .hero.is-info a.navbar-item.is-active, .hero.is-info .navbar-link:hover, .hero.is-info .navbar-link.is-active {
  color: #fff;
  background-color: #4697b3;
}

.hero.is-info .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-info .tabs a:hover {
  opacity: 1;
}

.hero.is-info .tabs li.is-active a {
  opacity: 1;
  color: #56a2bd !important;
}

.hero.is-info .tabs.is-boxed a, .hero.is-info .tabs.is-toggle a {
  color: #fff;
}

.hero.is-info .tabs.is-boxed a:hover, .hero.is-info .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-info .tabs.is-boxed li.is-active a, .hero.is-info .tabs.is-boxed li.is-active a:hover, .hero.is-info .tabs.is-toggle li.is-active a, .hero.is-info .tabs.is-toggle li.is-active a:hover {
  color: #56a2bd;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-info.is-bold {
  background-image: linear-gradient(141deg, #34a1ac 0%, #56a2bd 71%, #639ec9 100%);
}

@media screen and (width <= 768px) {
  .hero.is-info.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #34a1ac 0%, #56a2bd 71%, #639ec9 100%);
  }
}

.hero.is-success {
  color: #fff;
  background-color: #65b996;
}

.hero.is-success a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-success strong {
  color: inherit;
}

.hero.is-success .title, .hero.is-success .has-background-white .hero-title, .has-background-white .hero.is-success .hero-title, .hero.is-success h3, .hero.is-success h2, .hero.is-success h1 {
  color: #fff;
}

.hero.is-success .subtitle {
  color: #ffffffe6;
}

.hero.is-success .subtitle a:not(.button), .hero.is-success .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-success .navbar-menu {
    background-color: #65b996;
  }
}

.hero.is-success .navbar-item, .hero.is-success .navbar-link {
  color: #ffffffb3;
}

.hero.is-success a.navbar-item:hover, .hero.is-success a.navbar-item.is-active, .hero.is-success .navbar-link:hover, .hero.is-success .navbar-link.is-active {
  color: #fff;
  background-color: #53b18a;
}

.hero.is-success .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-success .tabs a:hover {
  opacity: 1;
}

.hero.is-success .tabs li.is-active a {
  opacity: 1;
  color: #65b996 !important;
}

.hero.is-success .tabs.is-boxed a, .hero.is-success .tabs.is-toggle a {
  color: #fff;
}

.hero.is-success .tabs.is-boxed a:hover, .hero.is-success .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-success .tabs.is-boxed li.is-active a, .hero.is-success .tabs.is-boxed li.is-active a:hover, .hero.is-success .tabs.is-toggle li.is-active a, .hero.is-success .tabs.is-toggle li.is-active a:hover {
  color: #65b996;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-success.is-bold {
  background-image: linear-gradient(141deg, #3ead6c 0%, #65b996 71%, #72c6b1 100%);
}

@media screen and (width <= 768px) {
  .hero.is-success.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #3ead6c 0%, #65b996 71%, #72c6b1 100%);
  }
}

.hero.is-warning {
  color: #000000b3;
  background-color: #fbdc8e;
}

.hero.is-warning a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-warning strong {
  color: inherit;
}

.hero.is-warning .title, .hero.is-warning .has-background-white .hero-title, .has-background-white .hero.is-warning .hero-title, .hero.is-warning h3, .hero.is-warning h2, .hero.is-warning h1 {
  color: #000000b3;
}

.hero.is-warning .subtitle {
  color: #000000e6;
}

.hero.is-warning .subtitle a:not(.button), .hero.is-warning .subtitle strong {
  color: #000000b3;
}

@media screen and (width <= 1023px) {
  .hero.is-warning .navbar-menu {
    background-color: #fbdc8e;
  }
}

.hero.is-warning .navbar-item, .hero.is-warning .navbar-link {
  color: #000000b3;
}

.hero.is-warning a.navbar-item:hover, .hero.is-warning a.navbar-item.is-active, .hero.is-warning .navbar-link:hover, .hero.is-warning .navbar-link.is-active {
  color: #000000b3;
  background-color: #fad475;
}

.hero.is-warning .tabs a {
  color: #000000b3;
  opacity: .9;
}

.hero.is-warning .tabs a:hover {
  opacity: 1;
}

.hero.is-warning .tabs li.is-active a {
  opacity: 1;
  color: #fbdc8e !important;
}

.hero.is-warning .tabs.is-boxed a, .hero.is-warning .tabs.is-toggle a {
  color: #000000b3;
}

.hero.is-warning .tabs.is-boxed a:hover, .hero.is-warning .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-warning .tabs.is-boxed li.is-active a, .hero.is-warning .tabs.is-boxed li.is-active a:hover, .hero.is-warning .tabs.is-toggle li.is-active a, .hero.is-warning .tabs.is-toggle li.is-active a:hover {
  color: #fbdc8e;
  background-color: #000000b3;
  border-color: #000000b3;
}

.hero.is-warning.is-bold {
  background-image: linear-gradient(141deg, #ffb357 0%, #fbdc8e 71%, #fef4a4 100%);
}

@media screen and (width <= 768px) {
  .hero.is-warning.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #ffb357 0%, #fbdc8e 71%, #fef4a4 100%);
  }
}

.hero.is-danger {
  color: #fff;
  background-color: #fc5c46;
}

.hero.is-danger a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-danger strong {
  color: inherit;
}

.hero.is-danger .title, .hero.is-danger .has-background-white .hero-title, .has-background-white .hero.is-danger .hero-title, .hero.is-danger h3, .hero.is-danger h2, .hero.is-danger h1 {
  color: #fff;
}

.hero.is-danger .subtitle {
  color: #ffffffe6;
}

.hero.is-danger .subtitle a:not(.button), .hero.is-danger .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-danger .navbar-menu {
    background-color: #fc5c46;
  }
}

.hero.is-danger .navbar-item, .hero.is-danger .navbar-link {
  color: #ffffffb3;
}

.hero.is-danger a.navbar-item:hover, .hero.is-danger a.navbar-item.is-active, .hero.is-danger .navbar-link:hover, .hero.is-danger .navbar-link.is-active {
  color: #fff;
  background-color: #fc462d;
}

.hero.is-danger .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-danger .tabs a:hover {
  opacity: 1;
}

.hero.is-danger .tabs li.is-active a {
  opacity: 1;
  color: #fc5c46 !important;
}

.hero.is-danger .tabs.is-boxed a, .hero.is-danger .tabs.is-toggle a {
  color: #fff;
}

.hero.is-danger .tabs.is-boxed a:hover, .hero.is-danger .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-danger .tabs.is-boxed li.is-active a, .hero.is-danger .tabs.is-boxed li.is-active a:hover, .hero.is-danger .tabs.is-toggle li.is-active a, .hero.is-danger .tabs.is-toggle li.is-active a:hover {
  color: #fc5c46;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-danger.is-bold {
  background-image: linear-gradient(141deg, #ff101b 0%, #fc5c46 71%, #ff8b5d 100%);
}

@media screen and (width <= 768px) {
  .hero.is-danger.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #ff101b 0%, #fc5c46 71%, #ff8b5d 100%);
  }
}

.hero.is-small .hero-body {
  padding: 1.5rem;
}

@media screen and (width >= 769px), print {
  .hero.is-medium .hero-body {
    padding: 9rem 4.5rem;
  }

  .hero.is-large .hero-body {
    padding: 18rem 6rem;
  }
}

.hero.is-halfheight .hero-body, .hero.is-fullheight .hero-body, .hero.is-fullheight-with-navbar .hero-body {
  align-items: center;
  display: flex;
}

.hero.is-halfheight .hero-body > .container, .hero.is-fullheight .hero-body > .container, .hero.is-fullheight-with-navbar .hero-body > .container {
  flex-grow: 1;
  flex-shrink: 1;
}

.hero.is-halfheight {
  min-height: 50vh;
}

.hero.is-fullheight {
  min-height: 100vh;
}

.hero-video {
  overflow: hidden;
}

.hero-video video {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.hero-video.is-transparent {
  opacity: .3;
}

@media screen and (width <= 768px) {
  .hero-video {
    display: none;
  }
}

.hero-buttons {
  margin-top: 1.5rem;
}

@media screen and (width <= 768px) {
  .hero-buttons .button {
    display: flex;
  }

  .hero-buttons .button:not(:last-child) {
    margin-bottom: .75rem;
  }
}

@media screen and (width >= 769px), print {
  .hero-buttons {
    justify-content: center;
    display: flex;
  }

  .hero-buttons .button:not(:last-child) {
    margin-right: 1.5rem;
  }
}

.hero-head, .hero-foot {
  flex-grow: 0;
  flex-shrink: 0;
}

.hero-body {
  flex-grow: 1;
  flex-shrink: 0;
  padding: 3rem 1.5rem;
}

@media screen and (width >= 769px), print {
  .hero-body {
    padding: 3rem;
  }
}

.section {
  padding: 5rem 1.5rem;
}

@media screen and (width >= 1024px) {
  .section {
    padding: 5rem 3rem;
  }

  .section.is-medium {
    padding: 9rem 4.5rem;
  }

  .section.is-large {
    padding: 18rem 6rem;
  }
}

.footer {
  background-color: #f5f5f4;
  padding: 3rem 1.5rem 6rem;
}

.notification {
  border-width: 0;
  border-radius: 0;
}

.notification.is-primary {
  border-style: solid;
  border-color: #ebf1ff;
}

.notification.is-primary.is-light {
  border-style: solid;
  border-color: #004afa;
}

.notification.is-link {
  border-style: solid;
  border-color: #ebf1ff;
}

.notification.is-link.is-light {
  border-style: solid;
  border-color: #004afa;
}

.notification.is-info {
  border-style: solid;
  border-color: #f0f7f9;
}

.notification.is-info.is-light {
  border-style: solid;
  border-color: #357288;
}

.notification.is-success {
  border-style: solid;
  border-color: #f1f9f5;
}

.notification.is-success.is-light {
  border-style: solid;
  border-color: #357459;
}

.notification.is-warning {
  border-style: solid;
  border-color: #fef9eb;
}

.notification.is-warning.is-light {
  border-style: solid;
  border-color: #8f6805;
}

.notification.is-danger {
  border-style: solid;
  border-color: #ffedeb;
}

.notification.is-danger.is-light {
  border-style: solid;
  border-color: #c91b03;
}

.navbar-item, .navbar-link {
  padding: .5rem 1.5rem;
  font-size: .875rem;
  font-weight: 500;
}

.is-absolute {
  position: absolute;
}

.is-relative {
  position: relative;
}

.is-fixed {
  position: fixed;
}

.is-sticky {
  position: sticky;
}

.is-top-0 {
  top: 0;
}

.is-right-0 {
  right: 0;
}

.is-bottom-0 {
  bottom: 0;
}

.is-left-0 {
  left: 0;
}

.is-inset-0 {
  inset: 0;
}

.is-cover {
  object-fit: cover;
}

.is-rounded {
  border-radius: 9999px;
}

.box {
  border: 1px solid #e7e5e4;
}

.button {
  font-weight: 700;
}

.-m-1 {
  margin: -.25rem !important;
}

.-mt-1 {
  margin-top: -.25rem !important;
}

.-mr-1 {
  margin-right: -.25rem !important;
}

.-mb-1 {
  margin-bottom: -.25rem !important;
}

.-ml-1 {
  margin-left: -.25rem !important;
}

.-mx-1 {
  margin-left: -.25rem !important;
  margin-right: -.25rem !important;
}

.-my-1 {
  margin-top: -.25rem !important;
  margin-bottom: -.25rem !important;
}

.-m-2 {
  margin: -.5rem !important;
}

.-mt-2 {
  margin-top: -.5rem !important;
}

.-mr-2 {
  margin-right: -.5rem !important;
}

.-mb-2 {
  margin-bottom: -.5rem !important;
}

.-ml-2 {
  margin-left: -.5rem !important;
}

.-mx-2 {
  margin-left: -.5rem !important;
  margin-right: -.5rem !important;
}

.-my-2 {
  margin-top: -.5rem !important;
  margin-bottom: -.5rem !important;
}

.-m-3 {
  margin: -.75rem !important;
}

.-mt-3 {
  margin-top: -.75rem !important;
}

.-mr-3 {
  margin-right: -.75rem !important;
}

.-mb-3 {
  margin-bottom: -.75rem !important;
}

.-ml-3 {
  margin-left: -.75rem !important;
}

.-mx-3 {
  margin-left: -.75rem !important;
  margin-right: -.75rem !important;
}

.-my-3 {
  margin-top: -.75rem !important;
  margin-bottom: -.75rem !important;
}

.-m-4 {
  margin: -1rem !important;
}

.-mt-4 {
  margin-top: -1rem !important;
}

.-mr-4 {
  margin-right: -1rem !important;
}

.-mb-4 {
  margin-bottom: -1rem !important;
}

.-ml-4 {
  margin-left: -1rem !important;
}

.-mx-4 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.-my-4 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.-m-5 {
  margin: -1.25rem !important;
}

.-mt-5 {
  margin-top: -1.25rem !important;
}

.-mr-5 {
  margin-right: -1.25rem !important;
}

.-mb-5 {
  margin-bottom: -1.25rem !important;
}

.-ml-5 {
  margin-left: -1.25rem !important;
}

.-mx-5 {
  margin-left: -1.25rem !important;
  margin-right: -1.25rem !important;
}

.-my-5 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.-m-6 {
  margin: -1.5rem !important;
}

.-mt-6 {
  margin-top: -1.5rem !important;
}

.-mr-6 {
  margin-right: -1.5rem !important;
}

.-mb-6 {
  margin-bottom: -1.5rem !important;
}

.-ml-6 {
  margin-left: -1.5rem !important;
}

.-mx-6 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.-my-6 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.-m-7 {
  margin: -1.75rem !important;
}

.-mt-7 {
  margin-top: -1.75rem !important;
}

.-mr-7 {
  margin-right: -1.75rem !important;
}

.-mb-7 {
  margin-bottom: -1.75rem !important;
}

.-ml-7 {
  margin-left: -1.75rem !important;
}

.-mx-7 {
  margin-left: -1.75rem !important;
  margin-right: -1.75rem !important;
}

.-my-7 {
  margin-top: -1.75rem !important;
  margin-bottom: -1.75rem !important;
}

.-m-8 {
  margin: -2rem !important;
}

.-mt-8 {
  margin-top: -2rem !important;
}

.-mr-8 {
  margin-right: -2rem !important;
}

.-mb-8 {
  margin-bottom: -2rem !important;
}

.-ml-8 {
  margin-left: -2rem !important;
}

.-mx-8 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.-my-8 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.-m-9 {
  margin: -2.25rem !important;
}

.-mt-9 {
  margin-top: -2.25rem !important;
}

.-mr-9 {
  margin-right: -2.25rem !important;
}

.-mb-9 {
  margin-bottom: -2.25rem !important;
}

.-ml-9 {
  margin-left: -2.25rem !important;
}

.-mx-9 {
  margin-left: -2.25rem !important;
  margin-right: -2.25rem !important;
}

.-my-9 {
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important;
}

.-m-10 {
  margin: -2.5rem !important;
}

.-mt-10 {
  margin-top: -2.5rem !important;
}

.-mr-10 {
  margin-right: -2.5rem !important;
}

.-mb-10 {
  margin-bottom: -2.5rem !important;
}

.-ml-10 {
  margin-left: -2.5rem !important;
}

.-mx-10 {
  margin-left: -2.5rem !important;
  margin-right: -2.5rem !important;
}

.-my-10 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.-m-11 {
  margin: -2.75rem !important;
}

.-mt-11 {
  margin-top: -2.75rem !important;
}

.-mr-11 {
  margin-right: -2.75rem !important;
}

.-mb-11 {
  margin-bottom: -2.75rem !important;
}

.-ml-11 {
  margin-left: -2.75rem !important;
}

.-mx-11 {
  margin-left: -2.75rem !important;
  margin-right: -2.75rem !important;
}

.-my-11 {
  margin-top: -2.75rem !important;
  margin-bottom: -2.75rem !important;
}

.-m-12 {
  margin: -3rem !important;
}

.-mt-12 {
  margin-top: -3rem !important;
}

.-mr-12 {
  margin-right: -3rem !important;
}

.-mb-12 {
  margin-bottom: -3rem !important;
}

.-ml-12 {
  margin-left: -3rem !important;
}

.-mx-12 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.-my-12 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.-m-14 {
  margin: -3.5rem !important;
}

.-mt-14 {
  margin-top: -3.5rem !important;
}

.-mr-14 {
  margin-right: -3.5rem !important;
}

.-mb-14 {
  margin-bottom: -3.5rem !important;
}

.-ml-14 {
  margin-left: -3.5rem !important;
}

.-mx-14 {
  margin-left: -3.5rem !important;
  margin-right: -3.5rem !important;
}

.-my-14 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.-m-16 {
  margin: -4rem !important;
}

.-mt-16 {
  margin-top: -4rem !important;
}

.-mr-16 {
  margin-right: -4rem !important;
}

.-mb-16 {
  margin-bottom: -4rem !important;
}

.-ml-16 {
  margin-left: -4rem !important;
}

.-mx-16 {
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}

.-my-16 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.-m-20 {
  margin: -5rem !important;
}

.-mt-20 {
  margin-top: -5rem !important;
}

.-mr-20 {
  margin-right: -5rem !important;
}

.-mb-20 {
  margin-bottom: -5rem !important;
}

.-ml-20 {
  margin-left: -5rem !important;
}

.-mx-20 {
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}

.-my-20 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.-m-24 {
  margin: -6rem !important;
}

.-mt-24 {
  margin-top: -6rem !important;
}

.-mr-24 {
  margin-right: -6rem !important;
}

.-mb-24 {
  margin-bottom: -6rem !important;
}

.-ml-24 {
  margin-left: -6rem !important;
}

.-mx-24 {
  margin-left: -6rem !important;
  margin-right: -6rem !important;
}

.-my-24 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.-m-28 {
  margin: -7rem !important;
}

.-mt-28 {
  margin-top: -7rem !important;
}

.-mr-28 {
  margin-right: -7rem !important;
}

.-mb-28 {
  margin-bottom: -7rem !important;
}

.-ml-28 {
  margin-left: -7rem !important;
}

.-mx-28 {
  margin-left: -7rem !important;
  margin-right: -7rem !important;
}

.-my-28 {
  margin-top: -7rem !important;
  margin-bottom: -7rem !important;
}

.-m-32 {
  margin: -8rem !important;
}

.-mt-32 {
  margin-top: -8rem !important;
}

.-mr-32 {
  margin-right: -8rem !important;
}

.-mb-32 {
  margin-bottom: -8rem !important;
}

.-ml-32 {
  margin-left: -8rem !important;
}

.-mx-32 {
  margin-left: -8rem !important;
  margin-right: -8rem !important;
}

.-my-32 {
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.-m-36 {
  margin: -9rem !important;
}

.-mt-36 {
  margin-top: -9rem !important;
}

.-mr-36 {
  margin-right: -9rem !important;
}

.-mb-36 {
  margin-bottom: -9rem !important;
}

.-ml-36 {
  margin-left: -9rem !important;
}

.-mx-36 {
  margin-left: -9rem !important;
  margin-right: -9rem !important;
}

.-my-36 {
  margin-top: -9rem !important;
  margin-bottom: -9rem !important;
}

.-m-40 {
  margin: -10rem !important;
}

.-mt-40 {
  margin-top: -10rem !important;
}

.-mr-40 {
  margin-right: -10rem !important;
}

.-mb-40 {
  margin-bottom: -10rem !important;
}

.-ml-40 {
  margin-left: -10rem !important;
}

.-mx-40 {
  margin-left: -10rem !important;
  margin-right: -10rem !important;
}

.-my-40 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.-m-44 {
  margin: -11rem !important;
}

.-mt-44 {
  margin-top: -11rem !important;
}

.-mr-44 {
  margin-right: -11rem !important;
}

.-mb-44 {
  margin-bottom: -11rem !important;
}

.-ml-44 {
  margin-left: -11rem !important;
}

.-mx-44 {
  margin-left: -11rem !important;
  margin-right: -11rem !important;
}

.-my-44 {
  margin-top: -11rem !important;
  margin-bottom: -11rem !important;
}

.-m-48 {
  margin: -12rem !important;
}

.-mt-48 {
  margin-top: -12rem !important;
}

.-mr-48 {
  margin-right: -12rem !important;
}

.-mb-48 {
  margin-bottom: -12rem !important;
}

.-ml-48 {
  margin-left: -12rem !important;
}

.-mx-48 {
  margin-left: -12rem !important;
  margin-right: -12rem !important;
}

.-my-48 {
  margin-top: -12rem !important;
  margin-bottom: -12rem !important;
}

.-m-52 {
  margin: -13rem !important;
}

.-mt-52 {
  margin-top: -13rem !important;
}

.-mr-52 {
  margin-right: -13rem !important;
}

.-mb-52 {
  margin-bottom: -13rem !important;
}

.-ml-52 {
  margin-left: -13rem !important;
}

.-mx-52 {
  margin-left: -13rem !important;
  margin-right: -13rem !important;
}

.-my-52 {
  margin-top: -13rem !important;
  margin-bottom: -13rem !important;
}

.-m-56 {
  margin: -14rem !important;
}

.-mt-56 {
  margin-top: -14rem !important;
}

.-mr-56 {
  margin-right: -14rem !important;
}

.-mb-56 {
  margin-bottom: -14rem !important;
}

.-ml-56 {
  margin-left: -14rem !important;
}

.-mx-56 {
  margin-left: -14rem !important;
  margin-right: -14rem !important;
}

.-my-56 {
  margin-top: -14rem !important;
  margin-bottom: -14rem !important;
}

.-m-60 {
  margin: -15rem !important;
}

.-mt-60 {
  margin-top: -15rem !important;
}

.-mr-60 {
  margin-right: -15rem !important;
}

.-mb-60 {
  margin-bottom: -15rem !important;
}

.-ml-60 {
  margin-left: -15rem !important;
}

.-mx-60 {
  margin-left: -15rem !important;
  margin-right: -15rem !important;
}

.-my-60 {
  margin-top: -15rem !important;
  margin-bottom: -15rem !important;
}

.-m-64 {
  margin: -16rem !important;
}

.-mt-64 {
  margin-top: -16rem !important;
}

.-mr-64 {
  margin-right: -16rem !important;
}

.-mb-64 {
  margin-bottom: -16rem !important;
}

.-ml-64 {
  margin-left: -16rem !important;
}

.-mx-64 {
  margin-left: -16rem !important;
  margin-right: -16rem !important;
}

.-my-64 {
  margin-top: -16rem !important;
  margin-bottom: -16rem !important;
}

.-m-72 {
  margin: -18rem !important;
}

.-mt-72 {
  margin-top: -18rem !important;
}

.-mr-72 {
  margin-right: -18rem !important;
}

.-mb-72 {
  margin-bottom: -18rem !important;
}

.-ml-72 {
  margin-left: -18rem !important;
}

.-mx-72 {
  margin-left: -18rem !important;
  margin-right: -18rem !important;
}

.-my-72 {
  margin-top: -18rem !important;
  margin-bottom: -18rem !important;
}

.-m-80 {
  margin: -20rem !important;
}

.-mt-80 {
  margin-top: -20rem !important;
}

.-mr-80 {
  margin-right: -20rem !important;
}

.-mb-80 {
  margin-bottom: -20rem !important;
}

.-ml-80 {
  margin-left: -20rem !important;
}

.-mx-80 {
  margin-left: -20rem !important;
  margin-right: -20rem !important;
}

.-my-80 {
  margin-top: -20rem !important;
  margin-bottom: -20rem !important;
}

.-m-96 {
  margin: -24rem !important;
}

.-mt-96 {
  margin-top: -24rem !important;
}

.-mr-96 {
  margin-right: -24rem !important;
}

.-mb-96 {
  margin-bottom: -24rem !important;
}

.-ml-96 {
  margin-left: -24rem !important;
}

.-mx-96 {
  margin-left: -24rem !important;
  margin-right: -24rem !important;
}

.-my-96 {
  margin-top: -24rem !important;
  margin-bottom: -24rem !important;
}

@media screen and (width <= 768px) {
  .m-auto-mobile {
    margin: auto !important;
  }

  .mt-auto-mobile {
    margin-top: auto !important;
  }

  .mr-auto-mobile {
    margin-right: auto !important;
  }

  .mb-auto-mobile {
    margin-bottom: auto !important;
  }

  .ml-auto-mobile {
    margin-left: auto !important;
  }

  .mx-auto-mobile {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-auto-mobile {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .m-0-mobile {
    margin: 0 !important;
  }

  .mt-0-mobile {
    margin-top: 0 !important;
  }

  .mr-0-mobile {
    margin-right: 0 !important;
  }

  .mb-0-mobile {
    margin-bottom: 0 !important;
  }

  .ml-0-mobile {
    margin-left: 0 !important;
  }

  .mx-0-mobile {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-0-mobile {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-1-mobile {
    margin: .25rem !important;
  }

  .-m-1-mobile {
    margin: -.25rem !important;
  }

  .mt-1-mobile {
    margin-top: .25rem !important;
  }

  .-mt-1-mobile {
    margin-top: -.25rem !important;
  }

  .mr-1-mobile {
    margin-right: .25rem !important;
  }

  .-mr-1-mobile {
    margin-right: -.25rem !important;
  }

  .mb-1-mobile {
    margin-bottom: .25rem !important;
  }

  .-mb-1-mobile {
    margin-bottom: -.25rem !important;
  }

  .ml-1-mobile {
    margin-left: .25rem !important;
  }

  .-ml-1-mobile {
    margin-left: -.25rem !important;
  }

  .mx-1-mobile {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .-mx-1-mobile {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .my-1-mobile {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .-my-1-mobile {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .m-2-mobile {
    margin: .5rem !important;
  }

  .-m-2-mobile {
    margin: -.5rem !important;
  }

  .mt-2-mobile {
    margin-top: .5rem !important;
  }

  .-mt-2-mobile {
    margin-top: -.5rem !important;
  }

  .mr-2-mobile {
    margin-right: .5rem !important;
  }

  .-mr-2-mobile {
    margin-right: -.5rem !important;
  }

  .mb-2-mobile {
    margin-bottom: .5rem !important;
  }

  .-mb-2-mobile {
    margin-bottom: -.5rem !important;
  }

  .ml-2-mobile {
    margin-left: .5rem !important;
  }

  .-ml-2-mobile {
    margin-left: -.5rem !important;
  }

  .mx-2-mobile {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .-mx-2-mobile {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .my-2-mobile {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .-my-2-mobile {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .m-3-mobile {
    margin: .75rem !important;
  }

  .-m-3-mobile {
    margin: -.75rem !important;
  }

  .mt-3-mobile {
    margin-top: .75rem !important;
  }

  .-mt-3-mobile {
    margin-top: -.75rem !important;
  }

  .mr-3-mobile {
    margin-right: .75rem !important;
  }

  .-mr-3-mobile {
    margin-right: -.75rem !important;
  }

  .mb-3-mobile {
    margin-bottom: .75rem !important;
  }

  .-mb-3-mobile {
    margin-bottom: -.75rem !important;
  }

  .ml-3-mobile {
    margin-left: .75rem !important;
  }

  .-ml-3-mobile {
    margin-left: -.75rem !important;
  }

  .mx-3-mobile {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .-mx-3-mobile {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .my-3-mobile {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .-my-3-mobile {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .m-4-mobile {
    margin: 1rem !important;
  }

  .-m-4-mobile {
    margin: -1rem !important;
  }

  .mt-4-mobile {
    margin-top: 1rem !important;
  }

  .-mt-4-mobile {
    margin-top: -1rem !important;
  }

  .mr-4-mobile {
    margin-right: 1rem !important;
  }

  .-mr-4-mobile {
    margin-right: -1rem !important;
  }

  .mb-4-mobile {
    margin-bottom: 1rem !important;
  }

  .-mb-4-mobile {
    margin-bottom: -1rem !important;
  }

  .ml-4-mobile {
    margin-left: 1rem !important;
  }

  .-ml-4-mobile {
    margin-left: -1rem !important;
  }

  .mx-4-mobile {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .-mx-4-mobile {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .my-4-mobile {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .-my-4-mobile {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .m-5-mobile {
    margin: 1.25rem !important;
  }

  .-m-5-mobile {
    margin: -1.25rem !important;
  }

  .mt-5-mobile {
    margin-top: 1.25rem !important;
  }

  .-mt-5-mobile {
    margin-top: -1.25rem !important;
  }

  .mr-5-mobile {
    margin-right: 1.25rem !important;
  }

  .-mr-5-mobile {
    margin-right: -1.25rem !important;
  }

  .mb-5-mobile {
    margin-bottom: 1.25rem !important;
  }

  .-mb-5-mobile {
    margin-bottom: -1.25rem !important;
  }

  .ml-5-mobile {
    margin-left: 1.25rem !important;
  }

  .-ml-5-mobile {
    margin-left: -1.25rem !important;
  }

  .mx-5-mobile {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .-mx-5-mobile {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .my-5-mobile {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .-my-5-mobile {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .m-6-mobile {
    margin: 1.5rem !important;
  }

  .-m-6-mobile {
    margin: -1.5rem !important;
  }

  .mt-6-mobile {
    margin-top: 1.5rem !important;
  }

  .-mt-6-mobile {
    margin-top: -1.5rem !important;
  }

  .mr-6-mobile {
    margin-right: 1.5rem !important;
  }

  .-mr-6-mobile {
    margin-right: -1.5rem !important;
  }

  .mb-6-mobile {
    margin-bottom: 1.5rem !important;
  }

  .-mb-6-mobile {
    margin-bottom: -1.5rem !important;
  }

  .ml-6-mobile {
    margin-left: 1.5rem !important;
  }

  .-ml-6-mobile {
    margin-left: -1.5rem !important;
  }

  .mx-6-mobile {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .-mx-6-mobile {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .my-6-mobile {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .-my-6-mobile {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .m-7-mobile {
    margin: 1.75rem !important;
  }

  .-m-7-mobile {
    margin: -1.75rem !important;
  }

  .mt-7-mobile {
    margin-top: 1.75rem !important;
  }

  .-mt-7-mobile {
    margin-top: -1.75rem !important;
  }

  .mr-7-mobile {
    margin-right: 1.75rem !important;
  }

  .-mr-7-mobile {
    margin-right: -1.75rem !important;
  }

  .mb-7-mobile {
    margin-bottom: 1.75rem !important;
  }

  .-mb-7-mobile {
    margin-bottom: -1.75rem !important;
  }

  .ml-7-mobile {
    margin-left: 1.75rem !important;
  }

  .-ml-7-mobile {
    margin-left: -1.75rem !important;
  }

  .mx-7-mobile {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .-mx-7-mobile {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .my-7-mobile {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .-my-7-mobile {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .m-8-mobile {
    margin: 2rem !important;
  }

  .-m-8-mobile {
    margin: -2rem !important;
  }

  .mt-8-mobile {
    margin-top: 2rem !important;
  }

  .-mt-8-mobile {
    margin-top: -2rem !important;
  }

  .mr-8-mobile {
    margin-right: 2rem !important;
  }

  .-mr-8-mobile {
    margin-right: -2rem !important;
  }

  .mb-8-mobile {
    margin-bottom: 2rem !important;
  }

  .-mb-8-mobile {
    margin-bottom: -2rem !important;
  }

  .ml-8-mobile {
    margin-left: 2rem !important;
  }

  .-ml-8-mobile {
    margin-left: -2rem !important;
  }

  .mx-8-mobile {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .-mx-8-mobile {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .my-8-mobile {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .-my-8-mobile {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .m-9-mobile {
    margin: 2.25rem !important;
  }

  .-m-9-mobile {
    margin: -2.25rem !important;
  }

  .mt-9-mobile {
    margin-top: 2.25rem !important;
  }

  .-mt-9-mobile {
    margin-top: -2.25rem !important;
  }

  .mr-9-mobile {
    margin-right: 2.25rem !important;
  }

  .-mr-9-mobile {
    margin-right: -2.25rem !important;
  }

  .mb-9-mobile {
    margin-bottom: 2.25rem !important;
  }

  .-mb-9-mobile {
    margin-bottom: -2.25rem !important;
  }

  .ml-9-mobile {
    margin-left: 2.25rem !important;
  }

  .-ml-9-mobile {
    margin-left: -2.25rem !important;
  }

  .mx-9-mobile {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .-mx-9-mobile {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .my-9-mobile {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .-my-9-mobile {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .m-10-mobile {
    margin: 2.5rem !important;
  }

  .-m-10-mobile {
    margin: -2.5rem !important;
  }

  .mt-10-mobile {
    margin-top: 2.5rem !important;
  }

  .-mt-10-mobile {
    margin-top: -2.5rem !important;
  }

  .mr-10-mobile {
    margin-right: 2.5rem !important;
  }

  .-mr-10-mobile {
    margin-right: -2.5rem !important;
  }

  .mb-10-mobile {
    margin-bottom: 2.5rem !important;
  }

  .-mb-10-mobile {
    margin-bottom: -2.5rem !important;
  }

  .ml-10-mobile {
    margin-left: 2.5rem !important;
  }

  .-ml-10-mobile {
    margin-left: -2.5rem !important;
  }

  .mx-10-mobile {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .-mx-10-mobile {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .my-10-mobile {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .-my-10-mobile {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .m-11-mobile {
    margin: 2.75rem !important;
  }

  .-m-11-mobile {
    margin: -2.75rem !important;
  }

  .mt-11-mobile {
    margin-top: 2.75rem !important;
  }

  .-mt-11-mobile {
    margin-top: -2.75rem !important;
  }

  .mr-11-mobile {
    margin-right: 2.75rem !important;
  }

  .-mr-11-mobile {
    margin-right: -2.75rem !important;
  }

  .mb-11-mobile {
    margin-bottom: 2.75rem !important;
  }

  .-mb-11-mobile {
    margin-bottom: -2.75rem !important;
  }

  .ml-11-mobile {
    margin-left: 2.75rem !important;
  }

  .-ml-11-mobile {
    margin-left: -2.75rem !important;
  }

  .mx-11-mobile {
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
  }

  .-mx-11-mobile {
    margin-left: -2.75rem !important;
    margin-right: -2.75rem !important;
  }

  .my-11-mobile {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .-my-11-mobile {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .m-12-mobile {
    margin: 3rem !important;
  }

  .-m-12-mobile {
    margin: -3rem !important;
  }

  .mt-12-mobile {
    margin-top: 3rem !important;
  }

  .-mt-12-mobile {
    margin-top: -3rem !important;
  }

  .mr-12-mobile {
    margin-right: 3rem !important;
  }

  .-mr-12-mobile {
    margin-right: -3rem !important;
  }

  .mb-12-mobile {
    margin-bottom: 3rem !important;
  }

  .-mb-12-mobile {
    margin-bottom: -3rem !important;
  }

  .ml-12-mobile {
    margin-left: 3rem !important;
  }

  .-ml-12-mobile {
    margin-left: -3rem !important;
  }

  .mx-12-mobile {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .-mx-12-mobile {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-12-mobile {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .-my-12-mobile {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .m-14-mobile {
    margin: 3.5rem !important;
  }

  .-m-14-mobile {
    margin: -3.5rem !important;
  }

  .mt-14-mobile {
    margin-top: 3.5rem !important;
  }

  .-mt-14-mobile {
    margin-top: -3.5rem !important;
  }

  .mr-14-mobile {
    margin-right: 3.5rem !important;
  }

  .-mr-14-mobile {
    margin-right: -3.5rem !important;
  }

  .mb-14-mobile {
    margin-bottom: 3.5rem !important;
  }

  .-mb-14-mobile {
    margin-bottom: -3.5rem !important;
  }

  .ml-14-mobile {
    margin-left: 3.5rem !important;
  }

  .-ml-14-mobile {
    margin-left: -3.5rem !important;
  }

  .mx-14-mobile {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .-mx-14-mobile {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .my-14-mobile {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .-my-14-mobile {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .m-16-mobile {
    margin: 4rem !important;
  }

  .-m-16-mobile {
    margin: -4rem !important;
  }

  .mt-16-mobile {
    margin-top: 4rem !important;
  }

  .-mt-16-mobile {
    margin-top: -4rem !important;
  }

  .mr-16-mobile {
    margin-right: 4rem !important;
  }

  .-mr-16-mobile {
    margin-right: -4rem !important;
  }

  .mb-16-mobile {
    margin-bottom: 4rem !important;
  }

  .-mb-16-mobile {
    margin-bottom: -4rem !important;
  }

  .ml-16-mobile {
    margin-left: 4rem !important;
  }

  .-ml-16-mobile {
    margin-left: -4rem !important;
  }

  .mx-16-mobile {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .-mx-16-mobile {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .my-16-mobile {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .-my-16-mobile {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .m-20-mobile {
    margin: 5rem !important;
  }

  .-m-20-mobile {
    margin: -5rem !important;
  }

  .mt-20-mobile {
    margin-top: 5rem !important;
  }

  .-mt-20-mobile {
    margin-top: -5rem !important;
  }

  .mr-20-mobile {
    margin-right: 5rem !important;
  }

  .-mr-20-mobile {
    margin-right: -5rem !important;
  }

  .mb-20-mobile {
    margin-bottom: 5rem !important;
  }

  .-mb-20-mobile {
    margin-bottom: -5rem !important;
  }

  .ml-20-mobile {
    margin-left: 5rem !important;
  }

  .-ml-20-mobile {
    margin-left: -5rem !important;
  }

  .mx-20-mobile {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .-mx-20-mobile {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .my-20-mobile {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .-my-20-mobile {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .m-24-mobile {
    margin: 6rem !important;
  }

  .-m-24-mobile {
    margin: -6rem !important;
  }

  .mt-24-mobile {
    margin-top: 6rem !important;
  }

  .-mt-24-mobile {
    margin-top: -6rem !important;
  }

  .mr-24-mobile {
    margin-right: 6rem !important;
  }

  .-mr-24-mobile {
    margin-right: -6rem !important;
  }

  .mb-24-mobile {
    margin-bottom: 6rem !important;
  }

  .-mb-24-mobile {
    margin-bottom: -6rem !important;
  }

  .ml-24-mobile {
    margin-left: 6rem !important;
  }

  .-ml-24-mobile {
    margin-left: -6rem !important;
  }

  .mx-24-mobile {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .-mx-24-mobile {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .my-24-mobile {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .-my-24-mobile {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .m-28-mobile {
    margin: 7rem !important;
  }

  .-m-28-mobile {
    margin: -7rem !important;
  }

  .mt-28-mobile {
    margin-top: 7rem !important;
  }

  .-mt-28-mobile {
    margin-top: -7rem !important;
  }

  .mr-28-mobile {
    margin-right: 7rem !important;
  }

  .-mr-28-mobile {
    margin-right: -7rem !important;
  }

  .mb-28-mobile {
    margin-bottom: 7rem !important;
  }

  .-mb-28-mobile {
    margin-bottom: -7rem !important;
  }

  .ml-28-mobile {
    margin-left: 7rem !important;
  }

  .-ml-28-mobile {
    margin-left: -7rem !important;
  }

  .mx-28-mobile {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .-mx-28-mobile {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .my-28-mobile {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .-my-28-mobile {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .m-32-mobile {
    margin: 8rem !important;
  }

  .-m-32-mobile {
    margin: -8rem !important;
  }

  .mt-32-mobile {
    margin-top: 8rem !important;
  }

  .-mt-32-mobile {
    margin-top: -8rem !important;
  }

  .mr-32-mobile {
    margin-right: 8rem !important;
  }

  .-mr-32-mobile {
    margin-right: -8rem !important;
  }

  .mb-32-mobile {
    margin-bottom: 8rem !important;
  }

  .-mb-32-mobile {
    margin-bottom: -8rem !important;
  }

  .ml-32-mobile {
    margin-left: 8rem !important;
  }

  .-ml-32-mobile {
    margin-left: -8rem !important;
  }

  .mx-32-mobile {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .-mx-32-mobile {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .my-32-mobile {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .-my-32-mobile {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .m-36-mobile {
    margin: 9rem !important;
  }

  .-m-36-mobile {
    margin: -9rem !important;
  }

  .mt-36-mobile {
    margin-top: 9rem !important;
  }

  .-mt-36-mobile {
    margin-top: -9rem !important;
  }

  .mr-36-mobile {
    margin-right: 9rem !important;
  }

  .-mr-36-mobile {
    margin-right: -9rem !important;
  }

  .mb-36-mobile {
    margin-bottom: 9rem !important;
  }

  .-mb-36-mobile {
    margin-bottom: -9rem !important;
  }

  .ml-36-mobile {
    margin-left: 9rem !important;
  }

  .-ml-36-mobile {
    margin-left: -9rem !important;
  }

  .mx-36-mobile {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .-mx-36-mobile {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .my-36-mobile {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .-my-36-mobile {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .m-40-mobile {
    margin: 10rem !important;
  }

  .-m-40-mobile {
    margin: -10rem !important;
  }

  .mt-40-mobile {
    margin-top: 10rem !important;
  }

  .-mt-40-mobile {
    margin-top: -10rem !important;
  }

  .mr-40-mobile {
    margin-right: 10rem !important;
  }

  .-mr-40-mobile {
    margin-right: -10rem !important;
  }

  .mb-40-mobile {
    margin-bottom: 10rem !important;
  }

  .-mb-40-mobile {
    margin-bottom: -10rem !important;
  }

  .ml-40-mobile {
    margin-left: 10rem !important;
  }

  .-ml-40-mobile {
    margin-left: -10rem !important;
  }

  .mx-40-mobile {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .-mx-40-mobile {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .my-40-mobile {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .-my-40-mobile {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .m-44-mobile {
    margin: 11rem !important;
  }

  .-m-44-mobile {
    margin: -11rem !important;
  }

  .mt-44-mobile {
    margin-top: 11rem !important;
  }

  .-mt-44-mobile {
    margin-top: -11rem !important;
  }

  .mr-44-mobile {
    margin-right: 11rem !important;
  }

  .-mr-44-mobile {
    margin-right: -11rem !important;
  }

  .mb-44-mobile {
    margin-bottom: 11rem !important;
  }

  .-mb-44-mobile {
    margin-bottom: -11rem !important;
  }

  .ml-44-mobile {
    margin-left: 11rem !important;
  }

  .-ml-44-mobile {
    margin-left: -11rem !important;
  }

  .mx-44-mobile {
    margin-left: 11rem !important;
    margin-right: 11rem !important;
  }

  .-mx-44-mobile {
    margin-left: -11rem !important;
    margin-right: -11rem !important;
  }

  .my-44-mobile {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .-my-44-mobile {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .m-48-mobile {
    margin: 12rem !important;
  }

  .-m-48-mobile {
    margin: -12rem !important;
  }

  .mt-48-mobile {
    margin-top: 12rem !important;
  }

  .-mt-48-mobile {
    margin-top: -12rem !important;
  }

  .mr-48-mobile {
    margin-right: 12rem !important;
  }

  .-mr-48-mobile {
    margin-right: -12rem !important;
  }

  .mb-48-mobile {
    margin-bottom: 12rem !important;
  }

  .-mb-48-mobile {
    margin-bottom: -12rem !important;
  }

  .ml-48-mobile {
    margin-left: 12rem !important;
  }

  .-ml-48-mobile {
    margin-left: -12rem !important;
  }

  .mx-48-mobile {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .-mx-48-mobile {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .my-48-mobile {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .-my-48-mobile {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .m-52-mobile {
    margin: 13rem !important;
  }

  .-m-52-mobile {
    margin: -13rem !important;
  }

  .mt-52-mobile {
    margin-top: 13rem !important;
  }

  .-mt-52-mobile {
    margin-top: -13rem !important;
  }

  .mr-52-mobile {
    margin-right: 13rem !important;
  }

  .-mr-52-mobile {
    margin-right: -13rem !important;
  }

  .mb-52-mobile {
    margin-bottom: 13rem !important;
  }

  .-mb-52-mobile {
    margin-bottom: -13rem !important;
  }

  .ml-52-mobile {
    margin-left: 13rem !important;
  }

  .-ml-52-mobile {
    margin-left: -13rem !important;
  }

  .mx-52-mobile {
    margin-left: 13rem !important;
    margin-right: 13rem !important;
  }

  .-mx-52-mobile {
    margin-left: -13rem !important;
    margin-right: -13rem !important;
  }

  .my-52-mobile {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }

  .-my-52-mobile {
    margin-top: -13rem !important;
    margin-bottom: -13rem !important;
  }

  .m-56-mobile {
    margin: 14rem !important;
  }

  .-m-56-mobile {
    margin: -14rem !important;
  }

  .mt-56-mobile {
    margin-top: 14rem !important;
  }

  .-mt-56-mobile {
    margin-top: -14rem !important;
  }

  .mr-56-mobile {
    margin-right: 14rem !important;
  }

  .-mr-56-mobile {
    margin-right: -14rem !important;
  }

  .mb-56-mobile {
    margin-bottom: 14rem !important;
  }

  .-mb-56-mobile {
    margin-bottom: -14rem !important;
  }

  .ml-56-mobile {
    margin-left: 14rem !important;
  }

  .-ml-56-mobile {
    margin-left: -14rem !important;
  }

  .mx-56-mobile {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .-mx-56-mobile {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .my-56-mobile {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .-my-56-mobile {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .m-60-mobile {
    margin: 15rem !important;
  }

  .-m-60-mobile {
    margin: -15rem !important;
  }

  .mt-60-mobile {
    margin-top: 15rem !important;
  }

  .-mt-60-mobile {
    margin-top: -15rem !important;
  }

  .mr-60-mobile {
    margin-right: 15rem !important;
  }

  .-mr-60-mobile {
    margin-right: -15rem !important;
  }

  .mb-60-mobile {
    margin-bottom: 15rem !important;
  }

  .-mb-60-mobile {
    margin-bottom: -15rem !important;
  }

  .ml-60-mobile {
    margin-left: 15rem !important;
  }

  .-ml-60-mobile {
    margin-left: -15rem !important;
  }

  .mx-60-mobile {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .-mx-60-mobile {
    margin-left: -15rem !important;
    margin-right: -15rem !important;
  }

  .my-60-mobile {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .-my-60-mobile {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .m-64-mobile {
    margin: 16rem !important;
  }

  .-m-64-mobile {
    margin: -16rem !important;
  }

  .mt-64-mobile {
    margin-top: 16rem !important;
  }

  .-mt-64-mobile {
    margin-top: -16rem !important;
  }

  .mr-64-mobile {
    margin-right: 16rem !important;
  }

  .-mr-64-mobile {
    margin-right: -16rem !important;
  }

  .mb-64-mobile {
    margin-bottom: 16rem !important;
  }

  .-mb-64-mobile {
    margin-bottom: -16rem !important;
  }

  .ml-64-mobile {
    margin-left: 16rem !important;
  }

  .-ml-64-mobile {
    margin-left: -16rem !important;
  }

  .mx-64-mobile {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .-mx-64-mobile {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .my-64-mobile {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .-my-64-mobile {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .m-72-mobile {
    margin: 18rem !important;
  }

  .-m-72-mobile {
    margin: -18rem !important;
  }

  .mt-72-mobile {
    margin-top: 18rem !important;
  }

  .-mt-72-mobile {
    margin-top: -18rem !important;
  }

  .mr-72-mobile {
    margin-right: 18rem !important;
  }

  .-mr-72-mobile {
    margin-right: -18rem !important;
  }

  .mb-72-mobile {
    margin-bottom: 18rem !important;
  }

  .-mb-72-mobile {
    margin-bottom: -18rem !important;
  }

  .ml-72-mobile {
    margin-left: 18rem !important;
  }

  .-ml-72-mobile {
    margin-left: -18rem !important;
  }

  .mx-72-mobile {
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .-mx-72-mobile {
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .my-72-mobile {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .-my-72-mobile {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .m-80-mobile {
    margin: 20rem !important;
  }

  .-m-80-mobile {
    margin: -20rem !important;
  }

  .mt-80-mobile {
    margin-top: 20rem !important;
  }

  .-mt-80-mobile {
    margin-top: -20rem !important;
  }

  .mr-80-mobile {
    margin-right: 20rem !important;
  }

  .-mr-80-mobile {
    margin-right: -20rem !important;
  }

  .mb-80-mobile {
    margin-bottom: 20rem !important;
  }

  .-mb-80-mobile {
    margin-bottom: -20rem !important;
  }

  .ml-80-mobile {
    margin-left: 20rem !important;
  }

  .-ml-80-mobile {
    margin-left: -20rem !important;
  }

  .mx-80-mobile {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .-mx-80-mobile {
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .my-80-mobile {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .-my-80-mobile {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .m-96-mobile {
    margin: 24rem !important;
  }

  .-m-96-mobile {
    margin: -24rem !important;
  }

  .mt-96-mobile {
    margin-top: 24rem !important;
  }

  .-mt-96-mobile {
    margin-top: -24rem !important;
  }

  .mr-96-mobile {
    margin-right: 24rem !important;
  }

  .-mr-96-mobile {
    margin-right: -24rem !important;
  }

  .mb-96-mobile {
    margin-bottom: 24rem !important;
  }

  .-mb-96-mobile {
    margin-bottom: -24rem !important;
  }

  .ml-96-mobile {
    margin-left: 24rem !important;
  }

  .-ml-96-mobile {
    margin-left: -24rem !important;
  }

  .mx-96-mobile {
    margin-left: 24rem !important;
    margin-right: 24rem !important;
  }

  .-mx-96-mobile {
    margin-left: -24rem !important;
    margin-right: -24rem !important;
  }

  .my-96-mobile {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }

  .-my-96-mobile {
    margin-top: -24rem !important;
    margin-bottom: -24rem !important;
  }

  .p-auto-mobile {
    padding: auto !important;
  }

  .pt-auto-mobile {
    padding-top: auto !important;
  }

  .pr-auto-mobile {
    padding-right: auto !important;
  }

  .pb-auto-mobile {
    padding-bottom: auto !important;
  }

  .pl-auto-mobile {
    padding-left: auto !important;
  }

  .px-auto-mobile {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .py-auto-mobile {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }

  .p-0-mobile {
    padding: 0 !important;
  }

  .pt-0-mobile {
    padding-top: 0 !important;
  }

  .pr-0-mobile {
    padding-right: 0 !important;
  }

  .pb-0-mobile {
    padding-bottom: 0 !important;
  }

  .pl-0-mobile {
    padding-left: 0 !important;
  }

  .px-0-mobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-0-mobile {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-1-mobile {
    padding: .25rem !important;
  }

  .pt-1-mobile {
    padding-top: .25rem !important;
  }

  .pr-1-mobile {
    padding-right: .25rem !important;
  }

  .pb-1-mobile {
    padding-bottom: .25rem !important;
  }

  .pl-1-mobile {
    padding-left: .25rem !important;
  }

  .px-1-mobile {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .py-1-mobile {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .p-2-mobile {
    padding: .5rem !important;
  }

  .pt-2-mobile {
    padding-top: .5rem !important;
  }

  .pr-2-mobile {
    padding-right: .5rem !important;
  }

  .pb-2-mobile {
    padding-bottom: .5rem !important;
  }

  .pl-2-mobile {
    padding-left: .5rem !important;
  }

  .px-2-mobile {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .py-2-mobile {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .p-3-mobile {
    padding: .75rem !important;
  }

  .pt-3-mobile {
    padding-top: .75rem !important;
  }

  .pr-3-mobile {
    padding-right: .75rem !important;
  }

  .pb-3-mobile {
    padding-bottom: .75rem !important;
  }

  .pl-3-mobile {
    padding-left: .75rem !important;
  }

  .px-3-mobile {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .py-3-mobile {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .p-4-mobile {
    padding: 1rem !important;
  }

  .pt-4-mobile {
    padding-top: 1rem !important;
  }

  .pr-4-mobile {
    padding-right: 1rem !important;
  }

  .pb-4-mobile {
    padding-bottom: 1rem !important;
  }

  .pl-4-mobile {
    padding-left: 1rem !important;
  }

  .px-4-mobile {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .py-4-mobile {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .p-5-mobile {
    padding: 1.25rem !important;
  }

  .pt-5-mobile {
    padding-top: 1.25rem !important;
  }

  .pr-5-mobile {
    padding-right: 1.25rem !important;
  }

  .pb-5-mobile {
    padding-bottom: 1.25rem !important;
  }

  .pl-5-mobile {
    padding-left: 1.25rem !important;
  }

  .px-5-mobile {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .py-5-mobile {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .p-6-mobile {
    padding: 1.5rem !important;
  }

  .pt-6-mobile {
    padding-top: 1.5rem !important;
  }

  .pr-6-mobile {
    padding-right: 1.5rem !important;
  }

  .pb-6-mobile {
    padding-bottom: 1.5rem !important;
  }

  .pl-6-mobile {
    padding-left: 1.5rem !important;
  }

  .px-6-mobile {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .py-6-mobile {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .p-7-mobile {
    padding: 1.75rem !important;
  }

  .pt-7-mobile {
    padding-top: 1.75rem !important;
  }

  .pr-7-mobile {
    padding-right: 1.75rem !important;
  }

  .pb-7-mobile {
    padding-bottom: 1.75rem !important;
  }

  .pl-7-mobile {
    padding-left: 1.75rem !important;
  }

  .px-7-mobile {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .py-7-mobile {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .p-8-mobile {
    padding: 2rem !important;
  }

  .pt-8-mobile {
    padding-top: 2rem !important;
  }

  .pr-8-mobile {
    padding-right: 2rem !important;
  }

  .pb-8-mobile {
    padding-bottom: 2rem !important;
  }

  .pl-8-mobile {
    padding-left: 2rem !important;
  }

  .px-8-mobile {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .py-8-mobile {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .p-9-mobile {
    padding: 2.25rem !important;
  }

  .pt-9-mobile {
    padding-top: 2.25rem !important;
  }

  .pr-9-mobile {
    padding-right: 2.25rem !important;
  }

  .pb-9-mobile {
    padding-bottom: 2.25rem !important;
  }

  .pl-9-mobile {
    padding-left: 2.25rem !important;
  }

  .px-9-mobile {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .py-9-mobile {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .p-10-mobile {
    padding: 2.5rem !important;
  }

  .pt-10-mobile {
    padding-top: 2.5rem !important;
  }

  .pr-10-mobile {
    padding-right: 2.5rem !important;
  }

  .pb-10-mobile {
    padding-bottom: 2.5rem !important;
  }

  .pl-10-mobile {
    padding-left: 2.5rem !important;
  }

  .px-10-mobile {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .py-10-mobile {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .p-11-mobile {
    padding: 2.75rem !important;
  }

  .pt-11-mobile {
    padding-top: 2.75rem !important;
  }

  .pr-11-mobile {
    padding-right: 2.75rem !important;
  }

  .pb-11-mobile {
    padding-bottom: 2.75rem !important;
  }

  .pl-11-mobile {
    padding-left: 2.75rem !important;
  }

  .px-11-mobile {
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  .py-11-mobile {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .p-12-mobile {
    padding: 3rem !important;
  }

  .pt-12-mobile {
    padding-top: 3rem !important;
  }

  .pr-12-mobile {
    padding-right: 3rem !important;
  }

  .pb-12-mobile {
    padding-bottom: 3rem !important;
  }

  .pl-12-mobile {
    padding-left: 3rem !important;
  }

  .px-12-mobile {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-12-mobile {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .p-14-mobile {
    padding: 3.5rem !important;
  }

  .pt-14-mobile {
    padding-top: 3.5rem !important;
  }

  .pr-14-mobile {
    padding-right: 3.5rem !important;
  }

  .pb-14-mobile {
    padding-bottom: 3.5rem !important;
  }

  .pl-14-mobile {
    padding-left: 3.5rem !important;
  }

  .px-14-mobile {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .py-14-mobile {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .p-16-mobile {
    padding: 4rem !important;
  }

  .pt-16-mobile {
    padding-top: 4rem !important;
  }

  .pr-16-mobile {
    padding-right: 4rem !important;
  }

  .pb-16-mobile {
    padding-bottom: 4rem !important;
  }

  .pl-16-mobile {
    padding-left: 4rem !important;
  }

  .px-16-mobile {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .py-16-mobile {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .p-20-mobile {
    padding: 5rem !important;
  }

  .pt-20-mobile {
    padding-top: 5rem !important;
  }

  .pr-20-mobile {
    padding-right: 5rem !important;
  }

  .pb-20-mobile {
    padding-bottom: 5rem !important;
  }

  .pl-20-mobile {
    padding-left: 5rem !important;
  }

  .px-20-mobile {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .py-20-mobile {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .p-24-mobile {
    padding: 6rem !important;
  }

  .pt-24-mobile {
    padding-top: 6rem !important;
  }

  .pr-24-mobile {
    padding-right: 6rem !important;
  }

  .pb-24-mobile {
    padding-bottom: 6rem !important;
  }

  .pl-24-mobile {
    padding-left: 6rem !important;
  }

  .px-24-mobile {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .py-24-mobile {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .p-28-mobile {
    padding: 7rem !important;
  }

  .pt-28-mobile {
    padding-top: 7rem !important;
  }

  .pr-28-mobile {
    padding-right: 7rem !important;
  }

  .pb-28-mobile {
    padding-bottom: 7rem !important;
  }

  .pl-28-mobile {
    padding-left: 7rem !important;
  }

  .px-28-mobile {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .py-28-mobile {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .p-32-mobile {
    padding: 8rem !important;
  }

  .pt-32-mobile {
    padding-top: 8rem !important;
  }

  .pr-32-mobile {
    padding-right: 8rem !important;
  }

  .pb-32-mobile {
    padding-bottom: 8rem !important;
  }

  .pl-32-mobile {
    padding-left: 8rem !important;
  }

  .px-32-mobile {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .py-32-mobile {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .p-36-mobile {
    padding: 9rem !important;
  }

  .pt-36-mobile {
    padding-top: 9rem !important;
  }

  .pr-36-mobile {
    padding-right: 9rem !important;
  }

  .pb-36-mobile {
    padding-bottom: 9rem !important;
  }

  .pl-36-mobile {
    padding-left: 9rem !important;
  }

  .px-36-mobile {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .py-36-mobile {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .p-40-mobile {
    padding: 10rem !important;
  }

  .pt-40-mobile {
    padding-top: 10rem !important;
  }

  .pr-40-mobile {
    padding-right: 10rem !important;
  }

  .pb-40-mobile {
    padding-bottom: 10rem !important;
  }

  .pl-40-mobile {
    padding-left: 10rem !important;
  }

  .px-40-mobile {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .py-40-mobile {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .p-44-mobile {
    padding: 11rem !important;
  }

  .pt-44-mobile {
    padding-top: 11rem !important;
  }

  .pr-44-mobile {
    padding-right: 11rem !important;
  }

  .pb-44-mobile {
    padding-bottom: 11rem !important;
  }

  .pl-44-mobile {
    padding-left: 11rem !important;
  }

  .px-44-mobile {
    padding-left: 11rem !important;
    padding-right: 11rem !important;
  }

  .py-44-mobile {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .p-48-mobile {
    padding: 12rem !important;
  }

  .pt-48-mobile {
    padding-top: 12rem !important;
  }

  .pr-48-mobile {
    padding-right: 12rem !important;
  }

  .pb-48-mobile {
    padding-bottom: 12rem !important;
  }

  .pl-48-mobile {
    padding-left: 12rem !important;
  }

  .px-48-mobile {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .py-48-mobile {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .p-52-mobile {
    padding: 13rem !important;
  }

  .pt-52-mobile {
    padding-top: 13rem !important;
  }

  .pr-52-mobile {
    padding-right: 13rem !important;
  }

  .pb-52-mobile {
    padding-bottom: 13rem !important;
  }

  .pl-52-mobile {
    padding-left: 13rem !important;
  }

  .px-52-mobile {
    padding-left: 13rem !important;
    padding-right: 13rem !important;
  }

  .py-52-mobile {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }

  .p-56-mobile {
    padding: 14rem !important;
  }

  .pt-56-mobile {
    padding-top: 14rem !important;
  }

  .pr-56-mobile {
    padding-right: 14rem !important;
  }

  .pb-56-mobile {
    padding-bottom: 14rem !important;
  }

  .pl-56-mobile {
    padding-left: 14rem !important;
  }

  .px-56-mobile {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .py-56-mobile {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .p-60-mobile {
    padding: 15rem !important;
  }

  .pt-60-mobile {
    padding-top: 15rem !important;
  }

  .pr-60-mobile {
    padding-right: 15rem !important;
  }

  .pb-60-mobile {
    padding-bottom: 15rem !important;
  }

  .pl-60-mobile {
    padding-left: 15rem !important;
  }

  .px-60-mobile {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .py-60-mobile {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .p-64-mobile {
    padding: 16rem !important;
  }

  .pt-64-mobile {
    padding-top: 16rem !important;
  }

  .pr-64-mobile {
    padding-right: 16rem !important;
  }

  .pb-64-mobile {
    padding-bottom: 16rem !important;
  }

  .pl-64-mobile {
    padding-left: 16rem !important;
  }

  .px-64-mobile {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .py-64-mobile {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .p-72-mobile {
    padding: 18rem !important;
  }

  .pt-72-mobile {
    padding-top: 18rem !important;
  }

  .pr-72-mobile {
    padding-right: 18rem !important;
  }

  .pb-72-mobile {
    padding-bottom: 18rem !important;
  }

  .pl-72-mobile {
    padding-left: 18rem !important;
  }

  .px-72-mobile {
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .py-72-mobile {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .p-80-mobile {
    padding: 20rem !important;
  }

  .pt-80-mobile {
    padding-top: 20rem !important;
  }

  .pr-80-mobile {
    padding-right: 20rem !important;
  }

  .pb-80-mobile {
    padding-bottom: 20rem !important;
  }

  .pl-80-mobile {
    padding-left: 20rem !important;
  }

  .px-80-mobile {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .py-80-mobile {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .p-96-mobile {
    padding: 24rem !important;
  }

  .pt-96-mobile {
    padding-top: 24rem !important;
  }

  .pr-96-mobile {
    padding-right: 24rem !important;
  }

  .pb-96-mobile {
    padding-bottom: 24rem !important;
  }

  .pl-96-mobile {
    padding-left: 24rem !important;
  }

  .px-96-mobile {
    padding-left: 24rem !important;
    padding-right: 24rem !important;
  }

  .py-96-mobile {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
}

@media screen and (width >= 769px), print {
  .m-auto-tablet {
    margin: auto !important;
  }

  .mt-auto-tablet {
    margin-top: auto !important;
  }

  .mr-auto-tablet {
    margin-right: auto !important;
  }

  .mb-auto-tablet {
    margin-bottom: auto !important;
  }

  .ml-auto-tablet {
    margin-left: auto !important;
  }

  .mx-auto-tablet {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-auto-tablet {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .m-0-tablet {
    margin: 0 !important;
  }

  .mt-0-tablet {
    margin-top: 0 !important;
  }

  .mr-0-tablet {
    margin-right: 0 !important;
  }

  .mb-0-tablet {
    margin-bottom: 0 !important;
  }

  .ml-0-tablet {
    margin-left: 0 !important;
  }

  .mx-0-tablet {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-0-tablet {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-1-tablet {
    margin: .25rem !important;
  }

  .-m-1-tablet {
    margin: -.25rem !important;
  }

  .mt-1-tablet {
    margin-top: .25rem !important;
  }

  .-mt-1-tablet {
    margin-top: -.25rem !important;
  }

  .mr-1-tablet {
    margin-right: .25rem !important;
  }

  .-mr-1-tablet {
    margin-right: -.25rem !important;
  }

  .mb-1-tablet {
    margin-bottom: .25rem !important;
  }

  .-mb-1-tablet {
    margin-bottom: -.25rem !important;
  }

  .ml-1-tablet {
    margin-left: .25rem !important;
  }

  .-ml-1-tablet {
    margin-left: -.25rem !important;
  }

  .mx-1-tablet {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .-mx-1-tablet {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .my-1-tablet {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .-my-1-tablet {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .m-2-tablet {
    margin: .5rem !important;
  }

  .-m-2-tablet {
    margin: -.5rem !important;
  }

  .mt-2-tablet {
    margin-top: .5rem !important;
  }

  .-mt-2-tablet {
    margin-top: -.5rem !important;
  }

  .mr-2-tablet {
    margin-right: .5rem !important;
  }

  .-mr-2-tablet {
    margin-right: -.5rem !important;
  }

  .mb-2-tablet {
    margin-bottom: .5rem !important;
  }

  .-mb-2-tablet {
    margin-bottom: -.5rem !important;
  }

  .ml-2-tablet {
    margin-left: .5rem !important;
  }

  .-ml-2-tablet {
    margin-left: -.5rem !important;
  }

  .mx-2-tablet {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .-mx-2-tablet {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .my-2-tablet {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .-my-2-tablet {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .m-3-tablet {
    margin: .75rem !important;
  }

  .-m-3-tablet {
    margin: -.75rem !important;
  }

  .mt-3-tablet {
    margin-top: .75rem !important;
  }

  .-mt-3-tablet {
    margin-top: -.75rem !important;
  }

  .mr-3-tablet {
    margin-right: .75rem !important;
  }

  .-mr-3-tablet {
    margin-right: -.75rem !important;
  }

  .mb-3-tablet {
    margin-bottom: .75rem !important;
  }

  .-mb-3-tablet {
    margin-bottom: -.75rem !important;
  }

  .ml-3-tablet {
    margin-left: .75rem !important;
  }

  .-ml-3-tablet {
    margin-left: -.75rem !important;
  }

  .mx-3-tablet {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .-mx-3-tablet {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .my-3-tablet {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .-my-3-tablet {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .m-4-tablet {
    margin: 1rem !important;
  }

  .-m-4-tablet {
    margin: -1rem !important;
  }

  .mt-4-tablet {
    margin-top: 1rem !important;
  }

  .-mt-4-tablet {
    margin-top: -1rem !important;
  }

  .mr-4-tablet {
    margin-right: 1rem !important;
  }

  .-mr-4-tablet {
    margin-right: -1rem !important;
  }

  .mb-4-tablet {
    margin-bottom: 1rem !important;
  }

  .-mb-4-tablet {
    margin-bottom: -1rem !important;
  }

  .ml-4-tablet {
    margin-left: 1rem !important;
  }

  .-ml-4-tablet {
    margin-left: -1rem !important;
  }

  .mx-4-tablet {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .-mx-4-tablet {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .my-4-tablet {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .-my-4-tablet {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .m-5-tablet {
    margin: 1.25rem !important;
  }

  .-m-5-tablet {
    margin: -1.25rem !important;
  }

  .mt-5-tablet {
    margin-top: 1.25rem !important;
  }

  .-mt-5-tablet {
    margin-top: -1.25rem !important;
  }

  .mr-5-tablet {
    margin-right: 1.25rem !important;
  }

  .-mr-5-tablet {
    margin-right: -1.25rem !important;
  }

  .mb-5-tablet {
    margin-bottom: 1.25rem !important;
  }

  .-mb-5-tablet {
    margin-bottom: -1.25rem !important;
  }

  .ml-5-tablet {
    margin-left: 1.25rem !important;
  }

  .-ml-5-tablet {
    margin-left: -1.25rem !important;
  }

  .mx-5-tablet {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .-mx-5-tablet {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .my-5-tablet {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .-my-5-tablet {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .m-6-tablet {
    margin: 1.5rem !important;
  }

  .-m-6-tablet {
    margin: -1.5rem !important;
  }

  .mt-6-tablet {
    margin-top: 1.5rem !important;
  }

  .-mt-6-tablet {
    margin-top: -1.5rem !important;
  }

  .mr-6-tablet {
    margin-right: 1.5rem !important;
  }

  .-mr-6-tablet {
    margin-right: -1.5rem !important;
  }

  .mb-6-tablet {
    margin-bottom: 1.5rem !important;
  }

  .-mb-6-tablet {
    margin-bottom: -1.5rem !important;
  }

  .ml-6-tablet {
    margin-left: 1.5rem !important;
  }

  .-ml-6-tablet {
    margin-left: -1.5rem !important;
  }

  .mx-6-tablet {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .-mx-6-tablet {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .my-6-tablet {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .-my-6-tablet {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .m-7-tablet {
    margin: 1.75rem !important;
  }

  .-m-7-tablet {
    margin: -1.75rem !important;
  }

  .mt-7-tablet {
    margin-top: 1.75rem !important;
  }

  .-mt-7-tablet {
    margin-top: -1.75rem !important;
  }

  .mr-7-tablet {
    margin-right: 1.75rem !important;
  }

  .-mr-7-tablet {
    margin-right: -1.75rem !important;
  }

  .mb-7-tablet {
    margin-bottom: 1.75rem !important;
  }

  .-mb-7-tablet {
    margin-bottom: -1.75rem !important;
  }

  .ml-7-tablet {
    margin-left: 1.75rem !important;
  }

  .-ml-7-tablet {
    margin-left: -1.75rem !important;
  }

  .mx-7-tablet {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .-mx-7-tablet {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .my-7-tablet {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .-my-7-tablet {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .m-8-tablet {
    margin: 2rem !important;
  }

  .-m-8-tablet {
    margin: -2rem !important;
  }

  .mt-8-tablet {
    margin-top: 2rem !important;
  }

  .-mt-8-tablet {
    margin-top: -2rem !important;
  }

  .mr-8-tablet {
    margin-right: 2rem !important;
  }

  .-mr-8-tablet {
    margin-right: -2rem !important;
  }

  .mb-8-tablet {
    margin-bottom: 2rem !important;
  }

  .-mb-8-tablet {
    margin-bottom: -2rem !important;
  }

  .ml-8-tablet {
    margin-left: 2rem !important;
  }

  .-ml-8-tablet {
    margin-left: -2rem !important;
  }

  .mx-8-tablet {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .-mx-8-tablet {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .my-8-tablet {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .-my-8-tablet {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .m-9-tablet {
    margin: 2.25rem !important;
  }

  .-m-9-tablet {
    margin: -2.25rem !important;
  }

  .mt-9-tablet {
    margin-top: 2.25rem !important;
  }

  .-mt-9-tablet {
    margin-top: -2.25rem !important;
  }

  .mr-9-tablet {
    margin-right: 2.25rem !important;
  }

  .-mr-9-tablet {
    margin-right: -2.25rem !important;
  }

  .mb-9-tablet {
    margin-bottom: 2.25rem !important;
  }

  .-mb-9-tablet {
    margin-bottom: -2.25rem !important;
  }

  .ml-9-tablet {
    margin-left: 2.25rem !important;
  }

  .-ml-9-tablet {
    margin-left: -2.25rem !important;
  }

  .mx-9-tablet {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .-mx-9-tablet {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .my-9-tablet {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .-my-9-tablet {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .m-10-tablet {
    margin: 2.5rem !important;
  }

  .-m-10-tablet {
    margin: -2.5rem !important;
  }

  .mt-10-tablet {
    margin-top: 2.5rem !important;
  }

  .-mt-10-tablet {
    margin-top: -2.5rem !important;
  }

  .mr-10-tablet {
    margin-right: 2.5rem !important;
  }

  .-mr-10-tablet {
    margin-right: -2.5rem !important;
  }

  .mb-10-tablet {
    margin-bottom: 2.5rem !important;
  }

  .-mb-10-tablet {
    margin-bottom: -2.5rem !important;
  }

  .ml-10-tablet {
    margin-left: 2.5rem !important;
  }

  .-ml-10-tablet {
    margin-left: -2.5rem !important;
  }

  .mx-10-tablet {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .-mx-10-tablet {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .my-10-tablet {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .-my-10-tablet {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .m-11-tablet {
    margin: 2.75rem !important;
  }

  .-m-11-tablet {
    margin: -2.75rem !important;
  }

  .mt-11-tablet {
    margin-top: 2.75rem !important;
  }

  .-mt-11-tablet {
    margin-top: -2.75rem !important;
  }

  .mr-11-tablet {
    margin-right: 2.75rem !important;
  }

  .-mr-11-tablet {
    margin-right: -2.75rem !important;
  }

  .mb-11-tablet {
    margin-bottom: 2.75rem !important;
  }

  .-mb-11-tablet {
    margin-bottom: -2.75rem !important;
  }

  .ml-11-tablet {
    margin-left: 2.75rem !important;
  }

  .-ml-11-tablet {
    margin-left: -2.75rem !important;
  }

  .mx-11-tablet {
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
  }

  .-mx-11-tablet {
    margin-left: -2.75rem !important;
    margin-right: -2.75rem !important;
  }

  .my-11-tablet {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .-my-11-tablet {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .m-12-tablet {
    margin: 3rem !important;
  }

  .-m-12-tablet {
    margin: -3rem !important;
  }

  .mt-12-tablet {
    margin-top: 3rem !important;
  }

  .-mt-12-tablet {
    margin-top: -3rem !important;
  }

  .mr-12-tablet {
    margin-right: 3rem !important;
  }

  .-mr-12-tablet {
    margin-right: -3rem !important;
  }

  .mb-12-tablet {
    margin-bottom: 3rem !important;
  }

  .-mb-12-tablet {
    margin-bottom: -3rem !important;
  }

  .ml-12-tablet {
    margin-left: 3rem !important;
  }

  .-ml-12-tablet {
    margin-left: -3rem !important;
  }

  .mx-12-tablet {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .-mx-12-tablet {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-12-tablet {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .-my-12-tablet {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .m-14-tablet {
    margin: 3.5rem !important;
  }

  .-m-14-tablet {
    margin: -3.5rem !important;
  }

  .mt-14-tablet {
    margin-top: 3.5rem !important;
  }

  .-mt-14-tablet {
    margin-top: -3.5rem !important;
  }

  .mr-14-tablet {
    margin-right: 3.5rem !important;
  }

  .-mr-14-tablet {
    margin-right: -3.5rem !important;
  }

  .mb-14-tablet {
    margin-bottom: 3.5rem !important;
  }

  .-mb-14-tablet {
    margin-bottom: -3.5rem !important;
  }

  .ml-14-tablet {
    margin-left: 3.5rem !important;
  }

  .-ml-14-tablet {
    margin-left: -3.5rem !important;
  }

  .mx-14-tablet {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .-mx-14-tablet {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .my-14-tablet {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .-my-14-tablet {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .m-16-tablet {
    margin: 4rem !important;
  }

  .-m-16-tablet {
    margin: -4rem !important;
  }

  .mt-16-tablet {
    margin-top: 4rem !important;
  }

  .-mt-16-tablet {
    margin-top: -4rem !important;
  }

  .mr-16-tablet {
    margin-right: 4rem !important;
  }

  .-mr-16-tablet {
    margin-right: -4rem !important;
  }

  .mb-16-tablet {
    margin-bottom: 4rem !important;
  }

  .-mb-16-tablet {
    margin-bottom: -4rem !important;
  }

  .ml-16-tablet {
    margin-left: 4rem !important;
  }

  .-ml-16-tablet {
    margin-left: -4rem !important;
  }

  .mx-16-tablet {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .-mx-16-tablet {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .my-16-tablet {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .-my-16-tablet {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .m-20-tablet {
    margin: 5rem !important;
  }

  .-m-20-tablet {
    margin: -5rem !important;
  }

  .mt-20-tablet {
    margin-top: 5rem !important;
  }

  .-mt-20-tablet {
    margin-top: -5rem !important;
  }

  .mr-20-tablet {
    margin-right: 5rem !important;
  }

  .-mr-20-tablet {
    margin-right: -5rem !important;
  }

  .mb-20-tablet {
    margin-bottom: 5rem !important;
  }

  .-mb-20-tablet {
    margin-bottom: -5rem !important;
  }

  .ml-20-tablet {
    margin-left: 5rem !important;
  }

  .-ml-20-tablet {
    margin-left: -5rem !important;
  }

  .mx-20-tablet {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .-mx-20-tablet {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .my-20-tablet {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .-my-20-tablet {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .m-24-tablet {
    margin: 6rem !important;
  }

  .-m-24-tablet {
    margin: -6rem !important;
  }

  .mt-24-tablet {
    margin-top: 6rem !important;
  }

  .-mt-24-tablet {
    margin-top: -6rem !important;
  }

  .mr-24-tablet {
    margin-right: 6rem !important;
  }

  .-mr-24-tablet {
    margin-right: -6rem !important;
  }

  .mb-24-tablet {
    margin-bottom: 6rem !important;
  }

  .-mb-24-tablet {
    margin-bottom: -6rem !important;
  }

  .ml-24-tablet {
    margin-left: 6rem !important;
  }

  .-ml-24-tablet {
    margin-left: -6rem !important;
  }

  .mx-24-tablet {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .-mx-24-tablet {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .my-24-tablet {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .-my-24-tablet {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .m-28-tablet {
    margin: 7rem !important;
  }

  .-m-28-tablet {
    margin: -7rem !important;
  }

  .mt-28-tablet {
    margin-top: 7rem !important;
  }

  .-mt-28-tablet {
    margin-top: -7rem !important;
  }

  .mr-28-tablet {
    margin-right: 7rem !important;
  }

  .-mr-28-tablet {
    margin-right: -7rem !important;
  }

  .mb-28-tablet {
    margin-bottom: 7rem !important;
  }

  .-mb-28-tablet {
    margin-bottom: -7rem !important;
  }

  .ml-28-tablet {
    margin-left: 7rem !important;
  }

  .-ml-28-tablet {
    margin-left: -7rem !important;
  }

  .mx-28-tablet {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .-mx-28-tablet {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .my-28-tablet {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .-my-28-tablet {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .m-32-tablet {
    margin: 8rem !important;
  }

  .-m-32-tablet {
    margin: -8rem !important;
  }

  .mt-32-tablet {
    margin-top: 8rem !important;
  }

  .-mt-32-tablet {
    margin-top: -8rem !important;
  }

  .mr-32-tablet {
    margin-right: 8rem !important;
  }

  .-mr-32-tablet {
    margin-right: -8rem !important;
  }

  .mb-32-tablet {
    margin-bottom: 8rem !important;
  }

  .-mb-32-tablet {
    margin-bottom: -8rem !important;
  }

  .ml-32-tablet {
    margin-left: 8rem !important;
  }

  .-ml-32-tablet {
    margin-left: -8rem !important;
  }

  .mx-32-tablet {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .-mx-32-tablet {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .my-32-tablet {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .-my-32-tablet {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .m-36-tablet {
    margin: 9rem !important;
  }

  .-m-36-tablet {
    margin: -9rem !important;
  }

  .mt-36-tablet {
    margin-top: 9rem !important;
  }

  .-mt-36-tablet {
    margin-top: -9rem !important;
  }

  .mr-36-tablet {
    margin-right: 9rem !important;
  }

  .-mr-36-tablet {
    margin-right: -9rem !important;
  }

  .mb-36-tablet {
    margin-bottom: 9rem !important;
  }

  .-mb-36-tablet {
    margin-bottom: -9rem !important;
  }

  .ml-36-tablet {
    margin-left: 9rem !important;
  }

  .-ml-36-tablet {
    margin-left: -9rem !important;
  }

  .mx-36-tablet {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .-mx-36-tablet {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .my-36-tablet {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .-my-36-tablet {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .m-40-tablet {
    margin: 10rem !important;
  }

  .-m-40-tablet {
    margin: -10rem !important;
  }

  .mt-40-tablet {
    margin-top: 10rem !important;
  }

  .-mt-40-tablet {
    margin-top: -10rem !important;
  }

  .mr-40-tablet {
    margin-right: 10rem !important;
  }

  .-mr-40-tablet {
    margin-right: -10rem !important;
  }

  .mb-40-tablet {
    margin-bottom: 10rem !important;
  }

  .-mb-40-tablet {
    margin-bottom: -10rem !important;
  }

  .ml-40-tablet {
    margin-left: 10rem !important;
  }

  .-ml-40-tablet {
    margin-left: -10rem !important;
  }

  .mx-40-tablet {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .-mx-40-tablet {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .my-40-tablet {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .-my-40-tablet {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .m-44-tablet {
    margin: 11rem !important;
  }

  .-m-44-tablet {
    margin: -11rem !important;
  }

  .mt-44-tablet {
    margin-top: 11rem !important;
  }

  .-mt-44-tablet {
    margin-top: -11rem !important;
  }

  .mr-44-tablet {
    margin-right: 11rem !important;
  }

  .-mr-44-tablet {
    margin-right: -11rem !important;
  }

  .mb-44-tablet {
    margin-bottom: 11rem !important;
  }

  .-mb-44-tablet {
    margin-bottom: -11rem !important;
  }

  .ml-44-tablet {
    margin-left: 11rem !important;
  }

  .-ml-44-tablet {
    margin-left: -11rem !important;
  }

  .mx-44-tablet {
    margin-left: 11rem !important;
    margin-right: 11rem !important;
  }

  .-mx-44-tablet {
    margin-left: -11rem !important;
    margin-right: -11rem !important;
  }

  .my-44-tablet {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .-my-44-tablet {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .m-48-tablet {
    margin: 12rem !important;
  }

  .-m-48-tablet {
    margin: -12rem !important;
  }

  .mt-48-tablet {
    margin-top: 12rem !important;
  }

  .-mt-48-tablet {
    margin-top: -12rem !important;
  }

  .mr-48-tablet {
    margin-right: 12rem !important;
  }

  .-mr-48-tablet {
    margin-right: -12rem !important;
  }

  .mb-48-tablet {
    margin-bottom: 12rem !important;
  }

  .-mb-48-tablet {
    margin-bottom: -12rem !important;
  }

  .ml-48-tablet {
    margin-left: 12rem !important;
  }

  .-ml-48-tablet {
    margin-left: -12rem !important;
  }

  .mx-48-tablet {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .-mx-48-tablet {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .my-48-tablet {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .-my-48-tablet {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .m-52-tablet {
    margin: 13rem !important;
  }

  .-m-52-tablet {
    margin: -13rem !important;
  }

  .mt-52-tablet {
    margin-top: 13rem !important;
  }

  .-mt-52-tablet {
    margin-top: -13rem !important;
  }

  .mr-52-tablet {
    margin-right: 13rem !important;
  }

  .-mr-52-tablet {
    margin-right: -13rem !important;
  }

  .mb-52-tablet {
    margin-bottom: 13rem !important;
  }

  .-mb-52-tablet {
    margin-bottom: -13rem !important;
  }

  .ml-52-tablet {
    margin-left: 13rem !important;
  }

  .-ml-52-tablet {
    margin-left: -13rem !important;
  }

  .mx-52-tablet {
    margin-left: 13rem !important;
    margin-right: 13rem !important;
  }

  .-mx-52-tablet {
    margin-left: -13rem !important;
    margin-right: -13rem !important;
  }

  .my-52-tablet {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }

  .-my-52-tablet {
    margin-top: -13rem !important;
    margin-bottom: -13rem !important;
  }

  .m-56-tablet {
    margin: 14rem !important;
  }

  .-m-56-tablet {
    margin: -14rem !important;
  }

  .mt-56-tablet {
    margin-top: 14rem !important;
  }

  .-mt-56-tablet {
    margin-top: -14rem !important;
  }

  .mr-56-tablet {
    margin-right: 14rem !important;
  }

  .-mr-56-tablet {
    margin-right: -14rem !important;
  }

  .mb-56-tablet {
    margin-bottom: 14rem !important;
  }

  .-mb-56-tablet {
    margin-bottom: -14rem !important;
  }

  .ml-56-tablet {
    margin-left: 14rem !important;
  }

  .-ml-56-tablet {
    margin-left: -14rem !important;
  }

  .mx-56-tablet {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .-mx-56-tablet {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .my-56-tablet {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .-my-56-tablet {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .m-60-tablet {
    margin: 15rem !important;
  }

  .-m-60-tablet {
    margin: -15rem !important;
  }

  .mt-60-tablet {
    margin-top: 15rem !important;
  }

  .-mt-60-tablet {
    margin-top: -15rem !important;
  }

  .mr-60-tablet {
    margin-right: 15rem !important;
  }

  .-mr-60-tablet {
    margin-right: -15rem !important;
  }

  .mb-60-tablet {
    margin-bottom: 15rem !important;
  }

  .-mb-60-tablet {
    margin-bottom: -15rem !important;
  }

  .ml-60-tablet {
    margin-left: 15rem !important;
  }

  .-ml-60-tablet {
    margin-left: -15rem !important;
  }

  .mx-60-tablet {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .-mx-60-tablet {
    margin-left: -15rem !important;
    margin-right: -15rem !important;
  }

  .my-60-tablet {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .-my-60-tablet {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .m-64-tablet {
    margin: 16rem !important;
  }

  .-m-64-tablet {
    margin: -16rem !important;
  }

  .mt-64-tablet {
    margin-top: 16rem !important;
  }

  .-mt-64-tablet {
    margin-top: -16rem !important;
  }

  .mr-64-tablet {
    margin-right: 16rem !important;
  }

  .-mr-64-tablet {
    margin-right: -16rem !important;
  }

  .mb-64-tablet {
    margin-bottom: 16rem !important;
  }

  .-mb-64-tablet {
    margin-bottom: -16rem !important;
  }

  .ml-64-tablet {
    margin-left: 16rem !important;
  }

  .-ml-64-tablet {
    margin-left: -16rem !important;
  }

  .mx-64-tablet {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .-mx-64-tablet {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .my-64-tablet {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .-my-64-tablet {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .m-72-tablet {
    margin: 18rem !important;
  }

  .-m-72-tablet {
    margin: -18rem !important;
  }

  .mt-72-tablet {
    margin-top: 18rem !important;
  }

  .-mt-72-tablet {
    margin-top: -18rem !important;
  }

  .mr-72-tablet {
    margin-right: 18rem !important;
  }

  .-mr-72-tablet {
    margin-right: -18rem !important;
  }

  .mb-72-tablet {
    margin-bottom: 18rem !important;
  }

  .-mb-72-tablet {
    margin-bottom: -18rem !important;
  }

  .ml-72-tablet {
    margin-left: 18rem !important;
  }

  .-ml-72-tablet {
    margin-left: -18rem !important;
  }

  .mx-72-tablet {
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .-mx-72-tablet {
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .my-72-tablet {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .-my-72-tablet {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .m-80-tablet {
    margin: 20rem !important;
  }

  .-m-80-tablet {
    margin: -20rem !important;
  }

  .mt-80-tablet {
    margin-top: 20rem !important;
  }

  .-mt-80-tablet {
    margin-top: -20rem !important;
  }

  .mr-80-tablet {
    margin-right: 20rem !important;
  }

  .-mr-80-tablet {
    margin-right: -20rem !important;
  }

  .mb-80-tablet {
    margin-bottom: 20rem !important;
  }

  .-mb-80-tablet {
    margin-bottom: -20rem !important;
  }

  .ml-80-tablet {
    margin-left: 20rem !important;
  }

  .-ml-80-tablet {
    margin-left: -20rem !important;
  }

  .mx-80-tablet {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .-mx-80-tablet {
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .my-80-tablet {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .-my-80-tablet {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .m-96-tablet {
    margin: 24rem !important;
  }

  .-m-96-tablet {
    margin: -24rem !important;
  }

  .mt-96-tablet {
    margin-top: 24rem !important;
  }

  .-mt-96-tablet {
    margin-top: -24rem !important;
  }

  .mr-96-tablet {
    margin-right: 24rem !important;
  }

  .-mr-96-tablet {
    margin-right: -24rem !important;
  }

  .mb-96-tablet {
    margin-bottom: 24rem !important;
  }

  .-mb-96-tablet {
    margin-bottom: -24rem !important;
  }

  .ml-96-tablet {
    margin-left: 24rem !important;
  }

  .-ml-96-tablet {
    margin-left: -24rem !important;
  }

  .mx-96-tablet {
    margin-left: 24rem !important;
    margin-right: 24rem !important;
  }

  .-mx-96-tablet {
    margin-left: -24rem !important;
    margin-right: -24rem !important;
  }

  .my-96-tablet {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }

  .-my-96-tablet {
    margin-top: -24rem !important;
    margin-bottom: -24rem !important;
  }

  .p-auto-tablet {
    padding: auto !important;
  }

  .pt-auto-tablet {
    padding-top: auto !important;
  }

  .pr-auto-tablet {
    padding-right: auto !important;
  }

  .pb-auto-tablet {
    padding-bottom: auto !important;
  }

  .pl-auto-tablet {
    padding-left: auto !important;
  }

  .px-auto-tablet {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .py-auto-tablet {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }

  .p-0-tablet {
    padding: 0 !important;
  }

  .pt-0-tablet {
    padding-top: 0 !important;
  }

  .pr-0-tablet {
    padding-right: 0 !important;
  }

  .pb-0-tablet {
    padding-bottom: 0 !important;
  }

  .pl-0-tablet {
    padding-left: 0 !important;
  }

  .px-0-tablet {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-0-tablet {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-1-tablet {
    padding: .25rem !important;
  }

  .pt-1-tablet {
    padding-top: .25rem !important;
  }

  .pr-1-tablet {
    padding-right: .25rem !important;
  }

  .pb-1-tablet {
    padding-bottom: .25rem !important;
  }

  .pl-1-tablet {
    padding-left: .25rem !important;
  }

  .px-1-tablet {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .py-1-tablet {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .p-2-tablet {
    padding: .5rem !important;
  }

  .pt-2-tablet {
    padding-top: .5rem !important;
  }

  .pr-2-tablet {
    padding-right: .5rem !important;
  }

  .pb-2-tablet {
    padding-bottom: .5rem !important;
  }

  .pl-2-tablet {
    padding-left: .5rem !important;
  }

  .px-2-tablet {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .py-2-tablet {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .p-3-tablet {
    padding: .75rem !important;
  }

  .pt-3-tablet {
    padding-top: .75rem !important;
  }

  .pr-3-tablet {
    padding-right: .75rem !important;
  }

  .pb-3-tablet {
    padding-bottom: .75rem !important;
  }

  .pl-3-tablet {
    padding-left: .75rem !important;
  }

  .px-3-tablet {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .py-3-tablet {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .p-4-tablet {
    padding: 1rem !important;
  }

  .pt-4-tablet {
    padding-top: 1rem !important;
  }

  .pr-4-tablet {
    padding-right: 1rem !important;
  }

  .pb-4-tablet {
    padding-bottom: 1rem !important;
  }

  .pl-4-tablet {
    padding-left: 1rem !important;
  }

  .px-4-tablet {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .py-4-tablet {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .p-5-tablet {
    padding: 1.25rem !important;
  }

  .pt-5-tablet {
    padding-top: 1.25rem !important;
  }

  .pr-5-tablet {
    padding-right: 1.25rem !important;
  }

  .pb-5-tablet {
    padding-bottom: 1.25rem !important;
  }

  .pl-5-tablet {
    padding-left: 1.25rem !important;
  }

  .px-5-tablet {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .py-5-tablet {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .p-6-tablet {
    padding: 1.5rem !important;
  }

  .pt-6-tablet {
    padding-top: 1.5rem !important;
  }

  .pr-6-tablet {
    padding-right: 1.5rem !important;
  }

  .pb-6-tablet {
    padding-bottom: 1.5rem !important;
  }

  .pl-6-tablet {
    padding-left: 1.5rem !important;
  }

  .px-6-tablet {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .py-6-tablet {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .p-7-tablet {
    padding: 1.75rem !important;
  }

  .pt-7-tablet {
    padding-top: 1.75rem !important;
  }

  .pr-7-tablet {
    padding-right: 1.75rem !important;
  }

  .pb-7-tablet {
    padding-bottom: 1.75rem !important;
  }

  .pl-7-tablet {
    padding-left: 1.75rem !important;
  }

  .px-7-tablet {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .py-7-tablet {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .p-8-tablet {
    padding: 2rem !important;
  }

  .pt-8-tablet {
    padding-top: 2rem !important;
  }

  .pr-8-tablet {
    padding-right: 2rem !important;
  }

  .pb-8-tablet {
    padding-bottom: 2rem !important;
  }

  .pl-8-tablet {
    padding-left: 2rem !important;
  }

  .px-8-tablet {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .py-8-tablet {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .p-9-tablet {
    padding: 2.25rem !important;
  }

  .pt-9-tablet {
    padding-top: 2.25rem !important;
  }

  .pr-9-tablet {
    padding-right: 2.25rem !important;
  }

  .pb-9-tablet {
    padding-bottom: 2.25rem !important;
  }

  .pl-9-tablet {
    padding-left: 2.25rem !important;
  }

  .px-9-tablet {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .py-9-tablet {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .p-10-tablet {
    padding: 2.5rem !important;
  }

  .pt-10-tablet {
    padding-top: 2.5rem !important;
  }

  .pr-10-tablet {
    padding-right: 2.5rem !important;
  }

  .pb-10-tablet {
    padding-bottom: 2.5rem !important;
  }

  .pl-10-tablet {
    padding-left: 2.5rem !important;
  }

  .px-10-tablet {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .py-10-tablet {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .p-11-tablet {
    padding: 2.75rem !important;
  }

  .pt-11-tablet {
    padding-top: 2.75rem !important;
  }

  .pr-11-tablet {
    padding-right: 2.75rem !important;
  }

  .pb-11-tablet {
    padding-bottom: 2.75rem !important;
  }

  .pl-11-tablet {
    padding-left: 2.75rem !important;
  }

  .px-11-tablet {
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  .py-11-tablet {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .p-12-tablet {
    padding: 3rem !important;
  }

  .pt-12-tablet {
    padding-top: 3rem !important;
  }

  .pr-12-tablet {
    padding-right: 3rem !important;
  }

  .pb-12-tablet {
    padding-bottom: 3rem !important;
  }

  .pl-12-tablet {
    padding-left: 3rem !important;
  }

  .px-12-tablet {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-12-tablet {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .p-14-tablet {
    padding: 3.5rem !important;
  }

  .pt-14-tablet {
    padding-top: 3.5rem !important;
  }

  .pr-14-tablet {
    padding-right: 3.5rem !important;
  }

  .pb-14-tablet {
    padding-bottom: 3.5rem !important;
  }

  .pl-14-tablet {
    padding-left: 3.5rem !important;
  }

  .px-14-tablet {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .py-14-tablet {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .p-16-tablet {
    padding: 4rem !important;
  }

  .pt-16-tablet {
    padding-top: 4rem !important;
  }

  .pr-16-tablet {
    padding-right: 4rem !important;
  }

  .pb-16-tablet {
    padding-bottom: 4rem !important;
  }

  .pl-16-tablet {
    padding-left: 4rem !important;
  }

  .px-16-tablet {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .py-16-tablet {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .p-20-tablet {
    padding: 5rem !important;
  }

  .pt-20-tablet {
    padding-top: 5rem !important;
  }

  .pr-20-tablet {
    padding-right: 5rem !important;
  }

  .pb-20-tablet {
    padding-bottom: 5rem !important;
  }

  .pl-20-tablet {
    padding-left: 5rem !important;
  }

  .px-20-tablet {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .py-20-tablet {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .p-24-tablet {
    padding: 6rem !important;
  }

  .pt-24-tablet {
    padding-top: 6rem !important;
  }

  .pr-24-tablet {
    padding-right: 6rem !important;
  }

  .pb-24-tablet {
    padding-bottom: 6rem !important;
  }

  .pl-24-tablet {
    padding-left: 6rem !important;
  }

  .px-24-tablet {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .py-24-tablet {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .p-28-tablet {
    padding: 7rem !important;
  }

  .pt-28-tablet {
    padding-top: 7rem !important;
  }

  .pr-28-tablet {
    padding-right: 7rem !important;
  }

  .pb-28-tablet {
    padding-bottom: 7rem !important;
  }

  .pl-28-tablet {
    padding-left: 7rem !important;
  }

  .px-28-tablet {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .py-28-tablet {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .p-32-tablet {
    padding: 8rem !important;
  }

  .pt-32-tablet {
    padding-top: 8rem !important;
  }

  .pr-32-tablet {
    padding-right: 8rem !important;
  }

  .pb-32-tablet {
    padding-bottom: 8rem !important;
  }

  .pl-32-tablet {
    padding-left: 8rem !important;
  }

  .px-32-tablet {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .py-32-tablet {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .p-36-tablet {
    padding: 9rem !important;
  }

  .pt-36-tablet {
    padding-top: 9rem !important;
  }

  .pr-36-tablet {
    padding-right: 9rem !important;
  }

  .pb-36-tablet {
    padding-bottom: 9rem !important;
  }

  .pl-36-tablet {
    padding-left: 9rem !important;
  }

  .px-36-tablet {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .py-36-tablet {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .p-40-tablet {
    padding: 10rem !important;
  }

  .pt-40-tablet {
    padding-top: 10rem !important;
  }

  .pr-40-tablet {
    padding-right: 10rem !important;
  }

  .pb-40-tablet {
    padding-bottom: 10rem !important;
  }

  .pl-40-tablet {
    padding-left: 10rem !important;
  }

  .px-40-tablet {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .py-40-tablet {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .p-44-tablet {
    padding: 11rem !important;
  }

  .pt-44-tablet {
    padding-top: 11rem !important;
  }

  .pr-44-tablet {
    padding-right: 11rem !important;
  }

  .pb-44-tablet {
    padding-bottom: 11rem !important;
  }

  .pl-44-tablet {
    padding-left: 11rem !important;
  }

  .px-44-tablet {
    padding-left: 11rem !important;
    padding-right: 11rem !important;
  }

  .py-44-tablet {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .p-48-tablet {
    padding: 12rem !important;
  }

  .pt-48-tablet {
    padding-top: 12rem !important;
  }

  .pr-48-tablet {
    padding-right: 12rem !important;
  }

  .pb-48-tablet {
    padding-bottom: 12rem !important;
  }

  .pl-48-tablet {
    padding-left: 12rem !important;
  }

  .px-48-tablet {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .py-48-tablet {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .p-52-tablet {
    padding: 13rem !important;
  }

  .pt-52-tablet {
    padding-top: 13rem !important;
  }

  .pr-52-tablet {
    padding-right: 13rem !important;
  }

  .pb-52-tablet {
    padding-bottom: 13rem !important;
  }

  .pl-52-tablet {
    padding-left: 13rem !important;
  }

  .px-52-tablet {
    padding-left: 13rem !important;
    padding-right: 13rem !important;
  }

  .py-52-tablet {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }

  .p-56-tablet {
    padding: 14rem !important;
  }

  .pt-56-tablet {
    padding-top: 14rem !important;
  }

  .pr-56-tablet {
    padding-right: 14rem !important;
  }

  .pb-56-tablet {
    padding-bottom: 14rem !important;
  }

  .pl-56-tablet {
    padding-left: 14rem !important;
  }

  .px-56-tablet {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .py-56-tablet {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .p-60-tablet {
    padding: 15rem !important;
  }

  .pt-60-tablet {
    padding-top: 15rem !important;
  }

  .pr-60-tablet {
    padding-right: 15rem !important;
  }

  .pb-60-tablet {
    padding-bottom: 15rem !important;
  }

  .pl-60-tablet {
    padding-left: 15rem !important;
  }

  .px-60-tablet {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .py-60-tablet {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .p-64-tablet {
    padding: 16rem !important;
  }

  .pt-64-tablet {
    padding-top: 16rem !important;
  }

  .pr-64-tablet {
    padding-right: 16rem !important;
  }

  .pb-64-tablet {
    padding-bottom: 16rem !important;
  }

  .pl-64-tablet {
    padding-left: 16rem !important;
  }

  .px-64-tablet {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .py-64-tablet {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .p-72-tablet {
    padding: 18rem !important;
  }

  .pt-72-tablet {
    padding-top: 18rem !important;
  }

  .pr-72-tablet {
    padding-right: 18rem !important;
  }

  .pb-72-tablet {
    padding-bottom: 18rem !important;
  }

  .pl-72-tablet {
    padding-left: 18rem !important;
  }

  .px-72-tablet {
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .py-72-tablet {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .p-80-tablet {
    padding: 20rem !important;
  }

  .pt-80-tablet {
    padding-top: 20rem !important;
  }

  .pr-80-tablet {
    padding-right: 20rem !important;
  }

  .pb-80-tablet {
    padding-bottom: 20rem !important;
  }

  .pl-80-tablet {
    padding-left: 20rem !important;
  }

  .px-80-tablet {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .py-80-tablet {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .p-96-tablet {
    padding: 24rem !important;
  }

  .pt-96-tablet {
    padding-top: 24rem !important;
  }

  .pr-96-tablet {
    padding-right: 24rem !important;
  }

  .pb-96-tablet {
    padding-bottom: 24rem !important;
  }

  .pl-96-tablet {
    padding-left: 24rem !important;
  }

  .px-96-tablet {
    padding-left: 24rem !important;
    padding-right: 24rem !important;
  }

  .py-96-tablet {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .m-auto-tablet-only {
    margin: auto !important;
  }

  .mt-auto-tablet-only {
    margin-top: auto !important;
  }

  .mr-auto-tablet-only {
    margin-right: auto !important;
  }

  .mb-auto-tablet-only {
    margin-bottom: auto !important;
  }

  .ml-auto-tablet-only {
    margin-left: auto !important;
  }

  .mx-auto-tablet-only {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-auto-tablet-only {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .m-0-tablet-only {
    margin: 0 !important;
  }

  .mt-0-tablet-only {
    margin-top: 0 !important;
  }

  .mr-0-tablet-only {
    margin-right: 0 !important;
  }

  .mb-0-tablet-only {
    margin-bottom: 0 !important;
  }

  .ml-0-tablet-only {
    margin-left: 0 !important;
  }

  .mx-0-tablet-only {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-0-tablet-only {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-1-tablet-only {
    margin: .25rem !important;
  }

  .-m-1-tablet-only {
    margin: -.25rem !important;
  }

  .mt-1-tablet-only {
    margin-top: .25rem !important;
  }

  .-mt-1-tablet-only {
    margin-top: -.25rem !important;
  }

  .mr-1-tablet-only {
    margin-right: .25rem !important;
  }

  .-mr-1-tablet-only {
    margin-right: -.25rem !important;
  }

  .mb-1-tablet-only {
    margin-bottom: .25rem !important;
  }

  .-mb-1-tablet-only {
    margin-bottom: -.25rem !important;
  }

  .ml-1-tablet-only {
    margin-left: .25rem !important;
  }

  .-ml-1-tablet-only {
    margin-left: -.25rem !important;
  }

  .mx-1-tablet-only {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .-mx-1-tablet-only {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .my-1-tablet-only {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .-my-1-tablet-only {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .m-2-tablet-only {
    margin: .5rem !important;
  }

  .-m-2-tablet-only {
    margin: -.5rem !important;
  }

  .mt-2-tablet-only {
    margin-top: .5rem !important;
  }

  .-mt-2-tablet-only {
    margin-top: -.5rem !important;
  }

  .mr-2-tablet-only {
    margin-right: .5rem !important;
  }

  .-mr-2-tablet-only {
    margin-right: -.5rem !important;
  }

  .mb-2-tablet-only {
    margin-bottom: .5rem !important;
  }

  .-mb-2-tablet-only {
    margin-bottom: -.5rem !important;
  }

  .ml-2-tablet-only {
    margin-left: .5rem !important;
  }

  .-ml-2-tablet-only {
    margin-left: -.5rem !important;
  }

  .mx-2-tablet-only {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .-mx-2-tablet-only {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .my-2-tablet-only {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .-my-2-tablet-only {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .m-3-tablet-only {
    margin: .75rem !important;
  }

  .-m-3-tablet-only {
    margin: -.75rem !important;
  }

  .mt-3-tablet-only {
    margin-top: .75rem !important;
  }

  .-mt-3-tablet-only {
    margin-top: -.75rem !important;
  }

  .mr-3-tablet-only {
    margin-right: .75rem !important;
  }

  .-mr-3-tablet-only {
    margin-right: -.75rem !important;
  }

  .mb-3-tablet-only {
    margin-bottom: .75rem !important;
  }

  .-mb-3-tablet-only {
    margin-bottom: -.75rem !important;
  }

  .ml-3-tablet-only {
    margin-left: .75rem !important;
  }

  .-ml-3-tablet-only {
    margin-left: -.75rem !important;
  }

  .mx-3-tablet-only {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .-mx-3-tablet-only {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .my-3-tablet-only {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .-my-3-tablet-only {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .m-4-tablet-only {
    margin: 1rem !important;
  }

  .-m-4-tablet-only {
    margin: -1rem !important;
  }

  .mt-4-tablet-only {
    margin-top: 1rem !important;
  }

  .-mt-4-tablet-only {
    margin-top: -1rem !important;
  }

  .mr-4-tablet-only {
    margin-right: 1rem !important;
  }

  .-mr-4-tablet-only {
    margin-right: -1rem !important;
  }

  .mb-4-tablet-only {
    margin-bottom: 1rem !important;
  }

  .-mb-4-tablet-only {
    margin-bottom: -1rem !important;
  }

  .ml-4-tablet-only {
    margin-left: 1rem !important;
  }

  .-ml-4-tablet-only {
    margin-left: -1rem !important;
  }

  .mx-4-tablet-only {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .-mx-4-tablet-only {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .my-4-tablet-only {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .-my-4-tablet-only {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .m-5-tablet-only {
    margin: 1.25rem !important;
  }

  .-m-5-tablet-only {
    margin: -1.25rem !important;
  }

  .mt-5-tablet-only {
    margin-top: 1.25rem !important;
  }

  .-mt-5-tablet-only {
    margin-top: -1.25rem !important;
  }

  .mr-5-tablet-only {
    margin-right: 1.25rem !important;
  }

  .-mr-5-tablet-only {
    margin-right: -1.25rem !important;
  }

  .mb-5-tablet-only {
    margin-bottom: 1.25rem !important;
  }

  .-mb-5-tablet-only {
    margin-bottom: -1.25rem !important;
  }

  .ml-5-tablet-only {
    margin-left: 1.25rem !important;
  }

  .-ml-5-tablet-only {
    margin-left: -1.25rem !important;
  }

  .mx-5-tablet-only {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .-mx-5-tablet-only {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .my-5-tablet-only {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .-my-5-tablet-only {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .m-6-tablet-only {
    margin: 1.5rem !important;
  }

  .-m-6-tablet-only {
    margin: -1.5rem !important;
  }

  .mt-6-tablet-only {
    margin-top: 1.5rem !important;
  }

  .-mt-6-tablet-only {
    margin-top: -1.5rem !important;
  }

  .mr-6-tablet-only {
    margin-right: 1.5rem !important;
  }

  .-mr-6-tablet-only {
    margin-right: -1.5rem !important;
  }

  .mb-6-tablet-only {
    margin-bottom: 1.5rem !important;
  }

  .-mb-6-tablet-only {
    margin-bottom: -1.5rem !important;
  }

  .ml-6-tablet-only {
    margin-left: 1.5rem !important;
  }

  .-ml-6-tablet-only {
    margin-left: -1.5rem !important;
  }

  .mx-6-tablet-only {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .-mx-6-tablet-only {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .my-6-tablet-only {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .-my-6-tablet-only {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .m-7-tablet-only {
    margin: 1.75rem !important;
  }

  .-m-7-tablet-only {
    margin: -1.75rem !important;
  }

  .mt-7-tablet-only {
    margin-top: 1.75rem !important;
  }

  .-mt-7-tablet-only {
    margin-top: -1.75rem !important;
  }

  .mr-7-tablet-only {
    margin-right: 1.75rem !important;
  }

  .-mr-7-tablet-only {
    margin-right: -1.75rem !important;
  }

  .mb-7-tablet-only {
    margin-bottom: 1.75rem !important;
  }

  .-mb-7-tablet-only {
    margin-bottom: -1.75rem !important;
  }

  .ml-7-tablet-only {
    margin-left: 1.75rem !important;
  }

  .-ml-7-tablet-only {
    margin-left: -1.75rem !important;
  }

  .mx-7-tablet-only {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .-mx-7-tablet-only {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .my-7-tablet-only {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .-my-7-tablet-only {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .m-8-tablet-only {
    margin: 2rem !important;
  }

  .-m-8-tablet-only {
    margin: -2rem !important;
  }

  .mt-8-tablet-only {
    margin-top: 2rem !important;
  }

  .-mt-8-tablet-only {
    margin-top: -2rem !important;
  }

  .mr-8-tablet-only {
    margin-right: 2rem !important;
  }

  .-mr-8-tablet-only {
    margin-right: -2rem !important;
  }

  .mb-8-tablet-only {
    margin-bottom: 2rem !important;
  }

  .-mb-8-tablet-only {
    margin-bottom: -2rem !important;
  }

  .ml-8-tablet-only {
    margin-left: 2rem !important;
  }

  .-ml-8-tablet-only {
    margin-left: -2rem !important;
  }

  .mx-8-tablet-only {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .-mx-8-tablet-only {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .my-8-tablet-only {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .-my-8-tablet-only {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .m-9-tablet-only {
    margin: 2.25rem !important;
  }

  .-m-9-tablet-only {
    margin: -2.25rem !important;
  }

  .mt-9-tablet-only {
    margin-top: 2.25rem !important;
  }

  .-mt-9-tablet-only {
    margin-top: -2.25rem !important;
  }

  .mr-9-tablet-only {
    margin-right: 2.25rem !important;
  }

  .-mr-9-tablet-only {
    margin-right: -2.25rem !important;
  }

  .mb-9-tablet-only {
    margin-bottom: 2.25rem !important;
  }

  .-mb-9-tablet-only {
    margin-bottom: -2.25rem !important;
  }

  .ml-9-tablet-only {
    margin-left: 2.25rem !important;
  }

  .-ml-9-tablet-only {
    margin-left: -2.25rem !important;
  }

  .mx-9-tablet-only {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .-mx-9-tablet-only {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .my-9-tablet-only {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .-my-9-tablet-only {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .m-10-tablet-only {
    margin: 2.5rem !important;
  }

  .-m-10-tablet-only {
    margin: -2.5rem !important;
  }

  .mt-10-tablet-only {
    margin-top: 2.5rem !important;
  }

  .-mt-10-tablet-only {
    margin-top: -2.5rem !important;
  }

  .mr-10-tablet-only {
    margin-right: 2.5rem !important;
  }

  .-mr-10-tablet-only {
    margin-right: -2.5rem !important;
  }

  .mb-10-tablet-only {
    margin-bottom: 2.5rem !important;
  }

  .-mb-10-tablet-only {
    margin-bottom: -2.5rem !important;
  }

  .ml-10-tablet-only {
    margin-left: 2.5rem !important;
  }

  .-ml-10-tablet-only {
    margin-left: -2.5rem !important;
  }

  .mx-10-tablet-only {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .-mx-10-tablet-only {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .my-10-tablet-only {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .-my-10-tablet-only {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .m-11-tablet-only {
    margin: 2.75rem !important;
  }

  .-m-11-tablet-only {
    margin: -2.75rem !important;
  }

  .mt-11-tablet-only {
    margin-top: 2.75rem !important;
  }

  .-mt-11-tablet-only {
    margin-top: -2.75rem !important;
  }

  .mr-11-tablet-only {
    margin-right: 2.75rem !important;
  }

  .-mr-11-tablet-only {
    margin-right: -2.75rem !important;
  }

  .mb-11-tablet-only {
    margin-bottom: 2.75rem !important;
  }

  .-mb-11-tablet-only {
    margin-bottom: -2.75rem !important;
  }

  .ml-11-tablet-only {
    margin-left: 2.75rem !important;
  }

  .-ml-11-tablet-only {
    margin-left: -2.75rem !important;
  }

  .mx-11-tablet-only {
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
  }

  .-mx-11-tablet-only {
    margin-left: -2.75rem !important;
    margin-right: -2.75rem !important;
  }

  .my-11-tablet-only {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .-my-11-tablet-only {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .m-12-tablet-only {
    margin: 3rem !important;
  }

  .-m-12-tablet-only {
    margin: -3rem !important;
  }

  .mt-12-tablet-only {
    margin-top: 3rem !important;
  }

  .-mt-12-tablet-only {
    margin-top: -3rem !important;
  }

  .mr-12-tablet-only {
    margin-right: 3rem !important;
  }

  .-mr-12-tablet-only {
    margin-right: -3rem !important;
  }

  .mb-12-tablet-only {
    margin-bottom: 3rem !important;
  }

  .-mb-12-tablet-only {
    margin-bottom: -3rem !important;
  }

  .ml-12-tablet-only {
    margin-left: 3rem !important;
  }

  .-ml-12-tablet-only {
    margin-left: -3rem !important;
  }

  .mx-12-tablet-only {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .-mx-12-tablet-only {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-12-tablet-only {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .-my-12-tablet-only {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .m-14-tablet-only {
    margin: 3.5rem !important;
  }

  .-m-14-tablet-only {
    margin: -3.5rem !important;
  }

  .mt-14-tablet-only {
    margin-top: 3.5rem !important;
  }

  .-mt-14-tablet-only {
    margin-top: -3.5rem !important;
  }

  .mr-14-tablet-only {
    margin-right: 3.5rem !important;
  }

  .-mr-14-tablet-only {
    margin-right: -3.5rem !important;
  }

  .mb-14-tablet-only {
    margin-bottom: 3.5rem !important;
  }

  .-mb-14-tablet-only {
    margin-bottom: -3.5rem !important;
  }

  .ml-14-tablet-only {
    margin-left: 3.5rem !important;
  }

  .-ml-14-tablet-only {
    margin-left: -3.5rem !important;
  }

  .mx-14-tablet-only {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .-mx-14-tablet-only {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .my-14-tablet-only {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .-my-14-tablet-only {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .m-16-tablet-only {
    margin: 4rem !important;
  }

  .-m-16-tablet-only {
    margin: -4rem !important;
  }

  .mt-16-tablet-only {
    margin-top: 4rem !important;
  }

  .-mt-16-tablet-only {
    margin-top: -4rem !important;
  }

  .mr-16-tablet-only {
    margin-right: 4rem !important;
  }

  .-mr-16-tablet-only {
    margin-right: -4rem !important;
  }

  .mb-16-tablet-only {
    margin-bottom: 4rem !important;
  }

  .-mb-16-tablet-only {
    margin-bottom: -4rem !important;
  }

  .ml-16-tablet-only {
    margin-left: 4rem !important;
  }

  .-ml-16-tablet-only {
    margin-left: -4rem !important;
  }

  .mx-16-tablet-only {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .-mx-16-tablet-only {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .my-16-tablet-only {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .-my-16-tablet-only {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .m-20-tablet-only {
    margin: 5rem !important;
  }

  .-m-20-tablet-only {
    margin: -5rem !important;
  }

  .mt-20-tablet-only {
    margin-top: 5rem !important;
  }

  .-mt-20-tablet-only {
    margin-top: -5rem !important;
  }

  .mr-20-tablet-only {
    margin-right: 5rem !important;
  }

  .-mr-20-tablet-only {
    margin-right: -5rem !important;
  }

  .mb-20-tablet-only {
    margin-bottom: 5rem !important;
  }

  .-mb-20-tablet-only {
    margin-bottom: -5rem !important;
  }

  .ml-20-tablet-only {
    margin-left: 5rem !important;
  }

  .-ml-20-tablet-only {
    margin-left: -5rem !important;
  }

  .mx-20-tablet-only {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .-mx-20-tablet-only {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .my-20-tablet-only {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .-my-20-tablet-only {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .m-24-tablet-only {
    margin: 6rem !important;
  }

  .-m-24-tablet-only {
    margin: -6rem !important;
  }

  .mt-24-tablet-only {
    margin-top: 6rem !important;
  }

  .-mt-24-tablet-only {
    margin-top: -6rem !important;
  }

  .mr-24-tablet-only {
    margin-right: 6rem !important;
  }

  .-mr-24-tablet-only {
    margin-right: -6rem !important;
  }

  .mb-24-tablet-only {
    margin-bottom: 6rem !important;
  }

  .-mb-24-tablet-only {
    margin-bottom: -6rem !important;
  }

  .ml-24-tablet-only {
    margin-left: 6rem !important;
  }

  .-ml-24-tablet-only {
    margin-left: -6rem !important;
  }

  .mx-24-tablet-only {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .-mx-24-tablet-only {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .my-24-tablet-only {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .-my-24-tablet-only {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .m-28-tablet-only {
    margin: 7rem !important;
  }

  .-m-28-tablet-only {
    margin: -7rem !important;
  }

  .mt-28-tablet-only {
    margin-top: 7rem !important;
  }

  .-mt-28-tablet-only {
    margin-top: -7rem !important;
  }

  .mr-28-tablet-only {
    margin-right: 7rem !important;
  }

  .-mr-28-tablet-only {
    margin-right: -7rem !important;
  }

  .mb-28-tablet-only {
    margin-bottom: 7rem !important;
  }

  .-mb-28-tablet-only {
    margin-bottom: -7rem !important;
  }

  .ml-28-tablet-only {
    margin-left: 7rem !important;
  }

  .-ml-28-tablet-only {
    margin-left: -7rem !important;
  }

  .mx-28-tablet-only {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .-mx-28-tablet-only {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .my-28-tablet-only {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .-my-28-tablet-only {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .m-32-tablet-only {
    margin: 8rem !important;
  }

  .-m-32-tablet-only {
    margin: -8rem !important;
  }

  .mt-32-tablet-only {
    margin-top: 8rem !important;
  }

  .-mt-32-tablet-only {
    margin-top: -8rem !important;
  }

  .mr-32-tablet-only {
    margin-right: 8rem !important;
  }

  .-mr-32-tablet-only {
    margin-right: -8rem !important;
  }

  .mb-32-tablet-only {
    margin-bottom: 8rem !important;
  }

  .-mb-32-tablet-only {
    margin-bottom: -8rem !important;
  }

  .ml-32-tablet-only {
    margin-left: 8rem !important;
  }

  .-ml-32-tablet-only {
    margin-left: -8rem !important;
  }

  .mx-32-tablet-only {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .-mx-32-tablet-only {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .my-32-tablet-only {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .-my-32-tablet-only {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .m-36-tablet-only {
    margin: 9rem !important;
  }

  .-m-36-tablet-only {
    margin: -9rem !important;
  }

  .mt-36-tablet-only {
    margin-top: 9rem !important;
  }

  .-mt-36-tablet-only {
    margin-top: -9rem !important;
  }

  .mr-36-tablet-only {
    margin-right: 9rem !important;
  }

  .-mr-36-tablet-only {
    margin-right: -9rem !important;
  }

  .mb-36-tablet-only {
    margin-bottom: 9rem !important;
  }

  .-mb-36-tablet-only {
    margin-bottom: -9rem !important;
  }

  .ml-36-tablet-only {
    margin-left: 9rem !important;
  }

  .-ml-36-tablet-only {
    margin-left: -9rem !important;
  }

  .mx-36-tablet-only {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .-mx-36-tablet-only {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .my-36-tablet-only {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .-my-36-tablet-only {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .m-40-tablet-only {
    margin: 10rem !important;
  }

  .-m-40-tablet-only {
    margin: -10rem !important;
  }

  .mt-40-tablet-only {
    margin-top: 10rem !important;
  }

  .-mt-40-tablet-only {
    margin-top: -10rem !important;
  }

  .mr-40-tablet-only {
    margin-right: 10rem !important;
  }

  .-mr-40-tablet-only {
    margin-right: -10rem !important;
  }

  .mb-40-tablet-only {
    margin-bottom: 10rem !important;
  }

  .-mb-40-tablet-only {
    margin-bottom: -10rem !important;
  }

  .ml-40-tablet-only {
    margin-left: 10rem !important;
  }

  .-ml-40-tablet-only {
    margin-left: -10rem !important;
  }

  .mx-40-tablet-only {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .-mx-40-tablet-only {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .my-40-tablet-only {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .-my-40-tablet-only {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .m-44-tablet-only {
    margin: 11rem !important;
  }

  .-m-44-tablet-only {
    margin: -11rem !important;
  }

  .mt-44-tablet-only {
    margin-top: 11rem !important;
  }

  .-mt-44-tablet-only {
    margin-top: -11rem !important;
  }

  .mr-44-tablet-only {
    margin-right: 11rem !important;
  }

  .-mr-44-tablet-only {
    margin-right: -11rem !important;
  }

  .mb-44-tablet-only {
    margin-bottom: 11rem !important;
  }

  .-mb-44-tablet-only {
    margin-bottom: -11rem !important;
  }

  .ml-44-tablet-only {
    margin-left: 11rem !important;
  }

  .-ml-44-tablet-only {
    margin-left: -11rem !important;
  }

  .mx-44-tablet-only {
    margin-left: 11rem !important;
    margin-right: 11rem !important;
  }

  .-mx-44-tablet-only {
    margin-left: -11rem !important;
    margin-right: -11rem !important;
  }

  .my-44-tablet-only {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .-my-44-tablet-only {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .m-48-tablet-only {
    margin: 12rem !important;
  }

  .-m-48-tablet-only {
    margin: -12rem !important;
  }

  .mt-48-tablet-only {
    margin-top: 12rem !important;
  }

  .-mt-48-tablet-only {
    margin-top: -12rem !important;
  }

  .mr-48-tablet-only {
    margin-right: 12rem !important;
  }

  .-mr-48-tablet-only {
    margin-right: -12rem !important;
  }

  .mb-48-tablet-only {
    margin-bottom: 12rem !important;
  }

  .-mb-48-tablet-only {
    margin-bottom: -12rem !important;
  }

  .ml-48-tablet-only {
    margin-left: 12rem !important;
  }

  .-ml-48-tablet-only {
    margin-left: -12rem !important;
  }

  .mx-48-tablet-only {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .-mx-48-tablet-only {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .my-48-tablet-only {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .-my-48-tablet-only {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .m-52-tablet-only {
    margin: 13rem !important;
  }

  .-m-52-tablet-only {
    margin: -13rem !important;
  }

  .mt-52-tablet-only {
    margin-top: 13rem !important;
  }

  .-mt-52-tablet-only {
    margin-top: -13rem !important;
  }

  .mr-52-tablet-only {
    margin-right: 13rem !important;
  }

  .-mr-52-tablet-only {
    margin-right: -13rem !important;
  }

  .mb-52-tablet-only {
    margin-bottom: 13rem !important;
  }

  .-mb-52-tablet-only {
    margin-bottom: -13rem !important;
  }

  .ml-52-tablet-only {
    margin-left: 13rem !important;
  }

  .-ml-52-tablet-only {
    margin-left: -13rem !important;
  }

  .mx-52-tablet-only {
    margin-left: 13rem !important;
    margin-right: 13rem !important;
  }

  .-mx-52-tablet-only {
    margin-left: -13rem !important;
    margin-right: -13rem !important;
  }

  .my-52-tablet-only {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }

  .-my-52-tablet-only {
    margin-top: -13rem !important;
    margin-bottom: -13rem !important;
  }

  .m-56-tablet-only {
    margin: 14rem !important;
  }

  .-m-56-tablet-only {
    margin: -14rem !important;
  }

  .mt-56-tablet-only {
    margin-top: 14rem !important;
  }

  .-mt-56-tablet-only {
    margin-top: -14rem !important;
  }

  .mr-56-tablet-only {
    margin-right: 14rem !important;
  }

  .-mr-56-tablet-only {
    margin-right: -14rem !important;
  }

  .mb-56-tablet-only {
    margin-bottom: 14rem !important;
  }

  .-mb-56-tablet-only {
    margin-bottom: -14rem !important;
  }

  .ml-56-tablet-only {
    margin-left: 14rem !important;
  }

  .-ml-56-tablet-only {
    margin-left: -14rem !important;
  }

  .mx-56-tablet-only {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .-mx-56-tablet-only {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .my-56-tablet-only {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .-my-56-tablet-only {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .m-60-tablet-only {
    margin: 15rem !important;
  }

  .-m-60-tablet-only {
    margin: -15rem !important;
  }

  .mt-60-tablet-only {
    margin-top: 15rem !important;
  }

  .-mt-60-tablet-only {
    margin-top: -15rem !important;
  }

  .mr-60-tablet-only {
    margin-right: 15rem !important;
  }

  .-mr-60-tablet-only {
    margin-right: -15rem !important;
  }

  .mb-60-tablet-only {
    margin-bottom: 15rem !important;
  }

  .-mb-60-tablet-only {
    margin-bottom: -15rem !important;
  }

  .ml-60-tablet-only {
    margin-left: 15rem !important;
  }

  .-ml-60-tablet-only {
    margin-left: -15rem !important;
  }

  .mx-60-tablet-only {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .-mx-60-tablet-only {
    margin-left: -15rem !important;
    margin-right: -15rem !important;
  }

  .my-60-tablet-only {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .-my-60-tablet-only {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .m-64-tablet-only {
    margin: 16rem !important;
  }

  .-m-64-tablet-only {
    margin: -16rem !important;
  }

  .mt-64-tablet-only {
    margin-top: 16rem !important;
  }

  .-mt-64-tablet-only {
    margin-top: -16rem !important;
  }

  .mr-64-tablet-only {
    margin-right: 16rem !important;
  }

  .-mr-64-tablet-only {
    margin-right: -16rem !important;
  }

  .mb-64-tablet-only {
    margin-bottom: 16rem !important;
  }

  .-mb-64-tablet-only {
    margin-bottom: -16rem !important;
  }

  .ml-64-tablet-only {
    margin-left: 16rem !important;
  }

  .-ml-64-tablet-only {
    margin-left: -16rem !important;
  }

  .mx-64-tablet-only {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .-mx-64-tablet-only {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .my-64-tablet-only {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .-my-64-tablet-only {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .m-72-tablet-only {
    margin: 18rem !important;
  }

  .-m-72-tablet-only {
    margin: -18rem !important;
  }

  .mt-72-tablet-only {
    margin-top: 18rem !important;
  }

  .-mt-72-tablet-only {
    margin-top: -18rem !important;
  }

  .mr-72-tablet-only {
    margin-right: 18rem !important;
  }

  .-mr-72-tablet-only {
    margin-right: -18rem !important;
  }

  .mb-72-tablet-only {
    margin-bottom: 18rem !important;
  }

  .-mb-72-tablet-only {
    margin-bottom: -18rem !important;
  }

  .ml-72-tablet-only {
    margin-left: 18rem !important;
  }

  .-ml-72-tablet-only {
    margin-left: -18rem !important;
  }

  .mx-72-tablet-only {
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .-mx-72-tablet-only {
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .my-72-tablet-only {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .-my-72-tablet-only {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .m-80-tablet-only {
    margin: 20rem !important;
  }

  .-m-80-tablet-only {
    margin: -20rem !important;
  }

  .mt-80-tablet-only {
    margin-top: 20rem !important;
  }

  .-mt-80-tablet-only {
    margin-top: -20rem !important;
  }

  .mr-80-tablet-only {
    margin-right: 20rem !important;
  }

  .-mr-80-tablet-only {
    margin-right: -20rem !important;
  }

  .mb-80-tablet-only {
    margin-bottom: 20rem !important;
  }

  .-mb-80-tablet-only {
    margin-bottom: -20rem !important;
  }

  .ml-80-tablet-only {
    margin-left: 20rem !important;
  }

  .-ml-80-tablet-only {
    margin-left: -20rem !important;
  }

  .mx-80-tablet-only {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .-mx-80-tablet-only {
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .my-80-tablet-only {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .-my-80-tablet-only {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .m-96-tablet-only {
    margin: 24rem !important;
  }

  .-m-96-tablet-only {
    margin: -24rem !important;
  }

  .mt-96-tablet-only {
    margin-top: 24rem !important;
  }

  .-mt-96-tablet-only {
    margin-top: -24rem !important;
  }

  .mr-96-tablet-only {
    margin-right: 24rem !important;
  }

  .-mr-96-tablet-only {
    margin-right: -24rem !important;
  }

  .mb-96-tablet-only {
    margin-bottom: 24rem !important;
  }

  .-mb-96-tablet-only {
    margin-bottom: -24rem !important;
  }

  .ml-96-tablet-only {
    margin-left: 24rem !important;
  }

  .-ml-96-tablet-only {
    margin-left: -24rem !important;
  }

  .mx-96-tablet-only {
    margin-left: 24rem !important;
    margin-right: 24rem !important;
  }

  .-mx-96-tablet-only {
    margin-left: -24rem !important;
    margin-right: -24rem !important;
  }

  .my-96-tablet-only {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }

  .-my-96-tablet-only {
    margin-top: -24rem !important;
    margin-bottom: -24rem !important;
  }

  .p-auto-tablet-only {
    padding: auto !important;
  }

  .pt-auto-tablet-only {
    padding-top: auto !important;
  }

  .pr-auto-tablet-only {
    padding-right: auto !important;
  }

  .pb-auto-tablet-only {
    padding-bottom: auto !important;
  }

  .pl-auto-tablet-only {
    padding-left: auto !important;
  }

  .px-auto-tablet-only {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .py-auto-tablet-only {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }

  .p-0-tablet-only {
    padding: 0 !important;
  }

  .pt-0-tablet-only {
    padding-top: 0 !important;
  }

  .pr-0-tablet-only {
    padding-right: 0 !important;
  }

  .pb-0-tablet-only {
    padding-bottom: 0 !important;
  }

  .pl-0-tablet-only {
    padding-left: 0 !important;
  }

  .px-0-tablet-only {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-0-tablet-only {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-1-tablet-only {
    padding: .25rem !important;
  }

  .pt-1-tablet-only {
    padding-top: .25rem !important;
  }

  .pr-1-tablet-only {
    padding-right: .25rem !important;
  }

  .pb-1-tablet-only {
    padding-bottom: .25rem !important;
  }

  .pl-1-tablet-only {
    padding-left: .25rem !important;
  }

  .px-1-tablet-only {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .py-1-tablet-only {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .p-2-tablet-only {
    padding: .5rem !important;
  }

  .pt-2-tablet-only {
    padding-top: .5rem !important;
  }

  .pr-2-tablet-only {
    padding-right: .5rem !important;
  }

  .pb-2-tablet-only {
    padding-bottom: .5rem !important;
  }

  .pl-2-tablet-only {
    padding-left: .5rem !important;
  }

  .px-2-tablet-only {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .py-2-tablet-only {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .p-3-tablet-only {
    padding: .75rem !important;
  }

  .pt-3-tablet-only {
    padding-top: .75rem !important;
  }

  .pr-3-tablet-only {
    padding-right: .75rem !important;
  }

  .pb-3-tablet-only {
    padding-bottom: .75rem !important;
  }

  .pl-3-tablet-only {
    padding-left: .75rem !important;
  }

  .px-3-tablet-only {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .py-3-tablet-only {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .p-4-tablet-only {
    padding: 1rem !important;
  }

  .pt-4-tablet-only {
    padding-top: 1rem !important;
  }

  .pr-4-tablet-only {
    padding-right: 1rem !important;
  }

  .pb-4-tablet-only {
    padding-bottom: 1rem !important;
  }

  .pl-4-tablet-only {
    padding-left: 1rem !important;
  }

  .px-4-tablet-only {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .py-4-tablet-only {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .p-5-tablet-only {
    padding: 1.25rem !important;
  }

  .pt-5-tablet-only {
    padding-top: 1.25rem !important;
  }

  .pr-5-tablet-only {
    padding-right: 1.25rem !important;
  }

  .pb-5-tablet-only {
    padding-bottom: 1.25rem !important;
  }

  .pl-5-tablet-only {
    padding-left: 1.25rem !important;
  }

  .px-5-tablet-only {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .py-5-tablet-only {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .p-6-tablet-only {
    padding: 1.5rem !important;
  }

  .pt-6-tablet-only {
    padding-top: 1.5rem !important;
  }

  .pr-6-tablet-only {
    padding-right: 1.5rem !important;
  }

  .pb-6-tablet-only {
    padding-bottom: 1.5rem !important;
  }

  .pl-6-tablet-only {
    padding-left: 1.5rem !important;
  }

  .px-6-tablet-only {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .py-6-tablet-only {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .p-7-tablet-only {
    padding: 1.75rem !important;
  }

  .pt-7-tablet-only {
    padding-top: 1.75rem !important;
  }

  .pr-7-tablet-only {
    padding-right: 1.75rem !important;
  }

  .pb-7-tablet-only {
    padding-bottom: 1.75rem !important;
  }

  .pl-7-tablet-only {
    padding-left: 1.75rem !important;
  }

  .px-7-tablet-only {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .py-7-tablet-only {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .p-8-tablet-only {
    padding: 2rem !important;
  }

  .pt-8-tablet-only {
    padding-top: 2rem !important;
  }

  .pr-8-tablet-only {
    padding-right: 2rem !important;
  }

  .pb-8-tablet-only {
    padding-bottom: 2rem !important;
  }

  .pl-8-tablet-only {
    padding-left: 2rem !important;
  }

  .px-8-tablet-only {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .py-8-tablet-only {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .p-9-tablet-only {
    padding: 2.25rem !important;
  }

  .pt-9-tablet-only {
    padding-top: 2.25rem !important;
  }

  .pr-9-tablet-only {
    padding-right: 2.25rem !important;
  }

  .pb-9-tablet-only {
    padding-bottom: 2.25rem !important;
  }

  .pl-9-tablet-only {
    padding-left: 2.25rem !important;
  }

  .px-9-tablet-only {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .py-9-tablet-only {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .p-10-tablet-only {
    padding: 2.5rem !important;
  }

  .pt-10-tablet-only {
    padding-top: 2.5rem !important;
  }

  .pr-10-tablet-only {
    padding-right: 2.5rem !important;
  }

  .pb-10-tablet-only {
    padding-bottom: 2.5rem !important;
  }

  .pl-10-tablet-only {
    padding-left: 2.5rem !important;
  }

  .px-10-tablet-only {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .py-10-tablet-only {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .p-11-tablet-only {
    padding: 2.75rem !important;
  }

  .pt-11-tablet-only {
    padding-top: 2.75rem !important;
  }

  .pr-11-tablet-only {
    padding-right: 2.75rem !important;
  }

  .pb-11-tablet-only {
    padding-bottom: 2.75rem !important;
  }

  .pl-11-tablet-only {
    padding-left: 2.75rem !important;
  }

  .px-11-tablet-only {
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  .py-11-tablet-only {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .p-12-tablet-only {
    padding: 3rem !important;
  }

  .pt-12-tablet-only {
    padding-top: 3rem !important;
  }

  .pr-12-tablet-only {
    padding-right: 3rem !important;
  }

  .pb-12-tablet-only {
    padding-bottom: 3rem !important;
  }

  .pl-12-tablet-only {
    padding-left: 3rem !important;
  }

  .px-12-tablet-only {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-12-tablet-only {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .p-14-tablet-only {
    padding: 3.5rem !important;
  }

  .pt-14-tablet-only {
    padding-top: 3.5rem !important;
  }

  .pr-14-tablet-only {
    padding-right: 3.5rem !important;
  }

  .pb-14-tablet-only {
    padding-bottom: 3.5rem !important;
  }

  .pl-14-tablet-only {
    padding-left: 3.5rem !important;
  }

  .px-14-tablet-only {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .py-14-tablet-only {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .p-16-tablet-only {
    padding: 4rem !important;
  }

  .pt-16-tablet-only {
    padding-top: 4rem !important;
  }

  .pr-16-tablet-only {
    padding-right: 4rem !important;
  }

  .pb-16-tablet-only {
    padding-bottom: 4rem !important;
  }

  .pl-16-tablet-only {
    padding-left: 4rem !important;
  }

  .px-16-tablet-only {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .py-16-tablet-only {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .p-20-tablet-only {
    padding: 5rem !important;
  }

  .pt-20-tablet-only {
    padding-top: 5rem !important;
  }

  .pr-20-tablet-only {
    padding-right: 5rem !important;
  }

  .pb-20-tablet-only {
    padding-bottom: 5rem !important;
  }

  .pl-20-tablet-only {
    padding-left: 5rem !important;
  }

  .px-20-tablet-only {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .py-20-tablet-only {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .p-24-tablet-only {
    padding: 6rem !important;
  }

  .pt-24-tablet-only {
    padding-top: 6rem !important;
  }

  .pr-24-tablet-only {
    padding-right: 6rem !important;
  }

  .pb-24-tablet-only {
    padding-bottom: 6rem !important;
  }

  .pl-24-tablet-only {
    padding-left: 6rem !important;
  }

  .px-24-tablet-only {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .py-24-tablet-only {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .p-28-tablet-only {
    padding: 7rem !important;
  }

  .pt-28-tablet-only {
    padding-top: 7rem !important;
  }

  .pr-28-tablet-only {
    padding-right: 7rem !important;
  }

  .pb-28-tablet-only {
    padding-bottom: 7rem !important;
  }

  .pl-28-tablet-only {
    padding-left: 7rem !important;
  }

  .px-28-tablet-only {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .py-28-tablet-only {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .p-32-tablet-only {
    padding: 8rem !important;
  }

  .pt-32-tablet-only {
    padding-top: 8rem !important;
  }

  .pr-32-tablet-only {
    padding-right: 8rem !important;
  }

  .pb-32-tablet-only {
    padding-bottom: 8rem !important;
  }

  .pl-32-tablet-only {
    padding-left: 8rem !important;
  }

  .px-32-tablet-only {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .py-32-tablet-only {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .p-36-tablet-only {
    padding: 9rem !important;
  }

  .pt-36-tablet-only {
    padding-top: 9rem !important;
  }

  .pr-36-tablet-only {
    padding-right: 9rem !important;
  }

  .pb-36-tablet-only {
    padding-bottom: 9rem !important;
  }

  .pl-36-tablet-only {
    padding-left: 9rem !important;
  }

  .px-36-tablet-only {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .py-36-tablet-only {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .p-40-tablet-only {
    padding: 10rem !important;
  }

  .pt-40-tablet-only {
    padding-top: 10rem !important;
  }

  .pr-40-tablet-only {
    padding-right: 10rem !important;
  }

  .pb-40-tablet-only {
    padding-bottom: 10rem !important;
  }

  .pl-40-tablet-only {
    padding-left: 10rem !important;
  }

  .px-40-tablet-only {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .py-40-tablet-only {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .p-44-tablet-only {
    padding: 11rem !important;
  }

  .pt-44-tablet-only {
    padding-top: 11rem !important;
  }

  .pr-44-tablet-only {
    padding-right: 11rem !important;
  }

  .pb-44-tablet-only {
    padding-bottom: 11rem !important;
  }

  .pl-44-tablet-only {
    padding-left: 11rem !important;
  }

  .px-44-tablet-only {
    padding-left: 11rem !important;
    padding-right: 11rem !important;
  }

  .py-44-tablet-only {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .p-48-tablet-only {
    padding: 12rem !important;
  }

  .pt-48-tablet-only {
    padding-top: 12rem !important;
  }

  .pr-48-tablet-only {
    padding-right: 12rem !important;
  }

  .pb-48-tablet-only {
    padding-bottom: 12rem !important;
  }

  .pl-48-tablet-only {
    padding-left: 12rem !important;
  }

  .px-48-tablet-only {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .py-48-tablet-only {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .p-52-tablet-only {
    padding: 13rem !important;
  }

  .pt-52-tablet-only {
    padding-top: 13rem !important;
  }

  .pr-52-tablet-only {
    padding-right: 13rem !important;
  }

  .pb-52-tablet-only {
    padding-bottom: 13rem !important;
  }

  .pl-52-tablet-only {
    padding-left: 13rem !important;
  }

  .px-52-tablet-only {
    padding-left: 13rem !important;
    padding-right: 13rem !important;
  }

  .py-52-tablet-only {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }

  .p-56-tablet-only {
    padding: 14rem !important;
  }

  .pt-56-tablet-only {
    padding-top: 14rem !important;
  }

  .pr-56-tablet-only {
    padding-right: 14rem !important;
  }

  .pb-56-tablet-only {
    padding-bottom: 14rem !important;
  }

  .pl-56-tablet-only {
    padding-left: 14rem !important;
  }

  .px-56-tablet-only {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .py-56-tablet-only {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .p-60-tablet-only {
    padding: 15rem !important;
  }

  .pt-60-tablet-only {
    padding-top: 15rem !important;
  }

  .pr-60-tablet-only {
    padding-right: 15rem !important;
  }

  .pb-60-tablet-only {
    padding-bottom: 15rem !important;
  }

  .pl-60-tablet-only {
    padding-left: 15rem !important;
  }

  .px-60-tablet-only {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .py-60-tablet-only {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .p-64-tablet-only {
    padding: 16rem !important;
  }

  .pt-64-tablet-only {
    padding-top: 16rem !important;
  }

  .pr-64-tablet-only {
    padding-right: 16rem !important;
  }

  .pb-64-tablet-only {
    padding-bottom: 16rem !important;
  }

  .pl-64-tablet-only {
    padding-left: 16rem !important;
  }

  .px-64-tablet-only {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .py-64-tablet-only {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .p-72-tablet-only {
    padding: 18rem !important;
  }

  .pt-72-tablet-only {
    padding-top: 18rem !important;
  }

  .pr-72-tablet-only {
    padding-right: 18rem !important;
  }

  .pb-72-tablet-only {
    padding-bottom: 18rem !important;
  }

  .pl-72-tablet-only {
    padding-left: 18rem !important;
  }

  .px-72-tablet-only {
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .py-72-tablet-only {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .p-80-tablet-only {
    padding: 20rem !important;
  }

  .pt-80-tablet-only {
    padding-top: 20rem !important;
  }

  .pr-80-tablet-only {
    padding-right: 20rem !important;
  }

  .pb-80-tablet-only {
    padding-bottom: 20rem !important;
  }

  .pl-80-tablet-only {
    padding-left: 20rem !important;
  }

  .px-80-tablet-only {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .py-80-tablet-only {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .p-96-tablet-only {
    padding: 24rem !important;
  }

  .pt-96-tablet-only {
    padding-top: 24rem !important;
  }

  .pr-96-tablet-only {
    padding-right: 24rem !important;
  }

  .pb-96-tablet-only {
    padding-bottom: 24rem !important;
  }

  .pl-96-tablet-only {
    padding-left: 24rem !important;
  }

  .px-96-tablet-only {
    padding-left: 24rem !important;
    padding-right: 24rem !important;
  }

  .py-96-tablet-only {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
}

@media screen and (width <= 1023px) {
  .m-auto-touch {
    margin: auto !important;
  }

  .mt-auto-touch {
    margin-top: auto !important;
  }

  .mr-auto-touch {
    margin-right: auto !important;
  }

  .mb-auto-touch {
    margin-bottom: auto !important;
  }

  .ml-auto-touch {
    margin-left: auto !important;
  }

  .mx-auto-touch {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-auto-touch {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .m-0-touch {
    margin: 0 !important;
  }

  .mt-0-touch {
    margin-top: 0 !important;
  }

  .mr-0-touch {
    margin-right: 0 !important;
  }

  .mb-0-touch {
    margin-bottom: 0 !important;
  }

  .ml-0-touch {
    margin-left: 0 !important;
  }

  .mx-0-touch {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-0-touch {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-1-touch {
    margin: .25rem !important;
  }

  .-m-1-touch {
    margin: -.25rem !important;
  }

  .mt-1-touch {
    margin-top: .25rem !important;
  }

  .-mt-1-touch {
    margin-top: -.25rem !important;
  }

  .mr-1-touch {
    margin-right: .25rem !important;
  }

  .-mr-1-touch {
    margin-right: -.25rem !important;
  }

  .mb-1-touch {
    margin-bottom: .25rem !important;
  }

  .-mb-1-touch {
    margin-bottom: -.25rem !important;
  }

  .ml-1-touch {
    margin-left: .25rem !important;
  }

  .-ml-1-touch {
    margin-left: -.25rem !important;
  }

  .mx-1-touch {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .-mx-1-touch {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .my-1-touch {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .-my-1-touch {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .m-2-touch {
    margin: .5rem !important;
  }

  .-m-2-touch {
    margin: -.5rem !important;
  }

  .mt-2-touch {
    margin-top: .5rem !important;
  }

  .-mt-2-touch {
    margin-top: -.5rem !important;
  }

  .mr-2-touch {
    margin-right: .5rem !important;
  }

  .-mr-2-touch {
    margin-right: -.5rem !important;
  }

  .mb-2-touch {
    margin-bottom: .5rem !important;
  }

  .-mb-2-touch {
    margin-bottom: -.5rem !important;
  }

  .ml-2-touch {
    margin-left: .5rem !important;
  }

  .-ml-2-touch {
    margin-left: -.5rem !important;
  }

  .mx-2-touch {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .-mx-2-touch {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .my-2-touch {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .-my-2-touch {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .m-3-touch {
    margin: .75rem !important;
  }

  .-m-3-touch {
    margin: -.75rem !important;
  }

  .mt-3-touch {
    margin-top: .75rem !important;
  }

  .-mt-3-touch {
    margin-top: -.75rem !important;
  }

  .mr-3-touch {
    margin-right: .75rem !important;
  }

  .-mr-3-touch {
    margin-right: -.75rem !important;
  }

  .mb-3-touch {
    margin-bottom: .75rem !important;
  }

  .-mb-3-touch {
    margin-bottom: -.75rem !important;
  }

  .ml-3-touch {
    margin-left: .75rem !important;
  }

  .-ml-3-touch {
    margin-left: -.75rem !important;
  }

  .mx-3-touch {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .-mx-3-touch {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .my-3-touch {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .-my-3-touch {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .m-4-touch {
    margin: 1rem !important;
  }

  .-m-4-touch {
    margin: -1rem !important;
  }

  .mt-4-touch {
    margin-top: 1rem !important;
  }

  .-mt-4-touch {
    margin-top: -1rem !important;
  }

  .mr-4-touch {
    margin-right: 1rem !important;
  }

  .-mr-4-touch {
    margin-right: -1rem !important;
  }

  .mb-4-touch {
    margin-bottom: 1rem !important;
  }

  .-mb-4-touch {
    margin-bottom: -1rem !important;
  }

  .ml-4-touch {
    margin-left: 1rem !important;
  }

  .-ml-4-touch {
    margin-left: -1rem !important;
  }

  .mx-4-touch {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .-mx-4-touch {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .my-4-touch {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .-my-4-touch {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .m-5-touch {
    margin: 1.25rem !important;
  }

  .-m-5-touch {
    margin: -1.25rem !important;
  }

  .mt-5-touch {
    margin-top: 1.25rem !important;
  }

  .-mt-5-touch {
    margin-top: -1.25rem !important;
  }

  .mr-5-touch {
    margin-right: 1.25rem !important;
  }

  .-mr-5-touch {
    margin-right: -1.25rem !important;
  }

  .mb-5-touch {
    margin-bottom: 1.25rem !important;
  }

  .-mb-5-touch {
    margin-bottom: -1.25rem !important;
  }

  .ml-5-touch {
    margin-left: 1.25rem !important;
  }

  .-ml-5-touch {
    margin-left: -1.25rem !important;
  }

  .mx-5-touch {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .-mx-5-touch {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .my-5-touch {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .-my-5-touch {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .m-6-touch {
    margin: 1.5rem !important;
  }

  .-m-6-touch {
    margin: -1.5rem !important;
  }

  .mt-6-touch {
    margin-top: 1.5rem !important;
  }

  .-mt-6-touch {
    margin-top: -1.5rem !important;
  }

  .mr-6-touch {
    margin-right: 1.5rem !important;
  }

  .-mr-6-touch {
    margin-right: -1.5rem !important;
  }

  .mb-6-touch {
    margin-bottom: 1.5rem !important;
  }

  .-mb-6-touch {
    margin-bottom: -1.5rem !important;
  }

  .ml-6-touch {
    margin-left: 1.5rem !important;
  }

  .-ml-6-touch {
    margin-left: -1.5rem !important;
  }

  .mx-6-touch {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .-mx-6-touch {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .my-6-touch {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .-my-6-touch {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .m-7-touch {
    margin: 1.75rem !important;
  }

  .-m-7-touch {
    margin: -1.75rem !important;
  }

  .mt-7-touch {
    margin-top: 1.75rem !important;
  }

  .-mt-7-touch {
    margin-top: -1.75rem !important;
  }

  .mr-7-touch {
    margin-right: 1.75rem !important;
  }

  .-mr-7-touch {
    margin-right: -1.75rem !important;
  }

  .mb-7-touch {
    margin-bottom: 1.75rem !important;
  }

  .-mb-7-touch {
    margin-bottom: -1.75rem !important;
  }

  .ml-7-touch {
    margin-left: 1.75rem !important;
  }

  .-ml-7-touch {
    margin-left: -1.75rem !important;
  }

  .mx-7-touch {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .-mx-7-touch {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .my-7-touch {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .-my-7-touch {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .m-8-touch {
    margin: 2rem !important;
  }

  .-m-8-touch {
    margin: -2rem !important;
  }

  .mt-8-touch {
    margin-top: 2rem !important;
  }

  .-mt-8-touch {
    margin-top: -2rem !important;
  }

  .mr-8-touch {
    margin-right: 2rem !important;
  }

  .-mr-8-touch {
    margin-right: -2rem !important;
  }

  .mb-8-touch {
    margin-bottom: 2rem !important;
  }

  .-mb-8-touch {
    margin-bottom: -2rem !important;
  }

  .ml-8-touch {
    margin-left: 2rem !important;
  }

  .-ml-8-touch {
    margin-left: -2rem !important;
  }

  .mx-8-touch {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .-mx-8-touch {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .my-8-touch {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .-my-8-touch {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .m-9-touch {
    margin: 2.25rem !important;
  }

  .-m-9-touch {
    margin: -2.25rem !important;
  }

  .mt-9-touch {
    margin-top: 2.25rem !important;
  }

  .-mt-9-touch {
    margin-top: -2.25rem !important;
  }

  .mr-9-touch {
    margin-right: 2.25rem !important;
  }

  .-mr-9-touch {
    margin-right: -2.25rem !important;
  }

  .mb-9-touch {
    margin-bottom: 2.25rem !important;
  }

  .-mb-9-touch {
    margin-bottom: -2.25rem !important;
  }

  .ml-9-touch {
    margin-left: 2.25rem !important;
  }

  .-ml-9-touch {
    margin-left: -2.25rem !important;
  }

  .mx-9-touch {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .-mx-9-touch {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .my-9-touch {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .-my-9-touch {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .m-10-touch {
    margin: 2.5rem !important;
  }

  .-m-10-touch {
    margin: -2.5rem !important;
  }

  .mt-10-touch {
    margin-top: 2.5rem !important;
  }

  .-mt-10-touch {
    margin-top: -2.5rem !important;
  }

  .mr-10-touch {
    margin-right: 2.5rem !important;
  }

  .-mr-10-touch {
    margin-right: -2.5rem !important;
  }

  .mb-10-touch {
    margin-bottom: 2.5rem !important;
  }

  .-mb-10-touch {
    margin-bottom: -2.5rem !important;
  }

  .ml-10-touch {
    margin-left: 2.5rem !important;
  }

  .-ml-10-touch {
    margin-left: -2.5rem !important;
  }

  .mx-10-touch {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .-mx-10-touch {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .my-10-touch {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .-my-10-touch {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .m-11-touch {
    margin: 2.75rem !important;
  }

  .-m-11-touch {
    margin: -2.75rem !important;
  }

  .mt-11-touch {
    margin-top: 2.75rem !important;
  }

  .-mt-11-touch {
    margin-top: -2.75rem !important;
  }

  .mr-11-touch {
    margin-right: 2.75rem !important;
  }

  .-mr-11-touch {
    margin-right: -2.75rem !important;
  }

  .mb-11-touch {
    margin-bottom: 2.75rem !important;
  }

  .-mb-11-touch {
    margin-bottom: -2.75rem !important;
  }

  .ml-11-touch {
    margin-left: 2.75rem !important;
  }

  .-ml-11-touch {
    margin-left: -2.75rem !important;
  }

  .mx-11-touch {
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
  }

  .-mx-11-touch {
    margin-left: -2.75rem !important;
    margin-right: -2.75rem !important;
  }

  .my-11-touch {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .-my-11-touch {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .m-12-touch {
    margin: 3rem !important;
  }

  .-m-12-touch {
    margin: -3rem !important;
  }

  .mt-12-touch {
    margin-top: 3rem !important;
  }

  .-mt-12-touch {
    margin-top: -3rem !important;
  }

  .mr-12-touch {
    margin-right: 3rem !important;
  }

  .-mr-12-touch {
    margin-right: -3rem !important;
  }

  .mb-12-touch {
    margin-bottom: 3rem !important;
  }

  .-mb-12-touch {
    margin-bottom: -3rem !important;
  }

  .ml-12-touch {
    margin-left: 3rem !important;
  }

  .-ml-12-touch {
    margin-left: -3rem !important;
  }

  .mx-12-touch {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .-mx-12-touch {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-12-touch {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .-my-12-touch {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .m-14-touch {
    margin: 3.5rem !important;
  }

  .-m-14-touch {
    margin: -3.5rem !important;
  }

  .mt-14-touch {
    margin-top: 3.5rem !important;
  }

  .-mt-14-touch {
    margin-top: -3.5rem !important;
  }

  .mr-14-touch {
    margin-right: 3.5rem !important;
  }

  .-mr-14-touch {
    margin-right: -3.5rem !important;
  }

  .mb-14-touch {
    margin-bottom: 3.5rem !important;
  }

  .-mb-14-touch {
    margin-bottom: -3.5rem !important;
  }

  .ml-14-touch {
    margin-left: 3.5rem !important;
  }

  .-ml-14-touch {
    margin-left: -3.5rem !important;
  }

  .mx-14-touch {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .-mx-14-touch {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .my-14-touch {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .-my-14-touch {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .m-16-touch {
    margin: 4rem !important;
  }

  .-m-16-touch {
    margin: -4rem !important;
  }

  .mt-16-touch {
    margin-top: 4rem !important;
  }

  .-mt-16-touch {
    margin-top: -4rem !important;
  }

  .mr-16-touch {
    margin-right: 4rem !important;
  }

  .-mr-16-touch {
    margin-right: -4rem !important;
  }

  .mb-16-touch {
    margin-bottom: 4rem !important;
  }

  .-mb-16-touch {
    margin-bottom: -4rem !important;
  }

  .ml-16-touch {
    margin-left: 4rem !important;
  }

  .-ml-16-touch {
    margin-left: -4rem !important;
  }

  .mx-16-touch {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .-mx-16-touch {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .my-16-touch {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .-my-16-touch {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .m-20-touch {
    margin: 5rem !important;
  }

  .-m-20-touch {
    margin: -5rem !important;
  }

  .mt-20-touch {
    margin-top: 5rem !important;
  }

  .-mt-20-touch {
    margin-top: -5rem !important;
  }

  .mr-20-touch {
    margin-right: 5rem !important;
  }

  .-mr-20-touch {
    margin-right: -5rem !important;
  }

  .mb-20-touch {
    margin-bottom: 5rem !important;
  }

  .-mb-20-touch {
    margin-bottom: -5rem !important;
  }

  .ml-20-touch {
    margin-left: 5rem !important;
  }

  .-ml-20-touch {
    margin-left: -5rem !important;
  }

  .mx-20-touch {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .-mx-20-touch {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .my-20-touch {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .-my-20-touch {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .m-24-touch {
    margin: 6rem !important;
  }

  .-m-24-touch {
    margin: -6rem !important;
  }

  .mt-24-touch {
    margin-top: 6rem !important;
  }

  .-mt-24-touch {
    margin-top: -6rem !important;
  }

  .mr-24-touch {
    margin-right: 6rem !important;
  }

  .-mr-24-touch {
    margin-right: -6rem !important;
  }

  .mb-24-touch {
    margin-bottom: 6rem !important;
  }

  .-mb-24-touch {
    margin-bottom: -6rem !important;
  }

  .ml-24-touch {
    margin-left: 6rem !important;
  }

  .-ml-24-touch {
    margin-left: -6rem !important;
  }

  .mx-24-touch {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .-mx-24-touch {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .my-24-touch {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .-my-24-touch {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .m-28-touch {
    margin: 7rem !important;
  }

  .-m-28-touch {
    margin: -7rem !important;
  }

  .mt-28-touch {
    margin-top: 7rem !important;
  }

  .-mt-28-touch {
    margin-top: -7rem !important;
  }

  .mr-28-touch {
    margin-right: 7rem !important;
  }

  .-mr-28-touch {
    margin-right: -7rem !important;
  }

  .mb-28-touch {
    margin-bottom: 7rem !important;
  }

  .-mb-28-touch {
    margin-bottom: -7rem !important;
  }

  .ml-28-touch {
    margin-left: 7rem !important;
  }

  .-ml-28-touch {
    margin-left: -7rem !important;
  }

  .mx-28-touch {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .-mx-28-touch {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .my-28-touch {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .-my-28-touch {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .m-32-touch {
    margin: 8rem !important;
  }

  .-m-32-touch {
    margin: -8rem !important;
  }

  .mt-32-touch {
    margin-top: 8rem !important;
  }

  .-mt-32-touch {
    margin-top: -8rem !important;
  }

  .mr-32-touch {
    margin-right: 8rem !important;
  }

  .-mr-32-touch {
    margin-right: -8rem !important;
  }

  .mb-32-touch {
    margin-bottom: 8rem !important;
  }

  .-mb-32-touch {
    margin-bottom: -8rem !important;
  }

  .ml-32-touch {
    margin-left: 8rem !important;
  }

  .-ml-32-touch {
    margin-left: -8rem !important;
  }

  .mx-32-touch {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .-mx-32-touch {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .my-32-touch {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .-my-32-touch {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .m-36-touch {
    margin: 9rem !important;
  }

  .-m-36-touch {
    margin: -9rem !important;
  }

  .mt-36-touch {
    margin-top: 9rem !important;
  }

  .-mt-36-touch {
    margin-top: -9rem !important;
  }

  .mr-36-touch {
    margin-right: 9rem !important;
  }

  .-mr-36-touch {
    margin-right: -9rem !important;
  }

  .mb-36-touch {
    margin-bottom: 9rem !important;
  }

  .-mb-36-touch {
    margin-bottom: -9rem !important;
  }

  .ml-36-touch {
    margin-left: 9rem !important;
  }

  .-ml-36-touch {
    margin-left: -9rem !important;
  }

  .mx-36-touch {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .-mx-36-touch {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .my-36-touch {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .-my-36-touch {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .m-40-touch {
    margin: 10rem !important;
  }

  .-m-40-touch {
    margin: -10rem !important;
  }

  .mt-40-touch {
    margin-top: 10rem !important;
  }

  .-mt-40-touch {
    margin-top: -10rem !important;
  }

  .mr-40-touch {
    margin-right: 10rem !important;
  }

  .-mr-40-touch {
    margin-right: -10rem !important;
  }

  .mb-40-touch {
    margin-bottom: 10rem !important;
  }

  .-mb-40-touch {
    margin-bottom: -10rem !important;
  }

  .ml-40-touch {
    margin-left: 10rem !important;
  }

  .-ml-40-touch {
    margin-left: -10rem !important;
  }

  .mx-40-touch {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .-mx-40-touch {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .my-40-touch {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .-my-40-touch {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .m-44-touch {
    margin: 11rem !important;
  }

  .-m-44-touch {
    margin: -11rem !important;
  }

  .mt-44-touch {
    margin-top: 11rem !important;
  }

  .-mt-44-touch {
    margin-top: -11rem !important;
  }

  .mr-44-touch {
    margin-right: 11rem !important;
  }

  .-mr-44-touch {
    margin-right: -11rem !important;
  }

  .mb-44-touch {
    margin-bottom: 11rem !important;
  }

  .-mb-44-touch {
    margin-bottom: -11rem !important;
  }

  .ml-44-touch {
    margin-left: 11rem !important;
  }

  .-ml-44-touch {
    margin-left: -11rem !important;
  }

  .mx-44-touch {
    margin-left: 11rem !important;
    margin-right: 11rem !important;
  }

  .-mx-44-touch {
    margin-left: -11rem !important;
    margin-right: -11rem !important;
  }

  .my-44-touch {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .-my-44-touch {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .m-48-touch {
    margin: 12rem !important;
  }

  .-m-48-touch {
    margin: -12rem !important;
  }

  .mt-48-touch {
    margin-top: 12rem !important;
  }

  .-mt-48-touch {
    margin-top: -12rem !important;
  }

  .mr-48-touch {
    margin-right: 12rem !important;
  }

  .-mr-48-touch {
    margin-right: -12rem !important;
  }

  .mb-48-touch {
    margin-bottom: 12rem !important;
  }

  .-mb-48-touch {
    margin-bottom: -12rem !important;
  }

  .ml-48-touch {
    margin-left: 12rem !important;
  }

  .-ml-48-touch {
    margin-left: -12rem !important;
  }

  .mx-48-touch {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .-mx-48-touch {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .my-48-touch {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .-my-48-touch {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .m-52-touch {
    margin: 13rem !important;
  }

  .-m-52-touch {
    margin: -13rem !important;
  }

  .mt-52-touch {
    margin-top: 13rem !important;
  }

  .-mt-52-touch {
    margin-top: -13rem !important;
  }

  .mr-52-touch {
    margin-right: 13rem !important;
  }

  .-mr-52-touch {
    margin-right: -13rem !important;
  }

  .mb-52-touch {
    margin-bottom: 13rem !important;
  }

  .-mb-52-touch {
    margin-bottom: -13rem !important;
  }

  .ml-52-touch {
    margin-left: 13rem !important;
  }

  .-ml-52-touch {
    margin-left: -13rem !important;
  }

  .mx-52-touch {
    margin-left: 13rem !important;
    margin-right: 13rem !important;
  }

  .-mx-52-touch {
    margin-left: -13rem !important;
    margin-right: -13rem !important;
  }

  .my-52-touch {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }

  .-my-52-touch {
    margin-top: -13rem !important;
    margin-bottom: -13rem !important;
  }

  .m-56-touch {
    margin: 14rem !important;
  }

  .-m-56-touch {
    margin: -14rem !important;
  }

  .mt-56-touch {
    margin-top: 14rem !important;
  }

  .-mt-56-touch {
    margin-top: -14rem !important;
  }

  .mr-56-touch {
    margin-right: 14rem !important;
  }

  .-mr-56-touch {
    margin-right: -14rem !important;
  }

  .mb-56-touch {
    margin-bottom: 14rem !important;
  }

  .-mb-56-touch {
    margin-bottom: -14rem !important;
  }

  .ml-56-touch {
    margin-left: 14rem !important;
  }

  .-ml-56-touch {
    margin-left: -14rem !important;
  }

  .mx-56-touch {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .-mx-56-touch {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .my-56-touch {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .-my-56-touch {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .m-60-touch {
    margin: 15rem !important;
  }

  .-m-60-touch {
    margin: -15rem !important;
  }

  .mt-60-touch {
    margin-top: 15rem !important;
  }

  .-mt-60-touch {
    margin-top: -15rem !important;
  }

  .mr-60-touch {
    margin-right: 15rem !important;
  }

  .-mr-60-touch {
    margin-right: -15rem !important;
  }

  .mb-60-touch {
    margin-bottom: 15rem !important;
  }

  .-mb-60-touch {
    margin-bottom: -15rem !important;
  }

  .ml-60-touch {
    margin-left: 15rem !important;
  }

  .-ml-60-touch {
    margin-left: -15rem !important;
  }

  .mx-60-touch {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .-mx-60-touch {
    margin-left: -15rem !important;
    margin-right: -15rem !important;
  }

  .my-60-touch {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .-my-60-touch {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .m-64-touch {
    margin: 16rem !important;
  }

  .-m-64-touch {
    margin: -16rem !important;
  }

  .mt-64-touch {
    margin-top: 16rem !important;
  }

  .-mt-64-touch {
    margin-top: -16rem !important;
  }

  .mr-64-touch {
    margin-right: 16rem !important;
  }

  .-mr-64-touch {
    margin-right: -16rem !important;
  }

  .mb-64-touch {
    margin-bottom: 16rem !important;
  }

  .-mb-64-touch {
    margin-bottom: -16rem !important;
  }

  .ml-64-touch {
    margin-left: 16rem !important;
  }

  .-ml-64-touch {
    margin-left: -16rem !important;
  }

  .mx-64-touch {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .-mx-64-touch {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .my-64-touch {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .-my-64-touch {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .m-72-touch {
    margin: 18rem !important;
  }

  .-m-72-touch {
    margin: -18rem !important;
  }

  .mt-72-touch {
    margin-top: 18rem !important;
  }

  .-mt-72-touch {
    margin-top: -18rem !important;
  }

  .mr-72-touch {
    margin-right: 18rem !important;
  }

  .-mr-72-touch {
    margin-right: -18rem !important;
  }

  .mb-72-touch {
    margin-bottom: 18rem !important;
  }

  .-mb-72-touch {
    margin-bottom: -18rem !important;
  }

  .ml-72-touch {
    margin-left: 18rem !important;
  }

  .-ml-72-touch {
    margin-left: -18rem !important;
  }

  .mx-72-touch {
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .-mx-72-touch {
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .my-72-touch {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .-my-72-touch {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .m-80-touch {
    margin: 20rem !important;
  }

  .-m-80-touch {
    margin: -20rem !important;
  }

  .mt-80-touch {
    margin-top: 20rem !important;
  }

  .-mt-80-touch {
    margin-top: -20rem !important;
  }

  .mr-80-touch {
    margin-right: 20rem !important;
  }

  .-mr-80-touch {
    margin-right: -20rem !important;
  }

  .mb-80-touch {
    margin-bottom: 20rem !important;
  }

  .-mb-80-touch {
    margin-bottom: -20rem !important;
  }

  .ml-80-touch {
    margin-left: 20rem !important;
  }

  .-ml-80-touch {
    margin-left: -20rem !important;
  }

  .mx-80-touch {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .-mx-80-touch {
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .my-80-touch {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .-my-80-touch {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .m-96-touch {
    margin: 24rem !important;
  }

  .-m-96-touch {
    margin: -24rem !important;
  }

  .mt-96-touch {
    margin-top: 24rem !important;
  }

  .-mt-96-touch {
    margin-top: -24rem !important;
  }

  .mr-96-touch {
    margin-right: 24rem !important;
  }

  .-mr-96-touch {
    margin-right: -24rem !important;
  }

  .mb-96-touch {
    margin-bottom: 24rem !important;
  }

  .-mb-96-touch {
    margin-bottom: -24rem !important;
  }

  .ml-96-touch {
    margin-left: 24rem !important;
  }

  .-ml-96-touch {
    margin-left: -24rem !important;
  }

  .mx-96-touch {
    margin-left: 24rem !important;
    margin-right: 24rem !important;
  }

  .-mx-96-touch {
    margin-left: -24rem !important;
    margin-right: -24rem !important;
  }

  .my-96-touch {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }

  .-my-96-touch {
    margin-top: -24rem !important;
    margin-bottom: -24rem !important;
  }

  .p-auto-touch {
    padding: auto !important;
  }

  .pt-auto-touch {
    padding-top: auto !important;
  }

  .pr-auto-touch {
    padding-right: auto !important;
  }

  .pb-auto-touch {
    padding-bottom: auto !important;
  }

  .pl-auto-touch {
    padding-left: auto !important;
  }

  .px-auto-touch {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .py-auto-touch {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }

  .p-0-touch {
    padding: 0 !important;
  }

  .pt-0-touch {
    padding-top: 0 !important;
  }

  .pr-0-touch {
    padding-right: 0 !important;
  }

  .pb-0-touch {
    padding-bottom: 0 !important;
  }

  .pl-0-touch {
    padding-left: 0 !important;
  }

  .px-0-touch {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-0-touch {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-1-touch {
    padding: .25rem !important;
  }

  .pt-1-touch {
    padding-top: .25rem !important;
  }

  .pr-1-touch {
    padding-right: .25rem !important;
  }

  .pb-1-touch {
    padding-bottom: .25rem !important;
  }

  .pl-1-touch {
    padding-left: .25rem !important;
  }

  .px-1-touch {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .py-1-touch {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .p-2-touch {
    padding: .5rem !important;
  }

  .pt-2-touch {
    padding-top: .5rem !important;
  }

  .pr-2-touch {
    padding-right: .5rem !important;
  }

  .pb-2-touch {
    padding-bottom: .5rem !important;
  }

  .pl-2-touch {
    padding-left: .5rem !important;
  }

  .px-2-touch {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .py-2-touch {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .p-3-touch {
    padding: .75rem !important;
  }

  .pt-3-touch {
    padding-top: .75rem !important;
  }

  .pr-3-touch {
    padding-right: .75rem !important;
  }

  .pb-3-touch {
    padding-bottom: .75rem !important;
  }

  .pl-3-touch {
    padding-left: .75rem !important;
  }

  .px-3-touch {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .py-3-touch {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .p-4-touch {
    padding: 1rem !important;
  }

  .pt-4-touch {
    padding-top: 1rem !important;
  }

  .pr-4-touch {
    padding-right: 1rem !important;
  }

  .pb-4-touch {
    padding-bottom: 1rem !important;
  }

  .pl-4-touch {
    padding-left: 1rem !important;
  }

  .px-4-touch {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .py-4-touch {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .p-5-touch {
    padding: 1.25rem !important;
  }

  .pt-5-touch {
    padding-top: 1.25rem !important;
  }

  .pr-5-touch {
    padding-right: 1.25rem !important;
  }

  .pb-5-touch {
    padding-bottom: 1.25rem !important;
  }

  .pl-5-touch {
    padding-left: 1.25rem !important;
  }

  .px-5-touch {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .py-5-touch {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .p-6-touch {
    padding: 1.5rem !important;
  }

  .pt-6-touch {
    padding-top: 1.5rem !important;
  }

  .pr-6-touch {
    padding-right: 1.5rem !important;
  }

  .pb-6-touch {
    padding-bottom: 1.5rem !important;
  }

  .pl-6-touch {
    padding-left: 1.5rem !important;
  }

  .px-6-touch {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .py-6-touch {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .p-7-touch {
    padding: 1.75rem !important;
  }

  .pt-7-touch {
    padding-top: 1.75rem !important;
  }

  .pr-7-touch {
    padding-right: 1.75rem !important;
  }

  .pb-7-touch {
    padding-bottom: 1.75rem !important;
  }

  .pl-7-touch {
    padding-left: 1.75rem !important;
  }

  .px-7-touch {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .py-7-touch {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .p-8-touch {
    padding: 2rem !important;
  }

  .pt-8-touch {
    padding-top: 2rem !important;
  }

  .pr-8-touch {
    padding-right: 2rem !important;
  }

  .pb-8-touch {
    padding-bottom: 2rem !important;
  }

  .pl-8-touch {
    padding-left: 2rem !important;
  }

  .px-8-touch {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .py-8-touch {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .p-9-touch {
    padding: 2.25rem !important;
  }

  .pt-9-touch {
    padding-top: 2.25rem !important;
  }

  .pr-9-touch {
    padding-right: 2.25rem !important;
  }

  .pb-9-touch {
    padding-bottom: 2.25rem !important;
  }

  .pl-9-touch {
    padding-left: 2.25rem !important;
  }

  .px-9-touch {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .py-9-touch {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .p-10-touch {
    padding: 2.5rem !important;
  }

  .pt-10-touch {
    padding-top: 2.5rem !important;
  }

  .pr-10-touch {
    padding-right: 2.5rem !important;
  }

  .pb-10-touch {
    padding-bottom: 2.5rem !important;
  }

  .pl-10-touch {
    padding-left: 2.5rem !important;
  }

  .px-10-touch {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .py-10-touch {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .p-11-touch {
    padding: 2.75rem !important;
  }

  .pt-11-touch {
    padding-top: 2.75rem !important;
  }

  .pr-11-touch {
    padding-right: 2.75rem !important;
  }

  .pb-11-touch {
    padding-bottom: 2.75rem !important;
  }

  .pl-11-touch {
    padding-left: 2.75rem !important;
  }

  .px-11-touch {
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  .py-11-touch {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .p-12-touch {
    padding: 3rem !important;
  }

  .pt-12-touch {
    padding-top: 3rem !important;
  }

  .pr-12-touch {
    padding-right: 3rem !important;
  }

  .pb-12-touch {
    padding-bottom: 3rem !important;
  }

  .pl-12-touch {
    padding-left: 3rem !important;
  }

  .px-12-touch {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-12-touch {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .p-14-touch {
    padding: 3.5rem !important;
  }

  .pt-14-touch {
    padding-top: 3.5rem !important;
  }

  .pr-14-touch {
    padding-right: 3.5rem !important;
  }

  .pb-14-touch {
    padding-bottom: 3.5rem !important;
  }

  .pl-14-touch {
    padding-left: 3.5rem !important;
  }

  .px-14-touch {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .py-14-touch {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .p-16-touch {
    padding: 4rem !important;
  }

  .pt-16-touch {
    padding-top: 4rem !important;
  }

  .pr-16-touch {
    padding-right: 4rem !important;
  }

  .pb-16-touch {
    padding-bottom: 4rem !important;
  }

  .pl-16-touch {
    padding-left: 4rem !important;
  }

  .px-16-touch {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .py-16-touch {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .p-20-touch {
    padding: 5rem !important;
  }

  .pt-20-touch {
    padding-top: 5rem !important;
  }

  .pr-20-touch {
    padding-right: 5rem !important;
  }

  .pb-20-touch {
    padding-bottom: 5rem !important;
  }

  .pl-20-touch {
    padding-left: 5rem !important;
  }

  .px-20-touch {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .py-20-touch {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .p-24-touch {
    padding: 6rem !important;
  }

  .pt-24-touch {
    padding-top: 6rem !important;
  }

  .pr-24-touch {
    padding-right: 6rem !important;
  }

  .pb-24-touch {
    padding-bottom: 6rem !important;
  }

  .pl-24-touch {
    padding-left: 6rem !important;
  }

  .px-24-touch {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .py-24-touch {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .p-28-touch {
    padding: 7rem !important;
  }

  .pt-28-touch {
    padding-top: 7rem !important;
  }

  .pr-28-touch {
    padding-right: 7rem !important;
  }

  .pb-28-touch {
    padding-bottom: 7rem !important;
  }

  .pl-28-touch {
    padding-left: 7rem !important;
  }

  .px-28-touch {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .py-28-touch {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .p-32-touch {
    padding: 8rem !important;
  }

  .pt-32-touch {
    padding-top: 8rem !important;
  }

  .pr-32-touch {
    padding-right: 8rem !important;
  }

  .pb-32-touch {
    padding-bottom: 8rem !important;
  }

  .pl-32-touch {
    padding-left: 8rem !important;
  }

  .px-32-touch {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .py-32-touch {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .p-36-touch {
    padding: 9rem !important;
  }

  .pt-36-touch {
    padding-top: 9rem !important;
  }

  .pr-36-touch {
    padding-right: 9rem !important;
  }

  .pb-36-touch {
    padding-bottom: 9rem !important;
  }

  .pl-36-touch {
    padding-left: 9rem !important;
  }

  .px-36-touch {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .py-36-touch {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .p-40-touch {
    padding: 10rem !important;
  }

  .pt-40-touch {
    padding-top: 10rem !important;
  }

  .pr-40-touch {
    padding-right: 10rem !important;
  }

  .pb-40-touch {
    padding-bottom: 10rem !important;
  }

  .pl-40-touch {
    padding-left: 10rem !important;
  }

  .px-40-touch {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .py-40-touch {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .p-44-touch {
    padding: 11rem !important;
  }

  .pt-44-touch {
    padding-top: 11rem !important;
  }

  .pr-44-touch {
    padding-right: 11rem !important;
  }

  .pb-44-touch {
    padding-bottom: 11rem !important;
  }

  .pl-44-touch {
    padding-left: 11rem !important;
  }

  .px-44-touch {
    padding-left: 11rem !important;
    padding-right: 11rem !important;
  }

  .py-44-touch {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .p-48-touch {
    padding: 12rem !important;
  }

  .pt-48-touch {
    padding-top: 12rem !important;
  }

  .pr-48-touch {
    padding-right: 12rem !important;
  }

  .pb-48-touch {
    padding-bottom: 12rem !important;
  }

  .pl-48-touch {
    padding-left: 12rem !important;
  }

  .px-48-touch {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .py-48-touch {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .p-52-touch {
    padding: 13rem !important;
  }

  .pt-52-touch {
    padding-top: 13rem !important;
  }

  .pr-52-touch {
    padding-right: 13rem !important;
  }

  .pb-52-touch {
    padding-bottom: 13rem !important;
  }

  .pl-52-touch {
    padding-left: 13rem !important;
  }

  .px-52-touch {
    padding-left: 13rem !important;
    padding-right: 13rem !important;
  }

  .py-52-touch {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }

  .p-56-touch {
    padding: 14rem !important;
  }

  .pt-56-touch {
    padding-top: 14rem !important;
  }

  .pr-56-touch {
    padding-right: 14rem !important;
  }

  .pb-56-touch {
    padding-bottom: 14rem !important;
  }

  .pl-56-touch {
    padding-left: 14rem !important;
  }

  .px-56-touch {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .py-56-touch {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .p-60-touch {
    padding: 15rem !important;
  }

  .pt-60-touch {
    padding-top: 15rem !important;
  }

  .pr-60-touch {
    padding-right: 15rem !important;
  }

  .pb-60-touch {
    padding-bottom: 15rem !important;
  }

  .pl-60-touch {
    padding-left: 15rem !important;
  }

  .px-60-touch {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .py-60-touch {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .p-64-touch {
    padding: 16rem !important;
  }

  .pt-64-touch {
    padding-top: 16rem !important;
  }

  .pr-64-touch {
    padding-right: 16rem !important;
  }

  .pb-64-touch {
    padding-bottom: 16rem !important;
  }

  .pl-64-touch {
    padding-left: 16rem !important;
  }

  .px-64-touch {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .py-64-touch {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .p-72-touch {
    padding: 18rem !important;
  }

  .pt-72-touch {
    padding-top: 18rem !important;
  }

  .pr-72-touch {
    padding-right: 18rem !important;
  }

  .pb-72-touch {
    padding-bottom: 18rem !important;
  }

  .pl-72-touch {
    padding-left: 18rem !important;
  }

  .px-72-touch {
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .py-72-touch {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .p-80-touch {
    padding: 20rem !important;
  }

  .pt-80-touch {
    padding-top: 20rem !important;
  }

  .pr-80-touch {
    padding-right: 20rem !important;
  }

  .pb-80-touch {
    padding-bottom: 20rem !important;
  }

  .pl-80-touch {
    padding-left: 20rem !important;
  }

  .px-80-touch {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .py-80-touch {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .p-96-touch {
    padding: 24rem !important;
  }

  .pt-96-touch {
    padding-top: 24rem !important;
  }

  .pr-96-touch {
    padding-right: 24rem !important;
  }

  .pb-96-touch {
    padding-bottom: 24rem !important;
  }

  .pl-96-touch {
    padding-left: 24rem !important;
  }

  .px-96-touch {
    padding-left: 24rem !important;
    padding-right: 24rem !important;
  }

  .py-96-touch {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
}

@media screen and (width >= 1024px) {
  .m-auto-desktop {
    margin: auto !important;
  }

  .mt-auto-desktop {
    margin-top: auto !important;
  }

  .mr-auto-desktop {
    margin-right: auto !important;
  }

  .mb-auto-desktop {
    margin-bottom: auto !important;
  }

  .ml-auto-desktop {
    margin-left: auto !important;
  }

  .mx-auto-desktop {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-auto-desktop {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .m-0-desktop {
    margin: 0 !important;
  }

  .mt-0-desktop {
    margin-top: 0 !important;
  }

  .mr-0-desktop {
    margin-right: 0 !important;
  }

  .mb-0-desktop {
    margin-bottom: 0 !important;
  }

  .ml-0-desktop {
    margin-left: 0 !important;
  }

  .mx-0-desktop {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-0-desktop {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-1-desktop {
    margin: .25rem !important;
  }

  .-m-1-desktop {
    margin: -.25rem !important;
  }

  .mt-1-desktop {
    margin-top: .25rem !important;
  }

  .-mt-1-desktop {
    margin-top: -.25rem !important;
  }

  .mr-1-desktop {
    margin-right: .25rem !important;
  }

  .-mr-1-desktop {
    margin-right: -.25rem !important;
  }

  .mb-1-desktop {
    margin-bottom: .25rem !important;
  }

  .-mb-1-desktop {
    margin-bottom: -.25rem !important;
  }

  .ml-1-desktop {
    margin-left: .25rem !important;
  }

  .-ml-1-desktop {
    margin-left: -.25rem !important;
  }

  .mx-1-desktop {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .-mx-1-desktop {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .my-1-desktop {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .-my-1-desktop {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .m-2-desktop {
    margin: .5rem !important;
  }

  .-m-2-desktop {
    margin: -.5rem !important;
  }

  .mt-2-desktop {
    margin-top: .5rem !important;
  }

  .-mt-2-desktop {
    margin-top: -.5rem !important;
  }

  .mr-2-desktop {
    margin-right: .5rem !important;
  }

  .-mr-2-desktop {
    margin-right: -.5rem !important;
  }

  .mb-2-desktop {
    margin-bottom: .5rem !important;
  }

  .-mb-2-desktop {
    margin-bottom: -.5rem !important;
  }

  .ml-2-desktop {
    margin-left: .5rem !important;
  }

  .-ml-2-desktop {
    margin-left: -.5rem !important;
  }

  .mx-2-desktop {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .-mx-2-desktop {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .my-2-desktop {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .-my-2-desktop {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .m-3-desktop {
    margin: .75rem !important;
  }

  .-m-3-desktop {
    margin: -.75rem !important;
  }

  .mt-3-desktop {
    margin-top: .75rem !important;
  }

  .-mt-3-desktop {
    margin-top: -.75rem !important;
  }

  .mr-3-desktop {
    margin-right: .75rem !important;
  }

  .-mr-3-desktop {
    margin-right: -.75rem !important;
  }

  .mb-3-desktop {
    margin-bottom: .75rem !important;
  }

  .-mb-3-desktop {
    margin-bottom: -.75rem !important;
  }

  .ml-3-desktop {
    margin-left: .75rem !important;
  }

  .-ml-3-desktop {
    margin-left: -.75rem !important;
  }

  .mx-3-desktop {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .-mx-3-desktop {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .my-3-desktop {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .-my-3-desktop {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .m-4-desktop {
    margin: 1rem !important;
  }

  .-m-4-desktop {
    margin: -1rem !important;
  }

  .mt-4-desktop {
    margin-top: 1rem !important;
  }

  .-mt-4-desktop {
    margin-top: -1rem !important;
  }

  .mr-4-desktop {
    margin-right: 1rem !important;
  }

  .-mr-4-desktop {
    margin-right: -1rem !important;
  }

  .mb-4-desktop {
    margin-bottom: 1rem !important;
  }

  .-mb-4-desktop {
    margin-bottom: -1rem !important;
  }

  .ml-4-desktop {
    margin-left: 1rem !important;
  }

  .-ml-4-desktop {
    margin-left: -1rem !important;
  }

  .mx-4-desktop {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .-mx-4-desktop {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .my-4-desktop {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .-my-4-desktop {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .m-5-desktop {
    margin: 1.25rem !important;
  }

  .-m-5-desktop {
    margin: -1.25rem !important;
  }

  .mt-5-desktop {
    margin-top: 1.25rem !important;
  }

  .-mt-5-desktop {
    margin-top: -1.25rem !important;
  }

  .mr-5-desktop {
    margin-right: 1.25rem !important;
  }

  .-mr-5-desktop {
    margin-right: -1.25rem !important;
  }

  .mb-5-desktop {
    margin-bottom: 1.25rem !important;
  }

  .-mb-5-desktop {
    margin-bottom: -1.25rem !important;
  }

  .ml-5-desktop {
    margin-left: 1.25rem !important;
  }

  .-ml-5-desktop {
    margin-left: -1.25rem !important;
  }

  .mx-5-desktop {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .-mx-5-desktop {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .my-5-desktop {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .-my-5-desktop {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .m-6-desktop {
    margin: 1.5rem !important;
  }

  .-m-6-desktop {
    margin: -1.5rem !important;
  }

  .mt-6-desktop {
    margin-top: 1.5rem !important;
  }

  .-mt-6-desktop {
    margin-top: -1.5rem !important;
  }

  .mr-6-desktop {
    margin-right: 1.5rem !important;
  }

  .-mr-6-desktop {
    margin-right: -1.5rem !important;
  }

  .mb-6-desktop {
    margin-bottom: 1.5rem !important;
  }

  .-mb-6-desktop {
    margin-bottom: -1.5rem !important;
  }

  .ml-6-desktop {
    margin-left: 1.5rem !important;
  }

  .-ml-6-desktop {
    margin-left: -1.5rem !important;
  }

  .mx-6-desktop {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .-mx-6-desktop {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .my-6-desktop {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .-my-6-desktop {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .m-7-desktop {
    margin: 1.75rem !important;
  }

  .-m-7-desktop {
    margin: -1.75rem !important;
  }

  .mt-7-desktop {
    margin-top: 1.75rem !important;
  }

  .-mt-7-desktop {
    margin-top: -1.75rem !important;
  }

  .mr-7-desktop {
    margin-right: 1.75rem !important;
  }

  .-mr-7-desktop {
    margin-right: -1.75rem !important;
  }

  .mb-7-desktop {
    margin-bottom: 1.75rem !important;
  }

  .-mb-7-desktop {
    margin-bottom: -1.75rem !important;
  }

  .ml-7-desktop {
    margin-left: 1.75rem !important;
  }

  .-ml-7-desktop {
    margin-left: -1.75rem !important;
  }

  .mx-7-desktop {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .-mx-7-desktop {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .my-7-desktop {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .-my-7-desktop {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .m-8-desktop {
    margin: 2rem !important;
  }

  .-m-8-desktop {
    margin: -2rem !important;
  }

  .mt-8-desktop {
    margin-top: 2rem !important;
  }

  .-mt-8-desktop {
    margin-top: -2rem !important;
  }

  .mr-8-desktop {
    margin-right: 2rem !important;
  }

  .-mr-8-desktop {
    margin-right: -2rem !important;
  }

  .mb-8-desktop {
    margin-bottom: 2rem !important;
  }

  .-mb-8-desktop {
    margin-bottom: -2rem !important;
  }

  .ml-8-desktop {
    margin-left: 2rem !important;
  }

  .-ml-8-desktop {
    margin-left: -2rem !important;
  }

  .mx-8-desktop {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .-mx-8-desktop {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .my-8-desktop {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .-my-8-desktop {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .m-9-desktop {
    margin: 2.25rem !important;
  }

  .-m-9-desktop {
    margin: -2.25rem !important;
  }

  .mt-9-desktop {
    margin-top: 2.25rem !important;
  }

  .-mt-9-desktop {
    margin-top: -2.25rem !important;
  }

  .mr-9-desktop {
    margin-right: 2.25rem !important;
  }

  .-mr-9-desktop {
    margin-right: -2.25rem !important;
  }

  .mb-9-desktop {
    margin-bottom: 2.25rem !important;
  }

  .-mb-9-desktop {
    margin-bottom: -2.25rem !important;
  }

  .ml-9-desktop {
    margin-left: 2.25rem !important;
  }

  .-ml-9-desktop {
    margin-left: -2.25rem !important;
  }

  .mx-9-desktop {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .-mx-9-desktop {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .my-9-desktop {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .-my-9-desktop {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .m-10-desktop {
    margin: 2.5rem !important;
  }

  .-m-10-desktop {
    margin: -2.5rem !important;
  }

  .mt-10-desktop {
    margin-top: 2.5rem !important;
  }

  .-mt-10-desktop {
    margin-top: -2.5rem !important;
  }

  .mr-10-desktop {
    margin-right: 2.5rem !important;
  }

  .-mr-10-desktop {
    margin-right: -2.5rem !important;
  }

  .mb-10-desktop {
    margin-bottom: 2.5rem !important;
  }

  .-mb-10-desktop {
    margin-bottom: -2.5rem !important;
  }

  .ml-10-desktop {
    margin-left: 2.5rem !important;
  }

  .-ml-10-desktop {
    margin-left: -2.5rem !important;
  }

  .mx-10-desktop {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .-mx-10-desktop {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .my-10-desktop {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .-my-10-desktop {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .m-11-desktop {
    margin: 2.75rem !important;
  }

  .-m-11-desktop {
    margin: -2.75rem !important;
  }

  .mt-11-desktop {
    margin-top: 2.75rem !important;
  }

  .-mt-11-desktop {
    margin-top: -2.75rem !important;
  }

  .mr-11-desktop {
    margin-right: 2.75rem !important;
  }

  .-mr-11-desktop {
    margin-right: -2.75rem !important;
  }

  .mb-11-desktop {
    margin-bottom: 2.75rem !important;
  }

  .-mb-11-desktop {
    margin-bottom: -2.75rem !important;
  }

  .ml-11-desktop {
    margin-left: 2.75rem !important;
  }

  .-ml-11-desktop {
    margin-left: -2.75rem !important;
  }

  .mx-11-desktop {
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
  }

  .-mx-11-desktop {
    margin-left: -2.75rem !important;
    margin-right: -2.75rem !important;
  }

  .my-11-desktop {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .-my-11-desktop {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .m-12-desktop {
    margin: 3rem !important;
  }

  .-m-12-desktop {
    margin: -3rem !important;
  }

  .mt-12-desktop {
    margin-top: 3rem !important;
  }

  .-mt-12-desktop {
    margin-top: -3rem !important;
  }

  .mr-12-desktop {
    margin-right: 3rem !important;
  }

  .-mr-12-desktop {
    margin-right: -3rem !important;
  }

  .mb-12-desktop {
    margin-bottom: 3rem !important;
  }

  .-mb-12-desktop {
    margin-bottom: -3rem !important;
  }

  .ml-12-desktop {
    margin-left: 3rem !important;
  }

  .-ml-12-desktop {
    margin-left: -3rem !important;
  }

  .mx-12-desktop {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .-mx-12-desktop {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-12-desktop {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .-my-12-desktop {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .m-14-desktop {
    margin: 3.5rem !important;
  }

  .-m-14-desktop {
    margin: -3.5rem !important;
  }

  .mt-14-desktop {
    margin-top: 3.5rem !important;
  }

  .-mt-14-desktop {
    margin-top: -3.5rem !important;
  }

  .mr-14-desktop {
    margin-right: 3.5rem !important;
  }

  .-mr-14-desktop {
    margin-right: -3.5rem !important;
  }

  .mb-14-desktop {
    margin-bottom: 3.5rem !important;
  }

  .-mb-14-desktop {
    margin-bottom: -3.5rem !important;
  }

  .ml-14-desktop {
    margin-left: 3.5rem !important;
  }

  .-ml-14-desktop {
    margin-left: -3.5rem !important;
  }

  .mx-14-desktop {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .-mx-14-desktop {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .my-14-desktop {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .-my-14-desktop {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .m-16-desktop {
    margin: 4rem !important;
  }

  .-m-16-desktop {
    margin: -4rem !important;
  }

  .mt-16-desktop {
    margin-top: 4rem !important;
  }

  .-mt-16-desktop {
    margin-top: -4rem !important;
  }

  .mr-16-desktop {
    margin-right: 4rem !important;
  }

  .-mr-16-desktop {
    margin-right: -4rem !important;
  }

  .mb-16-desktop {
    margin-bottom: 4rem !important;
  }

  .-mb-16-desktop {
    margin-bottom: -4rem !important;
  }

  .ml-16-desktop {
    margin-left: 4rem !important;
  }

  .-ml-16-desktop {
    margin-left: -4rem !important;
  }

  .mx-16-desktop {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .-mx-16-desktop {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .my-16-desktop {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .-my-16-desktop {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .m-20-desktop {
    margin: 5rem !important;
  }

  .-m-20-desktop {
    margin: -5rem !important;
  }

  .mt-20-desktop {
    margin-top: 5rem !important;
  }

  .-mt-20-desktop {
    margin-top: -5rem !important;
  }

  .mr-20-desktop {
    margin-right: 5rem !important;
  }

  .-mr-20-desktop {
    margin-right: -5rem !important;
  }

  .mb-20-desktop {
    margin-bottom: 5rem !important;
  }

  .-mb-20-desktop {
    margin-bottom: -5rem !important;
  }

  .ml-20-desktop {
    margin-left: 5rem !important;
  }

  .-ml-20-desktop {
    margin-left: -5rem !important;
  }

  .mx-20-desktop {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .-mx-20-desktop {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .my-20-desktop {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .-my-20-desktop {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .m-24-desktop {
    margin: 6rem !important;
  }

  .-m-24-desktop {
    margin: -6rem !important;
  }

  .mt-24-desktop {
    margin-top: 6rem !important;
  }

  .-mt-24-desktop {
    margin-top: -6rem !important;
  }

  .mr-24-desktop {
    margin-right: 6rem !important;
  }

  .-mr-24-desktop {
    margin-right: -6rem !important;
  }

  .mb-24-desktop {
    margin-bottom: 6rem !important;
  }

  .-mb-24-desktop {
    margin-bottom: -6rem !important;
  }

  .ml-24-desktop {
    margin-left: 6rem !important;
  }

  .-ml-24-desktop {
    margin-left: -6rem !important;
  }

  .mx-24-desktop {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .-mx-24-desktop {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .my-24-desktop {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .-my-24-desktop {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .m-28-desktop {
    margin: 7rem !important;
  }

  .-m-28-desktop {
    margin: -7rem !important;
  }

  .mt-28-desktop {
    margin-top: 7rem !important;
  }

  .-mt-28-desktop {
    margin-top: -7rem !important;
  }

  .mr-28-desktop {
    margin-right: 7rem !important;
  }

  .-mr-28-desktop {
    margin-right: -7rem !important;
  }

  .mb-28-desktop {
    margin-bottom: 7rem !important;
  }

  .-mb-28-desktop {
    margin-bottom: -7rem !important;
  }

  .ml-28-desktop {
    margin-left: 7rem !important;
  }

  .-ml-28-desktop {
    margin-left: -7rem !important;
  }

  .mx-28-desktop {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .-mx-28-desktop {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .my-28-desktop {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .-my-28-desktop {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .m-32-desktop {
    margin: 8rem !important;
  }

  .-m-32-desktop {
    margin: -8rem !important;
  }

  .mt-32-desktop {
    margin-top: 8rem !important;
  }

  .-mt-32-desktop {
    margin-top: -8rem !important;
  }

  .mr-32-desktop {
    margin-right: 8rem !important;
  }

  .-mr-32-desktop {
    margin-right: -8rem !important;
  }

  .mb-32-desktop {
    margin-bottom: 8rem !important;
  }

  .-mb-32-desktop {
    margin-bottom: -8rem !important;
  }

  .ml-32-desktop {
    margin-left: 8rem !important;
  }

  .-ml-32-desktop {
    margin-left: -8rem !important;
  }

  .mx-32-desktop {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .-mx-32-desktop {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .my-32-desktop {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .-my-32-desktop {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .m-36-desktop {
    margin: 9rem !important;
  }

  .-m-36-desktop {
    margin: -9rem !important;
  }

  .mt-36-desktop {
    margin-top: 9rem !important;
  }

  .-mt-36-desktop {
    margin-top: -9rem !important;
  }

  .mr-36-desktop {
    margin-right: 9rem !important;
  }

  .-mr-36-desktop {
    margin-right: -9rem !important;
  }

  .mb-36-desktop {
    margin-bottom: 9rem !important;
  }

  .-mb-36-desktop {
    margin-bottom: -9rem !important;
  }

  .ml-36-desktop {
    margin-left: 9rem !important;
  }

  .-ml-36-desktop {
    margin-left: -9rem !important;
  }

  .mx-36-desktop {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .-mx-36-desktop {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .my-36-desktop {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .-my-36-desktop {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .m-40-desktop {
    margin: 10rem !important;
  }

  .-m-40-desktop {
    margin: -10rem !important;
  }

  .mt-40-desktop {
    margin-top: 10rem !important;
  }

  .-mt-40-desktop {
    margin-top: -10rem !important;
  }

  .mr-40-desktop {
    margin-right: 10rem !important;
  }

  .-mr-40-desktop {
    margin-right: -10rem !important;
  }

  .mb-40-desktop {
    margin-bottom: 10rem !important;
  }

  .-mb-40-desktop {
    margin-bottom: -10rem !important;
  }

  .ml-40-desktop {
    margin-left: 10rem !important;
  }

  .-ml-40-desktop {
    margin-left: -10rem !important;
  }

  .mx-40-desktop {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .-mx-40-desktop {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .my-40-desktop {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .-my-40-desktop {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .m-44-desktop {
    margin: 11rem !important;
  }

  .-m-44-desktop {
    margin: -11rem !important;
  }

  .mt-44-desktop {
    margin-top: 11rem !important;
  }

  .-mt-44-desktop {
    margin-top: -11rem !important;
  }

  .mr-44-desktop {
    margin-right: 11rem !important;
  }

  .-mr-44-desktop {
    margin-right: -11rem !important;
  }

  .mb-44-desktop {
    margin-bottom: 11rem !important;
  }

  .-mb-44-desktop {
    margin-bottom: -11rem !important;
  }

  .ml-44-desktop {
    margin-left: 11rem !important;
  }

  .-ml-44-desktop {
    margin-left: -11rem !important;
  }

  .mx-44-desktop {
    margin-left: 11rem !important;
    margin-right: 11rem !important;
  }

  .-mx-44-desktop {
    margin-left: -11rem !important;
    margin-right: -11rem !important;
  }

  .my-44-desktop {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .-my-44-desktop {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .m-48-desktop {
    margin: 12rem !important;
  }

  .-m-48-desktop {
    margin: -12rem !important;
  }

  .mt-48-desktop {
    margin-top: 12rem !important;
  }

  .-mt-48-desktop {
    margin-top: -12rem !important;
  }

  .mr-48-desktop {
    margin-right: 12rem !important;
  }

  .-mr-48-desktop {
    margin-right: -12rem !important;
  }

  .mb-48-desktop {
    margin-bottom: 12rem !important;
  }

  .-mb-48-desktop {
    margin-bottom: -12rem !important;
  }

  .ml-48-desktop {
    margin-left: 12rem !important;
  }

  .-ml-48-desktop {
    margin-left: -12rem !important;
  }

  .mx-48-desktop {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .-mx-48-desktop {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .my-48-desktop {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .-my-48-desktop {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .m-52-desktop {
    margin: 13rem !important;
  }

  .-m-52-desktop {
    margin: -13rem !important;
  }

  .mt-52-desktop {
    margin-top: 13rem !important;
  }

  .-mt-52-desktop {
    margin-top: -13rem !important;
  }

  .mr-52-desktop {
    margin-right: 13rem !important;
  }

  .-mr-52-desktop {
    margin-right: -13rem !important;
  }

  .mb-52-desktop {
    margin-bottom: 13rem !important;
  }

  .-mb-52-desktop {
    margin-bottom: -13rem !important;
  }

  .ml-52-desktop {
    margin-left: 13rem !important;
  }

  .-ml-52-desktop {
    margin-left: -13rem !important;
  }

  .mx-52-desktop {
    margin-left: 13rem !important;
    margin-right: 13rem !important;
  }

  .-mx-52-desktop {
    margin-left: -13rem !important;
    margin-right: -13rem !important;
  }

  .my-52-desktop {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }

  .-my-52-desktop {
    margin-top: -13rem !important;
    margin-bottom: -13rem !important;
  }

  .m-56-desktop {
    margin: 14rem !important;
  }

  .-m-56-desktop {
    margin: -14rem !important;
  }

  .mt-56-desktop {
    margin-top: 14rem !important;
  }

  .-mt-56-desktop {
    margin-top: -14rem !important;
  }

  .mr-56-desktop {
    margin-right: 14rem !important;
  }

  .-mr-56-desktop {
    margin-right: -14rem !important;
  }

  .mb-56-desktop {
    margin-bottom: 14rem !important;
  }

  .-mb-56-desktop {
    margin-bottom: -14rem !important;
  }

  .ml-56-desktop {
    margin-left: 14rem !important;
  }

  .-ml-56-desktop {
    margin-left: -14rem !important;
  }

  .mx-56-desktop {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .-mx-56-desktop {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .my-56-desktop {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .-my-56-desktop {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .m-60-desktop {
    margin: 15rem !important;
  }

  .-m-60-desktop {
    margin: -15rem !important;
  }

  .mt-60-desktop {
    margin-top: 15rem !important;
  }

  .-mt-60-desktop {
    margin-top: -15rem !important;
  }

  .mr-60-desktop {
    margin-right: 15rem !important;
  }

  .-mr-60-desktop {
    margin-right: -15rem !important;
  }

  .mb-60-desktop {
    margin-bottom: 15rem !important;
  }

  .-mb-60-desktop {
    margin-bottom: -15rem !important;
  }

  .ml-60-desktop {
    margin-left: 15rem !important;
  }

  .-ml-60-desktop {
    margin-left: -15rem !important;
  }

  .mx-60-desktop {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .-mx-60-desktop {
    margin-left: -15rem !important;
    margin-right: -15rem !important;
  }

  .my-60-desktop {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .-my-60-desktop {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .m-64-desktop {
    margin: 16rem !important;
  }

  .-m-64-desktop {
    margin: -16rem !important;
  }

  .mt-64-desktop {
    margin-top: 16rem !important;
  }

  .-mt-64-desktop {
    margin-top: -16rem !important;
  }

  .mr-64-desktop {
    margin-right: 16rem !important;
  }

  .-mr-64-desktop {
    margin-right: -16rem !important;
  }

  .mb-64-desktop {
    margin-bottom: 16rem !important;
  }

  .-mb-64-desktop {
    margin-bottom: -16rem !important;
  }

  .ml-64-desktop {
    margin-left: 16rem !important;
  }

  .-ml-64-desktop {
    margin-left: -16rem !important;
  }

  .mx-64-desktop {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .-mx-64-desktop {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .my-64-desktop {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .-my-64-desktop {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .m-72-desktop {
    margin: 18rem !important;
  }

  .-m-72-desktop {
    margin: -18rem !important;
  }

  .mt-72-desktop {
    margin-top: 18rem !important;
  }

  .-mt-72-desktop {
    margin-top: -18rem !important;
  }

  .mr-72-desktop {
    margin-right: 18rem !important;
  }

  .-mr-72-desktop {
    margin-right: -18rem !important;
  }

  .mb-72-desktop {
    margin-bottom: 18rem !important;
  }

  .-mb-72-desktop {
    margin-bottom: -18rem !important;
  }

  .ml-72-desktop {
    margin-left: 18rem !important;
  }

  .-ml-72-desktop {
    margin-left: -18rem !important;
  }

  .mx-72-desktop {
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .-mx-72-desktop {
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .my-72-desktop {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .-my-72-desktop {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .m-80-desktop {
    margin: 20rem !important;
  }

  .-m-80-desktop {
    margin: -20rem !important;
  }

  .mt-80-desktop {
    margin-top: 20rem !important;
  }

  .-mt-80-desktop {
    margin-top: -20rem !important;
  }

  .mr-80-desktop {
    margin-right: 20rem !important;
  }

  .-mr-80-desktop {
    margin-right: -20rem !important;
  }

  .mb-80-desktop {
    margin-bottom: 20rem !important;
  }

  .-mb-80-desktop {
    margin-bottom: -20rem !important;
  }

  .ml-80-desktop {
    margin-left: 20rem !important;
  }

  .-ml-80-desktop {
    margin-left: -20rem !important;
  }

  .mx-80-desktop {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .-mx-80-desktop {
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .my-80-desktop {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .-my-80-desktop {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .m-96-desktop {
    margin: 24rem !important;
  }

  .-m-96-desktop {
    margin: -24rem !important;
  }

  .mt-96-desktop {
    margin-top: 24rem !important;
  }

  .-mt-96-desktop {
    margin-top: -24rem !important;
  }

  .mr-96-desktop {
    margin-right: 24rem !important;
  }

  .-mr-96-desktop {
    margin-right: -24rem !important;
  }

  .mb-96-desktop {
    margin-bottom: 24rem !important;
  }

  .-mb-96-desktop {
    margin-bottom: -24rem !important;
  }

  .ml-96-desktop {
    margin-left: 24rem !important;
  }

  .-ml-96-desktop {
    margin-left: -24rem !important;
  }

  .mx-96-desktop {
    margin-left: 24rem !important;
    margin-right: 24rem !important;
  }

  .-mx-96-desktop {
    margin-left: -24rem !important;
    margin-right: -24rem !important;
  }

  .my-96-desktop {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }

  .-my-96-desktop {
    margin-top: -24rem !important;
    margin-bottom: -24rem !important;
  }

  .p-auto-desktop {
    padding: auto !important;
  }

  .pt-auto-desktop {
    padding-top: auto !important;
  }

  .pr-auto-desktop {
    padding-right: auto !important;
  }

  .pb-auto-desktop {
    padding-bottom: auto !important;
  }

  .pl-auto-desktop {
    padding-left: auto !important;
  }

  .px-auto-desktop {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .py-auto-desktop {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }

  .p-0-desktop {
    padding: 0 !important;
  }

  .pt-0-desktop {
    padding-top: 0 !important;
  }

  .pr-0-desktop {
    padding-right: 0 !important;
  }

  .pb-0-desktop {
    padding-bottom: 0 !important;
  }

  .pl-0-desktop {
    padding-left: 0 !important;
  }

  .px-0-desktop {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-0-desktop {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-1-desktop {
    padding: .25rem !important;
  }

  .pt-1-desktop {
    padding-top: .25rem !important;
  }

  .pr-1-desktop {
    padding-right: .25rem !important;
  }

  .pb-1-desktop {
    padding-bottom: .25rem !important;
  }

  .pl-1-desktop {
    padding-left: .25rem !important;
  }

  .px-1-desktop {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .py-1-desktop {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .p-2-desktop {
    padding: .5rem !important;
  }

  .pt-2-desktop {
    padding-top: .5rem !important;
  }

  .pr-2-desktop {
    padding-right: .5rem !important;
  }

  .pb-2-desktop {
    padding-bottom: .5rem !important;
  }

  .pl-2-desktop {
    padding-left: .5rem !important;
  }

  .px-2-desktop {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .py-2-desktop {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .p-3-desktop {
    padding: .75rem !important;
  }

  .pt-3-desktop {
    padding-top: .75rem !important;
  }

  .pr-3-desktop {
    padding-right: .75rem !important;
  }

  .pb-3-desktop {
    padding-bottom: .75rem !important;
  }

  .pl-3-desktop {
    padding-left: .75rem !important;
  }

  .px-3-desktop {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .py-3-desktop {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .p-4-desktop {
    padding: 1rem !important;
  }

  .pt-4-desktop {
    padding-top: 1rem !important;
  }

  .pr-4-desktop {
    padding-right: 1rem !important;
  }

  .pb-4-desktop {
    padding-bottom: 1rem !important;
  }

  .pl-4-desktop {
    padding-left: 1rem !important;
  }

  .px-4-desktop {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .py-4-desktop {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .p-5-desktop {
    padding: 1.25rem !important;
  }

  .pt-5-desktop {
    padding-top: 1.25rem !important;
  }

  .pr-5-desktop {
    padding-right: 1.25rem !important;
  }

  .pb-5-desktop {
    padding-bottom: 1.25rem !important;
  }

  .pl-5-desktop {
    padding-left: 1.25rem !important;
  }

  .px-5-desktop {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .py-5-desktop {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .p-6-desktop {
    padding: 1.5rem !important;
  }

  .pt-6-desktop {
    padding-top: 1.5rem !important;
  }

  .pr-6-desktop {
    padding-right: 1.5rem !important;
  }

  .pb-6-desktop {
    padding-bottom: 1.5rem !important;
  }

  .pl-6-desktop {
    padding-left: 1.5rem !important;
  }

  .px-6-desktop {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .py-6-desktop {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .p-7-desktop {
    padding: 1.75rem !important;
  }

  .pt-7-desktop {
    padding-top: 1.75rem !important;
  }

  .pr-7-desktop {
    padding-right: 1.75rem !important;
  }

  .pb-7-desktop {
    padding-bottom: 1.75rem !important;
  }

  .pl-7-desktop {
    padding-left: 1.75rem !important;
  }

  .px-7-desktop {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .py-7-desktop {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .p-8-desktop {
    padding: 2rem !important;
  }

  .pt-8-desktop {
    padding-top: 2rem !important;
  }

  .pr-8-desktop {
    padding-right: 2rem !important;
  }

  .pb-8-desktop {
    padding-bottom: 2rem !important;
  }

  .pl-8-desktop {
    padding-left: 2rem !important;
  }

  .px-8-desktop {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .py-8-desktop {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .p-9-desktop {
    padding: 2.25rem !important;
  }

  .pt-9-desktop {
    padding-top: 2.25rem !important;
  }

  .pr-9-desktop {
    padding-right: 2.25rem !important;
  }

  .pb-9-desktop {
    padding-bottom: 2.25rem !important;
  }

  .pl-9-desktop {
    padding-left: 2.25rem !important;
  }

  .px-9-desktop {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .py-9-desktop {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .p-10-desktop {
    padding: 2.5rem !important;
  }

  .pt-10-desktop {
    padding-top: 2.5rem !important;
  }

  .pr-10-desktop {
    padding-right: 2.5rem !important;
  }

  .pb-10-desktop {
    padding-bottom: 2.5rem !important;
  }

  .pl-10-desktop {
    padding-left: 2.5rem !important;
  }

  .px-10-desktop {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .py-10-desktop {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .p-11-desktop {
    padding: 2.75rem !important;
  }

  .pt-11-desktop {
    padding-top: 2.75rem !important;
  }

  .pr-11-desktop {
    padding-right: 2.75rem !important;
  }

  .pb-11-desktop {
    padding-bottom: 2.75rem !important;
  }

  .pl-11-desktop {
    padding-left: 2.75rem !important;
  }

  .px-11-desktop {
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  .py-11-desktop {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .p-12-desktop {
    padding: 3rem !important;
  }

  .pt-12-desktop {
    padding-top: 3rem !important;
  }

  .pr-12-desktop {
    padding-right: 3rem !important;
  }

  .pb-12-desktop {
    padding-bottom: 3rem !important;
  }

  .pl-12-desktop {
    padding-left: 3rem !important;
  }

  .px-12-desktop {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-12-desktop {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .p-14-desktop {
    padding: 3.5rem !important;
  }

  .pt-14-desktop {
    padding-top: 3.5rem !important;
  }

  .pr-14-desktop {
    padding-right: 3.5rem !important;
  }

  .pb-14-desktop {
    padding-bottom: 3.5rem !important;
  }

  .pl-14-desktop {
    padding-left: 3.5rem !important;
  }

  .px-14-desktop {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .py-14-desktop {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .p-16-desktop {
    padding: 4rem !important;
  }

  .pt-16-desktop {
    padding-top: 4rem !important;
  }

  .pr-16-desktop {
    padding-right: 4rem !important;
  }

  .pb-16-desktop {
    padding-bottom: 4rem !important;
  }

  .pl-16-desktop {
    padding-left: 4rem !important;
  }

  .px-16-desktop {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .py-16-desktop {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .p-20-desktop {
    padding: 5rem !important;
  }

  .pt-20-desktop {
    padding-top: 5rem !important;
  }

  .pr-20-desktop {
    padding-right: 5rem !important;
  }

  .pb-20-desktop {
    padding-bottom: 5rem !important;
  }

  .pl-20-desktop {
    padding-left: 5rem !important;
  }

  .px-20-desktop {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .py-20-desktop {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .p-24-desktop {
    padding: 6rem !important;
  }

  .pt-24-desktop {
    padding-top: 6rem !important;
  }

  .pr-24-desktop {
    padding-right: 6rem !important;
  }

  .pb-24-desktop {
    padding-bottom: 6rem !important;
  }

  .pl-24-desktop {
    padding-left: 6rem !important;
  }

  .px-24-desktop {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .py-24-desktop {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .p-28-desktop {
    padding: 7rem !important;
  }

  .pt-28-desktop {
    padding-top: 7rem !important;
  }

  .pr-28-desktop {
    padding-right: 7rem !important;
  }

  .pb-28-desktop {
    padding-bottom: 7rem !important;
  }

  .pl-28-desktop {
    padding-left: 7rem !important;
  }

  .px-28-desktop {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .py-28-desktop {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .p-32-desktop {
    padding: 8rem !important;
  }

  .pt-32-desktop {
    padding-top: 8rem !important;
  }

  .pr-32-desktop {
    padding-right: 8rem !important;
  }

  .pb-32-desktop {
    padding-bottom: 8rem !important;
  }

  .pl-32-desktop {
    padding-left: 8rem !important;
  }

  .px-32-desktop {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .py-32-desktop {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .p-36-desktop {
    padding: 9rem !important;
  }

  .pt-36-desktop {
    padding-top: 9rem !important;
  }

  .pr-36-desktop {
    padding-right: 9rem !important;
  }

  .pb-36-desktop {
    padding-bottom: 9rem !important;
  }

  .pl-36-desktop {
    padding-left: 9rem !important;
  }

  .px-36-desktop {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .py-36-desktop {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .p-40-desktop {
    padding: 10rem !important;
  }

  .pt-40-desktop {
    padding-top: 10rem !important;
  }

  .pr-40-desktop {
    padding-right: 10rem !important;
  }

  .pb-40-desktop {
    padding-bottom: 10rem !important;
  }

  .pl-40-desktop {
    padding-left: 10rem !important;
  }

  .px-40-desktop {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .py-40-desktop {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .p-44-desktop {
    padding: 11rem !important;
  }

  .pt-44-desktop {
    padding-top: 11rem !important;
  }

  .pr-44-desktop {
    padding-right: 11rem !important;
  }

  .pb-44-desktop {
    padding-bottom: 11rem !important;
  }

  .pl-44-desktop {
    padding-left: 11rem !important;
  }

  .px-44-desktop {
    padding-left: 11rem !important;
    padding-right: 11rem !important;
  }

  .py-44-desktop {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .p-48-desktop {
    padding: 12rem !important;
  }

  .pt-48-desktop {
    padding-top: 12rem !important;
  }

  .pr-48-desktop {
    padding-right: 12rem !important;
  }

  .pb-48-desktop {
    padding-bottom: 12rem !important;
  }

  .pl-48-desktop {
    padding-left: 12rem !important;
  }

  .px-48-desktop {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .py-48-desktop {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .p-52-desktop {
    padding: 13rem !important;
  }

  .pt-52-desktop {
    padding-top: 13rem !important;
  }

  .pr-52-desktop {
    padding-right: 13rem !important;
  }

  .pb-52-desktop {
    padding-bottom: 13rem !important;
  }

  .pl-52-desktop {
    padding-left: 13rem !important;
  }

  .px-52-desktop {
    padding-left: 13rem !important;
    padding-right: 13rem !important;
  }

  .py-52-desktop {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }

  .p-56-desktop {
    padding: 14rem !important;
  }

  .pt-56-desktop {
    padding-top: 14rem !important;
  }

  .pr-56-desktop {
    padding-right: 14rem !important;
  }

  .pb-56-desktop {
    padding-bottom: 14rem !important;
  }

  .pl-56-desktop {
    padding-left: 14rem !important;
  }

  .px-56-desktop {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .py-56-desktop {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .p-60-desktop {
    padding: 15rem !important;
  }

  .pt-60-desktop {
    padding-top: 15rem !important;
  }

  .pr-60-desktop {
    padding-right: 15rem !important;
  }

  .pb-60-desktop {
    padding-bottom: 15rem !important;
  }

  .pl-60-desktop {
    padding-left: 15rem !important;
  }

  .px-60-desktop {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .py-60-desktop {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .p-64-desktop {
    padding: 16rem !important;
  }

  .pt-64-desktop {
    padding-top: 16rem !important;
  }

  .pr-64-desktop {
    padding-right: 16rem !important;
  }

  .pb-64-desktop {
    padding-bottom: 16rem !important;
  }

  .pl-64-desktop {
    padding-left: 16rem !important;
  }

  .px-64-desktop {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .py-64-desktop {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .p-72-desktop {
    padding: 18rem !important;
  }

  .pt-72-desktop {
    padding-top: 18rem !important;
  }

  .pr-72-desktop {
    padding-right: 18rem !important;
  }

  .pb-72-desktop {
    padding-bottom: 18rem !important;
  }

  .pl-72-desktop {
    padding-left: 18rem !important;
  }

  .px-72-desktop {
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .py-72-desktop {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .p-80-desktop {
    padding: 20rem !important;
  }

  .pt-80-desktop {
    padding-top: 20rem !important;
  }

  .pr-80-desktop {
    padding-right: 20rem !important;
  }

  .pb-80-desktop {
    padding-bottom: 20rem !important;
  }

  .pl-80-desktop {
    padding-left: 20rem !important;
  }

  .px-80-desktop {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .py-80-desktop {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .p-96-desktop {
    padding: 24rem !important;
  }

  .pt-96-desktop {
    padding-top: 24rem !important;
  }

  .pr-96-desktop {
    padding-right: 24rem !important;
  }

  .pb-96-desktop {
    padding-bottom: 24rem !important;
  }

  .pl-96-desktop {
    padding-left: 24rem !important;
  }

  .px-96-desktop {
    padding-left: 24rem !important;
    padding-right: 24rem !important;
  }

  .py-96-desktop {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .m-auto-desktop-only {
    margin: auto !important;
  }

  .mt-auto-desktop-only {
    margin-top: auto !important;
  }

  .mr-auto-desktop-only {
    margin-right: auto !important;
  }

  .mb-auto-desktop-only {
    margin-bottom: auto !important;
  }

  .ml-auto-desktop-only {
    margin-left: auto !important;
  }

  .mx-auto-desktop-only {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-auto-desktop-only {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .m-0-desktop-only {
    margin: 0 !important;
  }

  .mt-0-desktop-only {
    margin-top: 0 !important;
  }

  .mr-0-desktop-only {
    margin-right: 0 !important;
  }

  .mb-0-desktop-only {
    margin-bottom: 0 !important;
  }

  .ml-0-desktop-only {
    margin-left: 0 !important;
  }

  .mx-0-desktop-only {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-0-desktop-only {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-1-desktop-only {
    margin: .25rem !important;
  }

  .-m-1-desktop-only {
    margin: -.25rem !important;
  }

  .mt-1-desktop-only {
    margin-top: .25rem !important;
  }

  .-mt-1-desktop-only {
    margin-top: -.25rem !important;
  }

  .mr-1-desktop-only {
    margin-right: .25rem !important;
  }

  .-mr-1-desktop-only {
    margin-right: -.25rem !important;
  }

  .mb-1-desktop-only {
    margin-bottom: .25rem !important;
  }

  .-mb-1-desktop-only {
    margin-bottom: -.25rem !important;
  }

  .ml-1-desktop-only {
    margin-left: .25rem !important;
  }

  .-ml-1-desktop-only {
    margin-left: -.25rem !important;
  }

  .mx-1-desktop-only {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .-mx-1-desktop-only {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .my-1-desktop-only {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .-my-1-desktop-only {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .m-2-desktop-only {
    margin: .5rem !important;
  }

  .-m-2-desktop-only {
    margin: -.5rem !important;
  }

  .mt-2-desktop-only {
    margin-top: .5rem !important;
  }

  .-mt-2-desktop-only {
    margin-top: -.5rem !important;
  }

  .mr-2-desktop-only {
    margin-right: .5rem !important;
  }

  .-mr-2-desktop-only {
    margin-right: -.5rem !important;
  }

  .mb-2-desktop-only {
    margin-bottom: .5rem !important;
  }

  .-mb-2-desktop-only {
    margin-bottom: -.5rem !important;
  }

  .ml-2-desktop-only {
    margin-left: .5rem !important;
  }

  .-ml-2-desktop-only {
    margin-left: -.5rem !important;
  }

  .mx-2-desktop-only {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .-mx-2-desktop-only {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .my-2-desktop-only {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .-my-2-desktop-only {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .m-3-desktop-only {
    margin: .75rem !important;
  }

  .-m-3-desktop-only {
    margin: -.75rem !important;
  }

  .mt-3-desktop-only {
    margin-top: .75rem !important;
  }

  .-mt-3-desktop-only {
    margin-top: -.75rem !important;
  }

  .mr-3-desktop-only {
    margin-right: .75rem !important;
  }

  .-mr-3-desktop-only {
    margin-right: -.75rem !important;
  }

  .mb-3-desktop-only {
    margin-bottom: .75rem !important;
  }

  .-mb-3-desktop-only {
    margin-bottom: -.75rem !important;
  }

  .ml-3-desktop-only {
    margin-left: .75rem !important;
  }

  .-ml-3-desktop-only {
    margin-left: -.75rem !important;
  }

  .mx-3-desktop-only {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .-mx-3-desktop-only {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .my-3-desktop-only {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .-my-3-desktop-only {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .m-4-desktop-only {
    margin: 1rem !important;
  }

  .-m-4-desktop-only {
    margin: -1rem !important;
  }

  .mt-4-desktop-only {
    margin-top: 1rem !important;
  }

  .-mt-4-desktop-only {
    margin-top: -1rem !important;
  }

  .mr-4-desktop-only {
    margin-right: 1rem !important;
  }

  .-mr-4-desktop-only {
    margin-right: -1rem !important;
  }

  .mb-4-desktop-only {
    margin-bottom: 1rem !important;
  }

  .-mb-4-desktop-only {
    margin-bottom: -1rem !important;
  }

  .ml-4-desktop-only {
    margin-left: 1rem !important;
  }

  .-ml-4-desktop-only {
    margin-left: -1rem !important;
  }

  .mx-4-desktop-only {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .-mx-4-desktop-only {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .my-4-desktop-only {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .-my-4-desktop-only {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .m-5-desktop-only {
    margin: 1.25rem !important;
  }

  .-m-5-desktop-only {
    margin: -1.25rem !important;
  }

  .mt-5-desktop-only {
    margin-top: 1.25rem !important;
  }

  .-mt-5-desktop-only {
    margin-top: -1.25rem !important;
  }

  .mr-5-desktop-only {
    margin-right: 1.25rem !important;
  }

  .-mr-5-desktop-only {
    margin-right: -1.25rem !important;
  }

  .mb-5-desktop-only {
    margin-bottom: 1.25rem !important;
  }

  .-mb-5-desktop-only {
    margin-bottom: -1.25rem !important;
  }

  .ml-5-desktop-only {
    margin-left: 1.25rem !important;
  }

  .-ml-5-desktop-only {
    margin-left: -1.25rem !important;
  }

  .mx-5-desktop-only {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .-mx-5-desktop-only {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .my-5-desktop-only {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .-my-5-desktop-only {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .m-6-desktop-only {
    margin: 1.5rem !important;
  }

  .-m-6-desktop-only {
    margin: -1.5rem !important;
  }

  .mt-6-desktop-only {
    margin-top: 1.5rem !important;
  }

  .-mt-6-desktop-only {
    margin-top: -1.5rem !important;
  }

  .mr-6-desktop-only {
    margin-right: 1.5rem !important;
  }

  .-mr-6-desktop-only {
    margin-right: -1.5rem !important;
  }

  .mb-6-desktop-only {
    margin-bottom: 1.5rem !important;
  }

  .-mb-6-desktop-only {
    margin-bottom: -1.5rem !important;
  }

  .ml-6-desktop-only {
    margin-left: 1.5rem !important;
  }

  .-ml-6-desktop-only {
    margin-left: -1.5rem !important;
  }

  .mx-6-desktop-only {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .-mx-6-desktop-only {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .my-6-desktop-only {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .-my-6-desktop-only {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .m-7-desktop-only {
    margin: 1.75rem !important;
  }

  .-m-7-desktop-only {
    margin: -1.75rem !important;
  }

  .mt-7-desktop-only {
    margin-top: 1.75rem !important;
  }

  .-mt-7-desktop-only {
    margin-top: -1.75rem !important;
  }

  .mr-7-desktop-only {
    margin-right: 1.75rem !important;
  }

  .-mr-7-desktop-only {
    margin-right: -1.75rem !important;
  }

  .mb-7-desktop-only {
    margin-bottom: 1.75rem !important;
  }

  .-mb-7-desktop-only {
    margin-bottom: -1.75rem !important;
  }

  .ml-7-desktop-only {
    margin-left: 1.75rem !important;
  }

  .-ml-7-desktop-only {
    margin-left: -1.75rem !important;
  }

  .mx-7-desktop-only {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .-mx-7-desktop-only {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .my-7-desktop-only {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .-my-7-desktop-only {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .m-8-desktop-only {
    margin: 2rem !important;
  }

  .-m-8-desktop-only {
    margin: -2rem !important;
  }

  .mt-8-desktop-only {
    margin-top: 2rem !important;
  }

  .-mt-8-desktop-only {
    margin-top: -2rem !important;
  }

  .mr-8-desktop-only {
    margin-right: 2rem !important;
  }

  .-mr-8-desktop-only {
    margin-right: -2rem !important;
  }

  .mb-8-desktop-only {
    margin-bottom: 2rem !important;
  }

  .-mb-8-desktop-only {
    margin-bottom: -2rem !important;
  }

  .ml-8-desktop-only {
    margin-left: 2rem !important;
  }

  .-ml-8-desktop-only {
    margin-left: -2rem !important;
  }

  .mx-8-desktop-only {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .-mx-8-desktop-only {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .my-8-desktop-only {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .-my-8-desktop-only {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .m-9-desktop-only {
    margin: 2.25rem !important;
  }

  .-m-9-desktop-only {
    margin: -2.25rem !important;
  }

  .mt-9-desktop-only {
    margin-top: 2.25rem !important;
  }

  .-mt-9-desktop-only {
    margin-top: -2.25rem !important;
  }

  .mr-9-desktop-only {
    margin-right: 2.25rem !important;
  }

  .-mr-9-desktop-only {
    margin-right: -2.25rem !important;
  }

  .mb-9-desktop-only {
    margin-bottom: 2.25rem !important;
  }

  .-mb-9-desktop-only {
    margin-bottom: -2.25rem !important;
  }

  .ml-9-desktop-only {
    margin-left: 2.25rem !important;
  }

  .-ml-9-desktop-only {
    margin-left: -2.25rem !important;
  }

  .mx-9-desktop-only {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .-mx-9-desktop-only {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .my-9-desktop-only {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .-my-9-desktop-only {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .m-10-desktop-only {
    margin: 2.5rem !important;
  }

  .-m-10-desktop-only {
    margin: -2.5rem !important;
  }

  .mt-10-desktop-only {
    margin-top: 2.5rem !important;
  }

  .-mt-10-desktop-only {
    margin-top: -2.5rem !important;
  }

  .mr-10-desktop-only {
    margin-right: 2.5rem !important;
  }

  .-mr-10-desktop-only {
    margin-right: -2.5rem !important;
  }

  .mb-10-desktop-only {
    margin-bottom: 2.5rem !important;
  }

  .-mb-10-desktop-only {
    margin-bottom: -2.5rem !important;
  }

  .ml-10-desktop-only {
    margin-left: 2.5rem !important;
  }

  .-ml-10-desktop-only {
    margin-left: -2.5rem !important;
  }

  .mx-10-desktop-only {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .-mx-10-desktop-only {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .my-10-desktop-only {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .-my-10-desktop-only {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .m-11-desktop-only {
    margin: 2.75rem !important;
  }

  .-m-11-desktop-only {
    margin: -2.75rem !important;
  }

  .mt-11-desktop-only {
    margin-top: 2.75rem !important;
  }

  .-mt-11-desktop-only {
    margin-top: -2.75rem !important;
  }

  .mr-11-desktop-only {
    margin-right: 2.75rem !important;
  }

  .-mr-11-desktop-only {
    margin-right: -2.75rem !important;
  }

  .mb-11-desktop-only {
    margin-bottom: 2.75rem !important;
  }

  .-mb-11-desktop-only {
    margin-bottom: -2.75rem !important;
  }

  .ml-11-desktop-only {
    margin-left: 2.75rem !important;
  }

  .-ml-11-desktop-only {
    margin-left: -2.75rem !important;
  }

  .mx-11-desktop-only {
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
  }

  .-mx-11-desktop-only {
    margin-left: -2.75rem !important;
    margin-right: -2.75rem !important;
  }

  .my-11-desktop-only {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .-my-11-desktop-only {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .m-12-desktop-only {
    margin: 3rem !important;
  }

  .-m-12-desktop-only {
    margin: -3rem !important;
  }

  .mt-12-desktop-only {
    margin-top: 3rem !important;
  }

  .-mt-12-desktop-only {
    margin-top: -3rem !important;
  }

  .mr-12-desktop-only {
    margin-right: 3rem !important;
  }

  .-mr-12-desktop-only {
    margin-right: -3rem !important;
  }

  .mb-12-desktop-only {
    margin-bottom: 3rem !important;
  }

  .-mb-12-desktop-only {
    margin-bottom: -3rem !important;
  }

  .ml-12-desktop-only {
    margin-left: 3rem !important;
  }

  .-ml-12-desktop-only {
    margin-left: -3rem !important;
  }

  .mx-12-desktop-only {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .-mx-12-desktop-only {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-12-desktop-only {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .-my-12-desktop-only {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .m-14-desktop-only {
    margin: 3.5rem !important;
  }

  .-m-14-desktop-only {
    margin: -3.5rem !important;
  }

  .mt-14-desktop-only {
    margin-top: 3.5rem !important;
  }

  .-mt-14-desktop-only {
    margin-top: -3.5rem !important;
  }

  .mr-14-desktop-only {
    margin-right: 3.5rem !important;
  }

  .-mr-14-desktop-only {
    margin-right: -3.5rem !important;
  }

  .mb-14-desktop-only {
    margin-bottom: 3.5rem !important;
  }

  .-mb-14-desktop-only {
    margin-bottom: -3.5rem !important;
  }

  .ml-14-desktop-only {
    margin-left: 3.5rem !important;
  }

  .-ml-14-desktop-only {
    margin-left: -3.5rem !important;
  }

  .mx-14-desktop-only {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .-mx-14-desktop-only {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .my-14-desktop-only {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .-my-14-desktop-only {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .m-16-desktop-only {
    margin: 4rem !important;
  }

  .-m-16-desktop-only {
    margin: -4rem !important;
  }

  .mt-16-desktop-only {
    margin-top: 4rem !important;
  }

  .-mt-16-desktop-only {
    margin-top: -4rem !important;
  }

  .mr-16-desktop-only {
    margin-right: 4rem !important;
  }

  .-mr-16-desktop-only {
    margin-right: -4rem !important;
  }

  .mb-16-desktop-only {
    margin-bottom: 4rem !important;
  }

  .-mb-16-desktop-only {
    margin-bottom: -4rem !important;
  }

  .ml-16-desktop-only {
    margin-left: 4rem !important;
  }

  .-ml-16-desktop-only {
    margin-left: -4rem !important;
  }

  .mx-16-desktop-only {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .-mx-16-desktop-only {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .my-16-desktop-only {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .-my-16-desktop-only {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .m-20-desktop-only {
    margin: 5rem !important;
  }

  .-m-20-desktop-only {
    margin: -5rem !important;
  }

  .mt-20-desktop-only {
    margin-top: 5rem !important;
  }

  .-mt-20-desktop-only {
    margin-top: -5rem !important;
  }

  .mr-20-desktop-only {
    margin-right: 5rem !important;
  }

  .-mr-20-desktop-only {
    margin-right: -5rem !important;
  }

  .mb-20-desktop-only {
    margin-bottom: 5rem !important;
  }

  .-mb-20-desktop-only {
    margin-bottom: -5rem !important;
  }

  .ml-20-desktop-only {
    margin-left: 5rem !important;
  }

  .-ml-20-desktop-only {
    margin-left: -5rem !important;
  }

  .mx-20-desktop-only {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .-mx-20-desktop-only {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .my-20-desktop-only {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .-my-20-desktop-only {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .m-24-desktop-only {
    margin: 6rem !important;
  }

  .-m-24-desktop-only {
    margin: -6rem !important;
  }

  .mt-24-desktop-only {
    margin-top: 6rem !important;
  }

  .-mt-24-desktop-only {
    margin-top: -6rem !important;
  }

  .mr-24-desktop-only {
    margin-right: 6rem !important;
  }

  .-mr-24-desktop-only {
    margin-right: -6rem !important;
  }

  .mb-24-desktop-only {
    margin-bottom: 6rem !important;
  }

  .-mb-24-desktop-only {
    margin-bottom: -6rem !important;
  }

  .ml-24-desktop-only {
    margin-left: 6rem !important;
  }

  .-ml-24-desktop-only {
    margin-left: -6rem !important;
  }

  .mx-24-desktop-only {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .-mx-24-desktop-only {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .my-24-desktop-only {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .-my-24-desktop-only {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .m-28-desktop-only {
    margin: 7rem !important;
  }

  .-m-28-desktop-only {
    margin: -7rem !important;
  }

  .mt-28-desktop-only {
    margin-top: 7rem !important;
  }

  .-mt-28-desktop-only {
    margin-top: -7rem !important;
  }

  .mr-28-desktop-only {
    margin-right: 7rem !important;
  }

  .-mr-28-desktop-only {
    margin-right: -7rem !important;
  }

  .mb-28-desktop-only {
    margin-bottom: 7rem !important;
  }

  .-mb-28-desktop-only {
    margin-bottom: -7rem !important;
  }

  .ml-28-desktop-only {
    margin-left: 7rem !important;
  }

  .-ml-28-desktop-only {
    margin-left: -7rem !important;
  }

  .mx-28-desktop-only {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .-mx-28-desktop-only {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .my-28-desktop-only {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .-my-28-desktop-only {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .m-32-desktop-only {
    margin: 8rem !important;
  }

  .-m-32-desktop-only {
    margin: -8rem !important;
  }

  .mt-32-desktop-only {
    margin-top: 8rem !important;
  }

  .-mt-32-desktop-only {
    margin-top: -8rem !important;
  }

  .mr-32-desktop-only {
    margin-right: 8rem !important;
  }

  .-mr-32-desktop-only {
    margin-right: -8rem !important;
  }

  .mb-32-desktop-only {
    margin-bottom: 8rem !important;
  }

  .-mb-32-desktop-only {
    margin-bottom: -8rem !important;
  }

  .ml-32-desktop-only {
    margin-left: 8rem !important;
  }

  .-ml-32-desktop-only {
    margin-left: -8rem !important;
  }

  .mx-32-desktop-only {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .-mx-32-desktop-only {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .my-32-desktop-only {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .-my-32-desktop-only {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .m-36-desktop-only {
    margin: 9rem !important;
  }

  .-m-36-desktop-only {
    margin: -9rem !important;
  }

  .mt-36-desktop-only {
    margin-top: 9rem !important;
  }

  .-mt-36-desktop-only {
    margin-top: -9rem !important;
  }

  .mr-36-desktop-only {
    margin-right: 9rem !important;
  }

  .-mr-36-desktop-only {
    margin-right: -9rem !important;
  }

  .mb-36-desktop-only {
    margin-bottom: 9rem !important;
  }

  .-mb-36-desktop-only {
    margin-bottom: -9rem !important;
  }

  .ml-36-desktop-only {
    margin-left: 9rem !important;
  }

  .-ml-36-desktop-only {
    margin-left: -9rem !important;
  }

  .mx-36-desktop-only {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .-mx-36-desktop-only {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .my-36-desktop-only {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .-my-36-desktop-only {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .m-40-desktop-only {
    margin: 10rem !important;
  }

  .-m-40-desktop-only {
    margin: -10rem !important;
  }

  .mt-40-desktop-only {
    margin-top: 10rem !important;
  }

  .-mt-40-desktop-only {
    margin-top: -10rem !important;
  }

  .mr-40-desktop-only {
    margin-right: 10rem !important;
  }

  .-mr-40-desktop-only {
    margin-right: -10rem !important;
  }

  .mb-40-desktop-only {
    margin-bottom: 10rem !important;
  }

  .-mb-40-desktop-only {
    margin-bottom: -10rem !important;
  }

  .ml-40-desktop-only {
    margin-left: 10rem !important;
  }

  .-ml-40-desktop-only {
    margin-left: -10rem !important;
  }

  .mx-40-desktop-only {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .-mx-40-desktop-only {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .my-40-desktop-only {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .-my-40-desktop-only {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .m-44-desktop-only {
    margin: 11rem !important;
  }

  .-m-44-desktop-only {
    margin: -11rem !important;
  }

  .mt-44-desktop-only {
    margin-top: 11rem !important;
  }

  .-mt-44-desktop-only {
    margin-top: -11rem !important;
  }

  .mr-44-desktop-only {
    margin-right: 11rem !important;
  }

  .-mr-44-desktop-only {
    margin-right: -11rem !important;
  }

  .mb-44-desktop-only {
    margin-bottom: 11rem !important;
  }

  .-mb-44-desktop-only {
    margin-bottom: -11rem !important;
  }

  .ml-44-desktop-only {
    margin-left: 11rem !important;
  }

  .-ml-44-desktop-only {
    margin-left: -11rem !important;
  }

  .mx-44-desktop-only {
    margin-left: 11rem !important;
    margin-right: 11rem !important;
  }

  .-mx-44-desktop-only {
    margin-left: -11rem !important;
    margin-right: -11rem !important;
  }

  .my-44-desktop-only {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .-my-44-desktop-only {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .m-48-desktop-only {
    margin: 12rem !important;
  }

  .-m-48-desktop-only {
    margin: -12rem !important;
  }

  .mt-48-desktop-only {
    margin-top: 12rem !important;
  }

  .-mt-48-desktop-only {
    margin-top: -12rem !important;
  }

  .mr-48-desktop-only {
    margin-right: 12rem !important;
  }

  .-mr-48-desktop-only {
    margin-right: -12rem !important;
  }

  .mb-48-desktop-only {
    margin-bottom: 12rem !important;
  }

  .-mb-48-desktop-only {
    margin-bottom: -12rem !important;
  }

  .ml-48-desktop-only {
    margin-left: 12rem !important;
  }

  .-ml-48-desktop-only {
    margin-left: -12rem !important;
  }

  .mx-48-desktop-only {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .-mx-48-desktop-only {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .my-48-desktop-only {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .-my-48-desktop-only {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .m-52-desktop-only {
    margin: 13rem !important;
  }

  .-m-52-desktop-only {
    margin: -13rem !important;
  }

  .mt-52-desktop-only {
    margin-top: 13rem !important;
  }

  .-mt-52-desktop-only {
    margin-top: -13rem !important;
  }

  .mr-52-desktop-only {
    margin-right: 13rem !important;
  }

  .-mr-52-desktop-only {
    margin-right: -13rem !important;
  }

  .mb-52-desktop-only {
    margin-bottom: 13rem !important;
  }

  .-mb-52-desktop-only {
    margin-bottom: -13rem !important;
  }

  .ml-52-desktop-only {
    margin-left: 13rem !important;
  }

  .-ml-52-desktop-only {
    margin-left: -13rem !important;
  }

  .mx-52-desktop-only {
    margin-left: 13rem !important;
    margin-right: 13rem !important;
  }

  .-mx-52-desktop-only {
    margin-left: -13rem !important;
    margin-right: -13rem !important;
  }

  .my-52-desktop-only {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }

  .-my-52-desktop-only {
    margin-top: -13rem !important;
    margin-bottom: -13rem !important;
  }

  .m-56-desktop-only {
    margin: 14rem !important;
  }

  .-m-56-desktop-only {
    margin: -14rem !important;
  }

  .mt-56-desktop-only {
    margin-top: 14rem !important;
  }

  .-mt-56-desktop-only {
    margin-top: -14rem !important;
  }

  .mr-56-desktop-only {
    margin-right: 14rem !important;
  }

  .-mr-56-desktop-only {
    margin-right: -14rem !important;
  }

  .mb-56-desktop-only {
    margin-bottom: 14rem !important;
  }

  .-mb-56-desktop-only {
    margin-bottom: -14rem !important;
  }

  .ml-56-desktop-only {
    margin-left: 14rem !important;
  }

  .-ml-56-desktop-only {
    margin-left: -14rem !important;
  }

  .mx-56-desktop-only {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .-mx-56-desktop-only {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .my-56-desktop-only {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .-my-56-desktop-only {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .m-60-desktop-only {
    margin: 15rem !important;
  }

  .-m-60-desktop-only {
    margin: -15rem !important;
  }

  .mt-60-desktop-only {
    margin-top: 15rem !important;
  }

  .-mt-60-desktop-only {
    margin-top: -15rem !important;
  }

  .mr-60-desktop-only {
    margin-right: 15rem !important;
  }

  .-mr-60-desktop-only {
    margin-right: -15rem !important;
  }

  .mb-60-desktop-only {
    margin-bottom: 15rem !important;
  }

  .-mb-60-desktop-only {
    margin-bottom: -15rem !important;
  }

  .ml-60-desktop-only {
    margin-left: 15rem !important;
  }

  .-ml-60-desktop-only {
    margin-left: -15rem !important;
  }

  .mx-60-desktop-only {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .-mx-60-desktop-only {
    margin-left: -15rem !important;
    margin-right: -15rem !important;
  }

  .my-60-desktop-only {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .-my-60-desktop-only {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .m-64-desktop-only {
    margin: 16rem !important;
  }

  .-m-64-desktop-only {
    margin: -16rem !important;
  }

  .mt-64-desktop-only {
    margin-top: 16rem !important;
  }

  .-mt-64-desktop-only {
    margin-top: -16rem !important;
  }

  .mr-64-desktop-only {
    margin-right: 16rem !important;
  }

  .-mr-64-desktop-only {
    margin-right: -16rem !important;
  }

  .mb-64-desktop-only {
    margin-bottom: 16rem !important;
  }

  .-mb-64-desktop-only {
    margin-bottom: -16rem !important;
  }

  .ml-64-desktop-only {
    margin-left: 16rem !important;
  }

  .-ml-64-desktop-only {
    margin-left: -16rem !important;
  }

  .mx-64-desktop-only {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .-mx-64-desktop-only {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .my-64-desktop-only {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .-my-64-desktop-only {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .m-72-desktop-only {
    margin: 18rem !important;
  }

  .-m-72-desktop-only {
    margin: -18rem !important;
  }

  .mt-72-desktop-only {
    margin-top: 18rem !important;
  }

  .-mt-72-desktop-only {
    margin-top: -18rem !important;
  }

  .mr-72-desktop-only {
    margin-right: 18rem !important;
  }

  .-mr-72-desktop-only {
    margin-right: -18rem !important;
  }

  .mb-72-desktop-only {
    margin-bottom: 18rem !important;
  }

  .-mb-72-desktop-only {
    margin-bottom: -18rem !important;
  }

  .ml-72-desktop-only {
    margin-left: 18rem !important;
  }

  .-ml-72-desktop-only {
    margin-left: -18rem !important;
  }

  .mx-72-desktop-only {
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .-mx-72-desktop-only {
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .my-72-desktop-only {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .-my-72-desktop-only {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .m-80-desktop-only {
    margin: 20rem !important;
  }

  .-m-80-desktop-only {
    margin: -20rem !important;
  }

  .mt-80-desktop-only {
    margin-top: 20rem !important;
  }

  .-mt-80-desktop-only {
    margin-top: -20rem !important;
  }

  .mr-80-desktop-only {
    margin-right: 20rem !important;
  }

  .-mr-80-desktop-only {
    margin-right: -20rem !important;
  }

  .mb-80-desktop-only {
    margin-bottom: 20rem !important;
  }

  .-mb-80-desktop-only {
    margin-bottom: -20rem !important;
  }

  .ml-80-desktop-only {
    margin-left: 20rem !important;
  }

  .-ml-80-desktop-only {
    margin-left: -20rem !important;
  }

  .mx-80-desktop-only {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .-mx-80-desktop-only {
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .my-80-desktop-only {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .-my-80-desktop-only {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .m-96-desktop-only {
    margin: 24rem !important;
  }

  .-m-96-desktop-only {
    margin: -24rem !important;
  }

  .mt-96-desktop-only {
    margin-top: 24rem !important;
  }

  .-mt-96-desktop-only {
    margin-top: -24rem !important;
  }

  .mr-96-desktop-only {
    margin-right: 24rem !important;
  }

  .-mr-96-desktop-only {
    margin-right: -24rem !important;
  }

  .mb-96-desktop-only {
    margin-bottom: 24rem !important;
  }

  .-mb-96-desktop-only {
    margin-bottom: -24rem !important;
  }

  .ml-96-desktop-only {
    margin-left: 24rem !important;
  }

  .-ml-96-desktop-only {
    margin-left: -24rem !important;
  }

  .mx-96-desktop-only {
    margin-left: 24rem !important;
    margin-right: 24rem !important;
  }

  .-mx-96-desktop-only {
    margin-left: -24rem !important;
    margin-right: -24rem !important;
  }

  .my-96-desktop-only {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }

  .-my-96-desktop-only {
    margin-top: -24rem !important;
    margin-bottom: -24rem !important;
  }

  .p-auto-desktop-only {
    padding: auto !important;
  }

  .pt-auto-desktop-only {
    padding-top: auto !important;
  }

  .pr-auto-desktop-only {
    padding-right: auto !important;
  }

  .pb-auto-desktop-only {
    padding-bottom: auto !important;
  }

  .pl-auto-desktop-only {
    padding-left: auto !important;
  }

  .px-auto-desktop-only {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .py-auto-desktop-only {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }

  .p-0-desktop-only {
    padding: 0 !important;
  }

  .pt-0-desktop-only {
    padding-top: 0 !important;
  }

  .pr-0-desktop-only {
    padding-right: 0 !important;
  }

  .pb-0-desktop-only {
    padding-bottom: 0 !important;
  }

  .pl-0-desktop-only {
    padding-left: 0 !important;
  }

  .px-0-desktop-only {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-0-desktop-only {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-1-desktop-only {
    padding: .25rem !important;
  }

  .pt-1-desktop-only {
    padding-top: .25rem !important;
  }

  .pr-1-desktop-only {
    padding-right: .25rem !important;
  }

  .pb-1-desktop-only {
    padding-bottom: .25rem !important;
  }

  .pl-1-desktop-only {
    padding-left: .25rem !important;
  }

  .px-1-desktop-only {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .py-1-desktop-only {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .p-2-desktop-only {
    padding: .5rem !important;
  }

  .pt-2-desktop-only {
    padding-top: .5rem !important;
  }

  .pr-2-desktop-only {
    padding-right: .5rem !important;
  }

  .pb-2-desktop-only {
    padding-bottom: .5rem !important;
  }

  .pl-2-desktop-only {
    padding-left: .5rem !important;
  }

  .px-2-desktop-only {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .py-2-desktop-only {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .p-3-desktop-only {
    padding: .75rem !important;
  }

  .pt-3-desktop-only {
    padding-top: .75rem !important;
  }

  .pr-3-desktop-only {
    padding-right: .75rem !important;
  }

  .pb-3-desktop-only {
    padding-bottom: .75rem !important;
  }

  .pl-3-desktop-only {
    padding-left: .75rem !important;
  }

  .px-3-desktop-only {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .py-3-desktop-only {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .p-4-desktop-only {
    padding: 1rem !important;
  }

  .pt-4-desktop-only {
    padding-top: 1rem !important;
  }

  .pr-4-desktop-only {
    padding-right: 1rem !important;
  }

  .pb-4-desktop-only {
    padding-bottom: 1rem !important;
  }

  .pl-4-desktop-only {
    padding-left: 1rem !important;
  }

  .px-4-desktop-only {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .py-4-desktop-only {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .p-5-desktop-only {
    padding: 1.25rem !important;
  }

  .pt-5-desktop-only {
    padding-top: 1.25rem !important;
  }

  .pr-5-desktop-only {
    padding-right: 1.25rem !important;
  }

  .pb-5-desktop-only {
    padding-bottom: 1.25rem !important;
  }

  .pl-5-desktop-only {
    padding-left: 1.25rem !important;
  }

  .px-5-desktop-only {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .py-5-desktop-only {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .p-6-desktop-only {
    padding: 1.5rem !important;
  }

  .pt-6-desktop-only {
    padding-top: 1.5rem !important;
  }

  .pr-6-desktop-only {
    padding-right: 1.5rem !important;
  }

  .pb-6-desktop-only {
    padding-bottom: 1.5rem !important;
  }

  .pl-6-desktop-only {
    padding-left: 1.5rem !important;
  }

  .px-6-desktop-only {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .py-6-desktop-only {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .p-7-desktop-only {
    padding: 1.75rem !important;
  }

  .pt-7-desktop-only {
    padding-top: 1.75rem !important;
  }

  .pr-7-desktop-only {
    padding-right: 1.75rem !important;
  }

  .pb-7-desktop-only {
    padding-bottom: 1.75rem !important;
  }

  .pl-7-desktop-only {
    padding-left: 1.75rem !important;
  }

  .px-7-desktop-only {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .py-7-desktop-only {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .p-8-desktop-only {
    padding: 2rem !important;
  }

  .pt-8-desktop-only {
    padding-top: 2rem !important;
  }

  .pr-8-desktop-only {
    padding-right: 2rem !important;
  }

  .pb-8-desktop-only {
    padding-bottom: 2rem !important;
  }

  .pl-8-desktop-only {
    padding-left: 2rem !important;
  }

  .px-8-desktop-only {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .py-8-desktop-only {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .p-9-desktop-only {
    padding: 2.25rem !important;
  }

  .pt-9-desktop-only {
    padding-top: 2.25rem !important;
  }

  .pr-9-desktop-only {
    padding-right: 2.25rem !important;
  }

  .pb-9-desktop-only {
    padding-bottom: 2.25rem !important;
  }

  .pl-9-desktop-only {
    padding-left: 2.25rem !important;
  }

  .px-9-desktop-only {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .py-9-desktop-only {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .p-10-desktop-only {
    padding: 2.5rem !important;
  }

  .pt-10-desktop-only {
    padding-top: 2.5rem !important;
  }

  .pr-10-desktop-only {
    padding-right: 2.5rem !important;
  }

  .pb-10-desktop-only {
    padding-bottom: 2.5rem !important;
  }

  .pl-10-desktop-only {
    padding-left: 2.5rem !important;
  }

  .px-10-desktop-only {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .py-10-desktop-only {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .p-11-desktop-only {
    padding: 2.75rem !important;
  }

  .pt-11-desktop-only {
    padding-top: 2.75rem !important;
  }

  .pr-11-desktop-only {
    padding-right: 2.75rem !important;
  }

  .pb-11-desktop-only {
    padding-bottom: 2.75rem !important;
  }

  .pl-11-desktop-only {
    padding-left: 2.75rem !important;
  }

  .px-11-desktop-only {
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  .py-11-desktop-only {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .p-12-desktop-only {
    padding: 3rem !important;
  }

  .pt-12-desktop-only {
    padding-top: 3rem !important;
  }

  .pr-12-desktop-only {
    padding-right: 3rem !important;
  }

  .pb-12-desktop-only {
    padding-bottom: 3rem !important;
  }

  .pl-12-desktop-only {
    padding-left: 3rem !important;
  }

  .px-12-desktop-only {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-12-desktop-only {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .p-14-desktop-only {
    padding: 3.5rem !important;
  }

  .pt-14-desktop-only {
    padding-top: 3.5rem !important;
  }

  .pr-14-desktop-only {
    padding-right: 3.5rem !important;
  }

  .pb-14-desktop-only {
    padding-bottom: 3.5rem !important;
  }

  .pl-14-desktop-only {
    padding-left: 3.5rem !important;
  }

  .px-14-desktop-only {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .py-14-desktop-only {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .p-16-desktop-only {
    padding: 4rem !important;
  }

  .pt-16-desktop-only {
    padding-top: 4rem !important;
  }

  .pr-16-desktop-only {
    padding-right: 4rem !important;
  }

  .pb-16-desktop-only {
    padding-bottom: 4rem !important;
  }

  .pl-16-desktop-only {
    padding-left: 4rem !important;
  }

  .px-16-desktop-only {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .py-16-desktop-only {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .p-20-desktop-only {
    padding: 5rem !important;
  }

  .pt-20-desktop-only {
    padding-top: 5rem !important;
  }

  .pr-20-desktop-only {
    padding-right: 5rem !important;
  }

  .pb-20-desktop-only {
    padding-bottom: 5rem !important;
  }

  .pl-20-desktop-only {
    padding-left: 5rem !important;
  }

  .px-20-desktop-only {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .py-20-desktop-only {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .p-24-desktop-only {
    padding: 6rem !important;
  }

  .pt-24-desktop-only {
    padding-top: 6rem !important;
  }

  .pr-24-desktop-only {
    padding-right: 6rem !important;
  }

  .pb-24-desktop-only {
    padding-bottom: 6rem !important;
  }

  .pl-24-desktop-only {
    padding-left: 6rem !important;
  }

  .px-24-desktop-only {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .py-24-desktop-only {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .p-28-desktop-only {
    padding: 7rem !important;
  }

  .pt-28-desktop-only {
    padding-top: 7rem !important;
  }

  .pr-28-desktop-only {
    padding-right: 7rem !important;
  }

  .pb-28-desktop-only {
    padding-bottom: 7rem !important;
  }

  .pl-28-desktop-only {
    padding-left: 7rem !important;
  }

  .px-28-desktop-only {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .py-28-desktop-only {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .p-32-desktop-only {
    padding: 8rem !important;
  }

  .pt-32-desktop-only {
    padding-top: 8rem !important;
  }

  .pr-32-desktop-only {
    padding-right: 8rem !important;
  }

  .pb-32-desktop-only {
    padding-bottom: 8rem !important;
  }

  .pl-32-desktop-only {
    padding-left: 8rem !important;
  }

  .px-32-desktop-only {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .py-32-desktop-only {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .p-36-desktop-only {
    padding: 9rem !important;
  }

  .pt-36-desktop-only {
    padding-top: 9rem !important;
  }

  .pr-36-desktop-only {
    padding-right: 9rem !important;
  }

  .pb-36-desktop-only {
    padding-bottom: 9rem !important;
  }

  .pl-36-desktop-only {
    padding-left: 9rem !important;
  }

  .px-36-desktop-only {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .py-36-desktop-only {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .p-40-desktop-only {
    padding: 10rem !important;
  }

  .pt-40-desktop-only {
    padding-top: 10rem !important;
  }

  .pr-40-desktop-only {
    padding-right: 10rem !important;
  }

  .pb-40-desktop-only {
    padding-bottom: 10rem !important;
  }

  .pl-40-desktop-only {
    padding-left: 10rem !important;
  }

  .px-40-desktop-only {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .py-40-desktop-only {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .p-44-desktop-only {
    padding: 11rem !important;
  }

  .pt-44-desktop-only {
    padding-top: 11rem !important;
  }

  .pr-44-desktop-only {
    padding-right: 11rem !important;
  }

  .pb-44-desktop-only {
    padding-bottom: 11rem !important;
  }

  .pl-44-desktop-only {
    padding-left: 11rem !important;
  }

  .px-44-desktop-only {
    padding-left: 11rem !important;
    padding-right: 11rem !important;
  }

  .py-44-desktop-only {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .p-48-desktop-only {
    padding: 12rem !important;
  }

  .pt-48-desktop-only {
    padding-top: 12rem !important;
  }

  .pr-48-desktop-only {
    padding-right: 12rem !important;
  }

  .pb-48-desktop-only {
    padding-bottom: 12rem !important;
  }

  .pl-48-desktop-only {
    padding-left: 12rem !important;
  }

  .px-48-desktop-only {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .py-48-desktop-only {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .p-52-desktop-only {
    padding: 13rem !important;
  }

  .pt-52-desktop-only {
    padding-top: 13rem !important;
  }

  .pr-52-desktop-only {
    padding-right: 13rem !important;
  }

  .pb-52-desktop-only {
    padding-bottom: 13rem !important;
  }

  .pl-52-desktop-only {
    padding-left: 13rem !important;
  }

  .px-52-desktop-only {
    padding-left: 13rem !important;
    padding-right: 13rem !important;
  }

  .py-52-desktop-only {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }

  .p-56-desktop-only {
    padding: 14rem !important;
  }

  .pt-56-desktop-only {
    padding-top: 14rem !important;
  }

  .pr-56-desktop-only {
    padding-right: 14rem !important;
  }

  .pb-56-desktop-only {
    padding-bottom: 14rem !important;
  }

  .pl-56-desktop-only {
    padding-left: 14rem !important;
  }

  .px-56-desktop-only {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .py-56-desktop-only {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .p-60-desktop-only {
    padding: 15rem !important;
  }

  .pt-60-desktop-only {
    padding-top: 15rem !important;
  }

  .pr-60-desktop-only {
    padding-right: 15rem !important;
  }

  .pb-60-desktop-only {
    padding-bottom: 15rem !important;
  }

  .pl-60-desktop-only {
    padding-left: 15rem !important;
  }

  .px-60-desktop-only {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .py-60-desktop-only {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .p-64-desktop-only {
    padding: 16rem !important;
  }

  .pt-64-desktop-only {
    padding-top: 16rem !important;
  }

  .pr-64-desktop-only {
    padding-right: 16rem !important;
  }

  .pb-64-desktop-only {
    padding-bottom: 16rem !important;
  }

  .pl-64-desktop-only {
    padding-left: 16rem !important;
  }

  .px-64-desktop-only {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .py-64-desktop-only {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .p-72-desktop-only {
    padding: 18rem !important;
  }

  .pt-72-desktop-only {
    padding-top: 18rem !important;
  }

  .pr-72-desktop-only {
    padding-right: 18rem !important;
  }

  .pb-72-desktop-only {
    padding-bottom: 18rem !important;
  }

  .pl-72-desktop-only {
    padding-left: 18rem !important;
  }

  .px-72-desktop-only {
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .py-72-desktop-only {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .p-80-desktop-only {
    padding: 20rem !important;
  }

  .pt-80-desktop-only {
    padding-top: 20rem !important;
  }

  .pr-80-desktop-only {
    padding-right: 20rem !important;
  }

  .pb-80-desktop-only {
    padding-bottom: 20rem !important;
  }

  .pl-80-desktop-only {
    padding-left: 20rem !important;
  }

  .px-80-desktop-only {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .py-80-desktop-only {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .p-96-desktop-only {
    padding: 24rem !important;
  }

  .pt-96-desktop-only {
    padding-top: 24rem !important;
  }

  .pr-96-desktop-only {
    padding-right: 24rem !important;
  }

  .pb-96-desktop-only {
    padding-bottom: 24rem !important;
  }

  .pl-96-desktop-only {
    padding-left: 24rem !important;
  }

  .px-96-desktop-only {
    padding-left: 24rem !important;
    padding-right: 24rem !important;
  }

  .py-96-desktop-only {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
}

@media screen and (width >= 1216px) {
  .m-auto-widescreen {
    margin: auto !important;
  }

  .mt-auto-widescreen {
    margin-top: auto !important;
  }

  .mr-auto-widescreen {
    margin-right: auto !important;
  }

  .mb-auto-widescreen {
    margin-bottom: auto !important;
  }

  .ml-auto-widescreen {
    margin-left: auto !important;
  }

  .mx-auto-widescreen {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-auto-widescreen {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .m-0-widescreen {
    margin: 0 !important;
  }

  .mt-0-widescreen {
    margin-top: 0 !important;
  }

  .mr-0-widescreen {
    margin-right: 0 !important;
  }

  .mb-0-widescreen {
    margin-bottom: 0 !important;
  }

  .ml-0-widescreen {
    margin-left: 0 !important;
  }

  .mx-0-widescreen {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-0-widescreen {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-1-widescreen {
    margin: .25rem !important;
  }

  .-m-1-widescreen {
    margin: -.25rem !important;
  }

  .mt-1-widescreen {
    margin-top: .25rem !important;
  }

  .-mt-1-widescreen {
    margin-top: -.25rem !important;
  }

  .mr-1-widescreen {
    margin-right: .25rem !important;
  }

  .-mr-1-widescreen {
    margin-right: -.25rem !important;
  }

  .mb-1-widescreen {
    margin-bottom: .25rem !important;
  }

  .-mb-1-widescreen {
    margin-bottom: -.25rem !important;
  }

  .ml-1-widescreen {
    margin-left: .25rem !important;
  }

  .-ml-1-widescreen {
    margin-left: -.25rem !important;
  }

  .mx-1-widescreen {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .-mx-1-widescreen {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .my-1-widescreen {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .-my-1-widescreen {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .m-2-widescreen {
    margin: .5rem !important;
  }

  .-m-2-widescreen {
    margin: -.5rem !important;
  }

  .mt-2-widescreen {
    margin-top: .5rem !important;
  }

  .-mt-2-widescreen {
    margin-top: -.5rem !important;
  }

  .mr-2-widescreen {
    margin-right: .5rem !important;
  }

  .-mr-2-widescreen {
    margin-right: -.5rem !important;
  }

  .mb-2-widescreen {
    margin-bottom: .5rem !important;
  }

  .-mb-2-widescreen {
    margin-bottom: -.5rem !important;
  }

  .ml-2-widescreen {
    margin-left: .5rem !important;
  }

  .-ml-2-widescreen {
    margin-left: -.5rem !important;
  }

  .mx-2-widescreen {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .-mx-2-widescreen {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .my-2-widescreen {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .-my-2-widescreen {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .m-3-widescreen {
    margin: .75rem !important;
  }

  .-m-3-widescreen {
    margin: -.75rem !important;
  }

  .mt-3-widescreen {
    margin-top: .75rem !important;
  }

  .-mt-3-widescreen {
    margin-top: -.75rem !important;
  }

  .mr-3-widescreen {
    margin-right: .75rem !important;
  }

  .-mr-3-widescreen {
    margin-right: -.75rem !important;
  }

  .mb-3-widescreen {
    margin-bottom: .75rem !important;
  }

  .-mb-3-widescreen {
    margin-bottom: -.75rem !important;
  }

  .ml-3-widescreen {
    margin-left: .75rem !important;
  }

  .-ml-3-widescreen {
    margin-left: -.75rem !important;
  }

  .mx-3-widescreen {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .-mx-3-widescreen {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .my-3-widescreen {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .-my-3-widescreen {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .m-4-widescreen {
    margin: 1rem !important;
  }

  .-m-4-widescreen {
    margin: -1rem !important;
  }

  .mt-4-widescreen {
    margin-top: 1rem !important;
  }

  .-mt-4-widescreen {
    margin-top: -1rem !important;
  }

  .mr-4-widescreen {
    margin-right: 1rem !important;
  }

  .-mr-4-widescreen {
    margin-right: -1rem !important;
  }

  .mb-4-widescreen {
    margin-bottom: 1rem !important;
  }

  .-mb-4-widescreen {
    margin-bottom: -1rem !important;
  }

  .ml-4-widescreen {
    margin-left: 1rem !important;
  }

  .-ml-4-widescreen {
    margin-left: -1rem !important;
  }

  .mx-4-widescreen {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .-mx-4-widescreen {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .my-4-widescreen {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .-my-4-widescreen {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .m-5-widescreen {
    margin: 1.25rem !important;
  }

  .-m-5-widescreen {
    margin: -1.25rem !important;
  }

  .mt-5-widescreen {
    margin-top: 1.25rem !important;
  }

  .-mt-5-widescreen {
    margin-top: -1.25rem !important;
  }

  .mr-5-widescreen {
    margin-right: 1.25rem !important;
  }

  .-mr-5-widescreen {
    margin-right: -1.25rem !important;
  }

  .mb-5-widescreen {
    margin-bottom: 1.25rem !important;
  }

  .-mb-5-widescreen {
    margin-bottom: -1.25rem !important;
  }

  .ml-5-widescreen {
    margin-left: 1.25rem !important;
  }

  .-ml-5-widescreen {
    margin-left: -1.25rem !important;
  }

  .mx-5-widescreen {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .-mx-5-widescreen {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .my-5-widescreen {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .-my-5-widescreen {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .m-6-widescreen {
    margin: 1.5rem !important;
  }

  .-m-6-widescreen {
    margin: -1.5rem !important;
  }

  .mt-6-widescreen {
    margin-top: 1.5rem !important;
  }

  .-mt-6-widescreen {
    margin-top: -1.5rem !important;
  }

  .mr-6-widescreen {
    margin-right: 1.5rem !important;
  }

  .-mr-6-widescreen {
    margin-right: -1.5rem !important;
  }

  .mb-6-widescreen {
    margin-bottom: 1.5rem !important;
  }

  .-mb-6-widescreen {
    margin-bottom: -1.5rem !important;
  }

  .ml-6-widescreen {
    margin-left: 1.5rem !important;
  }

  .-ml-6-widescreen {
    margin-left: -1.5rem !important;
  }

  .mx-6-widescreen {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .-mx-6-widescreen {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .my-6-widescreen {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .-my-6-widescreen {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .m-7-widescreen {
    margin: 1.75rem !important;
  }

  .-m-7-widescreen {
    margin: -1.75rem !important;
  }

  .mt-7-widescreen {
    margin-top: 1.75rem !important;
  }

  .-mt-7-widescreen {
    margin-top: -1.75rem !important;
  }

  .mr-7-widescreen {
    margin-right: 1.75rem !important;
  }

  .-mr-7-widescreen {
    margin-right: -1.75rem !important;
  }

  .mb-7-widescreen {
    margin-bottom: 1.75rem !important;
  }

  .-mb-7-widescreen {
    margin-bottom: -1.75rem !important;
  }

  .ml-7-widescreen {
    margin-left: 1.75rem !important;
  }

  .-ml-7-widescreen {
    margin-left: -1.75rem !important;
  }

  .mx-7-widescreen {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .-mx-7-widescreen {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .my-7-widescreen {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .-my-7-widescreen {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .m-8-widescreen {
    margin: 2rem !important;
  }

  .-m-8-widescreen {
    margin: -2rem !important;
  }

  .mt-8-widescreen {
    margin-top: 2rem !important;
  }

  .-mt-8-widescreen {
    margin-top: -2rem !important;
  }

  .mr-8-widescreen {
    margin-right: 2rem !important;
  }

  .-mr-8-widescreen {
    margin-right: -2rem !important;
  }

  .mb-8-widescreen {
    margin-bottom: 2rem !important;
  }

  .-mb-8-widescreen {
    margin-bottom: -2rem !important;
  }

  .ml-8-widescreen {
    margin-left: 2rem !important;
  }

  .-ml-8-widescreen {
    margin-left: -2rem !important;
  }

  .mx-8-widescreen {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .-mx-8-widescreen {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .my-8-widescreen {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .-my-8-widescreen {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .m-9-widescreen {
    margin: 2.25rem !important;
  }

  .-m-9-widescreen {
    margin: -2.25rem !important;
  }

  .mt-9-widescreen {
    margin-top: 2.25rem !important;
  }

  .-mt-9-widescreen {
    margin-top: -2.25rem !important;
  }

  .mr-9-widescreen {
    margin-right: 2.25rem !important;
  }

  .-mr-9-widescreen {
    margin-right: -2.25rem !important;
  }

  .mb-9-widescreen {
    margin-bottom: 2.25rem !important;
  }

  .-mb-9-widescreen {
    margin-bottom: -2.25rem !important;
  }

  .ml-9-widescreen {
    margin-left: 2.25rem !important;
  }

  .-ml-9-widescreen {
    margin-left: -2.25rem !important;
  }

  .mx-9-widescreen {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .-mx-9-widescreen {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .my-9-widescreen {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .-my-9-widescreen {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .m-10-widescreen {
    margin: 2.5rem !important;
  }

  .-m-10-widescreen {
    margin: -2.5rem !important;
  }

  .mt-10-widescreen {
    margin-top: 2.5rem !important;
  }

  .-mt-10-widescreen {
    margin-top: -2.5rem !important;
  }

  .mr-10-widescreen {
    margin-right: 2.5rem !important;
  }

  .-mr-10-widescreen {
    margin-right: -2.5rem !important;
  }

  .mb-10-widescreen {
    margin-bottom: 2.5rem !important;
  }

  .-mb-10-widescreen {
    margin-bottom: -2.5rem !important;
  }

  .ml-10-widescreen {
    margin-left: 2.5rem !important;
  }

  .-ml-10-widescreen {
    margin-left: -2.5rem !important;
  }

  .mx-10-widescreen {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .-mx-10-widescreen {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .my-10-widescreen {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .-my-10-widescreen {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .m-11-widescreen {
    margin: 2.75rem !important;
  }

  .-m-11-widescreen {
    margin: -2.75rem !important;
  }

  .mt-11-widescreen {
    margin-top: 2.75rem !important;
  }

  .-mt-11-widescreen {
    margin-top: -2.75rem !important;
  }

  .mr-11-widescreen {
    margin-right: 2.75rem !important;
  }

  .-mr-11-widescreen {
    margin-right: -2.75rem !important;
  }

  .mb-11-widescreen {
    margin-bottom: 2.75rem !important;
  }

  .-mb-11-widescreen {
    margin-bottom: -2.75rem !important;
  }

  .ml-11-widescreen {
    margin-left: 2.75rem !important;
  }

  .-ml-11-widescreen {
    margin-left: -2.75rem !important;
  }

  .mx-11-widescreen {
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
  }

  .-mx-11-widescreen {
    margin-left: -2.75rem !important;
    margin-right: -2.75rem !important;
  }

  .my-11-widescreen {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .-my-11-widescreen {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .m-12-widescreen {
    margin: 3rem !important;
  }

  .-m-12-widescreen {
    margin: -3rem !important;
  }

  .mt-12-widescreen {
    margin-top: 3rem !important;
  }

  .-mt-12-widescreen {
    margin-top: -3rem !important;
  }

  .mr-12-widescreen {
    margin-right: 3rem !important;
  }

  .-mr-12-widescreen {
    margin-right: -3rem !important;
  }

  .mb-12-widescreen {
    margin-bottom: 3rem !important;
  }

  .-mb-12-widescreen {
    margin-bottom: -3rem !important;
  }

  .ml-12-widescreen {
    margin-left: 3rem !important;
  }

  .-ml-12-widescreen {
    margin-left: -3rem !important;
  }

  .mx-12-widescreen {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .-mx-12-widescreen {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-12-widescreen {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .-my-12-widescreen {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .m-14-widescreen {
    margin: 3.5rem !important;
  }

  .-m-14-widescreen {
    margin: -3.5rem !important;
  }

  .mt-14-widescreen {
    margin-top: 3.5rem !important;
  }

  .-mt-14-widescreen {
    margin-top: -3.5rem !important;
  }

  .mr-14-widescreen {
    margin-right: 3.5rem !important;
  }

  .-mr-14-widescreen {
    margin-right: -3.5rem !important;
  }

  .mb-14-widescreen {
    margin-bottom: 3.5rem !important;
  }

  .-mb-14-widescreen {
    margin-bottom: -3.5rem !important;
  }

  .ml-14-widescreen {
    margin-left: 3.5rem !important;
  }

  .-ml-14-widescreen {
    margin-left: -3.5rem !important;
  }

  .mx-14-widescreen {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .-mx-14-widescreen {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .my-14-widescreen {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .-my-14-widescreen {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .m-16-widescreen {
    margin: 4rem !important;
  }

  .-m-16-widescreen {
    margin: -4rem !important;
  }

  .mt-16-widescreen {
    margin-top: 4rem !important;
  }

  .-mt-16-widescreen {
    margin-top: -4rem !important;
  }

  .mr-16-widescreen {
    margin-right: 4rem !important;
  }

  .-mr-16-widescreen {
    margin-right: -4rem !important;
  }

  .mb-16-widescreen {
    margin-bottom: 4rem !important;
  }

  .-mb-16-widescreen {
    margin-bottom: -4rem !important;
  }

  .ml-16-widescreen {
    margin-left: 4rem !important;
  }

  .-ml-16-widescreen {
    margin-left: -4rem !important;
  }

  .mx-16-widescreen {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .-mx-16-widescreen {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .my-16-widescreen {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .-my-16-widescreen {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .m-20-widescreen {
    margin: 5rem !important;
  }

  .-m-20-widescreen {
    margin: -5rem !important;
  }

  .mt-20-widescreen {
    margin-top: 5rem !important;
  }

  .-mt-20-widescreen {
    margin-top: -5rem !important;
  }

  .mr-20-widescreen {
    margin-right: 5rem !important;
  }

  .-mr-20-widescreen {
    margin-right: -5rem !important;
  }

  .mb-20-widescreen {
    margin-bottom: 5rem !important;
  }

  .-mb-20-widescreen {
    margin-bottom: -5rem !important;
  }

  .ml-20-widescreen {
    margin-left: 5rem !important;
  }

  .-ml-20-widescreen {
    margin-left: -5rem !important;
  }

  .mx-20-widescreen {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .-mx-20-widescreen {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .my-20-widescreen {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .-my-20-widescreen {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .m-24-widescreen {
    margin: 6rem !important;
  }

  .-m-24-widescreen {
    margin: -6rem !important;
  }

  .mt-24-widescreen {
    margin-top: 6rem !important;
  }

  .-mt-24-widescreen {
    margin-top: -6rem !important;
  }

  .mr-24-widescreen {
    margin-right: 6rem !important;
  }

  .-mr-24-widescreen {
    margin-right: -6rem !important;
  }

  .mb-24-widescreen {
    margin-bottom: 6rem !important;
  }

  .-mb-24-widescreen {
    margin-bottom: -6rem !important;
  }

  .ml-24-widescreen {
    margin-left: 6rem !important;
  }

  .-ml-24-widescreen {
    margin-left: -6rem !important;
  }

  .mx-24-widescreen {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .-mx-24-widescreen {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .my-24-widescreen {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .-my-24-widescreen {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .m-28-widescreen {
    margin: 7rem !important;
  }

  .-m-28-widescreen {
    margin: -7rem !important;
  }

  .mt-28-widescreen {
    margin-top: 7rem !important;
  }

  .-mt-28-widescreen {
    margin-top: -7rem !important;
  }

  .mr-28-widescreen {
    margin-right: 7rem !important;
  }

  .-mr-28-widescreen {
    margin-right: -7rem !important;
  }

  .mb-28-widescreen {
    margin-bottom: 7rem !important;
  }

  .-mb-28-widescreen {
    margin-bottom: -7rem !important;
  }

  .ml-28-widescreen {
    margin-left: 7rem !important;
  }

  .-ml-28-widescreen {
    margin-left: -7rem !important;
  }

  .mx-28-widescreen {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .-mx-28-widescreen {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .my-28-widescreen {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .-my-28-widescreen {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .m-32-widescreen {
    margin: 8rem !important;
  }

  .-m-32-widescreen {
    margin: -8rem !important;
  }

  .mt-32-widescreen {
    margin-top: 8rem !important;
  }

  .-mt-32-widescreen {
    margin-top: -8rem !important;
  }

  .mr-32-widescreen {
    margin-right: 8rem !important;
  }

  .-mr-32-widescreen {
    margin-right: -8rem !important;
  }

  .mb-32-widescreen {
    margin-bottom: 8rem !important;
  }

  .-mb-32-widescreen {
    margin-bottom: -8rem !important;
  }

  .ml-32-widescreen {
    margin-left: 8rem !important;
  }

  .-ml-32-widescreen {
    margin-left: -8rem !important;
  }

  .mx-32-widescreen {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .-mx-32-widescreen {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .my-32-widescreen {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .-my-32-widescreen {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .m-36-widescreen {
    margin: 9rem !important;
  }

  .-m-36-widescreen {
    margin: -9rem !important;
  }

  .mt-36-widescreen {
    margin-top: 9rem !important;
  }

  .-mt-36-widescreen {
    margin-top: -9rem !important;
  }

  .mr-36-widescreen {
    margin-right: 9rem !important;
  }

  .-mr-36-widescreen {
    margin-right: -9rem !important;
  }

  .mb-36-widescreen {
    margin-bottom: 9rem !important;
  }

  .-mb-36-widescreen {
    margin-bottom: -9rem !important;
  }

  .ml-36-widescreen {
    margin-left: 9rem !important;
  }

  .-ml-36-widescreen {
    margin-left: -9rem !important;
  }

  .mx-36-widescreen {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .-mx-36-widescreen {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .my-36-widescreen {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .-my-36-widescreen {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .m-40-widescreen {
    margin: 10rem !important;
  }

  .-m-40-widescreen {
    margin: -10rem !important;
  }

  .mt-40-widescreen {
    margin-top: 10rem !important;
  }

  .-mt-40-widescreen {
    margin-top: -10rem !important;
  }

  .mr-40-widescreen {
    margin-right: 10rem !important;
  }

  .-mr-40-widescreen {
    margin-right: -10rem !important;
  }

  .mb-40-widescreen {
    margin-bottom: 10rem !important;
  }

  .-mb-40-widescreen {
    margin-bottom: -10rem !important;
  }

  .ml-40-widescreen {
    margin-left: 10rem !important;
  }

  .-ml-40-widescreen {
    margin-left: -10rem !important;
  }

  .mx-40-widescreen {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .-mx-40-widescreen {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .my-40-widescreen {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .-my-40-widescreen {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .m-44-widescreen {
    margin: 11rem !important;
  }

  .-m-44-widescreen {
    margin: -11rem !important;
  }

  .mt-44-widescreen {
    margin-top: 11rem !important;
  }

  .-mt-44-widescreen {
    margin-top: -11rem !important;
  }

  .mr-44-widescreen {
    margin-right: 11rem !important;
  }

  .-mr-44-widescreen {
    margin-right: -11rem !important;
  }

  .mb-44-widescreen {
    margin-bottom: 11rem !important;
  }

  .-mb-44-widescreen {
    margin-bottom: -11rem !important;
  }

  .ml-44-widescreen {
    margin-left: 11rem !important;
  }

  .-ml-44-widescreen {
    margin-left: -11rem !important;
  }

  .mx-44-widescreen {
    margin-left: 11rem !important;
    margin-right: 11rem !important;
  }

  .-mx-44-widescreen {
    margin-left: -11rem !important;
    margin-right: -11rem !important;
  }

  .my-44-widescreen {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .-my-44-widescreen {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .m-48-widescreen {
    margin: 12rem !important;
  }

  .-m-48-widescreen {
    margin: -12rem !important;
  }

  .mt-48-widescreen {
    margin-top: 12rem !important;
  }

  .-mt-48-widescreen {
    margin-top: -12rem !important;
  }

  .mr-48-widescreen {
    margin-right: 12rem !important;
  }

  .-mr-48-widescreen {
    margin-right: -12rem !important;
  }

  .mb-48-widescreen {
    margin-bottom: 12rem !important;
  }

  .-mb-48-widescreen {
    margin-bottom: -12rem !important;
  }

  .ml-48-widescreen {
    margin-left: 12rem !important;
  }

  .-ml-48-widescreen {
    margin-left: -12rem !important;
  }

  .mx-48-widescreen {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .-mx-48-widescreen {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .my-48-widescreen {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .-my-48-widescreen {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .m-52-widescreen {
    margin: 13rem !important;
  }

  .-m-52-widescreen {
    margin: -13rem !important;
  }

  .mt-52-widescreen {
    margin-top: 13rem !important;
  }

  .-mt-52-widescreen {
    margin-top: -13rem !important;
  }

  .mr-52-widescreen {
    margin-right: 13rem !important;
  }

  .-mr-52-widescreen {
    margin-right: -13rem !important;
  }

  .mb-52-widescreen {
    margin-bottom: 13rem !important;
  }

  .-mb-52-widescreen {
    margin-bottom: -13rem !important;
  }

  .ml-52-widescreen {
    margin-left: 13rem !important;
  }

  .-ml-52-widescreen {
    margin-left: -13rem !important;
  }

  .mx-52-widescreen {
    margin-left: 13rem !important;
    margin-right: 13rem !important;
  }

  .-mx-52-widescreen {
    margin-left: -13rem !important;
    margin-right: -13rem !important;
  }

  .my-52-widescreen {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }

  .-my-52-widescreen {
    margin-top: -13rem !important;
    margin-bottom: -13rem !important;
  }

  .m-56-widescreen {
    margin: 14rem !important;
  }

  .-m-56-widescreen {
    margin: -14rem !important;
  }

  .mt-56-widescreen {
    margin-top: 14rem !important;
  }

  .-mt-56-widescreen {
    margin-top: -14rem !important;
  }

  .mr-56-widescreen {
    margin-right: 14rem !important;
  }

  .-mr-56-widescreen {
    margin-right: -14rem !important;
  }

  .mb-56-widescreen {
    margin-bottom: 14rem !important;
  }

  .-mb-56-widescreen {
    margin-bottom: -14rem !important;
  }

  .ml-56-widescreen {
    margin-left: 14rem !important;
  }

  .-ml-56-widescreen {
    margin-left: -14rem !important;
  }

  .mx-56-widescreen {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .-mx-56-widescreen {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .my-56-widescreen {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .-my-56-widescreen {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .m-60-widescreen {
    margin: 15rem !important;
  }

  .-m-60-widescreen {
    margin: -15rem !important;
  }

  .mt-60-widescreen {
    margin-top: 15rem !important;
  }

  .-mt-60-widescreen {
    margin-top: -15rem !important;
  }

  .mr-60-widescreen {
    margin-right: 15rem !important;
  }

  .-mr-60-widescreen {
    margin-right: -15rem !important;
  }

  .mb-60-widescreen {
    margin-bottom: 15rem !important;
  }

  .-mb-60-widescreen {
    margin-bottom: -15rem !important;
  }

  .ml-60-widescreen {
    margin-left: 15rem !important;
  }

  .-ml-60-widescreen {
    margin-left: -15rem !important;
  }

  .mx-60-widescreen {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .-mx-60-widescreen {
    margin-left: -15rem !important;
    margin-right: -15rem !important;
  }

  .my-60-widescreen {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .-my-60-widescreen {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .m-64-widescreen {
    margin: 16rem !important;
  }

  .-m-64-widescreen {
    margin: -16rem !important;
  }

  .mt-64-widescreen {
    margin-top: 16rem !important;
  }

  .-mt-64-widescreen {
    margin-top: -16rem !important;
  }

  .mr-64-widescreen {
    margin-right: 16rem !important;
  }

  .-mr-64-widescreen {
    margin-right: -16rem !important;
  }

  .mb-64-widescreen {
    margin-bottom: 16rem !important;
  }

  .-mb-64-widescreen {
    margin-bottom: -16rem !important;
  }

  .ml-64-widescreen {
    margin-left: 16rem !important;
  }

  .-ml-64-widescreen {
    margin-left: -16rem !important;
  }

  .mx-64-widescreen {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .-mx-64-widescreen {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .my-64-widescreen {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .-my-64-widescreen {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .m-72-widescreen {
    margin: 18rem !important;
  }

  .-m-72-widescreen {
    margin: -18rem !important;
  }

  .mt-72-widescreen {
    margin-top: 18rem !important;
  }

  .-mt-72-widescreen {
    margin-top: -18rem !important;
  }

  .mr-72-widescreen {
    margin-right: 18rem !important;
  }

  .-mr-72-widescreen {
    margin-right: -18rem !important;
  }

  .mb-72-widescreen {
    margin-bottom: 18rem !important;
  }

  .-mb-72-widescreen {
    margin-bottom: -18rem !important;
  }

  .ml-72-widescreen {
    margin-left: 18rem !important;
  }

  .-ml-72-widescreen {
    margin-left: -18rem !important;
  }

  .mx-72-widescreen {
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .-mx-72-widescreen {
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .my-72-widescreen {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .-my-72-widescreen {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .m-80-widescreen {
    margin: 20rem !important;
  }

  .-m-80-widescreen {
    margin: -20rem !important;
  }

  .mt-80-widescreen {
    margin-top: 20rem !important;
  }

  .-mt-80-widescreen {
    margin-top: -20rem !important;
  }

  .mr-80-widescreen {
    margin-right: 20rem !important;
  }

  .-mr-80-widescreen {
    margin-right: -20rem !important;
  }

  .mb-80-widescreen {
    margin-bottom: 20rem !important;
  }

  .-mb-80-widescreen {
    margin-bottom: -20rem !important;
  }

  .ml-80-widescreen {
    margin-left: 20rem !important;
  }

  .-ml-80-widescreen {
    margin-left: -20rem !important;
  }

  .mx-80-widescreen {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .-mx-80-widescreen {
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .my-80-widescreen {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .-my-80-widescreen {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .m-96-widescreen {
    margin: 24rem !important;
  }

  .-m-96-widescreen {
    margin: -24rem !important;
  }

  .mt-96-widescreen {
    margin-top: 24rem !important;
  }

  .-mt-96-widescreen {
    margin-top: -24rem !important;
  }

  .mr-96-widescreen {
    margin-right: 24rem !important;
  }

  .-mr-96-widescreen {
    margin-right: -24rem !important;
  }

  .mb-96-widescreen {
    margin-bottom: 24rem !important;
  }

  .-mb-96-widescreen {
    margin-bottom: -24rem !important;
  }

  .ml-96-widescreen {
    margin-left: 24rem !important;
  }

  .-ml-96-widescreen {
    margin-left: -24rem !important;
  }

  .mx-96-widescreen {
    margin-left: 24rem !important;
    margin-right: 24rem !important;
  }

  .-mx-96-widescreen {
    margin-left: -24rem !important;
    margin-right: -24rem !important;
  }

  .my-96-widescreen {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }

  .-my-96-widescreen {
    margin-top: -24rem !important;
    margin-bottom: -24rem !important;
  }

  .p-auto-widescreen {
    padding: auto !important;
  }

  .pt-auto-widescreen {
    padding-top: auto !important;
  }

  .pr-auto-widescreen {
    padding-right: auto !important;
  }

  .pb-auto-widescreen {
    padding-bottom: auto !important;
  }

  .pl-auto-widescreen {
    padding-left: auto !important;
  }

  .px-auto-widescreen {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .py-auto-widescreen {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }

  .p-0-widescreen {
    padding: 0 !important;
  }

  .pt-0-widescreen {
    padding-top: 0 !important;
  }

  .pr-0-widescreen {
    padding-right: 0 !important;
  }

  .pb-0-widescreen {
    padding-bottom: 0 !important;
  }

  .pl-0-widescreen {
    padding-left: 0 !important;
  }

  .px-0-widescreen {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-0-widescreen {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-1-widescreen {
    padding: .25rem !important;
  }

  .pt-1-widescreen {
    padding-top: .25rem !important;
  }

  .pr-1-widescreen {
    padding-right: .25rem !important;
  }

  .pb-1-widescreen {
    padding-bottom: .25rem !important;
  }

  .pl-1-widescreen {
    padding-left: .25rem !important;
  }

  .px-1-widescreen {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .py-1-widescreen {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .p-2-widescreen {
    padding: .5rem !important;
  }

  .pt-2-widescreen {
    padding-top: .5rem !important;
  }

  .pr-2-widescreen {
    padding-right: .5rem !important;
  }

  .pb-2-widescreen {
    padding-bottom: .5rem !important;
  }

  .pl-2-widescreen {
    padding-left: .5rem !important;
  }

  .px-2-widescreen {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .py-2-widescreen {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .p-3-widescreen {
    padding: .75rem !important;
  }

  .pt-3-widescreen {
    padding-top: .75rem !important;
  }

  .pr-3-widescreen {
    padding-right: .75rem !important;
  }

  .pb-3-widescreen {
    padding-bottom: .75rem !important;
  }

  .pl-3-widescreen {
    padding-left: .75rem !important;
  }

  .px-3-widescreen {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .py-3-widescreen {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .p-4-widescreen {
    padding: 1rem !important;
  }

  .pt-4-widescreen {
    padding-top: 1rem !important;
  }

  .pr-4-widescreen {
    padding-right: 1rem !important;
  }

  .pb-4-widescreen {
    padding-bottom: 1rem !important;
  }

  .pl-4-widescreen {
    padding-left: 1rem !important;
  }

  .px-4-widescreen {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .py-4-widescreen {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .p-5-widescreen {
    padding: 1.25rem !important;
  }

  .pt-5-widescreen {
    padding-top: 1.25rem !important;
  }

  .pr-5-widescreen {
    padding-right: 1.25rem !important;
  }

  .pb-5-widescreen {
    padding-bottom: 1.25rem !important;
  }

  .pl-5-widescreen {
    padding-left: 1.25rem !important;
  }

  .px-5-widescreen {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .py-5-widescreen {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .p-6-widescreen {
    padding: 1.5rem !important;
  }

  .pt-6-widescreen {
    padding-top: 1.5rem !important;
  }

  .pr-6-widescreen {
    padding-right: 1.5rem !important;
  }

  .pb-6-widescreen {
    padding-bottom: 1.5rem !important;
  }

  .pl-6-widescreen {
    padding-left: 1.5rem !important;
  }

  .px-6-widescreen {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .py-6-widescreen {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .p-7-widescreen {
    padding: 1.75rem !important;
  }

  .pt-7-widescreen {
    padding-top: 1.75rem !important;
  }

  .pr-7-widescreen {
    padding-right: 1.75rem !important;
  }

  .pb-7-widescreen {
    padding-bottom: 1.75rem !important;
  }

  .pl-7-widescreen {
    padding-left: 1.75rem !important;
  }

  .px-7-widescreen {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .py-7-widescreen {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .p-8-widescreen {
    padding: 2rem !important;
  }

  .pt-8-widescreen {
    padding-top: 2rem !important;
  }

  .pr-8-widescreen {
    padding-right: 2rem !important;
  }

  .pb-8-widescreen {
    padding-bottom: 2rem !important;
  }

  .pl-8-widescreen {
    padding-left: 2rem !important;
  }

  .px-8-widescreen {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .py-8-widescreen {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .p-9-widescreen {
    padding: 2.25rem !important;
  }

  .pt-9-widescreen {
    padding-top: 2.25rem !important;
  }

  .pr-9-widescreen {
    padding-right: 2.25rem !important;
  }

  .pb-9-widescreen {
    padding-bottom: 2.25rem !important;
  }

  .pl-9-widescreen {
    padding-left: 2.25rem !important;
  }

  .px-9-widescreen {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .py-9-widescreen {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .p-10-widescreen {
    padding: 2.5rem !important;
  }

  .pt-10-widescreen {
    padding-top: 2.5rem !important;
  }

  .pr-10-widescreen {
    padding-right: 2.5rem !important;
  }

  .pb-10-widescreen {
    padding-bottom: 2.5rem !important;
  }

  .pl-10-widescreen {
    padding-left: 2.5rem !important;
  }

  .px-10-widescreen {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .py-10-widescreen {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .p-11-widescreen {
    padding: 2.75rem !important;
  }

  .pt-11-widescreen {
    padding-top: 2.75rem !important;
  }

  .pr-11-widescreen {
    padding-right: 2.75rem !important;
  }

  .pb-11-widescreen {
    padding-bottom: 2.75rem !important;
  }

  .pl-11-widescreen {
    padding-left: 2.75rem !important;
  }

  .px-11-widescreen {
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  .py-11-widescreen {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .p-12-widescreen {
    padding: 3rem !important;
  }

  .pt-12-widescreen {
    padding-top: 3rem !important;
  }

  .pr-12-widescreen {
    padding-right: 3rem !important;
  }

  .pb-12-widescreen {
    padding-bottom: 3rem !important;
  }

  .pl-12-widescreen {
    padding-left: 3rem !important;
  }

  .px-12-widescreen {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-12-widescreen {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .p-14-widescreen {
    padding: 3.5rem !important;
  }

  .pt-14-widescreen {
    padding-top: 3.5rem !important;
  }

  .pr-14-widescreen {
    padding-right: 3.5rem !important;
  }

  .pb-14-widescreen {
    padding-bottom: 3.5rem !important;
  }

  .pl-14-widescreen {
    padding-left: 3.5rem !important;
  }

  .px-14-widescreen {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .py-14-widescreen {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .p-16-widescreen {
    padding: 4rem !important;
  }

  .pt-16-widescreen {
    padding-top: 4rem !important;
  }

  .pr-16-widescreen {
    padding-right: 4rem !important;
  }

  .pb-16-widescreen {
    padding-bottom: 4rem !important;
  }

  .pl-16-widescreen {
    padding-left: 4rem !important;
  }

  .px-16-widescreen {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .py-16-widescreen {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .p-20-widescreen {
    padding: 5rem !important;
  }

  .pt-20-widescreen {
    padding-top: 5rem !important;
  }

  .pr-20-widescreen {
    padding-right: 5rem !important;
  }

  .pb-20-widescreen {
    padding-bottom: 5rem !important;
  }

  .pl-20-widescreen {
    padding-left: 5rem !important;
  }

  .px-20-widescreen {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .py-20-widescreen {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .p-24-widescreen {
    padding: 6rem !important;
  }

  .pt-24-widescreen {
    padding-top: 6rem !important;
  }

  .pr-24-widescreen {
    padding-right: 6rem !important;
  }

  .pb-24-widescreen {
    padding-bottom: 6rem !important;
  }

  .pl-24-widescreen {
    padding-left: 6rem !important;
  }

  .px-24-widescreen {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .py-24-widescreen {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .p-28-widescreen {
    padding: 7rem !important;
  }

  .pt-28-widescreen {
    padding-top: 7rem !important;
  }

  .pr-28-widescreen {
    padding-right: 7rem !important;
  }

  .pb-28-widescreen {
    padding-bottom: 7rem !important;
  }

  .pl-28-widescreen {
    padding-left: 7rem !important;
  }

  .px-28-widescreen {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .py-28-widescreen {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .p-32-widescreen {
    padding: 8rem !important;
  }

  .pt-32-widescreen {
    padding-top: 8rem !important;
  }

  .pr-32-widescreen {
    padding-right: 8rem !important;
  }

  .pb-32-widescreen {
    padding-bottom: 8rem !important;
  }

  .pl-32-widescreen {
    padding-left: 8rem !important;
  }

  .px-32-widescreen {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .py-32-widescreen {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .p-36-widescreen {
    padding: 9rem !important;
  }

  .pt-36-widescreen {
    padding-top: 9rem !important;
  }

  .pr-36-widescreen {
    padding-right: 9rem !important;
  }

  .pb-36-widescreen {
    padding-bottom: 9rem !important;
  }

  .pl-36-widescreen {
    padding-left: 9rem !important;
  }

  .px-36-widescreen {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .py-36-widescreen {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .p-40-widescreen {
    padding: 10rem !important;
  }

  .pt-40-widescreen {
    padding-top: 10rem !important;
  }

  .pr-40-widescreen {
    padding-right: 10rem !important;
  }

  .pb-40-widescreen {
    padding-bottom: 10rem !important;
  }

  .pl-40-widescreen {
    padding-left: 10rem !important;
  }

  .px-40-widescreen {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .py-40-widescreen {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .p-44-widescreen {
    padding: 11rem !important;
  }

  .pt-44-widescreen {
    padding-top: 11rem !important;
  }

  .pr-44-widescreen {
    padding-right: 11rem !important;
  }

  .pb-44-widescreen {
    padding-bottom: 11rem !important;
  }

  .pl-44-widescreen {
    padding-left: 11rem !important;
  }

  .px-44-widescreen {
    padding-left: 11rem !important;
    padding-right: 11rem !important;
  }

  .py-44-widescreen {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .p-48-widescreen {
    padding: 12rem !important;
  }

  .pt-48-widescreen {
    padding-top: 12rem !important;
  }

  .pr-48-widescreen {
    padding-right: 12rem !important;
  }

  .pb-48-widescreen {
    padding-bottom: 12rem !important;
  }

  .pl-48-widescreen {
    padding-left: 12rem !important;
  }

  .px-48-widescreen {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .py-48-widescreen {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .p-52-widescreen {
    padding: 13rem !important;
  }

  .pt-52-widescreen {
    padding-top: 13rem !important;
  }

  .pr-52-widescreen {
    padding-right: 13rem !important;
  }

  .pb-52-widescreen {
    padding-bottom: 13rem !important;
  }

  .pl-52-widescreen {
    padding-left: 13rem !important;
  }

  .px-52-widescreen {
    padding-left: 13rem !important;
    padding-right: 13rem !important;
  }

  .py-52-widescreen {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }

  .p-56-widescreen {
    padding: 14rem !important;
  }

  .pt-56-widescreen {
    padding-top: 14rem !important;
  }

  .pr-56-widescreen {
    padding-right: 14rem !important;
  }

  .pb-56-widescreen {
    padding-bottom: 14rem !important;
  }

  .pl-56-widescreen {
    padding-left: 14rem !important;
  }

  .px-56-widescreen {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .py-56-widescreen {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .p-60-widescreen {
    padding: 15rem !important;
  }

  .pt-60-widescreen {
    padding-top: 15rem !important;
  }

  .pr-60-widescreen {
    padding-right: 15rem !important;
  }

  .pb-60-widescreen {
    padding-bottom: 15rem !important;
  }

  .pl-60-widescreen {
    padding-left: 15rem !important;
  }

  .px-60-widescreen {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .py-60-widescreen {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .p-64-widescreen {
    padding: 16rem !important;
  }

  .pt-64-widescreen {
    padding-top: 16rem !important;
  }

  .pr-64-widescreen {
    padding-right: 16rem !important;
  }

  .pb-64-widescreen {
    padding-bottom: 16rem !important;
  }

  .pl-64-widescreen {
    padding-left: 16rem !important;
  }

  .px-64-widescreen {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .py-64-widescreen {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .p-72-widescreen {
    padding: 18rem !important;
  }

  .pt-72-widescreen {
    padding-top: 18rem !important;
  }

  .pr-72-widescreen {
    padding-right: 18rem !important;
  }

  .pb-72-widescreen {
    padding-bottom: 18rem !important;
  }

  .pl-72-widescreen {
    padding-left: 18rem !important;
  }

  .px-72-widescreen {
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .py-72-widescreen {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .p-80-widescreen {
    padding: 20rem !important;
  }

  .pt-80-widescreen {
    padding-top: 20rem !important;
  }

  .pr-80-widescreen {
    padding-right: 20rem !important;
  }

  .pb-80-widescreen {
    padding-bottom: 20rem !important;
  }

  .pl-80-widescreen {
    padding-left: 20rem !important;
  }

  .px-80-widescreen {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .py-80-widescreen {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .p-96-widescreen {
    padding: 24rem !important;
  }

  .pt-96-widescreen {
    padding-top: 24rem !important;
  }

  .pr-96-widescreen {
    padding-right: 24rem !important;
  }

  .pb-96-widescreen {
    padding-bottom: 24rem !important;
  }

  .pl-96-widescreen {
    padding-left: 24rem !important;
  }

  .px-96-widescreen {
    padding-left: 24rem !important;
    padding-right: 24rem !important;
  }

  .py-96-widescreen {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .m-auto-widescreen-only {
    margin: auto !important;
  }

  .mt-auto-widescreen-only {
    margin-top: auto !important;
  }

  .mr-auto-widescreen-only {
    margin-right: auto !important;
  }

  .mb-auto-widescreen-only {
    margin-bottom: auto !important;
  }

  .ml-auto-widescreen-only {
    margin-left: auto !important;
  }

  .mx-auto-widescreen-only {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-auto-widescreen-only {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .m-0-widescreen-only {
    margin: 0 !important;
  }

  .mt-0-widescreen-only {
    margin-top: 0 !important;
  }

  .mr-0-widescreen-only {
    margin-right: 0 !important;
  }

  .mb-0-widescreen-only {
    margin-bottom: 0 !important;
  }

  .ml-0-widescreen-only {
    margin-left: 0 !important;
  }

  .mx-0-widescreen-only {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-0-widescreen-only {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-1-widescreen-only {
    margin: .25rem !important;
  }

  .-m-1-widescreen-only {
    margin: -.25rem !important;
  }

  .mt-1-widescreen-only {
    margin-top: .25rem !important;
  }

  .-mt-1-widescreen-only {
    margin-top: -.25rem !important;
  }

  .mr-1-widescreen-only {
    margin-right: .25rem !important;
  }

  .-mr-1-widescreen-only {
    margin-right: -.25rem !important;
  }

  .mb-1-widescreen-only {
    margin-bottom: .25rem !important;
  }

  .-mb-1-widescreen-only {
    margin-bottom: -.25rem !important;
  }

  .ml-1-widescreen-only {
    margin-left: .25rem !important;
  }

  .-ml-1-widescreen-only {
    margin-left: -.25rem !important;
  }

  .mx-1-widescreen-only {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .-mx-1-widescreen-only {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .my-1-widescreen-only {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .-my-1-widescreen-only {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .m-2-widescreen-only {
    margin: .5rem !important;
  }

  .-m-2-widescreen-only {
    margin: -.5rem !important;
  }

  .mt-2-widescreen-only {
    margin-top: .5rem !important;
  }

  .-mt-2-widescreen-only {
    margin-top: -.5rem !important;
  }

  .mr-2-widescreen-only {
    margin-right: .5rem !important;
  }

  .-mr-2-widescreen-only {
    margin-right: -.5rem !important;
  }

  .mb-2-widescreen-only {
    margin-bottom: .5rem !important;
  }

  .-mb-2-widescreen-only {
    margin-bottom: -.5rem !important;
  }

  .ml-2-widescreen-only {
    margin-left: .5rem !important;
  }

  .-ml-2-widescreen-only {
    margin-left: -.5rem !important;
  }

  .mx-2-widescreen-only {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .-mx-2-widescreen-only {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .my-2-widescreen-only {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .-my-2-widescreen-only {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .m-3-widescreen-only {
    margin: .75rem !important;
  }

  .-m-3-widescreen-only {
    margin: -.75rem !important;
  }

  .mt-3-widescreen-only {
    margin-top: .75rem !important;
  }

  .-mt-3-widescreen-only {
    margin-top: -.75rem !important;
  }

  .mr-3-widescreen-only {
    margin-right: .75rem !important;
  }

  .-mr-3-widescreen-only {
    margin-right: -.75rem !important;
  }

  .mb-3-widescreen-only {
    margin-bottom: .75rem !important;
  }

  .-mb-3-widescreen-only {
    margin-bottom: -.75rem !important;
  }

  .ml-3-widescreen-only {
    margin-left: .75rem !important;
  }

  .-ml-3-widescreen-only {
    margin-left: -.75rem !important;
  }

  .mx-3-widescreen-only {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .-mx-3-widescreen-only {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .my-3-widescreen-only {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .-my-3-widescreen-only {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .m-4-widescreen-only {
    margin: 1rem !important;
  }

  .-m-4-widescreen-only {
    margin: -1rem !important;
  }

  .mt-4-widescreen-only {
    margin-top: 1rem !important;
  }

  .-mt-4-widescreen-only {
    margin-top: -1rem !important;
  }

  .mr-4-widescreen-only {
    margin-right: 1rem !important;
  }

  .-mr-4-widescreen-only {
    margin-right: -1rem !important;
  }

  .mb-4-widescreen-only {
    margin-bottom: 1rem !important;
  }

  .-mb-4-widescreen-only {
    margin-bottom: -1rem !important;
  }

  .ml-4-widescreen-only {
    margin-left: 1rem !important;
  }

  .-ml-4-widescreen-only {
    margin-left: -1rem !important;
  }

  .mx-4-widescreen-only {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .-mx-4-widescreen-only {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .my-4-widescreen-only {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .-my-4-widescreen-only {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .m-5-widescreen-only {
    margin: 1.25rem !important;
  }

  .-m-5-widescreen-only {
    margin: -1.25rem !important;
  }

  .mt-5-widescreen-only {
    margin-top: 1.25rem !important;
  }

  .-mt-5-widescreen-only {
    margin-top: -1.25rem !important;
  }

  .mr-5-widescreen-only {
    margin-right: 1.25rem !important;
  }

  .-mr-5-widescreen-only {
    margin-right: -1.25rem !important;
  }

  .mb-5-widescreen-only {
    margin-bottom: 1.25rem !important;
  }

  .-mb-5-widescreen-only {
    margin-bottom: -1.25rem !important;
  }

  .ml-5-widescreen-only {
    margin-left: 1.25rem !important;
  }

  .-ml-5-widescreen-only {
    margin-left: -1.25rem !important;
  }

  .mx-5-widescreen-only {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .-mx-5-widescreen-only {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .my-5-widescreen-only {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .-my-5-widescreen-only {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .m-6-widescreen-only {
    margin: 1.5rem !important;
  }

  .-m-6-widescreen-only {
    margin: -1.5rem !important;
  }

  .mt-6-widescreen-only {
    margin-top: 1.5rem !important;
  }

  .-mt-6-widescreen-only {
    margin-top: -1.5rem !important;
  }

  .mr-6-widescreen-only {
    margin-right: 1.5rem !important;
  }

  .-mr-6-widescreen-only {
    margin-right: -1.5rem !important;
  }

  .mb-6-widescreen-only {
    margin-bottom: 1.5rem !important;
  }

  .-mb-6-widescreen-only {
    margin-bottom: -1.5rem !important;
  }

  .ml-6-widescreen-only {
    margin-left: 1.5rem !important;
  }

  .-ml-6-widescreen-only {
    margin-left: -1.5rem !important;
  }

  .mx-6-widescreen-only {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .-mx-6-widescreen-only {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .my-6-widescreen-only {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .-my-6-widescreen-only {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .m-7-widescreen-only {
    margin: 1.75rem !important;
  }

  .-m-7-widescreen-only {
    margin: -1.75rem !important;
  }

  .mt-7-widescreen-only {
    margin-top: 1.75rem !important;
  }

  .-mt-7-widescreen-only {
    margin-top: -1.75rem !important;
  }

  .mr-7-widescreen-only {
    margin-right: 1.75rem !important;
  }

  .-mr-7-widescreen-only {
    margin-right: -1.75rem !important;
  }

  .mb-7-widescreen-only {
    margin-bottom: 1.75rem !important;
  }

  .-mb-7-widescreen-only {
    margin-bottom: -1.75rem !important;
  }

  .ml-7-widescreen-only {
    margin-left: 1.75rem !important;
  }

  .-ml-7-widescreen-only {
    margin-left: -1.75rem !important;
  }

  .mx-7-widescreen-only {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .-mx-7-widescreen-only {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .my-7-widescreen-only {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .-my-7-widescreen-only {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .m-8-widescreen-only {
    margin: 2rem !important;
  }

  .-m-8-widescreen-only {
    margin: -2rem !important;
  }

  .mt-8-widescreen-only {
    margin-top: 2rem !important;
  }

  .-mt-8-widescreen-only {
    margin-top: -2rem !important;
  }

  .mr-8-widescreen-only {
    margin-right: 2rem !important;
  }

  .-mr-8-widescreen-only {
    margin-right: -2rem !important;
  }

  .mb-8-widescreen-only {
    margin-bottom: 2rem !important;
  }

  .-mb-8-widescreen-only {
    margin-bottom: -2rem !important;
  }

  .ml-8-widescreen-only {
    margin-left: 2rem !important;
  }

  .-ml-8-widescreen-only {
    margin-left: -2rem !important;
  }

  .mx-8-widescreen-only {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .-mx-8-widescreen-only {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .my-8-widescreen-only {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .-my-8-widescreen-only {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .m-9-widescreen-only {
    margin: 2.25rem !important;
  }

  .-m-9-widescreen-only {
    margin: -2.25rem !important;
  }

  .mt-9-widescreen-only {
    margin-top: 2.25rem !important;
  }

  .-mt-9-widescreen-only {
    margin-top: -2.25rem !important;
  }

  .mr-9-widescreen-only {
    margin-right: 2.25rem !important;
  }

  .-mr-9-widescreen-only {
    margin-right: -2.25rem !important;
  }

  .mb-9-widescreen-only {
    margin-bottom: 2.25rem !important;
  }

  .-mb-9-widescreen-only {
    margin-bottom: -2.25rem !important;
  }

  .ml-9-widescreen-only {
    margin-left: 2.25rem !important;
  }

  .-ml-9-widescreen-only {
    margin-left: -2.25rem !important;
  }

  .mx-9-widescreen-only {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .-mx-9-widescreen-only {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .my-9-widescreen-only {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .-my-9-widescreen-only {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .m-10-widescreen-only {
    margin: 2.5rem !important;
  }

  .-m-10-widescreen-only {
    margin: -2.5rem !important;
  }

  .mt-10-widescreen-only {
    margin-top: 2.5rem !important;
  }

  .-mt-10-widescreen-only {
    margin-top: -2.5rem !important;
  }

  .mr-10-widescreen-only {
    margin-right: 2.5rem !important;
  }

  .-mr-10-widescreen-only {
    margin-right: -2.5rem !important;
  }

  .mb-10-widescreen-only {
    margin-bottom: 2.5rem !important;
  }

  .-mb-10-widescreen-only {
    margin-bottom: -2.5rem !important;
  }

  .ml-10-widescreen-only {
    margin-left: 2.5rem !important;
  }

  .-ml-10-widescreen-only {
    margin-left: -2.5rem !important;
  }

  .mx-10-widescreen-only {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .-mx-10-widescreen-only {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .my-10-widescreen-only {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .-my-10-widescreen-only {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .m-11-widescreen-only {
    margin: 2.75rem !important;
  }

  .-m-11-widescreen-only {
    margin: -2.75rem !important;
  }

  .mt-11-widescreen-only {
    margin-top: 2.75rem !important;
  }

  .-mt-11-widescreen-only {
    margin-top: -2.75rem !important;
  }

  .mr-11-widescreen-only {
    margin-right: 2.75rem !important;
  }

  .-mr-11-widescreen-only {
    margin-right: -2.75rem !important;
  }

  .mb-11-widescreen-only {
    margin-bottom: 2.75rem !important;
  }

  .-mb-11-widescreen-only {
    margin-bottom: -2.75rem !important;
  }

  .ml-11-widescreen-only {
    margin-left: 2.75rem !important;
  }

  .-ml-11-widescreen-only {
    margin-left: -2.75rem !important;
  }

  .mx-11-widescreen-only {
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
  }

  .-mx-11-widescreen-only {
    margin-left: -2.75rem !important;
    margin-right: -2.75rem !important;
  }

  .my-11-widescreen-only {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .-my-11-widescreen-only {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .m-12-widescreen-only {
    margin: 3rem !important;
  }

  .-m-12-widescreen-only {
    margin: -3rem !important;
  }

  .mt-12-widescreen-only {
    margin-top: 3rem !important;
  }

  .-mt-12-widescreen-only {
    margin-top: -3rem !important;
  }

  .mr-12-widescreen-only {
    margin-right: 3rem !important;
  }

  .-mr-12-widescreen-only {
    margin-right: -3rem !important;
  }

  .mb-12-widescreen-only {
    margin-bottom: 3rem !important;
  }

  .-mb-12-widescreen-only {
    margin-bottom: -3rem !important;
  }

  .ml-12-widescreen-only {
    margin-left: 3rem !important;
  }

  .-ml-12-widescreen-only {
    margin-left: -3rem !important;
  }

  .mx-12-widescreen-only {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .-mx-12-widescreen-only {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-12-widescreen-only {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .-my-12-widescreen-only {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .m-14-widescreen-only {
    margin: 3.5rem !important;
  }

  .-m-14-widescreen-only {
    margin: -3.5rem !important;
  }

  .mt-14-widescreen-only {
    margin-top: 3.5rem !important;
  }

  .-mt-14-widescreen-only {
    margin-top: -3.5rem !important;
  }

  .mr-14-widescreen-only {
    margin-right: 3.5rem !important;
  }

  .-mr-14-widescreen-only {
    margin-right: -3.5rem !important;
  }

  .mb-14-widescreen-only {
    margin-bottom: 3.5rem !important;
  }

  .-mb-14-widescreen-only {
    margin-bottom: -3.5rem !important;
  }

  .ml-14-widescreen-only {
    margin-left: 3.5rem !important;
  }

  .-ml-14-widescreen-only {
    margin-left: -3.5rem !important;
  }

  .mx-14-widescreen-only {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .-mx-14-widescreen-only {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .my-14-widescreen-only {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .-my-14-widescreen-only {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .m-16-widescreen-only {
    margin: 4rem !important;
  }

  .-m-16-widescreen-only {
    margin: -4rem !important;
  }

  .mt-16-widescreen-only {
    margin-top: 4rem !important;
  }

  .-mt-16-widescreen-only {
    margin-top: -4rem !important;
  }

  .mr-16-widescreen-only {
    margin-right: 4rem !important;
  }

  .-mr-16-widescreen-only {
    margin-right: -4rem !important;
  }

  .mb-16-widescreen-only {
    margin-bottom: 4rem !important;
  }

  .-mb-16-widescreen-only {
    margin-bottom: -4rem !important;
  }

  .ml-16-widescreen-only {
    margin-left: 4rem !important;
  }

  .-ml-16-widescreen-only {
    margin-left: -4rem !important;
  }

  .mx-16-widescreen-only {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .-mx-16-widescreen-only {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .my-16-widescreen-only {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .-my-16-widescreen-only {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .m-20-widescreen-only {
    margin: 5rem !important;
  }

  .-m-20-widescreen-only {
    margin: -5rem !important;
  }

  .mt-20-widescreen-only {
    margin-top: 5rem !important;
  }

  .-mt-20-widescreen-only {
    margin-top: -5rem !important;
  }

  .mr-20-widescreen-only {
    margin-right: 5rem !important;
  }

  .-mr-20-widescreen-only {
    margin-right: -5rem !important;
  }

  .mb-20-widescreen-only {
    margin-bottom: 5rem !important;
  }

  .-mb-20-widescreen-only {
    margin-bottom: -5rem !important;
  }

  .ml-20-widescreen-only {
    margin-left: 5rem !important;
  }

  .-ml-20-widescreen-only {
    margin-left: -5rem !important;
  }

  .mx-20-widescreen-only {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .-mx-20-widescreen-only {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .my-20-widescreen-only {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .-my-20-widescreen-only {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .m-24-widescreen-only {
    margin: 6rem !important;
  }

  .-m-24-widescreen-only {
    margin: -6rem !important;
  }

  .mt-24-widescreen-only {
    margin-top: 6rem !important;
  }

  .-mt-24-widescreen-only {
    margin-top: -6rem !important;
  }

  .mr-24-widescreen-only {
    margin-right: 6rem !important;
  }

  .-mr-24-widescreen-only {
    margin-right: -6rem !important;
  }

  .mb-24-widescreen-only {
    margin-bottom: 6rem !important;
  }

  .-mb-24-widescreen-only {
    margin-bottom: -6rem !important;
  }

  .ml-24-widescreen-only {
    margin-left: 6rem !important;
  }

  .-ml-24-widescreen-only {
    margin-left: -6rem !important;
  }

  .mx-24-widescreen-only {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .-mx-24-widescreen-only {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .my-24-widescreen-only {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .-my-24-widescreen-only {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .m-28-widescreen-only {
    margin: 7rem !important;
  }

  .-m-28-widescreen-only {
    margin: -7rem !important;
  }

  .mt-28-widescreen-only {
    margin-top: 7rem !important;
  }

  .-mt-28-widescreen-only {
    margin-top: -7rem !important;
  }

  .mr-28-widescreen-only {
    margin-right: 7rem !important;
  }

  .-mr-28-widescreen-only {
    margin-right: -7rem !important;
  }

  .mb-28-widescreen-only {
    margin-bottom: 7rem !important;
  }

  .-mb-28-widescreen-only {
    margin-bottom: -7rem !important;
  }

  .ml-28-widescreen-only {
    margin-left: 7rem !important;
  }

  .-ml-28-widescreen-only {
    margin-left: -7rem !important;
  }

  .mx-28-widescreen-only {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .-mx-28-widescreen-only {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .my-28-widescreen-only {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .-my-28-widescreen-only {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .m-32-widescreen-only {
    margin: 8rem !important;
  }

  .-m-32-widescreen-only {
    margin: -8rem !important;
  }

  .mt-32-widescreen-only {
    margin-top: 8rem !important;
  }

  .-mt-32-widescreen-only {
    margin-top: -8rem !important;
  }

  .mr-32-widescreen-only {
    margin-right: 8rem !important;
  }

  .-mr-32-widescreen-only {
    margin-right: -8rem !important;
  }

  .mb-32-widescreen-only {
    margin-bottom: 8rem !important;
  }

  .-mb-32-widescreen-only {
    margin-bottom: -8rem !important;
  }

  .ml-32-widescreen-only {
    margin-left: 8rem !important;
  }

  .-ml-32-widescreen-only {
    margin-left: -8rem !important;
  }

  .mx-32-widescreen-only {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .-mx-32-widescreen-only {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .my-32-widescreen-only {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .-my-32-widescreen-only {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .m-36-widescreen-only {
    margin: 9rem !important;
  }

  .-m-36-widescreen-only {
    margin: -9rem !important;
  }

  .mt-36-widescreen-only {
    margin-top: 9rem !important;
  }

  .-mt-36-widescreen-only {
    margin-top: -9rem !important;
  }

  .mr-36-widescreen-only {
    margin-right: 9rem !important;
  }

  .-mr-36-widescreen-only {
    margin-right: -9rem !important;
  }

  .mb-36-widescreen-only {
    margin-bottom: 9rem !important;
  }

  .-mb-36-widescreen-only {
    margin-bottom: -9rem !important;
  }

  .ml-36-widescreen-only {
    margin-left: 9rem !important;
  }

  .-ml-36-widescreen-only {
    margin-left: -9rem !important;
  }

  .mx-36-widescreen-only {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .-mx-36-widescreen-only {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .my-36-widescreen-only {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .-my-36-widescreen-only {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .m-40-widescreen-only {
    margin: 10rem !important;
  }

  .-m-40-widescreen-only {
    margin: -10rem !important;
  }

  .mt-40-widescreen-only {
    margin-top: 10rem !important;
  }

  .-mt-40-widescreen-only {
    margin-top: -10rem !important;
  }

  .mr-40-widescreen-only {
    margin-right: 10rem !important;
  }

  .-mr-40-widescreen-only {
    margin-right: -10rem !important;
  }

  .mb-40-widescreen-only {
    margin-bottom: 10rem !important;
  }

  .-mb-40-widescreen-only {
    margin-bottom: -10rem !important;
  }

  .ml-40-widescreen-only {
    margin-left: 10rem !important;
  }

  .-ml-40-widescreen-only {
    margin-left: -10rem !important;
  }

  .mx-40-widescreen-only {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .-mx-40-widescreen-only {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .my-40-widescreen-only {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .-my-40-widescreen-only {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .m-44-widescreen-only {
    margin: 11rem !important;
  }

  .-m-44-widescreen-only {
    margin: -11rem !important;
  }

  .mt-44-widescreen-only {
    margin-top: 11rem !important;
  }

  .-mt-44-widescreen-only {
    margin-top: -11rem !important;
  }

  .mr-44-widescreen-only {
    margin-right: 11rem !important;
  }

  .-mr-44-widescreen-only {
    margin-right: -11rem !important;
  }

  .mb-44-widescreen-only {
    margin-bottom: 11rem !important;
  }

  .-mb-44-widescreen-only {
    margin-bottom: -11rem !important;
  }

  .ml-44-widescreen-only {
    margin-left: 11rem !important;
  }

  .-ml-44-widescreen-only {
    margin-left: -11rem !important;
  }

  .mx-44-widescreen-only {
    margin-left: 11rem !important;
    margin-right: 11rem !important;
  }

  .-mx-44-widescreen-only {
    margin-left: -11rem !important;
    margin-right: -11rem !important;
  }

  .my-44-widescreen-only {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .-my-44-widescreen-only {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .m-48-widescreen-only {
    margin: 12rem !important;
  }

  .-m-48-widescreen-only {
    margin: -12rem !important;
  }

  .mt-48-widescreen-only {
    margin-top: 12rem !important;
  }

  .-mt-48-widescreen-only {
    margin-top: -12rem !important;
  }

  .mr-48-widescreen-only {
    margin-right: 12rem !important;
  }

  .-mr-48-widescreen-only {
    margin-right: -12rem !important;
  }

  .mb-48-widescreen-only {
    margin-bottom: 12rem !important;
  }

  .-mb-48-widescreen-only {
    margin-bottom: -12rem !important;
  }

  .ml-48-widescreen-only {
    margin-left: 12rem !important;
  }

  .-ml-48-widescreen-only {
    margin-left: -12rem !important;
  }

  .mx-48-widescreen-only {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .-mx-48-widescreen-only {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .my-48-widescreen-only {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .-my-48-widescreen-only {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .m-52-widescreen-only {
    margin: 13rem !important;
  }

  .-m-52-widescreen-only {
    margin: -13rem !important;
  }

  .mt-52-widescreen-only {
    margin-top: 13rem !important;
  }

  .-mt-52-widescreen-only {
    margin-top: -13rem !important;
  }

  .mr-52-widescreen-only {
    margin-right: 13rem !important;
  }

  .-mr-52-widescreen-only {
    margin-right: -13rem !important;
  }

  .mb-52-widescreen-only {
    margin-bottom: 13rem !important;
  }

  .-mb-52-widescreen-only {
    margin-bottom: -13rem !important;
  }

  .ml-52-widescreen-only {
    margin-left: 13rem !important;
  }

  .-ml-52-widescreen-only {
    margin-left: -13rem !important;
  }

  .mx-52-widescreen-only {
    margin-left: 13rem !important;
    margin-right: 13rem !important;
  }

  .-mx-52-widescreen-only {
    margin-left: -13rem !important;
    margin-right: -13rem !important;
  }

  .my-52-widescreen-only {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }

  .-my-52-widescreen-only {
    margin-top: -13rem !important;
    margin-bottom: -13rem !important;
  }

  .m-56-widescreen-only {
    margin: 14rem !important;
  }

  .-m-56-widescreen-only {
    margin: -14rem !important;
  }

  .mt-56-widescreen-only {
    margin-top: 14rem !important;
  }

  .-mt-56-widescreen-only {
    margin-top: -14rem !important;
  }

  .mr-56-widescreen-only {
    margin-right: 14rem !important;
  }

  .-mr-56-widescreen-only {
    margin-right: -14rem !important;
  }

  .mb-56-widescreen-only {
    margin-bottom: 14rem !important;
  }

  .-mb-56-widescreen-only {
    margin-bottom: -14rem !important;
  }

  .ml-56-widescreen-only {
    margin-left: 14rem !important;
  }

  .-ml-56-widescreen-only {
    margin-left: -14rem !important;
  }

  .mx-56-widescreen-only {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .-mx-56-widescreen-only {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .my-56-widescreen-only {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .-my-56-widescreen-only {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .m-60-widescreen-only {
    margin: 15rem !important;
  }

  .-m-60-widescreen-only {
    margin: -15rem !important;
  }

  .mt-60-widescreen-only {
    margin-top: 15rem !important;
  }

  .-mt-60-widescreen-only {
    margin-top: -15rem !important;
  }

  .mr-60-widescreen-only {
    margin-right: 15rem !important;
  }

  .-mr-60-widescreen-only {
    margin-right: -15rem !important;
  }

  .mb-60-widescreen-only {
    margin-bottom: 15rem !important;
  }

  .-mb-60-widescreen-only {
    margin-bottom: -15rem !important;
  }

  .ml-60-widescreen-only {
    margin-left: 15rem !important;
  }

  .-ml-60-widescreen-only {
    margin-left: -15rem !important;
  }

  .mx-60-widescreen-only {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .-mx-60-widescreen-only {
    margin-left: -15rem !important;
    margin-right: -15rem !important;
  }

  .my-60-widescreen-only {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .-my-60-widescreen-only {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .m-64-widescreen-only {
    margin: 16rem !important;
  }

  .-m-64-widescreen-only {
    margin: -16rem !important;
  }

  .mt-64-widescreen-only {
    margin-top: 16rem !important;
  }

  .-mt-64-widescreen-only {
    margin-top: -16rem !important;
  }

  .mr-64-widescreen-only {
    margin-right: 16rem !important;
  }

  .-mr-64-widescreen-only {
    margin-right: -16rem !important;
  }

  .mb-64-widescreen-only {
    margin-bottom: 16rem !important;
  }

  .-mb-64-widescreen-only {
    margin-bottom: -16rem !important;
  }

  .ml-64-widescreen-only {
    margin-left: 16rem !important;
  }

  .-ml-64-widescreen-only {
    margin-left: -16rem !important;
  }

  .mx-64-widescreen-only {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .-mx-64-widescreen-only {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .my-64-widescreen-only {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .-my-64-widescreen-only {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .m-72-widescreen-only {
    margin: 18rem !important;
  }

  .-m-72-widescreen-only {
    margin: -18rem !important;
  }

  .mt-72-widescreen-only {
    margin-top: 18rem !important;
  }

  .-mt-72-widescreen-only {
    margin-top: -18rem !important;
  }

  .mr-72-widescreen-only {
    margin-right: 18rem !important;
  }

  .-mr-72-widescreen-only {
    margin-right: -18rem !important;
  }

  .mb-72-widescreen-only {
    margin-bottom: 18rem !important;
  }

  .-mb-72-widescreen-only {
    margin-bottom: -18rem !important;
  }

  .ml-72-widescreen-only {
    margin-left: 18rem !important;
  }

  .-ml-72-widescreen-only {
    margin-left: -18rem !important;
  }

  .mx-72-widescreen-only {
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .-mx-72-widescreen-only {
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .my-72-widescreen-only {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .-my-72-widescreen-only {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .m-80-widescreen-only {
    margin: 20rem !important;
  }

  .-m-80-widescreen-only {
    margin: -20rem !important;
  }

  .mt-80-widescreen-only {
    margin-top: 20rem !important;
  }

  .-mt-80-widescreen-only {
    margin-top: -20rem !important;
  }

  .mr-80-widescreen-only {
    margin-right: 20rem !important;
  }

  .-mr-80-widescreen-only {
    margin-right: -20rem !important;
  }

  .mb-80-widescreen-only {
    margin-bottom: 20rem !important;
  }

  .-mb-80-widescreen-only {
    margin-bottom: -20rem !important;
  }

  .ml-80-widescreen-only {
    margin-left: 20rem !important;
  }

  .-ml-80-widescreen-only {
    margin-left: -20rem !important;
  }

  .mx-80-widescreen-only {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .-mx-80-widescreen-only {
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .my-80-widescreen-only {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .-my-80-widescreen-only {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .m-96-widescreen-only {
    margin: 24rem !important;
  }

  .-m-96-widescreen-only {
    margin: -24rem !important;
  }

  .mt-96-widescreen-only {
    margin-top: 24rem !important;
  }

  .-mt-96-widescreen-only {
    margin-top: -24rem !important;
  }

  .mr-96-widescreen-only {
    margin-right: 24rem !important;
  }

  .-mr-96-widescreen-only {
    margin-right: -24rem !important;
  }

  .mb-96-widescreen-only {
    margin-bottom: 24rem !important;
  }

  .-mb-96-widescreen-only {
    margin-bottom: -24rem !important;
  }

  .ml-96-widescreen-only {
    margin-left: 24rem !important;
  }

  .-ml-96-widescreen-only {
    margin-left: -24rem !important;
  }

  .mx-96-widescreen-only {
    margin-left: 24rem !important;
    margin-right: 24rem !important;
  }

  .-mx-96-widescreen-only {
    margin-left: -24rem !important;
    margin-right: -24rem !important;
  }

  .my-96-widescreen-only {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }

  .-my-96-widescreen-only {
    margin-top: -24rem !important;
    margin-bottom: -24rem !important;
  }

  .p-auto-widescreen-only {
    padding: auto !important;
  }

  .pt-auto-widescreen-only {
    padding-top: auto !important;
  }

  .pr-auto-widescreen-only {
    padding-right: auto !important;
  }

  .pb-auto-widescreen-only {
    padding-bottom: auto !important;
  }

  .pl-auto-widescreen-only {
    padding-left: auto !important;
  }

  .px-auto-widescreen-only {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .py-auto-widescreen-only {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }

  .p-0-widescreen-only {
    padding: 0 !important;
  }

  .pt-0-widescreen-only {
    padding-top: 0 !important;
  }

  .pr-0-widescreen-only {
    padding-right: 0 !important;
  }

  .pb-0-widescreen-only {
    padding-bottom: 0 !important;
  }

  .pl-0-widescreen-only {
    padding-left: 0 !important;
  }

  .px-0-widescreen-only {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-0-widescreen-only {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-1-widescreen-only {
    padding: .25rem !important;
  }

  .pt-1-widescreen-only {
    padding-top: .25rem !important;
  }

  .pr-1-widescreen-only {
    padding-right: .25rem !important;
  }

  .pb-1-widescreen-only {
    padding-bottom: .25rem !important;
  }

  .pl-1-widescreen-only {
    padding-left: .25rem !important;
  }

  .px-1-widescreen-only {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .py-1-widescreen-only {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .p-2-widescreen-only {
    padding: .5rem !important;
  }

  .pt-2-widescreen-only {
    padding-top: .5rem !important;
  }

  .pr-2-widescreen-only {
    padding-right: .5rem !important;
  }

  .pb-2-widescreen-only {
    padding-bottom: .5rem !important;
  }

  .pl-2-widescreen-only {
    padding-left: .5rem !important;
  }

  .px-2-widescreen-only {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .py-2-widescreen-only {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .p-3-widescreen-only {
    padding: .75rem !important;
  }

  .pt-3-widescreen-only {
    padding-top: .75rem !important;
  }

  .pr-3-widescreen-only {
    padding-right: .75rem !important;
  }

  .pb-3-widescreen-only {
    padding-bottom: .75rem !important;
  }

  .pl-3-widescreen-only {
    padding-left: .75rem !important;
  }

  .px-3-widescreen-only {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .py-3-widescreen-only {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .p-4-widescreen-only {
    padding: 1rem !important;
  }

  .pt-4-widescreen-only {
    padding-top: 1rem !important;
  }

  .pr-4-widescreen-only {
    padding-right: 1rem !important;
  }

  .pb-4-widescreen-only {
    padding-bottom: 1rem !important;
  }

  .pl-4-widescreen-only {
    padding-left: 1rem !important;
  }

  .px-4-widescreen-only {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .py-4-widescreen-only {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .p-5-widescreen-only {
    padding: 1.25rem !important;
  }

  .pt-5-widescreen-only {
    padding-top: 1.25rem !important;
  }

  .pr-5-widescreen-only {
    padding-right: 1.25rem !important;
  }

  .pb-5-widescreen-only {
    padding-bottom: 1.25rem !important;
  }

  .pl-5-widescreen-only {
    padding-left: 1.25rem !important;
  }

  .px-5-widescreen-only {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .py-5-widescreen-only {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .p-6-widescreen-only {
    padding: 1.5rem !important;
  }

  .pt-6-widescreen-only {
    padding-top: 1.5rem !important;
  }

  .pr-6-widescreen-only {
    padding-right: 1.5rem !important;
  }

  .pb-6-widescreen-only {
    padding-bottom: 1.5rem !important;
  }

  .pl-6-widescreen-only {
    padding-left: 1.5rem !important;
  }

  .px-6-widescreen-only {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .py-6-widescreen-only {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .p-7-widescreen-only {
    padding: 1.75rem !important;
  }

  .pt-7-widescreen-only {
    padding-top: 1.75rem !important;
  }

  .pr-7-widescreen-only {
    padding-right: 1.75rem !important;
  }

  .pb-7-widescreen-only {
    padding-bottom: 1.75rem !important;
  }

  .pl-7-widescreen-only {
    padding-left: 1.75rem !important;
  }

  .px-7-widescreen-only {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .py-7-widescreen-only {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .p-8-widescreen-only {
    padding: 2rem !important;
  }

  .pt-8-widescreen-only {
    padding-top: 2rem !important;
  }

  .pr-8-widescreen-only {
    padding-right: 2rem !important;
  }

  .pb-8-widescreen-only {
    padding-bottom: 2rem !important;
  }

  .pl-8-widescreen-only {
    padding-left: 2rem !important;
  }

  .px-8-widescreen-only {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .py-8-widescreen-only {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .p-9-widescreen-only {
    padding: 2.25rem !important;
  }

  .pt-9-widescreen-only {
    padding-top: 2.25rem !important;
  }

  .pr-9-widescreen-only {
    padding-right: 2.25rem !important;
  }

  .pb-9-widescreen-only {
    padding-bottom: 2.25rem !important;
  }

  .pl-9-widescreen-only {
    padding-left: 2.25rem !important;
  }

  .px-9-widescreen-only {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .py-9-widescreen-only {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .p-10-widescreen-only {
    padding: 2.5rem !important;
  }

  .pt-10-widescreen-only {
    padding-top: 2.5rem !important;
  }

  .pr-10-widescreen-only {
    padding-right: 2.5rem !important;
  }

  .pb-10-widescreen-only {
    padding-bottom: 2.5rem !important;
  }

  .pl-10-widescreen-only {
    padding-left: 2.5rem !important;
  }

  .px-10-widescreen-only {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .py-10-widescreen-only {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .p-11-widescreen-only {
    padding: 2.75rem !important;
  }

  .pt-11-widescreen-only {
    padding-top: 2.75rem !important;
  }

  .pr-11-widescreen-only {
    padding-right: 2.75rem !important;
  }

  .pb-11-widescreen-only {
    padding-bottom: 2.75rem !important;
  }

  .pl-11-widescreen-only {
    padding-left: 2.75rem !important;
  }

  .px-11-widescreen-only {
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  .py-11-widescreen-only {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .p-12-widescreen-only {
    padding: 3rem !important;
  }

  .pt-12-widescreen-only {
    padding-top: 3rem !important;
  }

  .pr-12-widescreen-only {
    padding-right: 3rem !important;
  }

  .pb-12-widescreen-only {
    padding-bottom: 3rem !important;
  }

  .pl-12-widescreen-only {
    padding-left: 3rem !important;
  }

  .px-12-widescreen-only {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-12-widescreen-only {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .p-14-widescreen-only {
    padding: 3.5rem !important;
  }

  .pt-14-widescreen-only {
    padding-top: 3.5rem !important;
  }

  .pr-14-widescreen-only {
    padding-right: 3.5rem !important;
  }

  .pb-14-widescreen-only {
    padding-bottom: 3.5rem !important;
  }

  .pl-14-widescreen-only {
    padding-left: 3.5rem !important;
  }

  .px-14-widescreen-only {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .py-14-widescreen-only {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .p-16-widescreen-only {
    padding: 4rem !important;
  }

  .pt-16-widescreen-only {
    padding-top: 4rem !important;
  }

  .pr-16-widescreen-only {
    padding-right: 4rem !important;
  }

  .pb-16-widescreen-only {
    padding-bottom: 4rem !important;
  }

  .pl-16-widescreen-only {
    padding-left: 4rem !important;
  }

  .px-16-widescreen-only {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .py-16-widescreen-only {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .p-20-widescreen-only {
    padding: 5rem !important;
  }

  .pt-20-widescreen-only {
    padding-top: 5rem !important;
  }

  .pr-20-widescreen-only {
    padding-right: 5rem !important;
  }

  .pb-20-widescreen-only {
    padding-bottom: 5rem !important;
  }

  .pl-20-widescreen-only {
    padding-left: 5rem !important;
  }

  .px-20-widescreen-only {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .py-20-widescreen-only {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .p-24-widescreen-only {
    padding: 6rem !important;
  }

  .pt-24-widescreen-only {
    padding-top: 6rem !important;
  }

  .pr-24-widescreen-only {
    padding-right: 6rem !important;
  }

  .pb-24-widescreen-only {
    padding-bottom: 6rem !important;
  }

  .pl-24-widescreen-only {
    padding-left: 6rem !important;
  }

  .px-24-widescreen-only {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .py-24-widescreen-only {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .p-28-widescreen-only {
    padding: 7rem !important;
  }

  .pt-28-widescreen-only {
    padding-top: 7rem !important;
  }

  .pr-28-widescreen-only {
    padding-right: 7rem !important;
  }

  .pb-28-widescreen-only {
    padding-bottom: 7rem !important;
  }

  .pl-28-widescreen-only {
    padding-left: 7rem !important;
  }

  .px-28-widescreen-only {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .py-28-widescreen-only {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .p-32-widescreen-only {
    padding: 8rem !important;
  }

  .pt-32-widescreen-only {
    padding-top: 8rem !important;
  }

  .pr-32-widescreen-only {
    padding-right: 8rem !important;
  }

  .pb-32-widescreen-only {
    padding-bottom: 8rem !important;
  }

  .pl-32-widescreen-only {
    padding-left: 8rem !important;
  }

  .px-32-widescreen-only {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .py-32-widescreen-only {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .p-36-widescreen-only {
    padding: 9rem !important;
  }

  .pt-36-widescreen-only {
    padding-top: 9rem !important;
  }

  .pr-36-widescreen-only {
    padding-right: 9rem !important;
  }

  .pb-36-widescreen-only {
    padding-bottom: 9rem !important;
  }

  .pl-36-widescreen-only {
    padding-left: 9rem !important;
  }

  .px-36-widescreen-only {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .py-36-widescreen-only {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .p-40-widescreen-only {
    padding: 10rem !important;
  }

  .pt-40-widescreen-only {
    padding-top: 10rem !important;
  }

  .pr-40-widescreen-only {
    padding-right: 10rem !important;
  }

  .pb-40-widescreen-only {
    padding-bottom: 10rem !important;
  }

  .pl-40-widescreen-only {
    padding-left: 10rem !important;
  }

  .px-40-widescreen-only {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .py-40-widescreen-only {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .p-44-widescreen-only {
    padding: 11rem !important;
  }

  .pt-44-widescreen-only {
    padding-top: 11rem !important;
  }

  .pr-44-widescreen-only {
    padding-right: 11rem !important;
  }

  .pb-44-widescreen-only {
    padding-bottom: 11rem !important;
  }

  .pl-44-widescreen-only {
    padding-left: 11rem !important;
  }

  .px-44-widescreen-only {
    padding-left: 11rem !important;
    padding-right: 11rem !important;
  }

  .py-44-widescreen-only {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .p-48-widescreen-only {
    padding: 12rem !important;
  }

  .pt-48-widescreen-only {
    padding-top: 12rem !important;
  }

  .pr-48-widescreen-only {
    padding-right: 12rem !important;
  }

  .pb-48-widescreen-only {
    padding-bottom: 12rem !important;
  }

  .pl-48-widescreen-only {
    padding-left: 12rem !important;
  }

  .px-48-widescreen-only {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .py-48-widescreen-only {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .p-52-widescreen-only {
    padding: 13rem !important;
  }

  .pt-52-widescreen-only {
    padding-top: 13rem !important;
  }

  .pr-52-widescreen-only {
    padding-right: 13rem !important;
  }

  .pb-52-widescreen-only {
    padding-bottom: 13rem !important;
  }

  .pl-52-widescreen-only {
    padding-left: 13rem !important;
  }

  .px-52-widescreen-only {
    padding-left: 13rem !important;
    padding-right: 13rem !important;
  }

  .py-52-widescreen-only {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }

  .p-56-widescreen-only {
    padding: 14rem !important;
  }

  .pt-56-widescreen-only {
    padding-top: 14rem !important;
  }

  .pr-56-widescreen-only {
    padding-right: 14rem !important;
  }

  .pb-56-widescreen-only {
    padding-bottom: 14rem !important;
  }

  .pl-56-widescreen-only {
    padding-left: 14rem !important;
  }

  .px-56-widescreen-only {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .py-56-widescreen-only {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .p-60-widescreen-only {
    padding: 15rem !important;
  }

  .pt-60-widescreen-only {
    padding-top: 15rem !important;
  }

  .pr-60-widescreen-only {
    padding-right: 15rem !important;
  }

  .pb-60-widescreen-only {
    padding-bottom: 15rem !important;
  }

  .pl-60-widescreen-only {
    padding-left: 15rem !important;
  }

  .px-60-widescreen-only {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .py-60-widescreen-only {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .p-64-widescreen-only {
    padding: 16rem !important;
  }

  .pt-64-widescreen-only {
    padding-top: 16rem !important;
  }

  .pr-64-widescreen-only {
    padding-right: 16rem !important;
  }

  .pb-64-widescreen-only {
    padding-bottom: 16rem !important;
  }

  .pl-64-widescreen-only {
    padding-left: 16rem !important;
  }

  .px-64-widescreen-only {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .py-64-widescreen-only {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .p-72-widescreen-only {
    padding: 18rem !important;
  }

  .pt-72-widescreen-only {
    padding-top: 18rem !important;
  }

  .pr-72-widescreen-only {
    padding-right: 18rem !important;
  }

  .pb-72-widescreen-only {
    padding-bottom: 18rem !important;
  }

  .pl-72-widescreen-only {
    padding-left: 18rem !important;
  }

  .px-72-widescreen-only {
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .py-72-widescreen-only {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .p-80-widescreen-only {
    padding: 20rem !important;
  }

  .pt-80-widescreen-only {
    padding-top: 20rem !important;
  }

  .pr-80-widescreen-only {
    padding-right: 20rem !important;
  }

  .pb-80-widescreen-only {
    padding-bottom: 20rem !important;
  }

  .pl-80-widescreen-only {
    padding-left: 20rem !important;
  }

  .px-80-widescreen-only {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .py-80-widescreen-only {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .p-96-widescreen-only {
    padding: 24rem !important;
  }

  .pt-96-widescreen-only {
    padding-top: 24rem !important;
  }

  .pr-96-widescreen-only {
    padding-right: 24rem !important;
  }

  .pb-96-widescreen-only {
    padding-bottom: 24rem !important;
  }

  .pl-96-widescreen-only {
    padding-left: 24rem !important;
  }

  .px-96-widescreen-only {
    padding-left: 24rem !important;
    padding-right: 24rem !important;
  }

  .py-96-widescreen-only {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
}

@media screen and (width >= 1408px) {
  .m-auto-fullhd {
    margin: auto !important;
  }

  .mt-auto-fullhd {
    margin-top: auto !important;
  }

  .mr-auto-fullhd {
    margin-right: auto !important;
  }

  .mb-auto-fullhd {
    margin-bottom: auto !important;
  }

  .ml-auto-fullhd {
    margin-left: auto !important;
  }

  .mx-auto-fullhd {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-auto-fullhd {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .m-0-fullhd {
    margin: 0 !important;
  }

  .mt-0-fullhd {
    margin-top: 0 !important;
  }

  .mr-0-fullhd {
    margin-right: 0 !important;
  }

  .mb-0-fullhd {
    margin-bottom: 0 !important;
  }

  .ml-0-fullhd {
    margin-left: 0 !important;
  }

  .mx-0-fullhd {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-0-fullhd {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-1-fullhd {
    margin: .25rem !important;
  }

  .-m-1-fullhd {
    margin: -.25rem !important;
  }

  .mt-1-fullhd {
    margin-top: .25rem !important;
  }

  .-mt-1-fullhd {
    margin-top: -.25rem !important;
  }

  .mr-1-fullhd {
    margin-right: .25rem !important;
  }

  .-mr-1-fullhd {
    margin-right: -.25rem !important;
  }

  .mb-1-fullhd {
    margin-bottom: .25rem !important;
  }

  .-mb-1-fullhd {
    margin-bottom: -.25rem !important;
  }

  .ml-1-fullhd {
    margin-left: .25rem !important;
  }

  .-ml-1-fullhd {
    margin-left: -.25rem !important;
  }

  .mx-1-fullhd {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .-mx-1-fullhd {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .my-1-fullhd {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .-my-1-fullhd {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .m-2-fullhd {
    margin: .5rem !important;
  }

  .-m-2-fullhd {
    margin: -.5rem !important;
  }

  .mt-2-fullhd {
    margin-top: .5rem !important;
  }

  .-mt-2-fullhd {
    margin-top: -.5rem !important;
  }

  .mr-2-fullhd {
    margin-right: .5rem !important;
  }

  .-mr-2-fullhd {
    margin-right: -.5rem !important;
  }

  .mb-2-fullhd {
    margin-bottom: .5rem !important;
  }

  .-mb-2-fullhd {
    margin-bottom: -.5rem !important;
  }

  .ml-2-fullhd {
    margin-left: .5rem !important;
  }

  .-ml-2-fullhd {
    margin-left: -.5rem !important;
  }

  .mx-2-fullhd {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .-mx-2-fullhd {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .my-2-fullhd {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .-my-2-fullhd {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .m-3-fullhd {
    margin: .75rem !important;
  }

  .-m-3-fullhd {
    margin: -.75rem !important;
  }

  .mt-3-fullhd {
    margin-top: .75rem !important;
  }

  .-mt-3-fullhd {
    margin-top: -.75rem !important;
  }

  .mr-3-fullhd {
    margin-right: .75rem !important;
  }

  .-mr-3-fullhd {
    margin-right: -.75rem !important;
  }

  .mb-3-fullhd {
    margin-bottom: .75rem !important;
  }

  .-mb-3-fullhd {
    margin-bottom: -.75rem !important;
  }

  .ml-3-fullhd {
    margin-left: .75rem !important;
  }

  .-ml-3-fullhd {
    margin-left: -.75rem !important;
  }

  .mx-3-fullhd {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .-mx-3-fullhd {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .my-3-fullhd {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .-my-3-fullhd {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .m-4-fullhd {
    margin: 1rem !important;
  }

  .-m-4-fullhd {
    margin: -1rem !important;
  }

  .mt-4-fullhd {
    margin-top: 1rem !important;
  }

  .-mt-4-fullhd {
    margin-top: -1rem !important;
  }

  .mr-4-fullhd {
    margin-right: 1rem !important;
  }

  .-mr-4-fullhd {
    margin-right: -1rem !important;
  }

  .mb-4-fullhd {
    margin-bottom: 1rem !important;
  }

  .-mb-4-fullhd {
    margin-bottom: -1rem !important;
  }

  .ml-4-fullhd {
    margin-left: 1rem !important;
  }

  .-ml-4-fullhd {
    margin-left: -1rem !important;
  }

  .mx-4-fullhd {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .-mx-4-fullhd {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .my-4-fullhd {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .-my-4-fullhd {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .m-5-fullhd {
    margin: 1.25rem !important;
  }

  .-m-5-fullhd {
    margin: -1.25rem !important;
  }

  .mt-5-fullhd {
    margin-top: 1.25rem !important;
  }

  .-mt-5-fullhd {
    margin-top: -1.25rem !important;
  }

  .mr-5-fullhd {
    margin-right: 1.25rem !important;
  }

  .-mr-5-fullhd {
    margin-right: -1.25rem !important;
  }

  .mb-5-fullhd {
    margin-bottom: 1.25rem !important;
  }

  .-mb-5-fullhd {
    margin-bottom: -1.25rem !important;
  }

  .ml-5-fullhd {
    margin-left: 1.25rem !important;
  }

  .-ml-5-fullhd {
    margin-left: -1.25rem !important;
  }

  .mx-5-fullhd {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .-mx-5-fullhd {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .my-5-fullhd {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .-my-5-fullhd {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .m-6-fullhd {
    margin: 1.5rem !important;
  }

  .-m-6-fullhd {
    margin: -1.5rem !important;
  }

  .mt-6-fullhd {
    margin-top: 1.5rem !important;
  }

  .-mt-6-fullhd {
    margin-top: -1.5rem !important;
  }

  .mr-6-fullhd {
    margin-right: 1.5rem !important;
  }

  .-mr-6-fullhd {
    margin-right: -1.5rem !important;
  }

  .mb-6-fullhd {
    margin-bottom: 1.5rem !important;
  }

  .-mb-6-fullhd {
    margin-bottom: -1.5rem !important;
  }

  .ml-6-fullhd {
    margin-left: 1.5rem !important;
  }

  .-ml-6-fullhd {
    margin-left: -1.5rem !important;
  }

  .mx-6-fullhd {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .-mx-6-fullhd {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .my-6-fullhd {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .-my-6-fullhd {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .m-7-fullhd {
    margin: 1.75rem !important;
  }

  .-m-7-fullhd {
    margin: -1.75rem !important;
  }

  .mt-7-fullhd {
    margin-top: 1.75rem !important;
  }

  .-mt-7-fullhd {
    margin-top: -1.75rem !important;
  }

  .mr-7-fullhd {
    margin-right: 1.75rem !important;
  }

  .-mr-7-fullhd {
    margin-right: -1.75rem !important;
  }

  .mb-7-fullhd {
    margin-bottom: 1.75rem !important;
  }

  .-mb-7-fullhd {
    margin-bottom: -1.75rem !important;
  }

  .ml-7-fullhd {
    margin-left: 1.75rem !important;
  }

  .-ml-7-fullhd {
    margin-left: -1.75rem !important;
  }

  .mx-7-fullhd {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .-mx-7-fullhd {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .my-7-fullhd {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .-my-7-fullhd {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .m-8-fullhd {
    margin: 2rem !important;
  }

  .-m-8-fullhd {
    margin: -2rem !important;
  }

  .mt-8-fullhd {
    margin-top: 2rem !important;
  }

  .-mt-8-fullhd {
    margin-top: -2rem !important;
  }

  .mr-8-fullhd {
    margin-right: 2rem !important;
  }

  .-mr-8-fullhd {
    margin-right: -2rem !important;
  }

  .mb-8-fullhd {
    margin-bottom: 2rem !important;
  }

  .-mb-8-fullhd {
    margin-bottom: -2rem !important;
  }

  .ml-8-fullhd {
    margin-left: 2rem !important;
  }

  .-ml-8-fullhd {
    margin-left: -2rem !important;
  }

  .mx-8-fullhd {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .-mx-8-fullhd {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .my-8-fullhd {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .-my-8-fullhd {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .m-9-fullhd {
    margin: 2.25rem !important;
  }

  .-m-9-fullhd {
    margin: -2.25rem !important;
  }

  .mt-9-fullhd {
    margin-top: 2.25rem !important;
  }

  .-mt-9-fullhd {
    margin-top: -2.25rem !important;
  }

  .mr-9-fullhd {
    margin-right: 2.25rem !important;
  }

  .-mr-9-fullhd {
    margin-right: -2.25rem !important;
  }

  .mb-9-fullhd {
    margin-bottom: 2.25rem !important;
  }

  .-mb-9-fullhd {
    margin-bottom: -2.25rem !important;
  }

  .ml-9-fullhd {
    margin-left: 2.25rem !important;
  }

  .-ml-9-fullhd {
    margin-left: -2.25rem !important;
  }

  .mx-9-fullhd {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .-mx-9-fullhd {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .my-9-fullhd {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .-my-9-fullhd {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .m-10-fullhd {
    margin: 2.5rem !important;
  }

  .-m-10-fullhd {
    margin: -2.5rem !important;
  }

  .mt-10-fullhd {
    margin-top: 2.5rem !important;
  }

  .-mt-10-fullhd {
    margin-top: -2.5rem !important;
  }

  .mr-10-fullhd {
    margin-right: 2.5rem !important;
  }

  .-mr-10-fullhd {
    margin-right: -2.5rem !important;
  }

  .mb-10-fullhd {
    margin-bottom: 2.5rem !important;
  }

  .-mb-10-fullhd {
    margin-bottom: -2.5rem !important;
  }

  .ml-10-fullhd {
    margin-left: 2.5rem !important;
  }

  .-ml-10-fullhd {
    margin-left: -2.5rem !important;
  }

  .mx-10-fullhd {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .-mx-10-fullhd {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .my-10-fullhd {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .-my-10-fullhd {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .m-11-fullhd {
    margin: 2.75rem !important;
  }

  .-m-11-fullhd {
    margin: -2.75rem !important;
  }

  .mt-11-fullhd {
    margin-top: 2.75rem !important;
  }

  .-mt-11-fullhd {
    margin-top: -2.75rem !important;
  }

  .mr-11-fullhd {
    margin-right: 2.75rem !important;
  }

  .-mr-11-fullhd {
    margin-right: -2.75rem !important;
  }

  .mb-11-fullhd {
    margin-bottom: 2.75rem !important;
  }

  .-mb-11-fullhd {
    margin-bottom: -2.75rem !important;
  }

  .ml-11-fullhd {
    margin-left: 2.75rem !important;
  }

  .-ml-11-fullhd {
    margin-left: -2.75rem !important;
  }

  .mx-11-fullhd {
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
  }

  .-mx-11-fullhd {
    margin-left: -2.75rem !important;
    margin-right: -2.75rem !important;
  }

  .my-11-fullhd {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .-my-11-fullhd {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .m-12-fullhd {
    margin: 3rem !important;
  }

  .-m-12-fullhd {
    margin: -3rem !important;
  }

  .mt-12-fullhd {
    margin-top: 3rem !important;
  }

  .-mt-12-fullhd {
    margin-top: -3rem !important;
  }

  .mr-12-fullhd {
    margin-right: 3rem !important;
  }

  .-mr-12-fullhd {
    margin-right: -3rem !important;
  }

  .mb-12-fullhd {
    margin-bottom: 3rem !important;
  }

  .-mb-12-fullhd {
    margin-bottom: -3rem !important;
  }

  .ml-12-fullhd {
    margin-left: 3rem !important;
  }

  .-ml-12-fullhd {
    margin-left: -3rem !important;
  }

  .mx-12-fullhd {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .-mx-12-fullhd {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-12-fullhd {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .-my-12-fullhd {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .m-14-fullhd {
    margin: 3.5rem !important;
  }

  .-m-14-fullhd {
    margin: -3.5rem !important;
  }

  .mt-14-fullhd {
    margin-top: 3.5rem !important;
  }

  .-mt-14-fullhd {
    margin-top: -3.5rem !important;
  }

  .mr-14-fullhd {
    margin-right: 3.5rem !important;
  }

  .-mr-14-fullhd {
    margin-right: -3.5rem !important;
  }

  .mb-14-fullhd {
    margin-bottom: 3.5rem !important;
  }

  .-mb-14-fullhd {
    margin-bottom: -3.5rem !important;
  }

  .ml-14-fullhd {
    margin-left: 3.5rem !important;
  }

  .-ml-14-fullhd {
    margin-left: -3.5rem !important;
  }

  .mx-14-fullhd {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .-mx-14-fullhd {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .my-14-fullhd {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .-my-14-fullhd {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .m-16-fullhd {
    margin: 4rem !important;
  }

  .-m-16-fullhd {
    margin: -4rem !important;
  }

  .mt-16-fullhd {
    margin-top: 4rem !important;
  }

  .-mt-16-fullhd {
    margin-top: -4rem !important;
  }

  .mr-16-fullhd {
    margin-right: 4rem !important;
  }

  .-mr-16-fullhd {
    margin-right: -4rem !important;
  }

  .mb-16-fullhd {
    margin-bottom: 4rem !important;
  }

  .-mb-16-fullhd {
    margin-bottom: -4rem !important;
  }

  .ml-16-fullhd {
    margin-left: 4rem !important;
  }

  .-ml-16-fullhd {
    margin-left: -4rem !important;
  }

  .mx-16-fullhd {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .-mx-16-fullhd {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .my-16-fullhd {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .-my-16-fullhd {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .m-20-fullhd {
    margin: 5rem !important;
  }

  .-m-20-fullhd {
    margin: -5rem !important;
  }

  .mt-20-fullhd {
    margin-top: 5rem !important;
  }

  .-mt-20-fullhd {
    margin-top: -5rem !important;
  }

  .mr-20-fullhd {
    margin-right: 5rem !important;
  }

  .-mr-20-fullhd {
    margin-right: -5rem !important;
  }

  .mb-20-fullhd {
    margin-bottom: 5rem !important;
  }

  .-mb-20-fullhd {
    margin-bottom: -5rem !important;
  }

  .ml-20-fullhd {
    margin-left: 5rem !important;
  }

  .-ml-20-fullhd {
    margin-left: -5rem !important;
  }

  .mx-20-fullhd {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .-mx-20-fullhd {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .my-20-fullhd {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .-my-20-fullhd {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .m-24-fullhd {
    margin: 6rem !important;
  }

  .-m-24-fullhd {
    margin: -6rem !important;
  }

  .mt-24-fullhd {
    margin-top: 6rem !important;
  }

  .-mt-24-fullhd {
    margin-top: -6rem !important;
  }

  .mr-24-fullhd {
    margin-right: 6rem !important;
  }

  .-mr-24-fullhd {
    margin-right: -6rem !important;
  }

  .mb-24-fullhd {
    margin-bottom: 6rem !important;
  }

  .-mb-24-fullhd {
    margin-bottom: -6rem !important;
  }

  .ml-24-fullhd {
    margin-left: 6rem !important;
  }

  .-ml-24-fullhd {
    margin-left: -6rem !important;
  }

  .mx-24-fullhd {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .-mx-24-fullhd {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .my-24-fullhd {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .-my-24-fullhd {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .m-28-fullhd {
    margin: 7rem !important;
  }

  .-m-28-fullhd {
    margin: -7rem !important;
  }

  .mt-28-fullhd {
    margin-top: 7rem !important;
  }

  .-mt-28-fullhd {
    margin-top: -7rem !important;
  }

  .mr-28-fullhd {
    margin-right: 7rem !important;
  }

  .-mr-28-fullhd {
    margin-right: -7rem !important;
  }

  .mb-28-fullhd {
    margin-bottom: 7rem !important;
  }

  .-mb-28-fullhd {
    margin-bottom: -7rem !important;
  }

  .ml-28-fullhd {
    margin-left: 7rem !important;
  }

  .-ml-28-fullhd {
    margin-left: -7rem !important;
  }

  .mx-28-fullhd {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .-mx-28-fullhd {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .my-28-fullhd {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .-my-28-fullhd {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .m-32-fullhd {
    margin: 8rem !important;
  }

  .-m-32-fullhd {
    margin: -8rem !important;
  }

  .mt-32-fullhd {
    margin-top: 8rem !important;
  }

  .-mt-32-fullhd {
    margin-top: -8rem !important;
  }

  .mr-32-fullhd {
    margin-right: 8rem !important;
  }

  .-mr-32-fullhd {
    margin-right: -8rem !important;
  }

  .mb-32-fullhd {
    margin-bottom: 8rem !important;
  }

  .-mb-32-fullhd {
    margin-bottom: -8rem !important;
  }

  .ml-32-fullhd {
    margin-left: 8rem !important;
  }

  .-ml-32-fullhd {
    margin-left: -8rem !important;
  }

  .mx-32-fullhd {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .-mx-32-fullhd {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .my-32-fullhd {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .-my-32-fullhd {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .m-36-fullhd {
    margin: 9rem !important;
  }

  .-m-36-fullhd {
    margin: -9rem !important;
  }

  .mt-36-fullhd {
    margin-top: 9rem !important;
  }

  .-mt-36-fullhd {
    margin-top: -9rem !important;
  }

  .mr-36-fullhd {
    margin-right: 9rem !important;
  }

  .-mr-36-fullhd {
    margin-right: -9rem !important;
  }

  .mb-36-fullhd {
    margin-bottom: 9rem !important;
  }

  .-mb-36-fullhd {
    margin-bottom: -9rem !important;
  }

  .ml-36-fullhd {
    margin-left: 9rem !important;
  }

  .-ml-36-fullhd {
    margin-left: -9rem !important;
  }

  .mx-36-fullhd {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .-mx-36-fullhd {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .my-36-fullhd {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .-my-36-fullhd {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .m-40-fullhd {
    margin: 10rem !important;
  }

  .-m-40-fullhd {
    margin: -10rem !important;
  }

  .mt-40-fullhd {
    margin-top: 10rem !important;
  }

  .-mt-40-fullhd {
    margin-top: -10rem !important;
  }

  .mr-40-fullhd {
    margin-right: 10rem !important;
  }

  .-mr-40-fullhd {
    margin-right: -10rem !important;
  }

  .mb-40-fullhd {
    margin-bottom: 10rem !important;
  }

  .-mb-40-fullhd {
    margin-bottom: -10rem !important;
  }

  .ml-40-fullhd {
    margin-left: 10rem !important;
  }

  .-ml-40-fullhd {
    margin-left: -10rem !important;
  }

  .mx-40-fullhd {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .-mx-40-fullhd {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .my-40-fullhd {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .-my-40-fullhd {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .m-44-fullhd {
    margin: 11rem !important;
  }

  .-m-44-fullhd {
    margin: -11rem !important;
  }

  .mt-44-fullhd {
    margin-top: 11rem !important;
  }

  .-mt-44-fullhd {
    margin-top: -11rem !important;
  }

  .mr-44-fullhd {
    margin-right: 11rem !important;
  }

  .-mr-44-fullhd {
    margin-right: -11rem !important;
  }

  .mb-44-fullhd {
    margin-bottom: 11rem !important;
  }

  .-mb-44-fullhd {
    margin-bottom: -11rem !important;
  }

  .ml-44-fullhd {
    margin-left: 11rem !important;
  }

  .-ml-44-fullhd {
    margin-left: -11rem !important;
  }

  .mx-44-fullhd {
    margin-left: 11rem !important;
    margin-right: 11rem !important;
  }

  .-mx-44-fullhd {
    margin-left: -11rem !important;
    margin-right: -11rem !important;
  }

  .my-44-fullhd {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .-my-44-fullhd {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .m-48-fullhd {
    margin: 12rem !important;
  }

  .-m-48-fullhd {
    margin: -12rem !important;
  }

  .mt-48-fullhd {
    margin-top: 12rem !important;
  }

  .-mt-48-fullhd {
    margin-top: -12rem !important;
  }

  .mr-48-fullhd {
    margin-right: 12rem !important;
  }

  .-mr-48-fullhd {
    margin-right: -12rem !important;
  }

  .mb-48-fullhd {
    margin-bottom: 12rem !important;
  }

  .-mb-48-fullhd {
    margin-bottom: -12rem !important;
  }

  .ml-48-fullhd {
    margin-left: 12rem !important;
  }

  .-ml-48-fullhd {
    margin-left: -12rem !important;
  }

  .mx-48-fullhd {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .-mx-48-fullhd {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .my-48-fullhd {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .-my-48-fullhd {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .m-52-fullhd {
    margin: 13rem !important;
  }

  .-m-52-fullhd {
    margin: -13rem !important;
  }

  .mt-52-fullhd {
    margin-top: 13rem !important;
  }

  .-mt-52-fullhd {
    margin-top: -13rem !important;
  }

  .mr-52-fullhd {
    margin-right: 13rem !important;
  }

  .-mr-52-fullhd {
    margin-right: -13rem !important;
  }

  .mb-52-fullhd {
    margin-bottom: 13rem !important;
  }

  .-mb-52-fullhd {
    margin-bottom: -13rem !important;
  }

  .ml-52-fullhd {
    margin-left: 13rem !important;
  }

  .-ml-52-fullhd {
    margin-left: -13rem !important;
  }

  .mx-52-fullhd {
    margin-left: 13rem !important;
    margin-right: 13rem !important;
  }

  .-mx-52-fullhd {
    margin-left: -13rem !important;
    margin-right: -13rem !important;
  }

  .my-52-fullhd {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }

  .-my-52-fullhd {
    margin-top: -13rem !important;
    margin-bottom: -13rem !important;
  }

  .m-56-fullhd {
    margin: 14rem !important;
  }

  .-m-56-fullhd {
    margin: -14rem !important;
  }

  .mt-56-fullhd {
    margin-top: 14rem !important;
  }

  .-mt-56-fullhd {
    margin-top: -14rem !important;
  }

  .mr-56-fullhd {
    margin-right: 14rem !important;
  }

  .-mr-56-fullhd {
    margin-right: -14rem !important;
  }

  .mb-56-fullhd {
    margin-bottom: 14rem !important;
  }

  .-mb-56-fullhd {
    margin-bottom: -14rem !important;
  }

  .ml-56-fullhd {
    margin-left: 14rem !important;
  }

  .-ml-56-fullhd {
    margin-left: -14rem !important;
  }

  .mx-56-fullhd {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .-mx-56-fullhd {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .my-56-fullhd {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .-my-56-fullhd {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .m-60-fullhd {
    margin: 15rem !important;
  }

  .-m-60-fullhd {
    margin: -15rem !important;
  }

  .mt-60-fullhd {
    margin-top: 15rem !important;
  }

  .-mt-60-fullhd {
    margin-top: -15rem !important;
  }

  .mr-60-fullhd {
    margin-right: 15rem !important;
  }

  .-mr-60-fullhd {
    margin-right: -15rem !important;
  }

  .mb-60-fullhd {
    margin-bottom: 15rem !important;
  }

  .-mb-60-fullhd {
    margin-bottom: -15rem !important;
  }

  .ml-60-fullhd {
    margin-left: 15rem !important;
  }

  .-ml-60-fullhd {
    margin-left: -15rem !important;
  }

  .mx-60-fullhd {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .-mx-60-fullhd {
    margin-left: -15rem !important;
    margin-right: -15rem !important;
  }

  .my-60-fullhd {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .-my-60-fullhd {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .m-64-fullhd {
    margin: 16rem !important;
  }

  .-m-64-fullhd {
    margin: -16rem !important;
  }

  .mt-64-fullhd {
    margin-top: 16rem !important;
  }

  .-mt-64-fullhd {
    margin-top: -16rem !important;
  }

  .mr-64-fullhd {
    margin-right: 16rem !important;
  }

  .-mr-64-fullhd {
    margin-right: -16rem !important;
  }

  .mb-64-fullhd {
    margin-bottom: 16rem !important;
  }

  .-mb-64-fullhd {
    margin-bottom: -16rem !important;
  }

  .ml-64-fullhd {
    margin-left: 16rem !important;
  }

  .-ml-64-fullhd {
    margin-left: -16rem !important;
  }

  .mx-64-fullhd {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .-mx-64-fullhd {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .my-64-fullhd {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .-my-64-fullhd {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .m-72-fullhd {
    margin: 18rem !important;
  }

  .-m-72-fullhd {
    margin: -18rem !important;
  }

  .mt-72-fullhd {
    margin-top: 18rem !important;
  }

  .-mt-72-fullhd {
    margin-top: -18rem !important;
  }

  .mr-72-fullhd {
    margin-right: 18rem !important;
  }

  .-mr-72-fullhd {
    margin-right: -18rem !important;
  }

  .mb-72-fullhd {
    margin-bottom: 18rem !important;
  }

  .-mb-72-fullhd {
    margin-bottom: -18rem !important;
  }

  .ml-72-fullhd {
    margin-left: 18rem !important;
  }

  .-ml-72-fullhd {
    margin-left: -18rem !important;
  }

  .mx-72-fullhd {
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .-mx-72-fullhd {
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .my-72-fullhd {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .-my-72-fullhd {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .m-80-fullhd {
    margin: 20rem !important;
  }

  .-m-80-fullhd {
    margin: -20rem !important;
  }

  .mt-80-fullhd {
    margin-top: 20rem !important;
  }

  .-mt-80-fullhd {
    margin-top: -20rem !important;
  }

  .mr-80-fullhd {
    margin-right: 20rem !important;
  }

  .-mr-80-fullhd {
    margin-right: -20rem !important;
  }

  .mb-80-fullhd {
    margin-bottom: 20rem !important;
  }

  .-mb-80-fullhd {
    margin-bottom: -20rem !important;
  }

  .ml-80-fullhd {
    margin-left: 20rem !important;
  }

  .-ml-80-fullhd {
    margin-left: -20rem !important;
  }

  .mx-80-fullhd {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .-mx-80-fullhd {
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .my-80-fullhd {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .-my-80-fullhd {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .m-96-fullhd {
    margin: 24rem !important;
  }

  .-m-96-fullhd {
    margin: -24rem !important;
  }

  .mt-96-fullhd {
    margin-top: 24rem !important;
  }

  .-mt-96-fullhd {
    margin-top: -24rem !important;
  }

  .mr-96-fullhd {
    margin-right: 24rem !important;
  }

  .-mr-96-fullhd {
    margin-right: -24rem !important;
  }

  .mb-96-fullhd {
    margin-bottom: 24rem !important;
  }

  .-mb-96-fullhd {
    margin-bottom: -24rem !important;
  }

  .ml-96-fullhd {
    margin-left: 24rem !important;
  }

  .-ml-96-fullhd {
    margin-left: -24rem !important;
  }

  .mx-96-fullhd {
    margin-left: 24rem !important;
    margin-right: 24rem !important;
  }

  .-mx-96-fullhd {
    margin-left: -24rem !important;
    margin-right: -24rem !important;
  }

  .my-96-fullhd {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }

  .-my-96-fullhd {
    margin-top: -24rem !important;
    margin-bottom: -24rem !important;
  }

  .p-auto-fullhd {
    padding: auto !important;
  }

  .pt-auto-fullhd {
    padding-top: auto !important;
  }

  .pr-auto-fullhd {
    padding-right: auto !important;
  }

  .pb-auto-fullhd {
    padding-bottom: auto !important;
  }

  .pl-auto-fullhd {
    padding-left: auto !important;
  }

  .px-auto-fullhd {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .py-auto-fullhd {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }

  .p-0-fullhd {
    padding: 0 !important;
  }

  .pt-0-fullhd {
    padding-top: 0 !important;
  }

  .pr-0-fullhd {
    padding-right: 0 !important;
  }

  .pb-0-fullhd {
    padding-bottom: 0 !important;
  }

  .pl-0-fullhd {
    padding-left: 0 !important;
  }

  .px-0-fullhd {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-0-fullhd {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-1-fullhd {
    padding: .25rem !important;
  }

  .pt-1-fullhd {
    padding-top: .25rem !important;
  }

  .pr-1-fullhd {
    padding-right: .25rem !important;
  }

  .pb-1-fullhd {
    padding-bottom: .25rem !important;
  }

  .pl-1-fullhd {
    padding-left: .25rem !important;
  }

  .px-1-fullhd {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .py-1-fullhd {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .p-2-fullhd {
    padding: .5rem !important;
  }

  .pt-2-fullhd {
    padding-top: .5rem !important;
  }

  .pr-2-fullhd {
    padding-right: .5rem !important;
  }

  .pb-2-fullhd {
    padding-bottom: .5rem !important;
  }

  .pl-2-fullhd {
    padding-left: .5rem !important;
  }

  .px-2-fullhd {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .py-2-fullhd {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .p-3-fullhd {
    padding: .75rem !important;
  }

  .pt-3-fullhd {
    padding-top: .75rem !important;
  }

  .pr-3-fullhd {
    padding-right: .75rem !important;
  }

  .pb-3-fullhd {
    padding-bottom: .75rem !important;
  }

  .pl-3-fullhd {
    padding-left: .75rem !important;
  }

  .px-3-fullhd {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .py-3-fullhd {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .p-4-fullhd {
    padding: 1rem !important;
  }

  .pt-4-fullhd {
    padding-top: 1rem !important;
  }

  .pr-4-fullhd {
    padding-right: 1rem !important;
  }

  .pb-4-fullhd {
    padding-bottom: 1rem !important;
  }

  .pl-4-fullhd {
    padding-left: 1rem !important;
  }

  .px-4-fullhd {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .py-4-fullhd {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .p-5-fullhd {
    padding: 1.25rem !important;
  }

  .pt-5-fullhd {
    padding-top: 1.25rem !important;
  }

  .pr-5-fullhd {
    padding-right: 1.25rem !important;
  }

  .pb-5-fullhd {
    padding-bottom: 1.25rem !important;
  }

  .pl-5-fullhd {
    padding-left: 1.25rem !important;
  }

  .px-5-fullhd {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .py-5-fullhd {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .p-6-fullhd {
    padding: 1.5rem !important;
  }

  .pt-6-fullhd {
    padding-top: 1.5rem !important;
  }

  .pr-6-fullhd {
    padding-right: 1.5rem !important;
  }

  .pb-6-fullhd {
    padding-bottom: 1.5rem !important;
  }

  .pl-6-fullhd {
    padding-left: 1.5rem !important;
  }

  .px-6-fullhd {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .py-6-fullhd {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .p-7-fullhd {
    padding: 1.75rem !important;
  }

  .pt-7-fullhd {
    padding-top: 1.75rem !important;
  }

  .pr-7-fullhd {
    padding-right: 1.75rem !important;
  }

  .pb-7-fullhd {
    padding-bottom: 1.75rem !important;
  }

  .pl-7-fullhd {
    padding-left: 1.75rem !important;
  }

  .px-7-fullhd {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .py-7-fullhd {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .p-8-fullhd {
    padding: 2rem !important;
  }

  .pt-8-fullhd {
    padding-top: 2rem !important;
  }

  .pr-8-fullhd {
    padding-right: 2rem !important;
  }

  .pb-8-fullhd {
    padding-bottom: 2rem !important;
  }

  .pl-8-fullhd {
    padding-left: 2rem !important;
  }

  .px-8-fullhd {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .py-8-fullhd {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .p-9-fullhd {
    padding: 2.25rem !important;
  }

  .pt-9-fullhd {
    padding-top: 2.25rem !important;
  }

  .pr-9-fullhd {
    padding-right: 2.25rem !important;
  }

  .pb-9-fullhd {
    padding-bottom: 2.25rem !important;
  }

  .pl-9-fullhd {
    padding-left: 2.25rem !important;
  }

  .px-9-fullhd {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .py-9-fullhd {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .p-10-fullhd {
    padding: 2.5rem !important;
  }

  .pt-10-fullhd {
    padding-top: 2.5rem !important;
  }

  .pr-10-fullhd {
    padding-right: 2.5rem !important;
  }

  .pb-10-fullhd {
    padding-bottom: 2.5rem !important;
  }

  .pl-10-fullhd {
    padding-left: 2.5rem !important;
  }

  .px-10-fullhd {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .py-10-fullhd {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .p-11-fullhd {
    padding: 2.75rem !important;
  }

  .pt-11-fullhd {
    padding-top: 2.75rem !important;
  }

  .pr-11-fullhd {
    padding-right: 2.75rem !important;
  }

  .pb-11-fullhd {
    padding-bottom: 2.75rem !important;
  }

  .pl-11-fullhd {
    padding-left: 2.75rem !important;
  }

  .px-11-fullhd {
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  .py-11-fullhd {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .p-12-fullhd {
    padding: 3rem !important;
  }

  .pt-12-fullhd {
    padding-top: 3rem !important;
  }

  .pr-12-fullhd {
    padding-right: 3rem !important;
  }

  .pb-12-fullhd {
    padding-bottom: 3rem !important;
  }

  .pl-12-fullhd {
    padding-left: 3rem !important;
  }

  .px-12-fullhd {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-12-fullhd {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .p-14-fullhd {
    padding: 3.5rem !important;
  }

  .pt-14-fullhd {
    padding-top: 3.5rem !important;
  }

  .pr-14-fullhd {
    padding-right: 3.5rem !important;
  }

  .pb-14-fullhd {
    padding-bottom: 3.5rem !important;
  }

  .pl-14-fullhd {
    padding-left: 3.5rem !important;
  }

  .px-14-fullhd {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .py-14-fullhd {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .p-16-fullhd {
    padding: 4rem !important;
  }

  .pt-16-fullhd {
    padding-top: 4rem !important;
  }

  .pr-16-fullhd {
    padding-right: 4rem !important;
  }

  .pb-16-fullhd {
    padding-bottom: 4rem !important;
  }

  .pl-16-fullhd {
    padding-left: 4rem !important;
  }

  .px-16-fullhd {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .py-16-fullhd {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .p-20-fullhd {
    padding: 5rem !important;
  }

  .pt-20-fullhd {
    padding-top: 5rem !important;
  }

  .pr-20-fullhd {
    padding-right: 5rem !important;
  }

  .pb-20-fullhd {
    padding-bottom: 5rem !important;
  }

  .pl-20-fullhd {
    padding-left: 5rem !important;
  }

  .px-20-fullhd {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .py-20-fullhd {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .p-24-fullhd {
    padding: 6rem !important;
  }

  .pt-24-fullhd {
    padding-top: 6rem !important;
  }

  .pr-24-fullhd {
    padding-right: 6rem !important;
  }

  .pb-24-fullhd {
    padding-bottom: 6rem !important;
  }

  .pl-24-fullhd {
    padding-left: 6rem !important;
  }

  .px-24-fullhd {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .py-24-fullhd {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .p-28-fullhd {
    padding: 7rem !important;
  }

  .pt-28-fullhd {
    padding-top: 7rem !important;
  }

  .pr-28-fullhd {
    padding-right: 7rem !important;
  }

  .pb-28-fullhd {
    padding-bottom: 7rem !important;
  }

  .pl-28-fullhd {
    padding-left: 7rem !important;
  }

  .px-28-fullhd {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .py-28-fullhd {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .p-32-fullhd {
    padding: 8rem !important;
  }

  .pt-32-fullhd {
    padding-top: 8rem !important;
  }

  .pr-32-fullhd {
    padding-right: 8rem !important;
  }

  .pb-32-fullhd {
    padding-bottom: 8rem !important;
  }

  .pl-32-fullhd {
    padding-left: 8rem !important;
  }

  .px-32-fullhd {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .py-32-fullhd {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .p-36-fullhd {
    padding: 9rem !important;
  }

  .pt-36-fullhd {
    padding-top: 9rem !important;
  }

  .pr-36-fullhd {
    padding-right: 9rem !important;
  }

  .pb-36-fullhd {
    padding-bottom: 9rem !important;
  }

  .pl-36-fullhd {
    padding-left: 9rem !important;
  }

  .px-36-fullhd {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .py-36-fullhd {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .p-40-fullhd {
    padding: 10rem !important;
  }

  .pt-40-fullhd {
    padding-top: 10rem !important;
  }

  .pr-40-fullhd {
    padding-right: 10rem !important;
  }

  .pb-40-fullhd {
    padding-bottom: 10rem !important;
  }

  .pl-40-fullhd {
    padding-left: 10rem !important;
  }

  .px-40-fullhd {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .py-40-fullhd {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .p-44-fullhd {
    padding: 11rem !important;
  }

  .pt-44-fullhd {
    padding-top: 11rem !important;
  }

  .pr-44-fullhd {
    padding-right: 11rem !important;
  }

  .pb-44-fullhd {
    padding-bottom: 11rem !important;
  }

  .pl-44-fullhd {
    padding-left: 11rem !important;
  }

  .px-44-fullhd {
    padding-left: 11rem !important;
    padding-right: 11rem !important;
  }

  .py-44-fullhd {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .p-48-fullhd {
    padding: 12rem !important;
  }

  .pt-48-fullhd {
    padding-top: 12rem !important;
  }

  .pr-48-fullhd {
    padding-right: 12rem !important;
  }

  .pb-48-fullhd {
    padding-bottom: 12rem !important;
  }

  .pl-48-fullhd {
    padding-left: 12rem !important;
  }

  .px-48-fullhd {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .py-48-fullhd {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .p-52-fullhd {
    padding: 13rem !important;
  }

  .pt-52-fullhd {
    padding-top: 13rem !important;
  }

  .pr-52-fullhd {
    padding-right: 13rem !important;
  }

  .pb-52-fullhd {
    padding-bottom: 13rem !important;
  }

  .pl-52-fullhd {
    padding-left: 13rem !important;
  }

  .px-52-fullhd {
    padding-left: 13rem !important;
    padding-right: 13rem !important;
  }

  .py-52-fullhd {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }

  .p-56-fullhd {
    padding: 14rem !important;
  }

  .pt-56-fullhd {
    padding-top: 14rem !important;
  }

  .pr-56-fullhd {
    padding-right: 14rem !important;
  }

  .pb-56-fullhd {
    padding-bottom: 14rem !important;
  }

  .pl-56-fullhd {
    padding-left: 14rem !important;
  }

  .px-56-fullhd {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .py-56-fullhd {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .p-60-fullhd {
    padding: 15rem !important;
  }

  .pt-60-fullhd {
    padding-top: 15rem !important;
  }

  .pr-60-fullhd {
    padding-right: 15rem !important;
  }

  .pb-60-fullhd {
    padding-bottom: 15rem !important;
  }

  .pl-60-fullhd {
    padding-left: 15rem !important;
  }

  .px-60-fullhd {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .py-60-fullhd {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .p-64-fullhd {
    padding: 16rem !important;
  }

  .pt-64-fullhd {
    padding-top: 16rem !important;
  }

  .pr-64-fullhd {
    padding-right: 16rem !important;
  }

  .pb-64-fullhd {
    padding-bottom: 16rem !important;
  }

  .pl-64-fullhd {
    padding-left: 16rem !important;
  }

  .px-64-fullhd {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .py-64-fullhd {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .p-72-fullhd {
    padding: 18rem !important;
  }

  .pt-72-fullhd {
    padding-top: 18rem !important;
  }

  .pr-72-fullhd {
    padding-right: 18rem !important;
  }

  .pb-72-fullhd {
    padding-bottom: 18rem !important;
  }

  .pl-72-fullhd {
    padding-left: 18rem !important;
  }

  .px-72-fullhd {
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .py-72-fullhd {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .p-80-fullhd {
    padding: 20rem !important;
  }

  .pt-80-fullhd {
    padding-top: 20rem !important;
  }

  .pr-80-fullhd {
    padding-right: 20rem !important;
  }

  .pb-80-fullhd {
    padding-bottom: 20rem !important;
  }

  .pl-80-fullhd {
    padding-left: 20rem !important;
  }

  .px-80-fullhd {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .py-80-fullhd {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .p-96-fullhd {
    padding: 24rem !important;
  }

  .pt-96-fullhd {
    padding-top: 24rem !important;
  }

  .pr-96-fullhd {
    padding-right: 24rem !important;
  }

  .pb-96-fullhd {
    padding-bottom: 24rem !important;
  }

  .pl-96-fullhd {
    padding-left: 24rem !important;
  }

  .px-96-fullhd {
    padding-left: 24rem !important;
    padding-right: 24rem !important;
  }

  .py-96-fullhd {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
}

.has-mw-0 {
  max-width: 0;
}

.has-mw-none {
  max-width: none;
}

.has-mw-xs {
  max-width: 20rem;
}

.has-mw-sm {
  max-width: 24rem;
}

.has-mw-md {
  max-width: 28rem;
}

.has-mw-lg {
  max-width: 32rem;
}

.has-mw-xl {
  max-width: 36rem;
}

.has-mw-2xl {
  max-width: 42rem;
}

.has-mw-3xl {
  max-width: 48rem;
}

.has-mw-4xl {
  max-width: 56rem;
}

.has-mw-5xl {
  max-width: 64rem;
}

.has-mw-6xl {
  max-width: 72rem;
}

.has-mw-7xl {
  max-width: 80rem;
}

@media screen and (width <= 768px) {
  .has-mw-0-mobile {
    max-width: 0;
  }

  .has-mw-none-mobile {
    max-width: none;
  }

  .has-mw-xs-mobile {
    max-width: 20rem;
  }

  .has-mw-sm-mobile {
    max-width: 24rem;
  }

  .has-mw-md-mobile {
    max-width: 28rem;
  }

  .has-mw-lg-mobile {
    max-width: 32rem;
  }

  .has-mw-xl-mobile {
    max-width: 36rem;
  }

  .has-mw-2xl-mobile {
    max-width: 42rem;
  }

  .has-mw-3xl-mobile {
    max-width: 48rem;
  }

  .has-mw-4xl-mobile {
    max-width: 56rem;
  }

  .has-mw-5xl-mobile {
    max-width: 64rem;
  }

  .has-mw-6xl-mobile {
    max-width: 72rem;
  }

  .has-mw-7xl-mobile {
    max-width: 80rem;
  }
}

@media screen and (width >= 769px), print {
  .has-mw-0-tablet {
    max-width: 0;
  }

  .has-mw-none-tablet {
    max-width: none;
  }

  .has-mw-xs-tablet {
    max-width: 20rem;
  }

  .has-mw-sm-tablet {
    max-width: 24rem;
  }

  .has-mw-md-tablet {
    max-width: 28rem;
  }

  .has-mw-lg-tablet {
    max-width: 32rem;
  }

  .has-mw-xl-tablet {
    max-width: 36rem;
  }

  .has-mw-2xl-tablet {
    max-width: 42rem;
  }

  .has-mw-3xl-tablet {
    max-width: 48rem;
  }

  .has-mw-4xl-tablet {
    max-width: 56rem;
  }

  .has-mw-5xl-tablet {
    max-width: 64rem;
  }

  .has-mw-6xl-tablet {
    max-width: 72rem;
  }

  .has-mw-7xl-tablet {
    max-width: 80rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-mw-0-tablet-only {
    max-width: 0;
  }

  .has-mw-none-tablet-only {
    max-width: none;
  }

  .has-mw-xs-tablet-only {
    max-width: 20rem;
  }

  .has-mw-sm-tablet-only {
    max-width: 24rem;
  }

  .has-mw-md-tablet-only {
    max-width: 28rem;
  }

  .has-mw-lg-tablet-only {
    max-width: 32rem;
  }

  .has-mw-xl-tablet-only {
    max-width: 36rem;
  }

  .has-mw-2xl-tablet-only {
    max-width: 42rem;
  }

  .has-mw-3xl-tablet-only {
    max-width: 48rem;
  }

  .has-mw-4xl-tablet-only {
    max-width: 56rem;
  }

  .has-mw-5xl-tablet-only {
    max-width: 64rem;
  }

  .has-mw-6xl-tablet-only {
    max-width: 72rem;
  }

  .has-mw-7xl-tablet-only {
    max-width: 80rem;
  }
}

@media screen and (width <= 1023px) {
  .has-mw-0-touch {
    max-width: 0;
  }

  .has-mw-none-touch {
    max-width: none;
  }

  .has-mw-xs-touch {
    max-width: 20rem;
  }

  .has-mw-sm-touch {
    max-width: 24rem;
  }

  .has-mw-md-touch {
    max-width: 28rem;
  }

  .has-mw-lg-touch {
    max-width: 32rem;
  }

  .has-mw-xl-touch {
    max-width: 36rem;
  }

  .has-mw-2xl-touch {
    max-width: 42rem;
  }

  .has-mw-3xl-touch {
    max-width: 48rem;
  }

  .has-mw-4xl-touch {
    max-width: 56rem;
  }

  .has-mw-5xl-touch {
    max-width: 64rem;
  }

  .has-mw-6xl-touch {
    max-width: 72rem;
  }

  .has-mw-7xl-touch {
    max-width: 80rem;
  }
}

@media screen and (width >= 1024px) {
  .has-mw-0-desktop {
    max-width: 0;
  }

  .has-mw-none-desktop {
    max-width: none;
  }

  .has-mw-xs-desktop {
    max-width: 20rem;
  }

  .has-mw-sm-desktop {
    max-width: 24rem;
  }

  .has-mw-md-desktop {
    max-width: 28rem;
  }

  .has-mw-lg-desktop {
    max-width: 32rem;
  }

  .has-mw-xl-desktop {
    max-width: 36rem;
  }

  .has-mw-2xl-desktop {
    max-width: 42rem;
  }

  .has-mw-3xl-desktop {
    max-width: 48rem;
  }

  .has-mw-4xl-desktop {
    max-width: 56rem;
  }

  .has-mw-5xl-desktop {
    max-width: 64rem;
  }

  .has-mw-6xl-desktop {
    max-width: 72rem;
  }

  .has-mw-7xl-desktop {
    max-width: 80rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-mw-0-desktop-only {
    max-width: 0;
  }

  .has-mw-none-desktop-only {
    max-width: none;
  }

  .has-mw-xs-desktop-only {
    max-width: 20rem;
  }

  .has-mw-sm-desktop-only {
    max-width: 24rem;
  }

  .has-mw-md-desktop-only {
    max-width: 28rem;
  }

  .has-mw-lg-desktop-only {
    max-width: 32rem;
  }

  .has-mw-xl-desktop-only {
    max-width: 36rem;
  }

  .has-mw-2xl-desktop-only {
    max-width: 42rem;
  }

  .has-mw-3xl-desktop-only {
    max-width: 48rem;
  }

  .has-mw-4xl-desktop-only {
    max-width: 56rem;
  }

  .has-mw-5xl-desktop-only {
    max-width: 64rem;
  }

  .has-mw-6xl-desktop-only {
    max-width: 72rem;
  }

  .has-mw-7xl-desktop-only {
    max-width: 80rem;
  }
}

@media screen and (width >= 1216px) {
  .has-mw-0-widescreen {
    max-width: 0;
  }

  .has-mw-none-widescreen {
    max-width: none;
  }

  .has-mw-xs-widescreen {
    max-width: 20rem;
  }

  .has-mw-sm-widescreen {
    max-width: 24rem;
  }

  .has-mw-md-widescreen {
    max-width: 28rem;
  }

  .has-mw-lg-widescreen {
    max-width: 32rem;
  }

  .has-mw-xl-widescreen {
    max-width: 36rem;
  }

  .has-mw-2xl-widescreen {
    max-width: 42rem;
  }

  .has-mw-3xl-widescreen {
    max-width: 48rem;
  }

  .has-mw-4xl-widescreen {
    max-width: 56rem;
  }

  .has-mw-5xl-widescreen {
    max-width: 64rem;
  }

  .has-mw-6xl-widescreen {
    max-width: 72rem;
  }

  .has-mw-7xl-widescreen {
    max-width: 80rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-mw-0-widescreen-only {
    max-width: 0;
  }

  .has-mw-none-widescreen-only {
    max-width: none;
  }

  .has-mw-xs-widescreen-only {
    max-width: 20rem;
  }

  .has-mw-sm-widescreen-only {
    max-width: 24rem;
  }

  .has-mw-md-widescreen-only {
    max-width: 28rem;
  }

  .has-mw-lg-widescreen-only {
    max-width: 32rem;
  }

  .has-mw-xl-widescreen-only {
    max-width: 36rem;
  }

  .has-mw-2xl-widescreen-only {
    max-width: 42rem;
  }

  .has-mw-3xl-widescreen-only {
    max-width: 48rem;
  }

  .has-mw-4xl-widescreen-only {
    max-width: 56rem;
  }

  .has-mw-5xl-widescreen-only {
    max-width: 64rem;
  }

  .has-mw-6xl-widescreen-only {
    max-width: 72rem;
  }

  .has-mw-7xl-widescreen-only {
    max-width: 80rem;
  }
}

@media screen and (width >= 1408px) {
  .has-mw-0-fullhd {
    max-width: 0;
  }

  .has-mw-none-fullhd {
    max-width: none;
  }

  .has-mw-xs-fullhd {
    max-width: 20rem;
  }

  .has-mw-sm-fullhd {
    max-width: 24rem;
  }

  .has-mw-md-fullhd {
    max-width: 28rem;
  }

  .has-mw-lg-fullhd {
    max-width: 32rem;
  }

  .has-mw-xl-fullhd {
    max-width: 36rem;
  }

  .has-mw-2xl-fullhd {
    max-width: 42rem;
  }

  .has-mw-3xl-fullhd {
    max-width: 48rem;
  }

  .has-mw-4xl-fullhd {
    max-width: 56rem;
  }

  .has-mw-5xl-fullhd {
    max-width: 64rem;
  }

  .has-mw-6xl-fullhd {
    max-width: 72rem;
  }

  .has-mw-7xl-fullhd {
    max-width: 80rem;
  }
}

body .content figure {
  margin-left: 0;
  margin-right: 0;
}

body .content figure img {
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 89%, 58% 89%, 33% 65%, 0 65%);
  clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 89%, 58% 89%, 33% 65%, 0 65%);
  margin-bottom: -7em;
}

h4 {
  font-size: 1.25em;
  font-weight: 800;
}

placeholder {
  color: #222;
}

.content h2:not(:first-child) {
  color: #0196ff;
  text-shadow: 1px 1px #050042, 2px 2px #050042;
}

@keyframes rotateGradient {
  0% {
    background-position: 100% 100%;
  }

  to {
    background-position: 0 0;
  }
}

.column.is-fixed-width {
  flex: 1;
  max-width: 100%;
}

@media screen and (width >= 781px) {
  .column.is-fixed-width {
    flex: none;
    width: 780px;
    max-width: 780px;
  }
}

.valign-middle {
  vertical-align: middle;
}

#logo span {
  white-space: nowrap;
  font-size: .8em;
  line-height: 5rem;
}

.above-title {
  font-style: oblique 35deg;
  font-weight: 800;
}

a:hover {
  text-shadow: none;
}

strong, h1, h2, h3 {
  font-style: oblique 35deg;
}

ul {
  list-style: circle;
}

.title, .has-background-white .hero-title, h1, h2, h3 {
  word-break: normal;
}

.subtitle {
  margin-bottom: 1em;
  font-style: oblique 35deg;
}

.table-of-contents ul {
  margin-left: .25em;
}

.table-of-contents li {
  margin-left: 0;
}

.has-background-dark, .has-background-dark a, .has-background-dark strong {
  color: #fff;
}

.has-background-dark .hero-title {
  text-shadow: 1px 1px #050042;
  background-image: linear-gradient(to bottom right, #004bff 50%, #fff 50%);
  background-size: 100% 150%;
  -webkit-background-clip: text;
  background-clip: text;
  font-style: oblique 35deg;
  font-weight: 800;
  animation: 1s ease-in-out forwards rotateGradient;
}

.has-background-dark .subtitle {
  text-shadow: 1px 1px #050042;
  color: #fff;
  margin-bottom: 0;
}

.has-background-dark .navbar button {
  padding-top: 3.5em;
}

.has-background-dark .navbar-item {
  text-shadow: 1px 1px #050042;
}

.has-background-dark .button-call-to-action {
  text-shadow: 1px 1px #050042;
  color: #fff;
  opacity: 1;
  background-color: #004bff;
  background-image: linear-gradient(to bottom right, #00f3 50%, #0000 50%);
  background-size: 100% 150%;
  border-color: #004bff;
  border-radius: 9px;
  font-size: 1.2rem;
  font-style: oblique 35deg;
  font-weight: 800;
  animation: 1s ease-in-out forwards rotateGradient;
}

.has-background-dark .button-call-to-action:hover {
  color: #fff;
  opacity: .8;
}

.has-background-dark h2, .has-background-dark h3 {
  text-shadow: 1px 1px #050042;
}

a:hover {
  text-shadow: none;
  text-decoration: underline;
}

.has-background-white .hero-title {
  text-shadow: 1px 1px #050042, 2px 2px #050042, 3px 3px #050042, 4px 4px #050042;
  color: #0196ff;
  background-image: linear-gradient(to bottom right, #004bff 50% 50%);
  background-size: 100% 150%;
  -webkit-background-clip: text;
  background-clip: text;
  font-style: oblique 35deg;
  font-weight: 800;
  animation: 1s ease-in-out forwards rotateGradient;
}

.table-of-contents ul {
  margin-left: 0;
  padding-left: .25em;
  list-style-type: disc;
}

.table-of-contents ul > li > ul, .table-of-contents ul > li > ul > li > ul, .table-of-contents ul ul ul {
  margin-left: 1em;
}

.hero-body {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.hero.is-fullheight-with-navbar {
  min-height: calc(100vh - 12rem);
}
/*# sourceMappingURL=index.4390773f.css.map */
